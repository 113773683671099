import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import {gsap, TimelineMax} from "gsap";
import {SplitText} from "gsap/SplitText";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

import {Footer} from "../components/Footer";
import {Carousel} from "../components/Carousel";

export class Home extends Component {

    constructor(props) {
        super(props);
        // reference to the DOM node
        this.homePreloadTitle = null;
        this.pageUrl = 'https://shelf.ramir.space/backend/pages?json=1';
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
        };
        this.chapterCarousel = [];
    }

    componentDidMount() {

        gsap.registerPlugin(SplitText);
        gsap.registerPlugin(MorphSVGPlugin);
        gsap.registerPlugin(ScrollTrigger);

        const myThis = this;

        fetch(myThis.pageUrl)
            .then(res => res.json())
            .then(
                (result) => {

                    document.querySelector('#PraviWrapper #backHome').innerHTML= '';

                    myThis.setState({
                        isLoaded: true,
                        data: result,
                    });

                    document.querySelector('header').classList.remove('collapsed');

                    // Scrollbar
                    SmoothScrollbar.use(OverscrollPlugin);

                    let scrollPositionX = 0,
                        scrollPositionY = 0,
                        scrollContainer = document.querySelector('.fadePage div'),
                        ScrollbarOptions = {
                        damping: 0.05,
                        thumbMinSize: 10,
                        renderByPixels: true,
                        alwaysShowTracks: false,
                        continuousScrolling: true,
                        plugins: {
                            overscroll: {
                                effect: 'bounce',
                                damping: 0.1,
                                maxOverscroll: 150,
                                glowColor: '#0a1033'
                            },
                        },
                    };

                    let scrollBarRoot = SmoothScrollbar.init(scrollContainer, ScrollbarOptions);

                    scrollBarRoot.addListener(({ offset }) => {
                        scrollPositionX = offset.x;
                        scrollPositionY = offset.y;
                    });

                    scrollBarRoot.setPosition(0,0);
                    scrollBarRoot.track.xAxis.element.remove();

                    ScrollTrigger.scrollerProxy(scrollContainer, {
                        scrollTop(value) {
                            if (arguments.length) {
                                scrollBarRoot.scrollTop = value;
                            }
                            return scrollBarRoot.scrollTop;
                        }
                    });

                    scrollBarRoot.addListener(ScrollTrigger.update);

                    //EOF. Smooth Scrollbar


                    //PRAVI
                    let deltaY,
                        currentScroll = 0,
                        menuOpened = false;

                    scrollBarRoot.addListener((s) => {

                        deltaY = -1

                        if(currentScroll < s.offset.y){
                            deltaY = 1
                        }

                        currentScroll = s.offset.y;

                        menuOpened = document.body.classList.contains('menuOpened') ? true : false;

                        if(!menuOpened){

                            if(currentScroll > 0){

                                if (deltaY < 0){
                                    document.querySelector('header').classList.remove('collapsed');
                                } else {
                                    document.querySelector('header').classList.add('collapsed');
                                }

                            } else {

                                document.querySelector('header').classList.remove('collapsed');

                            }

                        }

                    });

                    //EOF. PRAVI


                    //LOADING
                    let main = document.querySelector('main');

                    if(main.classList.contains('loading')){

                        //TimeLine
                        let loadingTL = gsap.timeline(),
                            splitTitleH1 = new SplitText('#mainTitle h1', {type: "words"}),
                            splitTitleH2 = new SplitText('#mainTitle h2', {type: "words"});

                        loadingTL
                            .fromTo('header', 1, {y: -100}, {y: 0})
                            .fromTo('#socialFixedRight', 1, {right: '-100px'}, {right: 0}, .5)
                            .fromTo('#morph', 1, {opacity: 0, scale: 0.5}, {opacity: 1, scale: 1}, 0.25)
                            .from(splitTitleH1.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.25}, .5)
                            .from(splitTitleH2.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.25}, 1)

                        document.querySelectorAll('#chaptersCards .col-4-12').forEach(function (card, index) {

                            if(index < 3){
                                loadingTL.fromTo(card, 1, {y: 100}, {y: 0}, .5 + index/5)
                            }

                        });

                        loadingTL.to(main, 0, {onComplete: function () {
                            main.classList.remove('loading');
                            }})


                    }

                    document.body.classList.remove('white', 'section1', 'section2', 'section3', 'section4', 'section5', 'article', 'test', 'game');
                    document.querySelector('header').classList.remove('collapsed');

                    let tlMorph1 = new TimelineMax({repeat: -1, yoyo: true});
                    let tlMorph2 = new TimelineMax({repeat: -1, yoyo: true});
                    let tlMorph3 = new TimelineMax({repeat: -1, yoyo: true});
                    let tlMorph4 = new TimelineMax({repeat: -1, yoyo: true});
                    let tlMorph5 = new TimelineMax({repeat: -1, yoyo: true});

                    tlMorph1.to("#Form_01-1", 2,{delay: 2, morphSVG:"#Form_02-1"})
                        .to("#Form_01-1", 2, {delay: 2, morphSVG:"#Form_03-1"})
                        .to("#Form_01-1", 2, {delay: 2, morphSVG:"#Form_04-1"})
                        .to("#Form_01-1", 2, {delay: 2, morphSVG:"#Form_05-1"})
                        .to("#Form_01-1", 2, {delay: 2, morphSVG:"#Form_06-1"})
                        .to("#Form_01-1", 2, {delay: 2, morphSVG:"#Form_01-1"});

                    tlMorph2.to("#Form_02-2", 1.5,{delay: 1, morphSVG:"#Form_03-2"})
                        .to("#Form_02-2", 1.5, {delay: 1, morphSVG:"#Form_04-2"})
                        .to("#Form_02-2", 1.5, {delay: 1, morphSVG:"#Form_05-2"})
                        .to("#Form_02-2", 1.5, {delay: 1, morphSVG:"#Form_06-2"})
                        .to("#Form_02-2", 1.5, {delay: 1, morphSVG:"#Form_01-2"})
                        .to("#Form_02-2", 1.5, {delay: 1, morphSVG:"#Form_02-2"});

                    tlMorph3.to("#Form_03-3", 2.5,{delay: 2.5, morphSVG:"#Form_04-3"})
                        .to("#Form_03-3", 2.5, {delay: 2.5, morphSVG:"#Form_05-3"})
                        .to("#Form_03-3", 2.5, {delay: 2.5, morphSVG:"#Form_06-3"})
                        .to("#Form_03-3", 2.5, {delay: 2.5, morphSVG:"#Form_01-3"})
                        .to("#Form_03-3", 2.5, {delay: 2.5, morphSVG:"#Form_02-3"})
                        .to("#Form_03-3", 2.5, {delay: 2.5, morphSVG:"#Form_03-3"});

                    tlMorph4.to("#Form_04-4", 1.5,{delay: 1, morphSVG:"#Form_05-4"})
                        .to("#Form_04-4", 1.5, {delay: 1, morphSVG:"#Form_06-4"})
                        .to("#Form_04-4", 1.5, {delay: 1, morphSVG:"#Form_01-4"})
                        .to("#Form_04-4", 1.5, {delay: 1, morphSVG:"#Form_02-4"})
                        .to("#Form_04-4", 1.5, {delay: 1, morphSVG:"#Form_03-4"})
                        .to("#Form_04-4", 1.5, {delay: 1, morphSVG:"#Form_04-4"});

                    tlMorph5.to("#Form_05-5", 1.5,{delay: 1.5, morphSVG:"#Form_06-5"})
                        .to("#Form_05-5", 1.5, {delay: 1.5, morphSVG:"#Form_01-5"})
                        .to("#Form_05-5", 1.5, {delay: 1.5, morphSVG:"#Form_02-5"})
                        .to("#Form_05-5", 1.5, {delay: 1.5, morphSVG:"#Form_03-5"})
                        .to("#Form_05-5", 1.5, {delay: 1.5, morphSVG:"#Form_04-5"})
                        .to("#Form_05-5", 1.5, {delay: 1.5, morphSVG:"#Form_05-5"});

                    //Interaction
                    gsap.to("#mainTitle h1", {
                        scrollTrigger: {
                            trigger: "#mainTitle",
                            start: 100,
                            end: 500,
                            scrub: true,
                            //markers: true,
                            scroller: scrollContainer
                        },
                        y: -50,
                    });

                    gsap.to("#mainTitle h2", {
                        scrollTrigger: {
                            trigger: "#mainTitle",
                            start: 200,
                            end: 500,
                            scrub: true,
                            //markers: true,
                            scroller: scrollContainer
                        },
                        y: -50,
                    });

                    let cards = document.querySelectorAll('a.card');

                    cards.forEach(function (card, index) {

                        card.addEventListener('click', function (e) {

                            e.preventDefault();

                            let chapterID = this.getAttribute('href'),
                                goToChapter = document.querySelector(chapterID);

                            scrollBarRoot.scrollIntoView(goToChapter);

                        });

                        gsap.to(card, {
                            scrollTrigger: {
                                trigger: "#chaptersCards",
                                start: 200 + index*100,
                                end: 500 + index*100,
                                scrub: true,
                                scroller: scrollContainer
                            },
                            y: -50,
                        });

                    });


                    document.querySelectorAll('.chapterCarousel').forEach(function (carousel, index) {

                        gsap.to(carousel.querySelector('h3.chapterTitle'), {
                            scrollTrigger: {
                                trigger: carousel,
                                start: index*250 + " center",
                                end: index*250 + 500 + " center",
                                scrub: true,
                                scroller: scrollContainer,
                            },
                            y: -100,
                        });

                        gsap.to(carousel.querySelector('.carouselWrapper'), {
                            scrollTrigger: {
                                trigger: carousel,
                                start: index*250 + 100 + " center",
                                end: index*250 + 500 + " center",
                                scrub: true,
                                scroller: scrollContainer
                            },
                            y: -100,
                        });

                    });

                    gsap.to(document.querySelector('#chapter6 h3.chapterTitle'), {
                        scrollTrigger: {
                            trigger: document.querySelector('#chapter6'),
                            start: 6*250 + " center",
                            end: 6*250 + 500 + " center",
                            scrub: true,
                            scroller: scrollContainer,
                        },
                        y: -100,
                    });

                    gsap.to(document.querySelector('#chapter6 .itemCard'), {
                        scrollTrigger: {
                            trigger: document.querySelector('#chapter6'),
                            start: 6*250 + 100 + " center",
                            end: 6*250 + 500 + " center",
                            scrub: true,
                            scroller: scrollContainer
                        },
                        y: -100,
                    });

                    /* If we have hash in location, smoothscroll to hash object */

                    let chapterID = window.location.hash;

                    if(chapterID != ''){

                        let chapterObject = document.querySelector(chapterID)

                        if(chapterObject){

                            setTimeout(function (){
                                scrollBarRoot.scrollIntoView(chapterObject);
                            }, 2000)

                        }

                    }

                    /*document.querySelectorAll('footer ul.chapters li a').forEach(function (footerAnchor){

                        footerAnchor.addEventListener('click', function (e){

                            e.preventDefault();

                            console.log(this);

                        })

                    });*/

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    myThis.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    componentWillUnmount() {

        console.log('Destroy Home');

        //Scrollbar.destroy(document.querySelector('.fadePage div'));

    }

    render() {

        const { data, isLoaded } = this.state;

        const meta = {
            title: data.length == 0 ? '' : data.content.title,//data.content.title,
            description: data.length == 0 ? '' : data.content.description,
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: data.length == 0 ? '' : data.content.keywords
                }
            }
        };

        return(
            <DocumentMeta {...meta}>

                {isLoaded ? (

                <div id="wrapperHome" className="wrapper">

                    <div id="progress-bar"></div>

                    <div id="morph">
                        <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1115.69 572.03">
                            <defs>
                                <linearGradient x1="95.7594735%" y1="28.0498826%" x2="9.46231621%" y2="73.6804105%" id="linearGradient-1">
                                    <stop stopColor="#0A1033" offset="0%"></stop>
                                    <stop stopColor="#1F2859" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="100.784181%" y1="94.9752922%" x2="4.43760838%" y2="8.67813485%" id="linearGradient-2">
                                    <stop stopColor="#1F2859" offset="0%"></stop>
                                    <stop stopColor="#0A1033" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="95.7594735%" y1="3.65342702%" x2="9.46231621%" y2="100%" id="linearGradient-3">
                                    <stop stopColor="#1F2859" offset="0%"></stop>
                                    <stop stopColor="#0A1033" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="100.784181%" y1="94.9752922%" x2="4.43760838%" y2="8.67813485%" id="linearGradient-4">
                                    <stop stopColor="#1F2859" offset="0%"></stop>
                                    <stop stopColor="#0A1033" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="95.7594735%" y1="3.65342702%" x2="9.46231621%" y2="100%" id="linearGradient-5">
                                    <stop stopColor="#1F2859" offset="0%"></stop>
                                    <stop stopColor="#0A1033" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <title>main page_graphics</title>
                            <g id="Page-1">
                                <path id="Form_01-1" data-name="Form 01" className="cls-1"
                                      d="M644.32,455.65a63,63,0,0,1,88.6,9,63,63,0,1,0,97.63-79.59,63,63,0,0,1,97.63-79.58,63,63,0,0,0,88.6,9l48.81-39.79,48.82-39.8a63,63,0,1,0-79.59-97.62L986,177.1,937.2,216.89a63,63,0,0,1-88.61-9A63,63,0,0,0,751,287.46,63,63,0,0,1,653.34,367a63,63,0,1,0-9,88.61"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_02-1" data-name="Form 02" className="cls-1"
                                      d="M644.32,455.65a63,63,0,0,1,88.6,9,63,63,0,1,0,97.63-79.59,63,63,0,0,1,97.63-79.58,63,63,0,1,0,97.62-79.59,63,63,0,0,1,9-88.6,63,63,0,1,0-88.6-9,63,63,0,0,1-97.63,79.58,63,63,0,0,0-88.61-9l-48.81,39.79-48.81,39.8-48.81,39.79L564.73,358a63,63,0,0,0,79.59,97.63"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_03-1" data-name="Form 03" className="cls-2"
                                      d="M644.32,455.65a63,63,0,0,1,88.6,9,63,63,0,0,0,88.61,9l48.81-39.79,48.82-39.8L968,354.31l48.81-39.79a63,63,0,0,0,9-88.61,63,63,0,0,1,9-88.6,63,63,0,1,0-88.6-9,63,63,0,0,1-9,88.6l-48.82,39.79-48.81,39.8a63,63,0,0,1-88.61-9,63,63,0,0,0-88.6-9l-48.81,39.79L564.73,358a63,63,0,0,0,79.59,97.63"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_04-1" data-name="Form 04" className="cls-2"
                                      d="M723.9,553.27a63,63,0,0,0,9-88.6,63,63,0,0,1,97.63-79.59,63,63,0,0,0,88.61,9l0,0,0,0L968,354.31l48.81-39.79,0,0,0,0h0l48.82-39.8,48.81-39.79h0a63,63,0,1,0-79.59-97.62,63,63,0,0,1-88.6-9,63,63,0,0,0-97.63,79.58A63,63,0,1,1,751,287.46,63,63,0,1,0,653.34,367a63,63,0,0,1-9,88.61,63,63,0,1,0,79.58,97.62"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_05-1" data-name="Form 05" className="cls-2"
                                      d="M723.9,553.27a63,63,0,0,0,9-88.6,63,63,0,0,1,97.63-79.59,63,63,0,0,0,88.61,9L968,354.31l48.81-39.79h0l48.82-39.8,48.81-39.79h0a63,63,0,1,0-79.59-97.62L986,177.1,937.2,216.89a63,63,0,0,1-88.61-9,63,63,0,0,0-88.61-9l-48.81,39.79-48.81,39.8a63,63,0,0,0-9,88.6,63,63,0,0,1-9,88.61,63,63,0,1,0,79.58,97.62"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_06-1" data-name="Form 06" className="cls-2"
                                      d="M644.32,455.65a63,63,0,0,1,88.6,9,63,63,0,0,0,88.61,9l48.81-39.79,48.82-39.8a63,63,0,0,0,9-88.6,63,63,0,1,1,97.62-79.59,63,63,0,1,0,9-88.6,63,63,0,0,1-88.6-9,63,63,0,0,0-97.63,79.58A63,63,0,1,1,751,287.46a63,63,0,0,0-88.6-9l-48.81,39.79L564.73,358a63,63,0,0,0,79.59,97.63"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                            </g>
                            <g id="Page-1-3" data-name="Page-1">
                                <path id="Form_01-2" data-name="Form 01" className="cls-1"
                                      d="M905.7,532.8a28,28,0,0,1,39.57,2.34,28,28,0,0,0,41.91-37.23,28,28,0,1,1,41.91-37.23,28,28,0,0,0,39.57,2.34l21-18.61,21-18.62a28,28,0,0,0-37.23-41.91l-20.95,18.61-21,18.62a28,28,0,0,1-39.57-2.34A28,28,0,1,0,950,456,28,28,0,1,1,908,493.23a28,28,0,1,0-2.34,39.57"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                                <path id="Form_02-2" data-name="Form 02" className="cls-1"
                                      d="M905.7,532.8a28,28,0,0,1,39.57,2.34,28,28,0,0,0,41.91-37.23,28,28,0,1,1,41.91-37.23A28,28,0,0,0,1071,423.45a28,28,0,0,1,2.34-39.57,28,28,0,1,0-39.57-2.34,28,28,0,0,1-41.91,37.23,28,28,0,0,0-39.57-2.34l-21,18.62-21,18.61-21,18.62-20.95,18.61A28,28,0,1,0,905.7,532.8"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                                <path id="Form_03-2" data-name="Form 03" className="cls-2"
                                      d="M905.7,532.8a28,28,0,0,1,39.57,2.34,28,28,0,0,0,39.57,2.34l21-18.61,21-18.62,21-18.61,21-18.62a28,28,0,0,0,2.34-39.57,28,28,0,0,1,2.34-39.57,28,28,0,1,0-39.57-2.34,28,28,0,0,1-2.34,39.57l-21,18.62-21,18.61A28,28,0,0,1,950,456a28,28,0,0,0-39.57-2.34l-21,18.62-20.95,18.61A28,28,0,1,0,905.7,532.8"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                                <path id="Form_04-2" data-name="Form 04" className="cls-2"
                                      d="M942.93,574.71a28,28,0,0,0,2.34-39.57,28,28,0,1,1,41.91-37.23,28,28,0,0,0,39.57,2.34l0,0,0,0,21-18.61,21-18.62,0,0,0,0h0l21-18.62,21-18.61h0a28,28,0,0,0-37.23-41.91,28,28,0,0,1-39.57-2.34,28,28,0,1,0-41.91,37.23A28,28,0,0,1,950,456,28,28,0,0,0,908,493.23a28,28,0,0,1-2.34,39.57,28,28,0,1,0,37.23,41.91"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                                <path id="Form_05-2" data-name="Form 05" className="cls-2"
                                      d="M942.93,574.71a28,28,0,0,0,2.34-39.57,28,28,0,1,1,41.91-37.23,28,28,0,0,0,39.57,2.34l21-18.61,21-18.62h0l21-18.62,21-18.61h0a28,28,0,0,0-37.23-41.91l-20.95,18.61-21,18.62a28,28,0,0,1-39.57-2.34,28,28,0,0,0-39.57-2.34l-21,18.62-21,18.61A28,28,0,0,0,908,493.23a28,28,0,0,1-2.34,39.57,28,28,0,1,0,37.23,41.91"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                                <path id="Form_06-2" data-name="Form 06" className="cls-2"
                                      d="M905.7,532.8a28,28,0,0,1,39.57,2.34,28,28,0,0,0,39.57,2.34l21-18.61,21-18.62a28,28,0,0,0,2.34-39.57A28,28,0,1,1,1071,423.45a28,28,0,1,0,2.34-39.57,28,28,0,0,1-39.57-2.34,28,28,0,1,0-41.91,37.23A28,28,0,0,1,950,456a28,28,0,0,0-39.57-2.34l-21,18.62-20.95,18.61A28,28,0,1,0,905.7,532.8"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-2)"/>
                            </g>
                            <g id="Page-1-5" data-name="Page-1">
                                <path id="Form_01-3" data-name="Form 01" className="cls-1"
                                      d="M205.11,125a55.84,55.84,0,0,1,56.29,55.4,55.85,55.85,0,0,0,111.7-.9,55.85,55.85,0,1,1,111.69-.89A55.85,55.85,0,0,0,541.08,234l55.85-.45,55.84-.45a55.85,55.85,0,0,0-.89-111.69L596,121.9l-55.84.44A55.84,55.84,0,0,1,483.89,67a55.85,55.85,0,0,0-111.69.89,55.85,55.85,0,0,1-111.69.89,55.85,55.85,0,1,0-55.4,56.3"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-3)"/>
                                <path id="Form_02-3" data-name="Form 02" className="cls-1"
                                      d="M205.11,125a55.84,55.84,0,0,1,56.29,55.4,55.85,55.85,0,0,0,111.7-.9,55.85,55.85,0,1,1,111.69-.89,55.85,55.85,0,1,0,111.69-.9,55.84,55.84,0,0,1,55.4-56.29,55.85,55.85,0,1,0-56.3-55.4,55.85,55.85,0,0,1-111.69.9,55.84,55.84,0,0,0-56.29-55.4L371.75,12l-55.84.45-55.85.45-55.84.45A55.85,55.85,0,0,0,205.11,125"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-3)"/>
                                <path id="Form_03-3" data-name="Form 03" className="cls-2"
                                      d="M205.11,125a55.84,55.84,0,0,1,56.29,55.4,55.84,55.84,0,0,0,56.3,55.39l55.84-.44,55.85-.45,55.84-.45,55.85-.44a55.85,55.85,0,0,0,55.4-56.3,55.84,55.84,0,0,1,55.4-56.29,55.85,55.85,0,1,0-56.3-55.4,55.83,55.83,0,0,1-55.39,56.29l-55.85.45-55.85.45a55.85,55.85,0,0,1-56.29-55.4,55.85,55.85,0,0,0-56.29-55.4l-55.85.45-55.84.45A55.85,55.85,0,0,0,205.11,125"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-3)"/>
                                <path id="Form_04-3" data-name="Form 04" className="cls-2"
                                      d="M206,236.72a55.84,55.84,0,0,0,55.39-56.29,55.85,55.85,0,1,1,111.7-.9,55.84,55.84,0,0,0,56.29,55.4h0l55.84-.45,55.85-.44h0l55.85-.45,55.84-.45h0a55.85,55.85,0,0,0-.89-111.69,55.85,55.85,0,0,1-56.3-55.4,55.85,55.85,0,1,0-111.69.9,55.85,55.85,0,1,1-111.69.89,55.85,55.85,0,1,0-111.69.89,55.85,55.85,0,0,1-55.4,56.3,55.85,55.85,0,1,0,.9,111.69"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-3)"/>
                                <path id="Form_05-3" data-name="Form 05" className="cls-2"
                                      d="M206,236.72a55.84,55.84,0,0,0,55.39-56.29,55.85,55.85,0,1,1,111.7-.9,55.84,55.84,0,0,0,56.29,55.4l55.84-.45,55.85-.44h0l55.85-.45,55.84-.45h0a55.85,55.85,0,0,0-.89-111.69L596,121.9l-55.84.44A55.84,55.84,0,0,1,483.89,67a55.84,55.84,0,0,0-56.29-55.4L371.75,12l-55.84.45a55.84,55.84,0,0,0-55.4,56.29,55.85,55.85,0,0,1-55.4,56.3,55.85,55.85,0,1,0,.9,111.69"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-1)"/>
                                <path id="Form_06-3" data-name="Form 06" className="cls-2"
                                      d="M205.11,125a55.84,55.84,0,0,1,56.29,55.4,55.84,55.84,0,0,0,56.3,55.39l55.84-.44,55.85-.45a55.86,55.86,0,0,0,55.4-56.29,55.85,55.85,0,1,1,111.69-.9,55.85,55.85,0,1,0,55.4-56.29,55.85,55.85,0,0,1-56.3-55.4,55.85,55.85,0,1,0-111.69.9,55.85,55.85,0,1,1-111.69.89,55.85,55.85,0,0,0-56.29-55.4l-55.85.45-55.84.45A55.85,55.85,0,0,0,205.11,125"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-3)"/>
                            </g>
                            <g id="Page-1-7" data-name="Page-1">
                                <path id="Form_01-4" data-name="Form 01" className="cls-1"
                                      d="M253.93,515.17A30,30,0,0,1,296,521a30,30,0,1,0,47.8-36.22,30,30,0,0,1,47.81-36.23,30,30,0,0,0,42,5.79l23.9-18.12,23.9-18.11a30,30,0,1,0-36.23-47.81l-23.9,18.12-23.9,18.11a30,30,0,0,1-42-5.79,30,30,0,1,0-47.8,36.23,30,30,0,1,1-47.81,36.23,30,30,0,1,0-5.79,42"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                                <path id="Form_02-4" data-name="Form 02" className="cls-1"
                                      d="M253.93,515.17A30,30,0,0,1,296,521a30,30,0,1,0,47.8-36.22,30,30,0,0,1,47.81-36.23,30,30,0,1,0,47.8-36.23,30,30,0,0,1,5.79-42,30,30,0,1,0-42-5.79,30,30,0,1,1-47.81,36.23,30,30,0,0,0-42-5.79L289.41,413l-23.9,18.11-23.9,18.12L217.7,467.36a30,30,0,0,0,36.23,47.81"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                                <path id="Form_03-4" data-name="Form 03" className="cls-2"
                                      d="M253.93,515.17A30,30,0,0,1,296,521a30,30,0,0,0,42,5.79l23.9-18.11,23.9-18.12,23.9-18.11,23.91-18.11a30,30,0,0,0,5.78-42,30,30,0,0,1,5.79-42,30,30,0,1,0-42-5.79,30,30,0,0,1-5.79,42l-23.91,18.11-23.9,18.12a30,30,0,0,1-42-5.79,30,30,0,0,0-42-5.79l-23.9,18.12L217.7,467.36a30,30,0,0,0,36.23,47.81"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                                <path id="Form_04-4" data-name="Form 04" className="cls-2"
                                      d="M290.16,563A30,30,0,0,0,296,521a30,30,0,0,1,47.8-36.22,30,30,0,0,0,42,5.78h0l23.9-18.11,23.91-18.11h0l23.9-18.11,23.9-18.11h0a30,30,0,1,0-36.23-47.81,30,30,0,0,1-42-5.79,30,30,0,0,0-47.81,36.23,30,30,0,1,1-47.8,36.23,30,30,0,0,0-47.81,36.23,30,30,0,0,1-5.79,42A30,30,0,1,0,290.16,563"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                                <path id="Form_05-4" data-name="Form 05" className="cls-2"
                                      d="M290.16,563A30,30,0,0,0,296,521a30,30,0,0,1,47.8-36.22,30,30,0,0,0,42,5.78l23.9-18.11,23.91-18.11h0l23.9-18.11,23.9-18.11h0a30,30,0,1,0-36.23-47.81l-23.9,18.12-23.9,18.11a30,30,0,0,1-42-5.79,30,30,0,0,0-42-5.79L289.41,413l-23.9,18.11a30,30,0,0,0-5.79,42,30,30,0,0,1-5.79,42A30,30,0,1,0,290.16,563"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                                <path id="Form_06-4" data-name="Form 06" className="cls-2"
                                      d="M253.93,515.17A30,30,0,0,1,296,521a30,30,0,0,0,42,5.79l23.9-18.11,23.9-18.12a30,30,0,0,0,5.79-42,30,30,0,1,1,47.8-36.23,30,30,0,1,0,5.79-42,30,30,0,0,1-42-5.79,30,30,0,0,0-47.81,36.23,30,30,0,1,1-47.8,36.23,30,30,0,0,0-42-5.79l-23.9,18.12L217.7,467.36a30,30,0,0,0,36.23,47.81"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-4)"/>
                            </g>
                            <g id="Page-1-9" data-name="Page-1">
                                <path id="Form_01-5" data-name="Form 01" className="cls-1"
                                      d="M83,401.38a38.09,38.09,0,0,1,53.38,7.36,38.11,38.11,0,0,0,60.74-46,38.1,38.1,0,1,1,60.73-46A38.09,38.09,0,0,0,311.25,324l30.37-23L372,278a38.11,38.11,0,0,0-46-60.74l-30.37,23-30.37,23A38.1,38.1,0,0,1,211.84,256a38.1,38.1,0,1,0-60.73,46,38.11,38.11,0,0,1-60.74,46A38.1,38.1,0,1,0,83,401.38"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                                <path id="Form_02-5" data-name="Form 02" className="cls-1"
                                      d="M83,401.38a38.09,38.09,0,0,1,53.38,7.36,38.11,38.11,0,0,0,60.74-46,38.1,38.1,0,1,1,60.73-46,38.11,38.11,0,0,0,60.74-46A38.1,38.1,0,0,1,326,217.27a38.1,38.1,0,1,0-53.38-7.35,38.11,38.11,0,0,1-60.74,46,38.09,38.09,0,0,0-53.38-7.36l-30.37,23-30.36,23-30.37,23L37,340.65a38.1,38.1,0,0,0,46,60.73"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                                <path id="Form_03-5" data-name="Form 03" className="cls-2"
                                      d="M83,401.38a38.09,38.09,0,0,1,53.38,7.36,38.1,38.1,0,0,0,53.38,7.35l30.37-23,30.37-23,30.36-23,30.37-23a38.1,38.1,0,0,0,7.36-53.38A38.1,38.1,0,0,1,326,217.27a38.1,38.1,0,1,0-53.38-7.35,38.09,38.09,0,0,1-7.36,53.38l-30.36,23-30.37,23A38.09,38.09,0,0,1,151.11,302a38.1,38.1,0,0,0-53.38-7.35l-30.37,23L37,340.65a38.1,38.1,0,0,0,46,60.73"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                                <path id="Form_04-5" data-name="Form 04" className="cls-2"
                                      d="M129.05,462.12a38.1,38.1,0,0,0,7.35-53.38,38.11,38.11,0,1,1,60.74-46,38.1,38.1,0,0,0,53.38,7.35l0,0,0,0,30.36-23,30.37-23,0,0,0,0h0l30.37-23L372,278h0a38.11,38.11,0,0,0-46-60.74,38.1,38.1,0,0,1-53.38-7.35,38.11,38.11,0,1,0-60.74,46,38.1,38.1,0,1,1-60.73,46,38.11,38.11,0,0,0-60.74,46A38.1,38.1,0,0,1,83,401.38a38.11,38.11,0,0,0,46,60.74"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                                <path id="Form_05-5" data-name="Form 05" className="cls-2"
                                      d="M129.05,462.12a38.1,38.1,0,0,0,7.35-53.38,38.11,38.11,0,1,1,60.74-46,38.1,38.1,0,0,0,53.38,7.35l30.36-23,30.37-23h0l30.37-23L372,278h0a38.11,38.11,0,0,0-46-60.74l-30.37,23-30.37,23A38.1,38.1,0,0,1,211.84,256a38.09,38.09,0,0,0-53.38-7.36l-30.37,23-30.36,23A38.1,38.1,0,0,0,90.37,348,38.1,38.1,0,0,1,83,401.38a38.11,38.11,0,0,0,46,60.74"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                                <path id="Form_06-5" data-name="Form 06" className="cls-2"
                                      d="M83,401.38a38.09,38.09,0,0,1,53.38,7.36,38.1,38.1,0,0,0,53.38,7.35l30.37-23,30.37-23a38.1,38.1,0,0,0,7.35-53.38,38.11,38.11,0,0,1,60.74-46A38.1,38.1,0,1,0,326,217.27a38.1,38.1,0,0,1-53.38-7.35,38.11,38.11,0,1,0-60.74,46,38.1,38.1,0,1,1-60.73,46,38.1,38.1,0,0,0-53.38-7.35l-30.37,23L37,340.65a38.1,38.1,0,0,0,46,60.73"
                                      transform="translate(-21.9 -9.76)" fill="url(#linearGradient-5)"/>
                            </g>
                        </svg>
                    </div>
                    <div id="mainTitle" className="fullScreen">
                        <div className="content" dangerouslySetInnerHTML={{__html: data.content.text}} />
                    </div>
                    <div id="chaptersCards" className="fullScreen">
                        <div className="container">
                            <div className="col-row">

                                {data.sections.map((item, index) =>
                                    <div className="col-4-12" key={'card-' + index}>
                                        <a className="card" href={'#chapter' + (index + 1)} >
                                            <img src={"https://shelf.ramir.space/backend/assets/uploads/images/content/" + item.img_url} alt={item.title} />
                                        </a>
                                    </div>
                                )}

                                {data.section_banners.map((item) =>
                                    <div className="col-4-12" key={'card-6'}>
                                        <a className="card" href={'#chapter6'} >
                                            <img src={"https://shelf.ramir.space/backend/assets/uploads/images/banners/" + item.img_url} alt={item.title} />
                                        </a>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    {data.sections.map((item, index) =>
                        <div id={"chapter" + (index + 1)} className={item.url == 'interaktivnaya_test-igra' ? 'chapterGame' : 'chapterCarousel'} key={'carousel-' + index}>
                            <div className='container'>
                                <h3 className="chapterTitle" dangerouslySetInnerHTML={{__html: item.anons}} />
                                <div className="col-row">
                                    <Carousel materials={item.materials} />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="clearfix"></div>

                    {data.carousel_banners.map((item, index) =>
                        <div id="chapter6" className="chapterGame" key={'chapterGame' + index}>
                            <div className='container'>
                                <h3 className="chapterTitle">Интерактивная тест-игра</h3>
                                <div className='col-row'>
                                    <div className='col-1-1'>
                                        <div className="itemCard game">
                                            <NavLink to={item.link} className="itemCardName">
                                                <div className="itemImageWrapper">
                                                    <img src={"https://shelf.ramir.space/backend/assets/uploads/images/banners/" + item.img_url} alt={item.title} />
                                                </div>
                                                <span className="itemCardType game"></span>
                                                <div className="itemContentWrapper" dangerouslySetInnerHTML={{__html: item.text}} />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>


                ) : (
                    <div id="wrapperHome" className="wrapper">
                        <p>
                            <img src="/loading.gif" alt="Загрузка..." />
                        </p>
                    </div>
                )}


                <Footer/>

            </DocumentMeta>
        )

    }

}
