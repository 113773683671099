import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Siema from "siema";

export class Carousel extends Component {

    constructor(props) {
        super(props);
        this.carousel = null;
        this.siemaCarousel = null;
    }

    componentDidMount() {

        const myThis = this;

        let carousel = document.querySelector('.carousel');

        let perPage = {
            768: 2, // 2 items for viewport wider than 800px
            990: 3 // 3 items for viewport wider than 1240px
        };

        if(carousel.querySelectorAll('.itemCard').length == 2){
            perPage = {
                990: 2 // 3 items for viewport wider than 1240px
            };
        } else if(carousel.querySelectorAll('.itemCard').length == 1) {
            perPage = 1;
        }

        if(carousel && carousel.querySelectorAll('.itemCard').length > 0){
            myThis.siemaCarousel = new Siema({
                selector: myThis.carousel.querySelector('.carousel'),
                duration: 200,
                easing: 'ease-out',
                perPage: perPage,
                startIndex: 0,
                draggable: true,
                multipleDrag: true,
                threshold: 20,
                loop: true,
                rtl: false,
            });

            myThis.carousel.querySelector('.prev').addEventListener('click', () => this.siemaCarousel.prev());
            myThis.carousel.querySelector('.next').addEventListener('click', () => this.siemaCarousel.next());
        }

    }

    componentWillUnmount() {

        let carousel = document.querySelector('.carousel');

        if(carousel && carousel.querySelectorAll('.itemCard').length > 0) {
            this.siemaCarousel.destroy();
        }

    }

    render() {

        const carouselItems = this.props.materials;

        return(
            <div className={'carouselWrapper ' + (carouselItems.length < 4 ? 'hideButtons' : '')} ref={div => this.carousel = div}>
                <div className="carousel">
                    {carouselItems.map((carouselItem, index) =>

                        <div className={'itemCard ' + carouselItem.abb} key={'itemCard-' + index}>
                            <NavLink className="itemCardName" to={'/article/' + carouselItem.url}>
                                <div className="itemImageWrapper">
                                    <img src={"https://shelf.ramir.space/backend/assets/uploads/images/content/" + carouselItem.img_url} alt={carouselItem.title} />
                                </div>
                                <span className={"itemCardType " + carouselItem.abb}></span>
                                {carouselItem.title}
                                <span className="itemCardReadingTime">{carouselItem.reading_time}</span>
                            </NavLink>
                        </div>

                    )}
                </div>
                <button className="prev"></button>
                <button className="next"></button>
            </div>
        );
    }
}