import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {getGPUTier} from 'detect-gpu';
import MobileDetect from "mobile-detect";

import './App.css';

//Styles
import "baguettebox.js/dist/baguetteBox.min.css"
import "./styles/normalize.css"
import "./styles/fonts.css"
import "./styles/main.css"

//Pages & Blocks
import {Pravi} from "./components/Pravi";
import {Home} from './pages/Home';
import {Article} from './pages/Article';
import {Game} from './pages/Game';

class App extends Component {

    constructor() {
        super();
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.GPUTier = getGPUTier().tier;
        this.goodGPU = false;
        if(this.GPUTier == 'GPU_DESKTOP_TIER_3' || this.GPUTier == 'GPU_DESKTOP_TIER_2'){
            this.goodGPU = true;
        }
        this.fullScreen = this.fullScreen.bind(this);

    }

    fullScreen(element) {
        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.webkitrequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if(element.mozRequestFullscreen) {
            element.mozRequestFullScreen();
        }
    }

    componentDidMount() {

        const myThis = this;

        if(myThis.isMobile){

            document.body.classList.add('isMobile');

            this.fullScreen(document.documentElement);

        }

        window.addEventListener('resize', function (){

            // myThis.MobileDetect = new MobileDetect(window.navigator.userAgent);
            // myThis.isMobile = myThis.MobileDetect.mobile();

            // if(myThis.isMobile){
            //     document.body.classList.add('isMobile');
            // } else {
            //     document.body.classList.remove('isMobile');
            // }

        });

    }

    render() {

        const timeout={
            enter: 600,
            exit: 600,
        }

        return (
            <Route render={({location}) => (

                <main className="loading">
                    <div id="preloader"></div>
                    <Pravi/>
                    <TransitionGroup component="div" className="fadePage">
                        <CSSTransition
                            key={location.key}
                            timeout={timeout}
                            classNames="fade"
                            onExit={e => {
                                window.scrollTo(0, 0);
                                document.querySelector('header').classList.remove('collapsed');
                            }}
                            unmountOnExit
                        >
                            <Switch location={location}>

                                <Route path='/' exact component={Home} />

                                <Route path='/game' component={Game} />

                                <Route path='/article/:id' component={Article} />

                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </main>

            )} />
        );

    }


}

export default App;
