import React, {Component} from 'react';
//import {NavLink} from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import {gsap, Power2, Linear} from "gsap";
import {SplitText} from "gsap/SplitText";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";
import MobileDetect from "mobile-detect";

export class Game extends Component {

    constructor(props) {
        super(props);
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.gamePreloadTitle = null;
        this.pageUrl = 'https://shelf.ramir.space/backend/pages?json=1';
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
        };

        //Level 1
        this.doAnswerLevel1 = this.doAnswerLevel1.bind(this);

        //Level 3
        this.startLevel31    = this.startLevel31.bind(this);
        this.goLevel31Scene1 = this.goLevel31Scene1.bind(this);
        this.doAnswerLevel31 = this.doAnswerLevel31.bind(this);
        this.goLevel32Scene1 = this.goLevel32Scene1.bind(this);
        this.goLevel32Scene2 = this.goLevel32Scene2.bind(this);
        this.goLevel32Timer  = this.goLevel32Timer.bind(this);

        //Level 4
        this.startLevel41 = this.startLevel41.bind(this);
        this.goLevel41Scene1 = this.goLevel41Scene1.bind(this);
        this.doAnswerLevel41 = this.doAnswerLevel41.bind(this);
        this.startLevel42 = this.startLevel42.bind(this);
        this.goLevel41Scene2 = this.goLevel41Scene2.bind(this);
        //Navigation
        this.goUp = this.goUp.bind(this);
        this.goDown = this.goDown.bind(this);
        this.goLeft = this.goLeft.bind(this);
        this.goRight = this.goRight.bind(this);

        //Level 5
        this.startLevel51 = this.startLevel51.bind(this);
        this.goLevel51Scene1 = this.goLevel51Scene1.bind(this);
        this.doAnswerLevel51 = this.doAnswerLevel51.bind(this);
        this.startLevel52 = this.startLevel52.bind(this);

        //The End!
        this.theEnd = this.theEnd.bind(this);

        // Full Screen Mode
        this.fullScreen = this.fullScreen.bind(this);

    }

    fullScreen(element) {
        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.webkitrequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if(element.mozRequestFullscreen) {
            element.mozRequestFullScreen();
        }
    }

    componentDidMount() {

        let myThis = this,
            header = document.querySelector('header'),
            socialFixedRight = document.querySelector('#socialFixedRight');

        document.body.classList.remove('white', 'section1', 'section2', 'section3', 'section4', 'section5', 'article', 'test');
        document.body.classList.add('game');

        if(this.isMobile){

            // var doc = window.document;
            // var docEl = doc.documentElement;
            //
            // var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
            // var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
            //
            // if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            //     requestFullScreen.call(docEl);
            // }
            // else {
            //     cancelFullScreen.call(doc);
            // }

            this.fullScreen(document.documentElement);

        }

        fetch(myThis.pageUrl)
            .then(res => res.json())
            .then(
                (result) => {

                    myThis.setState({
                        isLoaded: true,
                        data: result,
                    });

                    header.classList.add('collapsed');
                    socialFixedRight.classList.add('collapsed');

                    /* Sound */

                    let mute = document.getElementById('mute');

                    let soundtrackAudio    = document.createElement("audio");
                    soundtrackAudio.src  = "/music/sound1.mp3";
                    soundtrackAudio.loop = true;

                    mute.addEventListener('click', function (e) {

                        soundtrackAudio.play();

                        if(mute.classList.contains('muted')){
                            soundtrackAudio.muted = false;
                        } else {
                            soundtrackAudio.muted = true;
                        }

                        mute.classList.toggle('muted');

                        e.preventDefault();

                    });

                    /* EOF. Sound */

                    /* Scene 1 */
                    let loadingTL = gsap.timeline(),
                        splitTitleH1 = new SplitText('h1#gameTitle span', {type: "words"});

                    loadingTL
                        .fromTo('#close', 1, {right: '-100px'}, {right: '36px'}, .5)
                        .fromTo('#mute', 1, {right: '-100px'}, {right: '36px'}, .5)
                        .from(splitTitleH1.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.15}, .5)
                        .fromTo('#startGame',1, {bottom: "-25%"}, {bottom: "15%"}, .5)

                    let scene1Animation = gsap.timeline();
                    let scene1skyMove = gsap.timeline();

                    document.querySelectorAll('#level1 #clouds .cloud').forEach(function (cloud, index) {

                        let cloudSpeed = 0;

                        if(cloud.classList.contains('big')){
                            cloudSpeed = -40;
                        } else if(cloud.classList.contains('middle')){
                            cloudSpeed = 0;
                        } else if(cloud.classList.contains('small')){
                            cloudSpeed = 40;
                        }

                        scene1skyMove.fromTo(cloud, 160 + cloudSpeed,{x:-1920}, {x:1920, repeat:-1}, 0);
                    })

                    document.querySelectorAll('#level1 #sea path').forEach(function (ice, index) {
                        scene1Animation.fromTo(ice, 240,{x:1920}, {x:-1920, repeat:-1}, 0);
                    })

                    /* Scene 2 */

                    document.getElementById('startGame').addEventListener('click', function () {

                        let scene2Animation = gsap.timeline();

                        let startGameAudio    = document.createElement("audio");
                        startGameAudio.src  = "/music/yes.mp3";
                        startGameAudio.play();

                        scene2Animation.fromTo('#level1 #scene1', 1,{opacity: 1}, {opacity: 0});
                        scene2Animation.fromTo('#level1 #scene2', 1,{opacity: 0}, {opacity: 1}, 1);
                        scene2Animation.to('#level1 #scene1', 0,{visibility: 'hidden'}, 1);
                        scene2Animation.to('#level1 #scene2', 0,{visibility: 'visible'}, 1);
                        scene2Animation.to('#level1 #level1Bg', 1, {x: -window.innerWidth/4, scale: 1.5, ease: Power2.easeOut}, .5);
                        scene2Animation.to('#level1 #men', 1, {scale: 1.5, x: 500, y: -window.innerHeight/6, ease: Power2.easeOut}, .5);
                        scene2Animation.to('#level1 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                        scene2Animation.to('#level1 #men #scare', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                        scene2Animation.to('#level1 #leftHand', 1, {rotate: -110, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                        scene2Animation.to('#level1 #leftHand .forearm', 1, {rotate: -140, ease: Power2.easeOut, transformOrigin:"55% 15%"}, 1);

                    });

                    /* Scene 3 */

                    document.querySelector('#scene2 .goAhead').addEventListener('click', function () {

                        let scene3Animation = gsap.timeline();

                        scene3Animation.to('#level', 1,{opacity: 1}, 0);
                        scene3Animation.to('#level1 #scene2', 1,{opacity: 0}, 0);
                        scene3Animation.to('#level1 #scene2', 0,{visibility: 'hidden'}, 1);
                        scene3Animation.to('#level1 #scene3', 0,{visibility: 'visible'}, 1);
                        scene3Animation.to('#level1 #level1Bg', 1, {x: -window.innerWidth/2, y: -window.innerHeight/2, scale: 1.5, ease: Power2.easeOut}, .5);
                        scene3Animation.fromTo('#level1 #level1Answer1', 1.5,{opacity: 0, yPercent:100}, {opacity: 1, yPercent: 0, ease: Power2.easeOut}, .5);
                        scene3Animation.fromTo('#level1 #level1Answer2', 1.5,{opacity: 0, yPercent:100}, {opacity: 1, yPercent: 0, ease: Power2.easeOut}, 1);
                        scene3Animation.to('#level1 #men', 1, {scale: 1.75, x: 720, y: -window.innerHeight/6, ease: Power2.easeOut}, .5);
                        scene3Animation.to('#level1 #buildings #building1, #level1 #buildings #building3, #smoke #smoke1, #smoke #smoke3', 2, {x: -window.innerWidth}, 0);
                        scene3Animation.to('#level1 #buildings #building2, #smoke #smoke2', 2, {x: window.innerWidth}, 0);
                        scene3Animation.to('#level1 #leftHand', 1, {rotate: 0, ease: Power2.easeOut}, 1);
                        scene3Animation.to('#level1 #leftHand .forearm', 1, {rotate: 0, ease: Power2.easeOut}, 1);

                    });

                    document.querySelector('#level2 #scene22 .goAhead').addEventListener('click', function (e){

                        e.preventDefault();

                        let scene7Animation = gsap.timeline();

                        //Show level
                        scene7Animation.to('#level', 1,{opacity: 1}, 0);
                        scene7Animation.to('#level2 #scene23', 1,{opacity: 1, visibility: 'visible'}, 0);
                        scene7Animation.fromTo('#level2 #scene23 #level2MLSP #mlsp, #level2 #scene23 #level2MLSP #mlsp_structure', 1,{x: -window.innerWidth},{x: 0}, 0);
                        scene7Animation.fromTo('#level2 #scene23 .question, #level2 #scene23 .goAhead', 1,{x: window.innerWidth},{x: 0}, 0);


                    });

                    document.querySelector('#level2 #scene23 .goAhead').addEventListener('click', function (e){

                        e.preventDefault();

                        let scene8Animation = gsap.timeline();

                        scene8Animation.to('#level2 #scene23 #level2MLSP #mlsp path, #level2 #scene23 #level2MLSP #mlsp line, #level2 #scene23 #level2MLSP #mlsp rect, #level2 #scene23 #level2MLSP #mlsp polygon, #level2 #scene23 #level2MLSP #mlsp polyline', 1,{opacity: 0}, 0);
                        scene8Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure', 1,{opacity: 1}, 0);
                        scene8Animation.to('#level2 #scene23 .question, #level2 #scene23 .goAhead', 1,{y: window.innerHeight}, 0);
                        scene8Animation.to('#level2 #scene23 .testMLSP', 1,{left: 0}, 1);
                        scene8Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element1 path, #level2 #scene23 #level2MLSP #mlsp_structure #element1 line, #level2 #scene23 #level2MLSP #mlsp_structure #element1 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element1 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element1 polyline', 1,{fill: '#FF4F00', stroke: "#FF4F00"}, 1);

                    });

                    document.querySelectorAll('.testMLSPTForm .testMLSPContainer').forEach(function (testAnswer){

                        testAnswer.addEventListener('click', function (e){

                            e.preventDefault();

                            let testForm = testAnswer.parentElement;

                            testForm.querySelectorAll('.testMLSPContainer').forEach(function (testMLSPContainer){
                                testMLSPContainer.classList.remove('checked');
                                testMLSPContainer.querySelector('input[name = answer]').checked = false;
                            });

                            testAnswer.classList.add('checked');
                            testAnswer.querySelector('input[name = answer]').checked = true;

                            testForm.querySelector('button').disabled = false;

                        })

                    });

                    function insertAfter(referenceNode, newNode) {
                        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
                    }

                    // Start Test
                    document.getElementById('testMLSPT').addEventListener('submit', function (e){

                        e.preventDefault();

                        let testForm = this,
                            currentDesc = testForm.querySelector('p.desc'),
                            checkedAnswer = document.querySelectorAll('input[name = answer]:checked'),
                            questionNumber = testForm.querySelector('input[name = number]'),
                            questionNumberVal = testForm.querySelector('input[name = number]').value,
                            button = testForm.querySelector('button');

                        button.classList.add('loading');
                        button.disabled = true;

                        if(currentDesc){
                            currentDesc.remove()
                        }

                        if(testForm.classList.contains('goNext')){

                            //Removing checked status
                            testForm.querySelectorAll('.testMLSPContainer').forEach(function (testMLSPContainer){
                                testMLSPContainer.classList.remove('checked','false','true');
                                testMLSPContainer.querySelector('input[name = answer]').checked = false;
                            });

                            testForm.className = 'testMLSPTForm';

                            setTimeout(function (){

                                button.classList.remove('loading');
                                button.disabled = true;
                                button.innerText = 'Ответить'

                            }, 1000);

                            let scene9Animation = gsap.timeline();

                            if(questionNumberVal == 2) {

                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element1 path, #level2 #scene23 #level2MLSP #mlsp_structure #element1 line, #level2 #scene23 #level2MLSP #mlsp_structure #element1 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element1 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element1 polyline', 1,{fill: '#3E83CE', stroke: "#3E83CE"}, 1);
                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element2 path, #level2 #scene23 #level2MLSP #mlsp_structure #element2 line, #level2 #scene23 #level2MLSP #mlsp_structure #element2 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element2 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element2 polyline', 1,{fill: '#FF4F00', stroke: "#FF4F00"}, 1);

                            } else if(questionNumberVal == 3){

                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element2 path, #level2 #scene23 #level2MLSP #mlsp_structure #element2 line, #level2 #scene23 #level2MLSP #mlsp_structure #element2 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element2 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element2 polyline', 1,{fill: '#3E83CE', stroke: "#3E83CE"}, 1);
                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element3 path, #level2 #scene23 #level2MLSP #mlsp_structure #element3 line, #level2 #scene23 #level2MLSP #mlsp_structure #element3 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element3 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element3 polyline', 1,{stroke: "#FF4F00"}, 1);

                            } else if(questionNumberVal == 4){

                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure #element3 path, #level2 #scene23 #level2MLSP #mlsp_structure #element3 line, #level2 #scene23 #level2MLSP #mlsp_structure #element3 rect, #level2 #scene23 #level2MLSP #mlsp_structure #element3 polygon, #level2 #scene23 #level2MLSP #mlsp_structure #element3 polyline', 1,{stroke: "#3E83CE"}, 0);
                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp_structure', 1,{opacity: 0}, 0);
                                scene9Animation.to('#level2 #scene23 #level2MLSP #mlsp path, #level2 #scene23 #level2MLSP #mlsp line, #level2 #scene23 #level2MLSP #mlsp rect, #level2 #scene23 #level2MLSP #mlsp polygon, #level2 #scene23 #level2MLSP #mlsp polyline', 1,{opacity: 1}, 0);
                                scene9Animation.to('#testMLSPT', 1, {opacity: 0, visibility: 'hidden'}, 0)
                                scene9Animation.fromTo('#testSuccess', 1, {x: window.innerWidth, opacity: 0, visibility: 'hidden'}, {x: 0, opacity: 1, visibility: 'visible'}, 0)

                            }


                        } else {

                            if(checkedAnswer){

                                document.querySelectorAll('input[name = answer]').forEach(function (answer){

                                    if(answer.checked){

                                        let val = answer.value,
                                            desc = document.createElement('p'),
                                            status = document.createElement('span');

                                        desc.className = 'desc';

                                        if(questionNumberVal == 1){

                                            if(val == 1 || val == 3){

                                                let noAudio    = document.createElement("audio");
                                                noAudio.src  = "/music/no.mp3";
                                                noAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('false');

                                                status.className = 'false';
                                                status.innerText = 'Увы, неверно!';
                                                desc.innerText = 'Попробуйте еще раз, возможно вы немного поторопились с ответом.';
                                                desc.prepend(status);

                                            } else if(val == 2){

                                                let yesAudio    = document.createElement("audio");
                                                yesAudio.src  = "/music/yes.mp3";
                                                yesAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('true');

                                                status.className = 'true';
                                                status.innerText = 'Верно!';
                                                desc.innerText = 'МЛСП «Приразломная» оборудована двумя комплексами устройств прямой отгрузки нефти (КУПОН), работающими на основе крановой системы и позволяющими производить загрузку танкеров из нефтехранилища платформы. КУПОНы расположены на противоположных концах платформы, что делает возможным подход танкеров к платформе в сложных погодных и навигационных условиях.';
                                                desc.prepend(status);

                                                questionNumber.value = 2;

                                            }

                                            insertAfter(answer.parentElement, desc);

                                            setTimeout(function (){

                                                button.classList.remove('loading');
                                                button.disabled = false;

                                                if(val == 1 || val == 3){

                                                    button.innerText = 'Ещё раз!'

                                                } else if(val == 2){

                                                    testForm.classList.add('goNext');
                                                    button.innerText = 'Далее'

                                                }

                                            }, 1000);

                                        } else if(questionNumberVal == 2){

                                            if(val == 2 || val == 3){

                                                let noAudio    = document.createElement("audio");
                                                noAudio.src  = "/music/no.mp3";
                                                noAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('false');

                                                status.className = 'false';
                                                status.innerText = 'Увы, неверно!';
                                                desc.innerText = 'Попробуйте еще раз, возможно вы немного поторопились с ответом.';
                                                desc.prepend(status);

                                            } else if(val == 1){

                                                let yesAudio    = document.createElement("audio");
                                                yesAudio.src  = "/music/yes.mp3";
                                                yesAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('true');

                                                status.className = 'true';
                                                status.innerText = 'Верно!';
                                                desc.innerText = 'В кессоне МЛСП «Приразломная» находится состоящее из 16 отсеков нефтехранилище, а над ним расположены все остальные технологические комплексы и системы платформы. В танках- нефтехранилищах применяется «мокрый» способ хранения нефти – т.е. они постоянно заполнены либо нефтью, либо водой. Такой способ хранения исключает образование любой взрывоопасной среды, что является дополнительным условием безопасности платформы.';
                                                desc.prepend(status);

                                                questionNumber.value = 3;

                                            }

                                            insertAfter(answer.parentElement, desc);

                                            setTimeout(function (){

                                                button.classList.remove('loading');
                                                button.disabled = false;

                                                if(val == 2 || val == 3){

                                                    button.innerText = 'Ещё раз!'

                                                } else if(val == 1){

                                                    testForm.classList.add('goNext');
                                                    button.innerText = 'Далее'

                                                }

                                            }, 1000);

                                        } else if(questionNumberVal == 3){

                                            if(val == 1 || val == 2){

                                                let noAudio    = document.createElement("audio");
                                                noAudio.src  = "/music/no.mp3";
                                                noAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('false');

                                                status.className = 'false';
                                                status.innerText = 'Увы, неверно!';
                                                desc.innerText = 'Попробуйте еще раз, возможно вы немного поторопились с ответом.';
                                                desc.prepend(status);

                                            } else if(val == 3){

                                                let yesAudio    = document.createElement("audio");
                                                yesAudio.src  = "/music/yes.mp3";
                                                yesAudio.play();

                                                testForm.querySelector('.testMLSPContainer.checked').classList.add('true');

                                                status.className = 'true';
                                                status.innerText = 'Верно!';
                                                desc.innerText = 'За шесть лет промышленной эксплуатации на единственном в России шельфовом арктическом месторождении пробурили 19 горизонтальных скважин. Всего же планируется пробурить 32 скважины.';
                                                desc.prepend(status);

                                                questionNumber.value = 4;

                                            }

                                            insertAfter(answer.parentElement, desc);

                                            setTimeout(function (){

                                                button.classList.remove('loading');
                                                button.disabled = false;

                                                if(val == 1 || val == 2){

                                                    button.innerText = 'Ещё раз!'

                                                } else if(val == 3){

                                                    testForm.classList.add('goNext');
                                                    button.innerText = 'Далее'

                                                }

                                            }, 1000);

                                        }

                                    }

                                });

                            } else {

                                alert('Пожалуйста, сделайте свой выбор!');

                            }

                        }

                    });


                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    myThis.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    doAnswerLevel1(e){

        e.preventDefault();

        let currentAnswer = e.currentTarget;
        let scene4Animation = gsap.timeline();

        if(currentAnswer.classList.contains('wrongAnswer')){

            currentAnswer.classList.remove('wrongAnswer');

            scene4Animation.to('#level1 #scene4', 1, {yPercent: 100, opacity: 0, ease: Power2.easeInOut}, 0)
            scene4Animation.to('#level1 #scene4', 0, {visibility: 'hidden'}, 1)
            scene4Animation.to('#level1 #men', 1.5, {x: -window.innerWidth/2, ease: Power2.easeOut, onComplete: function (){
                gsap.fromTo('#level1 #men', 1, {x: window.innerWidth}, {x: 720, ease: Power2.easeOut});
            }}, 0);
            scene4Animation.to('#level1 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
            scene4Animation.to('#level1 #men #scare', .5, {opacity: 1, ease: Power2.easeOut}, .5);
            scene4Animation.to('#level1 #snow1, #level1 #snow2, #level1 #sea, #level1 #ground', 1, {opacity: 1, ease: Power2.easeOut}, .8);
            scene4Animation.to('#level1 #level1Answer1', 1,{yPercent:0, ease: Power2.easeIn}, 1);
            scene4Animation.to('#level1 #level1Answer2 .result', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
            scene4Animation.fromTo('#level1 #level1Answer2 > span', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, 0.25);
            scene4Animation.to('#level1 #level1Answer2', 1,{x:0, ease: Power2.easeIn}, .5);

        } else if(currentAnswer.classList.contains('rightAnswer')){

            let scene5Animation = gsap.timeline(),
                scene5skyMove = gsap.timeline(),
                scene5taleRotation = gsap.timeline();

            let helicopterAudio    = document.createElement("audio");
            helicopterAudio.src  = "/music/helicopter.mp3";
            helicopterAudio.play();

            //Change level
            scene5taleRotation.to('#level', 1,{opacity: 0}, 0);
            scene5taleRotation.to('#level .st2', 1,{opacity: 1, strokeDasharray: '56,160', onComplete: function (){
                    document.querySelector('#level .levelText .currentLevel').textContent = 2;
                }}, 0);

            // Tale Rotation
            scene5taleRotation.to('#level2 #tail_rotor', .5, {rotation:"360", transformOrigin: "center", ease:Linear.easeNone, repeat:-1}, 0);

            // Sky Moving
            document.querySelectorAll('#level2 #clouds .cloud').forEach(function (cloud, index) {

                let cloudSpeed = 0;

                if(cloud.classList.contains('big')){
                    cloudSpeed = -5;
                } else if(cloud.classList.contains('middle')){
                    cloudSpeed = 0;
                } else if(cloud.classList.contains('small')){
                    cloudSpeed = 5;
                }

                scene5skyMove.fromTo(cloud, 15 + cloudSpeed,{x:0}, {x:-1920, repeat:-1, ease:Linear.easeInOut}, 0);

            })

            let splitScene2Title = new SplitText('#level2 #scene22 .question', {type: "words"});

            scene5Animation.to('#level1', 1, {opacity: 0, scale: 1.1, ease: Power2.easeInOut}, 0)
            scene5Animation.to('#level2', 1, {visibility: 'visible', opacity: 1, ease: Power2.easeInOut}, 0)
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter', 1, {left: '-20%', top: '5%', scale: 7,  ease: Power2.easeInOut}, 5);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter', 2, {left: 0, top: 0, scale: 1, ease: Power2.easeInOut, onComplete: function (){
                scene5skyMove.pause();
            }}, 8);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter #helicopter', 1, {rotate: '8deg', ease: Power2.easeInOut}, 9);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter #helicopter', 2, {x: 725, y: 200, ease:Linear.easeNone}, 9.5);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter #helicopter', 1, {rotate: 0, ease:Linear.easeInOut}, 12);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter #helicopter', 2, {y: 322, ease:Linear.easeInOut, onComplete: function (){
                scene5taleRotation.pause();
                helicopterAudio.pause();
            }}, 13);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter', 5, {scale: 2.5, transformOrigin: "65% 50%",  ease: Power2.easeInOut}, 12);
            scene5Animation.to('#level2 #scene21 #level2BgHelicopter', 5, {scale: 2.5, transformOrigin: "65% 50%",  ease: Power2.easeInOut}, 12);
            scene5Animation.to('#level2 #scene22', 1, {visibility: 'visible', opacity: 1, ease: Power2.easeInOut}, 15)
            scene5Animation.from(splitScene2Title.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.15}, 15.5)
            scene5Animation.fromTo('#level2 #scene22 .goAhead',1, {bottom: "-25%"}, {bottom: "15%"}, 15.5)
            scene5Animation.fromTo('#level2 #scene22 .level',1, {y: "-25%"}, {y: 0}, 15.5)

        } else {

            if(this.isMobile){

                scene4Animation.fromTo('#level1 #men', 1.25, {x: 720}, {x: 2*window.innerWidth, ease: Power2.easeOut, onComplete: function (){
                        gsap.fromTo('#level1 #men', 1, {x: -window.innerWidth}, {x: 200, ease: Power2.easeOut}, 0);
                    }}, 0);

            } else {

                scene4Animation.fromTo('#level1 #men', 1.25, {x: 720}, {x: window.innerWidth, ease: Power2.easeOut, onComplete: function (){
                        gsap.fromTo('#level1 #men', 1, {x: -window.innerWidth}, {x: 200, ease: Power2.easeOut}, 0);
                    }}, 0);

            }


            scene4Animation.to('#level1 #snow1, #level1 #snow2, #level1 #sea, #level1 #ground', 1, {opacity: 0, ease: Power2.easeOut}, .8);

            //Wrong Answer
            if(currentAnswer.id == 'level1Answer2'){

                let noAudio    = document.createElement("audio");
                noAudio.src  = "/music/no.mp3";
                noAudio.play();

                currentAnswer.classList.add('wrongAnswer');

                scene4Animation.to('#level1 #level1Answer2 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                scene4Animation.fromTo('#level1 #level1Answer2 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, 0.25);
                scene4Animation.to('#level1 #level1Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                if(this.isMobile) {
                    scene4Animation.to('#level1 #level1Answer2', 1, {
                        x: window.innerWidth / 4,
                        ease: Power2.easeIn
                    }, .5);
                } else {
                    scene4Animation.to('#level1 #level1Answer2', 1, {
                        x: window.innerWidth / 4 + 100,
                        ease: Power2.easeIn
                    }, .5);
                }
                scene4Animation.to('#level1 #men #scare', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                scene4Animation.to('#level1 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            //Right Answer
            } else {

                let yesAudio    = document.createElement("audio");
                yesAudio.src  = "/music/yes.mp3";
                yesAudio.play();

                currentAnswer.classList.add('rightAnswer');

                scene4Animation.to('#level1 #level1Answer1 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                scene4Animation.fromTo('#level1 #level1Answer1 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, 0.25);
                scene4Animation.to('#level1 #level1Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                if(this.isMobile) {
                    scene4Animation.to('#level1 #level1Answer1', 1, {
                        x: window.innerWidth / 2 + 40,
                        ease: Power2.easeIn
                    }, .5);
                } else {
                    scene4Animation.to('#level1 #level1Answer1', 1, {
                        x: window.innerWidth / 2 + 100,
                        ease: Power2.easeIn
                    }, .5);
                }
                scene4Animation.to('#level1 #men #scare', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                scene4Animation.to('#level1 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            }

        }

    }



    startLevel31(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        //Change level
        sceneAnimation.to('#level', 1,{opacity: 0}, 0);
        sceneAnimation.to('#level .st2', 1,{opacity: 1, strokeDasharray: '80,160', onComplete: function (){
                document.querySelector('#level .levelText .currentLevel').textContent = 3;
            }}, 0);

        let splitTitle = new SplitText('#level3 #scene31 .question', {type: "words"});

        sceneAnimation.to('#level2', 1, {opacity: 0, scale: 1.1, ease: Power2.easeInOut}, 0)
        sceneAnimation.to('#level3', 1, {visibility: 'visible', opacity: 1, ease: Power2.easeInOut}, 0)
        sceneAnimation.from(splitTitle.words,.5, {duration: 1, yPercent: 150, opacity: 0, stagger: 0.05}, 0)
        sceneAnimation.fromTo('#level3 #scene31 .level',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, 0)
        sceneAnimation.fromTo('#level3 #scene31 .goAhead',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, .5)

    }

    goLevel31Scene1(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();
        let splitTitle = new SplitText('#level3 #scene32 .question', {type: "words"});

        if(this.isMobile){
            sceneAnimation.to('#level3 #men', 2, {x: 2*window.innerWidth, ease: Power2.easeOut}, 1);
        } else {
            sceneAnimation.to('#level3 #men', 2, {x: window.innerWidth, ease: Power2.easeOut}, 1);
        }
        sceneAnimation.fromTo('#level3 #scene31', 1,{opacity: 1}, {opacity: 0}, 1);
        sceneAnimation.fromTo('#level3 #scene32', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 1);
        sceneAnimation.to('#level3 #scene31', 0,{visibility: 'hidden'}, 2);
        sceneAnimation.from(splitTitle.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.1}, 1)
        sceneAnimation.fromTo('#level3 #level31Answer1', 1,{y:window.innerWidth/2}, {y:0, ease: Power2.easeOut}, 1);
        sceneAnimation.fromTo('#level3 #level31Answer2', 1,{y:window.innerWidth/2},{y:0, ease: Power2.easeOut}, 1.25);
        sceneAnimation.fromTo('#level3 #level31Answer3', 1,{y:window.innerWidth/2},{y:0, ease: Power2.easeOut}, 1.5);

    }

    doAnswerLevel31(e) {

        e.preventDefault();

        let currentAnswer = e.currentTarget,
            sceneAnimation = gsap.timeline();

        if(currentAnswer.classList.contains('wrongAnswer')){

            currentAnswer.classList.remove('wrongAnswer');

            let currentId = currentAnswer.closest('.answer').id;

            if(currentId == 'level31Answer2'){
                sceneAnimation.to('#level3 #level31Answer2 > span', .5,{y: 0, opacity: 1, ease: Power2.easeIn}, 0.25);
                sceneAnimation.fromTo('#level3 #level31Answer2 .result', .5,{y:0, opacity: 1}, {y:50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer2', 1,{x:0, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level3 #level31Answer3', 1,{yPercent:0, ease: Power2.easeIn}, .5);
            } else {
                sceneAnimation.to('#level3 #level31Answer3 > span', .5,{y: 0, opacity: 1, ease: Power2.easeIn}, 0.25);
                sceneAnimation.fromTo('#level3 #level31Answer3 .result', .5,{y:0, opacity: 1}, {y:50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer3', 1,{x:0, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level3 #level31Answer2', 1,{yPercent:0, ease: Power2.easeIn}, .5);
            }
            sceneAnimation.to('#level3 #level31Answer1', 1,{yPercent:0, ease: Power2.easeIn}, .5);

            if(this.isMobile){
                sceneAnimation.to('#level3 #men', 2, {x: -2*window.innerWidth, ease: Power2.easeOut}, 0);
            } else {
                sceneAnimation.to('#level3 #men', 2, {x: -window.innerWidth, ease: Power2.easeOut}, 0);
            }

            sceneAnimation.to('#level3 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
            sceneAnimation.to('#level3 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);

        } else if(currentAnswer.classList.contains('rightAnswer')){

            this.goLevel32Scene1(e);

        } else {

            if(this.isMobile){
                sceneAnimation.fromTo('#level3 #men', 2, {x: -2*window.innerWidth}, {x: -window.innerWidth, ease: Power2.easeOut}, 1);
            } else {
                sceneAnimation.fromTo('#level3 #men', 2, {x: -2*window.innerWidth}, {x: -window.innerWidth / 2, ease: Power2.easeOut}, 1);
            }

            sceneAnimation.to('#level3 #scene32 .question', 2, {y: -window.innerHeight/2, ease: Power2.easeOut}, 0);

            //Wrong Answer
            if(currentAnswer.id == 'level31Answer2'){

                currentAnswer.classList.add('wrongAnswer');

                let noAudio    = document.createElement("audio");
                noAudio.src  = "/music/no.mp3";
                noAudio.play();

                sceneAnimation.to('#level3 #level31Answer2 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level3 #level31Answer2 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level3 #level31Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer3', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer2', 1,{x:window.innerWidth/6, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level3 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level3 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            }else if(currentAnswer.id == 'level31Answer3'){

                currentAnswer.classList.add('wrongAnswer');

                let noAudio    = document.createElement("audio");
                noAudio.src  = "/music/no.mp3";
                noAudio.play();

                sceneAnimation.to('#level3 #level31Answer3 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level3 #level31Answer3 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level3 #level31Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer3', 1,{x:-window.innerWidth/6, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level3 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level3 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            //Right Answer
            } else {

                currentAnswer.classList.add('rightAnswer');

                let yesAudio    = document.createElement("audio");
                yesAudio.src  = "/music/yes.mp3";
                yesAudio.play();

                sceneAnimation.to('#level3 #level31Answer1 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level3 #level31Answer1 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level3 #level31Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer3', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level3 #level31Answer1', 1,{x: window.innerWidth / 2, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level3 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level3 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level3 #men #leftHand', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                sceneAnimation.to('#level3 #men #leftHand .forearm', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"50% 18%"}, 1);

            }

        }

    }

    goLevel32Scene1(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        sceneAnimation.fromTo('#level3 #scene32', 1,{opacity: 1, scale: 1}, {opacity: 0, scale: .9}, 0);
        sceneAnimation.fromTo('#level3 #scene33', 1,{opacity: 0, scale: .9}, {opacity: 1, scale: 1, visibility: 'visible'}, 0);

        sceneAnimation.fromTo('svg#level3Bg', 1,{opacity: 1, scale: 1}, {opacity: 0, scale: .9}, 0);
        sceneAnimation.fromTo('svg#level3Bg2', 1,{opacity: 0, scale: .9}, {opacity: 1, scale: 1, visibility: 'visible'}, 0);

        sceneAnimation.to('#level3 #scene32', 0,{visibility: 'hidden'}, 1);

        setTimeout(function (){

            let sceneAnimation2 = gsap.timeline();

            sceneAnimation2.fromTo('svg#level3Bg2 #centerScreen', 1,{opacity: 1, scale: 1}, {opacity: 0, scale: .5, transformOrigin: "center"}, 0);
            sceneAnimation2.fromTo('#level3 #scene33 .question', 1,{opacity: 0, scale: .5}, {opacity: 1, scale: 1}, 0.5);
            sceneAnimation2.fromTo('#level3 #scene33 button', 1,{opacity: 0, bottom: 0}, {opacity: 1, bottom: "10%"}, 0.5);

        }, 1250);

    }

    goLevel32Scene2(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        sceneAnimation.fromTo('#level3 #scene33', 1,{opacity: 1}, {opacity: 0}, 0);
        sceneAnimation.fromTo('#level3 #scene34', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);
        sceneAnimation.fromTo('svg#level3Bg2', 1,{opacity: 1}, {opacity: 0}, 0);
        sceneAnimation.fromTo('svg#level3Bg3', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);
        sceneAnimation.to('#level3 #scene33', 0,{visibility: 'hidden'}, 1);
        sceneAnimation.fromTo('#level3 #scene34 .question', 0,{opacity: 0, y: -100}, {opacity: 1, y: 0}, 1);

    }

    goLevel32Timer(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        sceneAnimation.to('#level3 #scene34 .question', 1,{opacity: 0, y: -100, onComplete: function (){
            gsap.to('#level3 #scene34 .question', 0, {visibility: 'hidden'});
        }}, 0);
        sceneAnimation.to('#level3 #scene34 #shadeBg', 1,{opacity: 0}, 0);
        sceneAnimation.to('#level3 #level3Bg3', 1,{x: '-50%', scale: 1.025}, 0);
        sceneAnimation.fromTo('#level3 #timerCounter', 1,{top: 0, opacity: 0}, {top: 36, opacity: 1}, 0);
        sceneAnimation.to('#level3 #scene34 #formFail', 1, {opacity: 0, visibility: 'hidden', marginTop: -100}, 0);

        let seconds = 20;
        let timerCounter = document.getElementById("timerCounter");

        let oilStatus = false;

        //On Space press
        document.addEventListener('keyup', e => {

            e.preventDefault();

            if (e.code === 'Space') {

                let oil1 = document.querySelector('#level3Bg3 #oil1'),
                    oil1OldStatus = parseInt(oil1.style.strokeDashoffset),
                    oil1NewStatus;

                let pumpOilAudio    = document.createElement("audio");
                pumpOilAudio.src  = "/music/oil_pump.mp3";

                if(oil1OldStatus < 0){
                    oil1NewStatus = oil1OldStatus + 300;
                    gsap.fromTo('#level3Bg3 #oil1', 1, {strokeDashoffset: oil1OldStatus}, {strokeDashoffset: oil1NewStatus}, 0);
                    pumpOilAudio.play();
                } else {

                    let oil2 = document.querySelector('#level3Bg3 #oil2'),
                        oil2OldStatus = parseInt(oil2.style.strokeDashoffset),
                        oil2NewStatus;

                    if(oil2OldStatus < 0){
                        oil2NewStatus = oil2OldStatus + 300;
                        gsap.fromTo('#level3Bg3 #oil2', 1, {strokeDashoffset: oil2OldStatus}, {strokeDashoffset: oil2NewStatus}, 0);
                        pumpOilAudio.play();
                    } else {
                        gsap.to('#level3Bg3 .fillOil', 1, {fill: '#013585'}, 0);
                        oilStatus = true;
                    }

                }

            }

        });

        let timeOut = false;

        //On wheel
        document.addEventListener('wheel', e => {

            e.preventDefault();

            if(timeOut == false){

                timeOut = true;

                setTimeout(function (){

                    timeOut = false;

                }, 1000);

                let oil1 = document.querySelector('#level3Bg3 #oil1'),
                    oil1OldStatus = parseInt(oil1.style.strokeDashoffset),
                    oil1NewStatus;

                let pumpOilAudio    = document.createElement("audio");
                pumpOilAudio.src  = "/music/oil_pump.mp3";

                if(oil1OldStatus < 0){
                    oil1NewStatus = oil1OldStatus + 600;
                    gsap.fromTo('#level3Bg3 #oil1', 1, {strokeDashoffset: oil1OldStatus}, {strokeDashoffset: oil1NewStatus}, 0);
                    pumpOilAudio.play();
                } else {

                    let oil2 = document.querySelector('#level3Bg3 #oil2'),
                        oil2OldStatus = parseInt(oil2.style.strokeDashoffset),
                        oil2NewStatus;

                    if(oil2OldStatus < 0){
                        oil2NewStatus = oil2OldStatus + 600;
                        gsap.fromTo('#level3Bg3 #oil2', 1, {strokeDashoffset: oil2OldStatus}, {strokeDashoffset: oil2NewStatus}, 0);
                        pumpOilAudio.play();
                    } else {
                        gsap.to('#level3Bg3 .fillOil', 1, {fill: '#013585'}, 0);
                        oilStatus = true;
                    }

                }

            }

        });


        //On touch
        document.addEventListener('touchstart', e => {

            e.preventDefault();

            if(timeOut == false){

                timeOut = true;

                setTimeout(function (){

                    timeOut = false;

                }, 1000);

                let oil1 = document.querySelector('#level3Bg3 #oil1'),
                    oil1OldStatus = parseInt(oil1.style.strokeDashoffset),
                    oil1NewStatus;

                let pumpOilAudio    = document.createElement("audio");
                pumpOilAudio.src  = "/music/oil_pump.mp3";

                if(oil1OldStatus < 0){
                    oil1NewStatus = oil1OldStatus + 600;
                    gsap.fromTo('#level3Bg3 #oil1', 1, {strokeDashoffset: oil1OldStatus}, {strokeDashoffset: oil1NewStatus}, 0);
                    pumpOilAudio.play();
                } else {

                    let oil2 = document.querySelector('#level3Bg3 #oil2'),
                        oil2OldStatus = parseInt(oil2.style.strokeDashoffset),
                        oil2NewStatus;

                    if(oil2OldStatus < 0){
                        oil2NewStatus = oil2OldStatus + 600;
                        gsap.fromTo('#level3Bg3 #oil2', 1, {strokeDashoffset: oil2OldStatus}, {strokeDashoffset: oil2NewStatus}, 0);
                        pumpOilAudio.play();
                    } else {
                        gsap.to('#level3Bg3 .fillOil', 1, {fill: '#013585'}, 0);
                        oilStatus = true;
                    }

                }

            }

        });

        let countDown = setInterval(() => {

            if (seconds > 0 && oilStatus == false) {

                seconds--;

                timerCounter.innerHTML = "00:" + (seconds < 10 ? "0" : "") + String(seconds);

                let oil1 = document.querySelector('#level3Bg3 #oil1'),
                    oil1OldStatus = parseInt(oil1.style.strokeDashoffset),
                    oil1NewStatus = oil1OldStatus - 100;

                if(oil1OldStatus < 0 && oil1OldStatus > -1600){

                    gsap.to('#level3Bg3 #oil1', 1, {strokeDashoffset: oil1NewStatus}, 0);

                } else {

                    let oil2 = document.querySelector('#level3Bg3 #oil2'),
                        oil2OldStatus = parseInt(oil2.style.strokeDashoffset),
                        oil2NewStatus = oil2OldStatus - 100;

                    if(oil2OldStatus < 0 && oil2OldStatus > -1400){

                        gsap.to('#level3Bg3 #oil2', 1, {strokeDashoffset: oil2NewStatus}, 0);

                    }

                }

            } else {

                clearInterval(countDown);

                let sceneAnimation2 = gsap.timeline();

                sceneAnimation2.to('#level3 #scene34 #shadeBg', 1,{opacity: .7}, 0);
                sceneAnimation2.to('#level3 #level3Bg3', 1,{x: '-75%', scale: 1}, 0);
                sceneAnimation2.to('#level3 #timerCounter', 1,{top: 0, opacity: 0}, 0);

                //Success
                if(oilStatus){
                    let yesAudio    = document.createElement("audio");
                    yesAudio.src  = "/music/yes.mp3";
                    yesAudio.play();

                    sceneAnimation2.fromTo('#level3 #scene34 #formSuccess', 1, {opacity: 0, visibility: 'hidden', marginTop: 100},{opacity: 1, visibility: 'visible', marginTop: 0}, 0.5);
                //Fail
                } else {
                    let noAudio    = document.createElement("audio");
                    noAudio.src  = "/music/no.mp3";
                    noAudio.play();

                    sceneAnimation2.fromTo('#level3 #scene34 #formFail', 1, {opacity: 0, visibility: 'hidden', marginTop: 100},{opacity: 1, visibility: 'visible', marginTop: 0}, 0);
                }

            }


        }, 1000);

    }

    /* Level 4 */

    startLevel41(e){

        e.preventDefault();

        let L4S1Animation = gsap.timeline();

        //Change level
        L4S1Animation.to('#level', 1,{opacity: 0}, 0);
        L4S1Animation.to('#level .st2', 1,{opacity: 1, strokeDasharray: '80,160', onComplete: function (){
                document.querySelector('#level .levelText .currentLevel').textContent = 4;
            }}, 0);

        let splitL4S1Title = new SplitText('#level4 #scene41 .question', {type: "words"});

        L4S1Animation.to('#level3', 1, {opacity: 0, scale: 1.1, ease: Power2.easeInOut}, 0)
        L4S1Animation.to('#level4', 1, {visibility: 'visible', opacity: 1, ease: Power2.easeInOut}, 0)
        L4S1Animation.from(splitL4S1Title.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.15}, 0)
        L4S1Animation.fromTo('#level4 #scene41 .level',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, 0)
        L4S1Animation.fromTo('#level4 #scene41 .goAhead',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, .5)

    }

    goLevel41Scene1(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        if(this.isMobile){
            sceneAnimation.to('#level4 #men', 2, {x: -2*window.innerWidth, ease: Power2.easeOut}, 1);
        } else {
            sceneAnimation.to('#level4 #men', 2, {x: -window.innerWidth, ease: Power2.easeOut}, 1);
        }
        sceneAnimation.fromTo('#level4 #scene41', 1,{opacity: 1}, {opacity: 0}, 1);
        sceneAnimation.fromTo('#level4 #scene42', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 1);
        sceneAnimation.to('#level4 #scene41', 0,{visibility: 'hidden'}, 2);
        sceneAnimation.fromTo('#level4 #level41Answer1', 1,{y:window.innerWidth/2}, {y:0, ease: Power2.easeOut}, 1);
        sceneAnimation.fromTo('#level4 #level41Answer2', 1,{y:window.innerWidth/2},{y:0, ease: Power2.easeOut}, 1.25);

    }

    doAnswerLevel41(e){

        e.preventDefault();

        let currentAnswer = e.currentTarget,
            sceneAnimation = gsap.timeline();

        if(currentAnswer.classList.contains('wrongAnswer')){

            currentAnswer.classList.remove('wrongAnswer');

            sceneAnimation.to('#level4 #level41Answer1 > span', .5,{y: 0, opacity: 1, ease: Power2.easeIn}, 0.25);
            sceneAnimation.fromTo('#level4 #level41Answer1 .result', .5,{y:0, opacity: 1}, {y:50, opacity: 0, ease: Power2.easeIn}, 0);
            sceneAnimation.to('#level4 #level41Answer1', 1,{x:0, ease: Power2.easeIn}, .5);
            sceneAnimation.to('#level4 #level41Answer2', 1,{yPercent:0, ease: Power2.easeIn}, .75);
            if(this.isMobile){
                sceneAnimation.to('#level4 #men', 2, {x: -2*window.innerWidth, ease: Power2.easeOut}, 0);
            } else {
                sceneAnimation.to('#level4 #men', 2, {x: -window.innerWidth, ease: Power2.easeOut}, 0);
            }
            sceneAnimation.to('#level4 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
            sceneAnimation.to('#level4 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);

        } else if(currentAnswer.classList.contains('rightAnswer')){

            sceneAnimation.fromTo('#level4 #scene42', 1,{opacity: 1, xPercent: 0}, {opacity: 0, xPercent: 100}, 0);
            sceneAnimation.fromTo('#level4 #scene43', 1,{opacity: 0, yPercent: 100}, {opacity: 1, yPercent: 0, visibility: 'visible'}, 0);
            sceneAnimation.to('#level4 #scene42', 0,{visibility: 'hidden'}, 1);

        } else {

            sceneAnimation.fromTo('#level4 #men', 2, {x: -window.innerWidth}, {x: 0, ease: Power2.easeOut}, .75);

            //Wrong Answer
            if(currentAnswer.id == 'level41Answer1'){

                currentAnswer.classList.add('wrongAnswer');

                let noAudio    = document.createElement("audio");
                noAudio.src  = "/music/no.mp3";
                noAudio.play();

                sceneAnimation.to('#level4 #level41Answer1 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level4 #level41Answer1 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level4 #level41Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level4 #level41Answer1', 1,{x:window.innerWidth/3, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level4 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level4 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            } else {

                currentAnswer.classList.add('rightAnswer');

                let yesAudio    = document.createElement("audio");
                yesAudio.src  = "/music/yes.mp3";
                yesAudio.play();

                sceneAnimation.to('#level4 #level41Answer2 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level4 #level41Answer2 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level4 #level41Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level4 #level41Answer2', 1,{x: window.innerWidth/12, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level4 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level4 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level4 #men #leftHand', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                sceneAnimation.to('#level4 #men #leftHand .forearm', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"50% 18%"}, 1);

            }

        }

    }

    startLevel42(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        sceneAnimation.to('#level4 #men #leftHand', 1, {rotate: 0, ease: Power2.easeOut}, 0);
        sceneAnimation.to('#level4 #men #leftHand .forearm', 1, {rotate: 0, ease: Power2.easeOut}, 0);
        sceneAnimation.to('#level4 #men', 2, {x: -window.innerWidth, ease: Power2.easeOut}, 0);
        sceneAnimation.fromTo('#level4 #scene43', 1,{opacity: 1, y: 0}, {opacity: 0, y: window.innerHeight}, 0);
        sceneAnimation.fromTo('#level4 #scene44', 1,{opacity: 0, y: -window.innerHeight}, {opacity: 1, y: 0, visibility: 'visible'}, 0);
        sceneAnimation.to('#level4 #scene43', 0,{visibility: 'hidden'}, 1);
        sceneAnimation.fromTo('#level4 #scene44 .goAhead', 1,{opacity: 0, y: 100}, {opacity: 1, y: 0}, 1);
        sceneAnimation.fromTo('svg#level4Bg', 1,{opacity: 1}, {opacity: 0}, 0);
        sceneAnimation.fromTo('svg#level4Bg2', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);

    }

    goLevel41Scene2(e){

        e.preventDefault();

        gsap.registerPlugin(MotionPathPlugin);

        let sceneAnimation = gsap.timeline();

        sceneAnimation.fromTo('svg#level4Bg2', 1,{opacity: 1}, {opacity: 0}, 0);
        sceneAnimation.fromTo('svg#level4Bg3', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);
        sceneAnimation.fromTo('#level4SeaBg', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);
        sceneAnimation.fromTo('#level4 #scene44', 1,{opacity: 1, scale: 1}, {opacity: 1, scale: 1.1, visibility: 'hidden'}, 0);
        sceneAnimation.fromTo('#level4 #scene45', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 0);

        let moveCounter = 0,
            callDawn = 0;

        document.addEventListener('keydown', function (e){

            //e.preventDefault();

            let myThis = this;

            if(callDawn == 0){

                switch (moveCounter){

                    case 0:

                        //key UP or W
                        if(e.keyCode == 38 || e.keyCode == 87){

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath:{
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .477,
                                    end: .41,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 1:

                        //key RIGHT or D
                        if(e.keyCode == 39 || e.keyCode == 68) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .41,
                                    end: .36,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 2:

                        //key UP or W
                        if(e.keyCode == 38 || e.keyCode == 87) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .36,
                                    end: .32,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 3:

                        //key RIGHT or D
                        if(e.keyCode == 39 || e.keyCode == 68) {

                            gsap.to("#topchev", {
                                duration: 2,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .32,
                                    end: .1,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 4:

                        //key DOWN or S
                        if(e.keyCode == 40 || e.keyCode == 83) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .1,
                                    end: .06,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 5:

                        //key RIGHT or D
                        if(e.keyCode == 39 || e.keyCode == 68) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: .06,
                                    end: 0,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 6:

                        //key DOWN or S
                        if(e.keyCode == 40 || e.keyCode == 83) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: 0,
                                    end: -0.07,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 7:

                        //key LEFT or A
                        if(e.keyCode == 37 || e.keyCode == 65) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -0.07,
                                    end: -0.12,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 8:

                        //key DOWN or S
                        if(e.keyCode == 40 || e.keyCode == 83) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -.12,
                                    end: -.17,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 9:

                        //key LEFT or A
                        if(e.keyCode == 37 || e.keyCode == 65) {

                            gsap.to("#topchev", {
                                duration: 2,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -.17,
                                    end: -.39,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 10:

                        //key UP or W
                        if(e.keyCode == 38 || e.keyCode == 87) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -.39,
                                    end: -.43,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 11:

                        //key LEFT or A
                        if(e.keyCode == 37 || e.keyCode == 65) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -.43,
                                    end: -.48,
                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                    case 12:

                        //key UP or W
                        if(e.keyCode == 38 || e.keyCode == 87) {

                            gsap.to("#topchev", {
                                duration: 1,
                                ease: "power1.inOut",
                                motionPath: {
                                    path: "#route path",
                                    align: "#route path",
                                    autoRotate: 90,
                                    alignOrigin: [.5, .5],
                                    start: -.48,
                                    end: -.55,
                                },
                                onComplete: function (){

                                    let sceneAnimation = gsap.timeline();

                                    //Scene
                                    sceneAnimation.fromTo('#level4 #scene45', 1,{opacity: 1}, {opacity: 0}, 0);
                                    sceneAnimation.fromTo('#level4 #scene46', 1,{opacity: 0}, {opacity: 1}, 0);
                                    sceneAnimation.to('#level4 #scene45', 0,{visibility: 'hidden'}, 1);
                                    sceneAnimation.to('#level4 #scene46', 0,{visibility: 'visible'}, 1);
                                    //BG
                                    sceneAnimation.fromTo('svg#level4Bg3', 1,{opacity: 1}, {opacity: 0}, 0);
                                    sceneAnimation.fromTo('#level4SeaBg', 1,{opacity: 1}, {opacity: 0}, 0);
                                    sceneAnimation.fromTo('svg#level4Bg', 1,{opacity: 0}, {opacity: 1}, 0);
                                    sceneAnimation.to('svg#level4Bg3', 0,{visibility: 'hidden'}, 1);
                                    sceneAnimation.to('#level4SeaBg', 0,{visibility: 'hidden'}, 1);
                                    sceneAnimation.to('svg#level4Bg', 0,{visibility: 'visible'}, 1);
                                    //Men
                                    sceneAnimation.to('#level4 #men', 2, {x: 0, ease: Power2.easeOut}, 0);
                                    sceneAnimation.to('#level4 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                                    sceneAnimation.to('#level4 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                                    sceneAnimation.to('#level4 #men #leftHand', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                                    sceneAnimation.to('#level4 #men #leftHand .forearm', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"50% 18%"}, 1);

                                    let yesAudio    = document.createElement("audio");
                                    yesAudio.src  = "/music/yes.mp3";
                                    yesAudio.play();

                                }
                            });

                            let rightDirectionAudio    = document.createElement("audio");
                            rightDirectionAudio.src  = "/music/ship_right_direction.mp3";
                            rightDirectionAudio.play();

                            moveCounter++;

                        } else {

                            let wrongDirectionAudio    = document.createElement("audio");
                            wrongDirectionAudio.src  = "/music/ship_wrong_direction.mp3";
                            wrongDirectionAudio.play();

                        }

                        break;

                }

            }

            callDawn = 1;

            setTimeout(function (){
                callDawn = 0;
            }, 1000);

        });

    }

    goUp(){
        var event = new KeyboardEvent('keydown', {'keyCode': 38});
        document.dispatchEvent(event);
    }

    goDown(){
        var event = new KeyboardEvent('keydown', {'keyCode': 40});
        document.dispatchEvent(event);
    }

    goLeft(){
        var event = new KeyboardEvent('keydown', {'keyCode': 37});
        document.dispatchEvent(event);
    }

    goRight(){
        var event = new KeyboardEvent('keydown', {'keyCode': 39});
        document.dispatchEvent(event);
    }


    /* Level 5 */

    startLevel51(e){

        e.preventDefault();

        let L5S1Animation = gsap.timeline();

        //Men
        L5S1Animation.to('#level4 #men', 2, {x: -window.innerWidth, ease: Power2.easeOut}, 0);
        L5S1Animation.fromTo('#level5 #men', 2, {x: window.innerWidth}, {x: 0, ease: Power2.easeOut}, 0);

        //Change level
        L5S1Animation.to('#level', 1,{opacity: 0}, 0);
        L5S1Animation.to('#level .st2', 1,{opacity: 1, strokeDasharray: '120,160', onComplete: function (){
            document.querySelector('#level .levelText .currentLevel').textContent = 5;
        }}, 0);

        let splitL5S1Title = new SplitText('#level5 #scene51 .question', {type: "words"});

        L5S1Animation.to('#level4', 1, {opacity: 0, scale: 1.1, ease: Power2.easeInOut}, 0)
        L5S1Animation.to('#level5', 1, {visibility: 'visible', opacity: 1, ease: Power2.easeInOut}, 0)
        L5S1Animation.from(splitL5S1Title.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.15}, 0)
        L5S1Animation.fromTo('#level5 #scene51 .level',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, 0)
        L5S1Animation.fromTo('#level5 #scene51 .goAhead',.5, {y: 25, opacity: 0}, {y: 0, opacity: 1}, .5)

    }

    goLevel51Scene1(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        sceneAnimation.fromTo('#level5 #scene51', 1,{opacity: 1}, {opacity: 0});
        sceneAnimation.fromTo('#level5 #scene52', 1,{opacity: 0}, {opacity: 1}, 1);
        sceneAnimation.to('#level5 #scene51', 0,{visibility: 'hidden'}, 1);
        sceneAnimation.to('#level5 #scene52', 0,{visibility: 'visible'}, 1);

        if(this.isMobile){
            sceneAnimation.to('#level5 #men', 2, {x: -window.innerWidth*2.5, ease: Power2.easeOut}, .5);
        } else {
            sceneAnimation.to('#level5 #men', 2, {x: -window.innerWidth*1.25, ease: Power2.easeOut}, .5);
        }

    }

    doAnswerLevel51(e){

        e.preventDefault();

        let currentAnswer = e.currentTarget,
            sceneAnimation = gsap.timeline();

        if(currentAnswer.classList.contains('wrongAnswer')){

            currentAnswer.classList.remove('wrongAnswer');

            sceneAnimation.to('#level5 #level51Answer1 > span', .5,{y: 0, opacity: 1, ease: Power2.easeIn}, 0.25);
            sceneAnimation.fromTo('#level5 #level51Answer1 .result', .5,{y:0, opacity: 1}, {y:50, opacity: 0, ease: Power2.easeIn}, 0);
            sceneAnimation.to('#level5 #level51Answer1', 1,{x:0, ease: Power2.easeIn}, .5);
            sceneAnimation.to('#level5 #level51Answer2', 1,{yPercent:0, ease: Power2.easeIn}, .5);
            if(this.isMobile){
                sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth*2.5, ease: Power2.easeOut}, 0);
            } else {
                sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth*1.25, ease: Power2.easeOut}, 0);
            }
            sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
            sceneAnimation.to('#level5 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);

        } else if(currentAnswer.classList.contains('rightAnswer')){

            sceneAnimation.fromTo('#level5 #scene52', 1,{opacity: 1, xPercent: 0}, {opacity: 0, xPercent: 100}, 0);
            sceneAnimation.fromTo('#level5 #scene53', 1,{opacity: 0, yPercent: 100}, {opacity: 1, yPercent: 0, visibility: 'visible'}, 0);
            sceneAnimation.to('#level5 #scene52', 0,{visibility: 'hidden'}, 1);

            let ovationsAudio    = document.createElement("audio");
            ovationsAudio.src  = "/music/ovations.mp3";
            ovationsAudio.play();

        } else {

            if(this.isMobile) {
                sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth, ease: Power2.easeOut}, .75);
            } else {
                sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth / 2, ease: Power2.easeOut}, .75);
            }

            //Wrong Answer
            if(currentAnswer.id == 'level51Answer1'){

                currentAnswer.classList.add('wrongAnswer');

                let noAudio    = document.createElement("audio");
                noAudio.src  = "/music/no.mp3";
                noAudio.play();

                sceneAnimation.to('#level5 #level51Answer1 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level5 #level51Answer1 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level5 #level51Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level5 #level51Answer1', 1,{x:window.innerWidth/4 + 100, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

                //Right Answer
            } else {

                currentAnswer.classList.add('rightAnswer');

                let yesAudio    = document.createElement("audio");
                yesAudio.src  = "/music/yes.mp3";
                yesAudio.play();

                sceneAnimation.to('#level5 #level51Answer2 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level5 #level51Answer2 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level5 #level51Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                if(this.isMobile){
                    sceneAnimation.to('#level5 #level51Answer2', 1,{x: 50, ease: Power2.easeIn}, .5);
                } else {
                    sceneAnimation.to('#level5 #level51Answer2', 1,{x: 100, ease: Power2.easeIn}, .5);
                }
                sceneAnimation.to('#level5 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #leftHand', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                sceneAnimation.to('#level5 #men #leftHand .forearm', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"50% 18%"}, 1);

            }

        }

    }

    startLevel52(e){

        e.preventDefault();

        let sceneAnimation = gsap.timeline();

        let splitL5S2Title = new SplitText('#level5 #scene54 .question', {type: "words"});

        sceneAnimation.to('#level5 #men #leftHand', 1, {rotate: 0, ease: Power2.easeOut}, 1);
        sceneAnimation.to('#level5 #men #leftHand .forearm', 1, {rotate: 0, ease: Power2.easeOut}, 1);
        sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth*1.25, ease: Power2.easeOut}, 1);
        sceneAnimation.fromTo('#level5 #scene53', 1,{opacity: 1}, {opacity: 0}, 1);
        sceneAnimation.fromTo('#level5 #scene54', 1,{opacity: 0}, {opacity: 1, visibility: 'visible'}, 1);
        sceneAnimation.to('#level5 #scene53', 0,{visibility: 'hidden'}, 2);
        sceneAnimation.from(splitL5S2Title.words,{duration: 1, yPercent: 150, opacity: 0, stagger: 0.15}, 1)
        sceneAnimation.fromTo('#level5 #level52Answer1', 1,{y:window.innerWidth/2}, {y:0, ease: Power2.easeOut}, 1);
        sceneAnimation.fromTo('#level5 #level52Answer2', 1,{y:window.innerWidth/2},{y:0, ease: Power2.easeOut}, 1.25);

    }

    doAnswerLevel52(e){

        e.preventDefault();

        let currentAnswer = e.currentTarget,
            sceneAnimation = gsap.timeline();

        if(currentAnswer.classList.contains('wrongAnswer')){

            currentAnswer.classList.remove('wrongAnswer');

            sceneAnimation.to('#level5 #level52Answer1 > span', .5,{y: 0, opacity: 1, ease: Power2.easeIn}, 0.25);
            sceneAnimation.fromTo('#level5 #level52Answer1 .result', .5,{y:0, opacity: 1}, {y:50, opacity: 0, ease: Power2.easeIn}, 0);
            sceneAnimation.to('#level5 #level52Answer1', 1,{x:0, ease: Power2.easeIn}, .5);
            sceneAnimation.to('#level5 #level52Answer2', 1,{yPercent:0, ease: Power2.easeIn}, .5);
            sceneAnimation.to('#level5 #scene54 .question', 1, {y: 0, ease: Power2.easeOut}, 0);
            sceneAnimation.to('#level5 #scene54 .answer', 1, {y: 0, ease: Power2.easeOut}, 0);
            sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth*1.25, ease: Power2.easeOut}, 0);
            sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
            sceneAnimation.to('#level5 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);

        } else if(currentAnswer.classList.contains('rightAnswer')){

            sceneAnimation.fromTo('#level5 #scene54', 1,{opacity: 1, xPercent: 0}, {opacity: 0, xPercent: 100}, 0);
            sceneAnimation.fromTo('#level5 #scene55', 1,{opacity: 0, yPercent: 100}, {opacity: 1, yPercent: 0, visibility: 'visible'}, 0);
            sceneAnimation.to('#level5 #scene54', 0,{visibility: 'hidden'}, 1);

        } else {

            sceneAnimation.to('#level5 #men', 1, {x: -window.innerWidth/2, ease: Power2.easeOut}, .75);
            sceneAnimation.to('#level5 #scene54 .question', 1, {y: -window.innerHeight/2, ease: Power2.easeOut}, 0);
            sceneAnimation.to('#level5 #scene54 .answer', 1, {y: -100, ease: Power2.easeOut}, 0);

            //Wrong Answer
            if(currentAnswer.id == 'level52Answer1'){

                currentAnswer.classList.add('wrongAnswer');

                sceneAnimation.to('#level5 #level52Answer1 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level5 #level52Answer1 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level5 #level52Answer2', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level5 #level52Answer1', 1,{x:window.innerWidth/4 + 100, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #upset', .5, {opacity: 1, ease: Power2.easeOut}, .5);

            //Right Answer
            } else {

                currentAnswer.classList.add('rightAnswer');

                sceneAnimation.to('#level5 #level52Answer2 > span', .5,{y:-50, opacity: 0, ease: Power2.easeIn}, 0);
                sceneAnimation.fromTo('#level5 #level52Answer2 .result', .5,{y:50, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeIn}, .25);
                sceneAnimation.to('#level5 #level52Answer1', 1,{yPercent:250, ease: Power2.easeIn}, 0);
                sceneAnimation.to('#level5 #level52Answer2', 1,{x: 100, ease: Power2.easeIn}, .5);
                sceneAnimation.to('#level5 #men #upset', .5, {opacity: 0, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #mouth', .5, {opacity: 1, ease: Power2.easeOut}, .5);
                sceneAnimation.to('#level5 #men #leftHand', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"15% 0%"}, 1);
                sceneAnimation.to('#level5 #men #leftHand .forearm', 1, {rotate: -90, ease: Power2.easeOut, transformOrigin:"50% 18%"}, 1);

            }

        }

    }

    theEnd(e){
        e.preventDefault();
        alert('That`s all Folks ;-)');
    }

    componentWillUnmount() {



    }

    render() {

        const { data, isLoaded } = this.state;

        const meta = {
            title: data.length == 0 ? '' : data.content.title,//data.content.title,
            description: data.length == 0 ? '' : data.content.description,
            canonical: '',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: data.length == 0 ? '' : data.content.keywords
                }
            }
        };

        return(
            <DocumentMeta {...meta}>

                {isLoaded ? (

                    <div id="wrapperGame" className="wrapper">

                        <div id="level1" className="level">
                            <div id="scene1" className="scene">
                                <h1 id="gameTitle"><span>Добудь арктическую нефть</span><br/> <span>с «Приразломной»</span></h1>
                                <button id="startGame">Начать игру</button>
                            </div>
                            <div id="scene2" className="scene">
                                <div className="sceneContent">
                                    <div className="level">Уровень 1</div>
                                    <div className="question">Как попасть на платформу «Приразломная»?</div>
                                    <button className="goAhead">Далее</button>
                                </div>
                            </div>
                            <div id="scene3" className="scene">
                                <div className="sceneContent">
                                    <a id="level1Answer1" className="answer" href="#" onClick={this.doAnswerLevel1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.73 268.73">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="circle">
                                                    <circle className="cls-1" cx="134.36" cy="134.36" r="123.86"/>
                                                    <g id="helicopter">
                                                        <path className="cls-2"
                                                              d="M118.89,125.08h72.22a26.79,26.79,0,0,1,26.79,26.79v0a9.14,9.14,0,0,1-9.14,9.14H154.82a35.93,35.93,0,0,1-35.93-35.93v0A0,0,0,0,1,118.89,125.08Z"/>
                                                        <path className="cls-2"
                                                              d="M192.82,125.14a10,10,0,0,0-10-10H59.2l-2.86-2.86c-.19-.19-.39-.37-.6-.55-4.77-4.06-11,3-6.6,7.46L54.92,125v.15Z"/>
                                                        <path className="cls-2"
                                                              d="M141.53,108h28a7.17,7.17,0,0,1,7.17,7.17v0a0,0,0,0,1,0,0H134.36a0,0,0,0,1,0,0v0A7.17,7.17,0,0,1,141.53,108Z"/>
                                                        <rect className="cls-3" x="177.7" y="133.32" width="7.68"
                                                              height="15.36" rx="2"/>
                                                        <rect className="cls-3" x="154.36" y="133.32" width="7.68"
                                                              height="15.36" rx="2"/>
                                                        <circle className="cls-2" cx="196.18" cy="167.66" r="4.54"/>
                                                        <circle className="cls-2" cx="153.71" cy="167.24" r="4.96"/>
                                                        <path className="cls-3"
                                                              d="M191.64,135.32v11.37a2,2,0,0,0,2,2h24.07a26.75,26.75,0,0,0-7.27-15.37h-16.8A2,2,0,0,0,191.64,135.32Z"/>
                                                        <line className="cls-4" x1="155.53" y1="101.16" x2="155.53"
                                                              y2="115.16"/>
                                                        <path className="cls-4"
                                                              d="M75.77,108.16s34.59-8,79.76-8,79.76,8,79.76,8"/>
                                                        <line className="cls-4" x1="42.23" y1="105.16" x2="59.34"
                                                              y2="122.27"/>
                                                        <line className="cls-4" x1="42.23" y1="123.77" x2="60.84"
                                                              y2="105.16"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>На работу<br/> на вертолёте</span>
                                        <div className="result">
                                            <p><strong>Верно.</strong><br/>Вахтовики летают на «Приразломную» на вертолетах в специальных спасательных гидрокостюмах. Это максимально быстрый и безопасный способ доставки рабочего персонала на платформу</p>
                                            <button>Дальше</button>
                                        </div>
                                    </a>
                                    <a id="level1Answer2" className="answer" href="#" onClick={this.doAnswerLevel1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.73 268.73">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="circle2">
                                                    <circle className="cls-1" cx="134.36" cy="134.36" r="123.86"/>
                                                    <g id="ship">
                                                        <path className="cls-2"
                                                              d="M181.2,166.49H93.1a23.2,23.2,0,0,1-23.19-23.2V135A2.12,2.12,0,0,1,72,132.85h51.22V140h63.63a7.11,7.11,0,0,1,7.11-7.11h8.79a2.12,2.12,0,0,1,2.13,2.12v7.81A23.72,23.72,0,0,1,181.2,166.49Z"/>
                                                        <rect className="cls-2" x="79.78" y="105.06" width="43.48"
                                                              height="29.5"/>
                                                        <path className="cls-2"
                                                              d="M75.25,96.36h55.43a0,0,0,0,1,0,0V98a12.28,12.28,0,0,1-12.28,12.28H80.32a6.07,6.07,0,0,1-6.07-6.07V97.36A1,1,0,0,1,75.25,96.36Z"/>
                                                        <path className="cls-3"
                                                              d="M126.63,106.67H113a1.06,1.06,0,0,1-1.06-1.06V101A1.06,1.06,0,0,1,113,100h17.39A13,13,0,0,1,126.63,106.67Z"/>
                                                        <rect className="cls-3" x="113" y="111.51" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="113" y="118.84" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="113" y="126.18" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="113" y="133.51" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="128.18" y="144.2" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="139.25" y="144.2" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="150.32" y="144.2" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="161.38" y="144.2" width="4.42"
                                                              height="4.42" rx="1"/>
                                                        <rect className="cls-3" x="195.8" y="136.78" width="2.3"
                                                              height="2.3" rx="1"/>
                                                        <polygon className="cls-2"
                                                                 points="110.71 97.78 99.32 97.78 95.31 85.83 106.7 85.83 110.71 97.78"/>
                                                        <path className="cls-4"
                                                              d="M54.71,172c7.32,0,7.32,6,14.64,6s7.31-6,14.63-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.64,6,7.32-6,14.64-6,7.32,6,14.64,6"/>
                                                        <path className="cls-4"
                                                              d="M54.71,184.37c7.32,0,7.32,6,14.64,6s7.31-6,14.63-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.63,6,7.32-6,14.64-6,7.32,6,14.64,6,7.32-6,14.64-6,7.32,6,14.64,6"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Я поплыву<br/> на корабле!</span>
                                        <div className="result">
                                            <p><strong>Увы, неверно.</strong><br/>Специальные суда снабжения доставляют на платформу грузы, необходимые для работы «Приразломной». Носовая часть у них, как у ледокола. А сзади — грузовая палуба. Начни уровень сначала!</p>
                                            <button>Заново</button>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <svg id="level1Bg"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect x="-532.27" className="st0" width="1920" height="1080"/>
                                </g>
                                <g id="clouds">
                                    <g className="cloud big">
                                        <path className="st1" d="M185.95,125.93H79.61c-17.92,0-32.44-14.53-32.44-32.44v0c0-17.92,14.53-32.44,32.44-32.44h106.34
			c17.92,0,32.44,14.53,32.44,32.44v0C218.4,111.41,203.87,125.93,185.95,125.93z"/>
                                        <circle className="st1" cx="156.37" cy="66.12" r="32.79"/>
                                    </g>
                                    <g className="cloud big">
                                        <path className="st1" d="M1206.41,421.27h-92.54c-15.89,0-28.77-12.88-28.77-28.77v0c0-15.89,12.88-28.77,28.77-28.77h92.54
			c15.89,0,28.77,12.88,28.77,28.77v0C1235.18,408.39,1222.3,421.27,1206.41,421.27z"/>
                                        <circle className="st1" cx="1180.18" cy="363.73" r="29.08"/>
                                    </g>
                                    <g className="cloud big">
                                        <path className="st1" d="M811.37,317.79h-92.54c-15.89,0-28.77-12.88-28.77-28.77v0c0-15.89,12.88-28.77,28.77-28.77h92.54
			c15.89,0,28.77,12.88,28.77,28.77v0C840.14,304.91,827.26,317.79,811.37,317.79z"/>
                                        <circle className="st1" cx="785.13" cy="260.24" r="29.08"/>
                                    </g>
                                    <g className="cloud middle">
                                        <path className="st1" d="M436.64,323.47h-61.73c-10.6,0-19.19-8.59-19.19-19.19v0c0-10.6,8.59-19.19,19.19-19.19h61.73
			c10.6,0,19.19,8.59,19.19,19.19v0C455.83,314.88,447.24,323.47,436.64,323.47z"/>
                                        <circle className="st1" cx="400.74" cy="285.08" r="19.4"/>
                                    </g>
                                    <g className="cloud middle">
                                        <path className="st1" d="M1080.81,542.46h-61.73c-10.6,0-19.19-8.59-19.19-19.19v0c0-10.6,8.59-19.19,19.19-19.19h61.73
			c10.6,0,19.19,8.59,19.19,19.19v0C1100,533.87,1091.41,542.46,1080.81,542.46z"/>
                                        <circle className="st1" cx="1044.91" cy="504.07" r="19.4"/>
                                    </g>
                                    <g className="cloud middle">
                                        <path className="st1" d="M1176.64,113.47h-61.73c-10.6,0-19.19-8.59-19.19-19.19v0c0-10.6,8.59-19.19,19.19-19.19h61.73
			c10.6,0,19.19,8.59,19.19,19.19v0C1195.83,104.88,1187.24,113.47,1176.64,113.47z"/>
                                        <circle className="st1" cx="1140.74" cy="75.08" r="19.4"/>
                                    </g>
                                    <g className="cloud small">
                                        <path className="st1" d="M1535.98,284.88h-41.01c-7.04,0-12.75-5.71-12.75-12.75v0c0-7.04,5.71-12.75,12.75-12.75h41.01
			c7.04,0,12.75,5.71,12.75,12.75v0C1548.73,279.17,1543.02,284.88,1535.98,284.88z"/>
                                        <circle className="st1" cx="1512.14" cy="259.38" r="12.89"/>
                                    </g>
                                    <g className="cloud small">
                                        <path className="st1" d="M79.48,553.47H38.47c-7.04,0-12.75-5.71-12.75-12.75v0c0-7.04,5.71-12.75,12.75-12.75h41.01
			c7.04,0,12.75,5.71,12.75,12.75v0C92.23,547.76,86.52,553.47,79.48,553.47z"/>
                                        <circle className="st1" cx="55.63" cy="527.97" r="12.89"/>
                                    </g>
                                </g>
                                <g id="ground">
                                    <rect x="-420.46" y="610.58" className="st3" width="1920" height="189.42"/>
                                </g>
                                <g id="sea">
                                    <rect x="-420.46" y="602.06" className="st4" width="1920" height="21.36"/>
                                    <path className="st1"
                                          d="M1029,615.46h-83l0,0c0-4.34,3.52-7.86,7.86-7.86h67.29C1025.48,607.61,1029,611.12,1029,615.46L1029,615.46z"
                                    />
                                    <path className="st1"
                                          d="M-136,612.99h-83l0,0c0-4.34,3.52-7.86,7.86-7.86h67.29C-139.52,605.13-136,608.65-136,612.99L-136,612.99z"/>
                                    <path className="st1" d="M20.36,615.46h-83l0,0c0-4.34,3.52-7.86,7.86-7.86H12.5C16.84,607.61,20.36,611.12,20.36,615.46L20.36,615.46z
		"/>
                                    <path className="st1" d="M-332.02,618.41h-83l0,0c0-4.34,3.52-7.86,7.86-7.86h67.29C-335.54,610.55-332.02,614.07-332.02,618.41
		L-332.02,618.41z"/>
                                    <path className="st1" d="M593.47,618.12h-39v0c0-4.16,3.37-7.54,7.54-7.54h23.93C590.1,610.58,593.47,613.95,593.47,618.12
		L593.47,618.12z"/>
                                    <path className="st1"
                                          d="M-1.64,608.9h-39l0,0c0-4.16,3.37-7.54,7.54-7.54h23.93C-5.02,601.37-1.64,604.74-1.64,608.9L-1.64,608.9z"/>
                                    <path className="st1" d="M487.65,618.85h-84.7l0,0c0-2.08,1.69-3.77,3.77-3.77h77.16C485.97,615.08,487.65,616.77,487.65,618.85
		L487.65,618.85z"/>
                                    <path className="st1" d="M1250.65,614.35h-36l0,0c0-3.45,2.8-6.25,6.25-6.25h23.5C1247.85,608.1,1250.65,610.9,1250.65,614.35
		L1250.65,614.35z"/>
                                    <path className="st1" d="M794.64,616.12h-36l0,0c0-3.45,2.8-6.25,6.25-6.25h23.5C791.84,609.87,794.64,612.67,794.64,616.12
		L794.64,616.12z"/>
                                    <path className="st1" d="M1630.48,612.99h-36l0,0c0-3.45,2.8-6.25,6.25-6.25h23.5C1627.68,606.74,1630.48,609.54,1630.48,612.99
		L1630.48,612.99z"/>
                                    <path className="st1" d="M1317.35,619.39h-83v0c0-4.34,3.52-7.86,7.86-7.86h67.29C1313.83,611.54,1317.35,615.05,1317.35,619.39
		L1317.35,619.39z"/>
                                    <path className="st1" d="M1494.84,612.03h-83l0,0c0-4.34,3.52-7.86,7.86-7.86h67.29C1491.32,604.17,1494.84,607.69,1494.84,612.03
		L1494.84,612.03z"/>
                                    <path className="st1" d="M276.48,612.36h-83l0,0c0-4.34,3.52-7.86,7.86-7.86h67.29C272.96,604.5,276.48,608.02,276.48,612.36
		L276.48,612.36z"/>
                                </g>
                                <g id="buildings">
                                    <g id="building1" className="building">
                                        <rect x="501.28" y="555.14" className="st4" width="316.58" height="84.59"/>
                                        <polygon className="st5"
                                                 points="817.86,555.14 501.28,555.14 531.99,531.01 848.57,531.01 		"/>
                                        <polyline className="st6"
                                                  points="848.57,531.01 879.28,555.14 879.28,639.72 817.86,639.72 817.86,555.14 848.57,531.01 		"/>
                                        <rect x="501.28" y="555.14" className="st7" width="317.24" height="7.3"/>
                                        <g>
                                            <g>
                                                <rect x="519.6" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="527.38" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="523.28" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1104.0824 56.6707)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="552.5" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="560.28" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="556.17" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1136.9768 23.7762)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="585.39" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="593.17" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="589.07" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1169.8713 -9.1183)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="618.29" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="626.07" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="621.96" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1202.7657 -42.0128)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="651.18" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="658.96" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="654.86" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1235.6603 -74.9073)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="684.08" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="691.85" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="687.75" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1268.5548 -107.8018)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="716.97" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="724.75" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="720.65" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1301.4492 -140.6962)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="749.87" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="757.64" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="753.54" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1334.3438 -173.5907)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="782.76" y="572.17" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="790.54" y="572.17" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="786.44" y="576.27"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1367.2383 -206.4852)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                        </g>
                                        <rect x="499.7" y="595.12" className="st8" width="317.06" height="39.32"/>
                                        <g>
                                            <g>
                                                <rect x="519.6" y="605.62" className="st5" width="16.41"
                                                      height="16.41"/>
                                                <rect x="527.38" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="523.28" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1137.5277 90.116)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="552.5" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="560.28" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="556.17" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1170.4222 57.2215)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="585.39" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="593.17" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="589.07" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1203.3167 24.327)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="618.29" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="626.07" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="621.96" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1236.2112 -8.5674)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="651.18" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="658.96" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="654.86" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1269.1056 -41.4619)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="684.08" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="691.85" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="687.75" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1302.0001 -74.3564)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="716.97" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="724.75" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="720.65" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1334.8947 -107.2509)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="749.87" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="757.64" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="753.54" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1367.7891 -140.1454)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                            <g>
                                                <rect x="782.76" y="605.62" className="st3" width="16.41"
                                                      height="16.41"/>
                                                <rect x="790.54" y="605.62" className="st4" width="0.85"
                                                      height="16.41"/>

                                                <rect x="786.44" y="609.72"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1400.6836 -173.0399)"
                                                      className="st4" width="0.85" height="8.2"/>
                                            </g>
                                        </g>
                                        <rect x="736.74" y="520.77" className="st7" width="7.88" height="22.3"/>
                                    </g>
                                    <g id="building2" className="building">
                                        <rect x="1152.58" y="543.52" className="st4" width="472.12" height="126.14"/>
                                        <polygon className="st5"
                                                 points="1624.7,543.52 1152.58,543.52 1198.38,507.54 1670.5,507.54 	"/>
                                        <polyline className="st6"
                                                  points="1670.5,507.54 1716.31,543.52 1716.31,669.67 1624.7,669.67 1624.7,543.52 1670.5,507.54 	"/>
                                        <rect x="1152.58" y="543.52" className="st7" width="472.12" height="10.89"/>
                                        <g>
                                            <g>
                                                <rect x="1179.91" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1191.51" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1185.39" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1767.1903 -604.8616)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1228.96" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1240.57" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1234.45" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1816.2469 -653.9182)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1278.02" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1289.62" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1283.5" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1865.3036 -702.9747)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1327.08" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1338.68" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1332.56" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1914.3601 -752.0313)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1376.13" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1387.73" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1381.62" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1963.4167 -801.088)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1425.19" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1436.79" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1430.67" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2012.4734 -850.1445)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1474.25" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1485.85" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1479.73" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2061.53 -899.2011)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1523.3" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1534.9" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1528.79" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2110.5864 -948.2577)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1572.36" y="568.93" className="st5" width="24.47" height="24.47"/>
                                                <rect x="1583.96" y="568.93" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1577.84" y="575.05"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2159.6431 -997.3143)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                        </g>
                                        <rect x="1152.58" y="603.15" className="st8" width="472.12" height="58.64"/>
                                        <g>
                                            <g>
                                                <rect x="1179.91" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1191.51" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1185.39" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1817.0685 -554.9835)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1228.96" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1240.57" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1234.45" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1866.1251 -604.04)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1278.02" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1289.62" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1283.5" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1915.1816 -653.0966)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1327.08" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1338.68" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1332.56" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1964.2383 -702.1532)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1376.13" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1387.73" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1381.62" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2013.2948 -751.2098)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1425.19" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1436.79" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1430.67" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2062.3516 -800.2664)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1474.25" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1485.85" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1479.73" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2111.408 -849.323)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1523.3" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1534.9" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1528.79" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2160.4646 -898.3796)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                            <g>
                                                <rect x="1572.36" y="618.81" className="st3" width="24.47" height="24.47"/>
                                                <rect x="1583.96" y="618.81" className="st4" width="1.27" height="24.47"/>

                                                <rect x="1577.84" y="624.92"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2209.5212 -947.4362)"
                                                      className="st4" width="1.27" height="12.24"/>
                                            </g>
                                        </g>
                                        <rect x="1503.73" y="492.27" className="st7" width="11.74" height="33.26"/>
                                    </g>
                                    <g id="building3" className="building">
                                        <rect x="56" y="548.36" className="st4" width="402" height="107.41"/>
                                        <polygon className="st5" points="458,548.36 56,548.36 95,517.72 497,517.72 	"/>
                                        <polyline className="st6"
                                                  points="497,517.72 536,548.36 536,655.77 458,655.77 458,548.36 497,517.72 	"/>
                                        <rect x="56" y="548.36" className="st7" width="402" height="9.28"/>
                                        <g>
                                            <g>
                                                <rect x="79.27" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="89.15" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="83.94" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 664.8916 495.9325)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="121.04" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="130.92" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="125.71" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 706.662 454.162)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="162.81" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="172.69" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="167.48" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 748.4325 412.3916)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="204.58" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="214.46" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="209.25" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 790.2029 370.6211)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="246.35" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="256.23" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="251.02" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 831.9734 328.8507)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="288.12" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="298" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="292.79" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 873.7438 287.0802)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="329.89" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="339.77" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="334.56" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 915.5143 245.3098)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="371.66" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="381.54" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="376.33" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 957.2847 203.5393)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="413.43" y="569.99" className="st5" width="20.84" height="20.84"/>
                                                <rect x="423.31" y="569.99" className="st4" width="1.08" height="20.84"/>

                                                <rect x="418.1" y="575.2"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 999.0552 161.7689)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                        </g>
                                        <rect x="56" y="599.13" className="st8" width="402" height="49.93"/>
                                        <g>
                                            <g>
                                                <rect x="79.27" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="89.15" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="83.94" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 707.3615 538.4024)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="121.04" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="130.92" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="125.71" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 749.132 496.632)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="162.81" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="172.69" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="167.48" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 790.9024 454.8615)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="204.58" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="214.46" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="209.25" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 832.6729 413.0911)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="246.35" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="256.23" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="251.02" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 874.4433 371.3206)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="288.12" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="298" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="292.79" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 916.2137 329.5502)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="329.89" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="339.77" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="334.56" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 957.9842 287.7798)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="371.66" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="381.54" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="376.33" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 999.7546 246.0093)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                            <g>
                                                <rect x="413.43" y="612.46" className="st3" width="20.84" height="20.84"/>
                                                <rect x="423.31" y="612.46" className="st4" width="1.08" height="20.84"/>

                                                <rect x="418.1" y="617.67"
                                                      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 1041.5251 204.2388)"
                                                      className="st4" width="1.08" height="10.42"/>
                                            </g>
                                        </g>
                                        <rect x="355" y="504.72" className="st7" width="10" height="28.32"/>
                                    </g>
                                </g>
                                <g id="smoke">
                                    <path id="smoke1" className="st3" d="M806.51,227H648.58c-27.61,0-50,22.39-50,50v0l-0.14,52.65c-0.02,8.48-6.91,15.35-15.39,15.35h-82.07
		c-12.14,0-21.97,9.84-21.97,21.97v25.47c0,8.59-6.96,15.55-15.55,15.55h-64.86c-24.07,0-43.59,19.52-43.59,43.59V485v1.72v18h10
		v-18v0c0-12,9.72-21.72,21.72-21.72h61c22.8,0,41.28-18.48,41.28-41.28V408v0c0-8.59,6.96-15.55,15.55-15.55h89.21
		c8.08,0,14.63-6.55,14.63-14.63v-36.56c0-12.64,10.24-22.88,22.88-22.88h175.24c25.23,0,45.69-20.46,45.69-45.69v0
		C852.2,247.46,831.74,227,806.51,227z"/>
                                    <path id="smoke3" className="st3" d="M1088.87,304.18H965.7c-21.54,0-38.99,17.46-38.99,38.99v0l-0.11,41.06c-0.02,6.62-5.39,11.97-12,11.97h-64.01
		c-9.46,0-17.14,7.67-17.14,17.14v19.86c0,6.7-5.43,12.13-12.13,12.13h-50.58c-18.77,0-34,15.22-34,33.99v26.06v1.34v14.04h7.88
		v-14.04v0c0-9.36,7.51-16.94,16.86-16.94h47.57c17.78,0,32.19-14.41,32.19-32.19v-12.26v0c0-6.7,5.43-12.13,12.13-12.13h69.58
		c6.3,0,11.41-5.11,11.41-11.41v-28.51c0-9.85,7.99-17.84,17.84-17.84h136.66c19.68,0,35.63-15.95,35.63-35.63v0
		C1124.5,320.13,1108.55,304.18,1088.87,304.18z"/>
                                    <path id="smoke2" className="st3" d="M2028.87,169.26h-183.68c-32.12,0-58.15,26.04-58.15,58.15v0l-0.17,61.24c-0.03,9.87-8.03,17.85-17.9,17.85
		h-95.45c-14.11,0-25.56,11.44-25.56,25.56v29.62c0,9.99-8.1,18.09-18.09,18.09h-75.43c-28,0-50.7,22.7-50.7,50.7v38.86v2v20.94
		h11.74v-20.94v0c0-13.95,11.2-25.26,25.15-25.26h70.95c26.52,0,48.01-21.5,48.01-48.01v-18.28v0c0-9.99,8.1-18.09,18.09-18.09
		h103.76c9.39,0,17.01-7.62,17.01-17.01v-42.52c0-14.7,11.91-26.61,26.61-26.61h203.81c29.35,0,53.14-23.79,53.14-53.14v0
		C2082.01,193.05,2058.22,169.26,2028.87,169.26z"/>
                                </g>
                                <g id="snow1">
                                    <g>
                                        <path className="st1" d="M632.05,765.09H308.96v0c0-17.05,13.82-30.87,30.87-30.87h261.35C618.23,734.22,632.05,748.04,632.05,765.09
			L632.05,765.09z"/>
                                        <path className="st1" d="M561.85,744.09H438.85v0c0-28.17,22.83-51,51-51h21C539.02,693.09,561.85,715.92,561.85,744.09L561.85,744.09
			z"/>
                                        <path className="st1" d="M488.95,744.09h-98.25v0c0-22.5,18.24-40.74,40.74-40.74h16.77C470.71,703.35,488.95,721.59,488.95,744.09
			L488.95,744.09z"/>
                                    </g>
                                    <g>
                                        <path className="st1"
                                              d="M61.5,729.66c0-14.66,11.88-26.54,26.54-26.54h116.73c14.66,0,26.54,11.88,26.54,26.54H61.5z"/>
                                        <path className="st1" d="M149.41,707.98H84.15l0,0c0-14.95,12.12-27.06,27.06-27.06h11.14C137.3,680.92,149.41,693.03,149.41,707.98
			L149.41,707.98z"/>
                                        <path className="st1" d="M196.08,707.98h-32.63l0,0c0-7.47,6.06-13.53,13.53-13.53h5.57C190.03,694.45,196.08,700.51,196.08,707.98
			L196.08,707.98z"/>
                                    </g>
                                </g>
                                <g id="snow2">
                                    <g>
                                        <path className="st1"
                                              d="M1145.78,712.94c0-10.59,8.59-19.18,19.18-19.18h37.99c10.59,0,19.18,8.59,19.18,19.18H1145.78z"/>
                                        <path className="st1" d="M1205.02,697.46h-30.75l0,0c0-7.04,5.71-12.75,12.75-12.75h5.25
			C1199.32,684.71,1205.02,690.42,1205.02,697.46L1205.02,697.46z"/>
                                    </g>
                                    <path className="st1"
                                          d="M666.54,781.87c0-18.4,14.92-33.32,33.32-33.32h65.99c18.4,0,33.32,14.92,33.32,33.32H666.54z"/>
                                    <path className="st1" d="M769.46,754.97h-53.41l0,0c0-12.23,9.91-22.15,22.15-22.15h9.12C759.54,732.83,769.46,742.74,769.46,754.97
		L769.46,754.97z"/>
                                    <path className="st1"
                                          d="M865.44,742.35c0-17.05,13.82-30.87,30.87-30.87h135.8c17.05,0,30.87,13.82,30.87,30.87H865.44z"/>
                                    <path className="st1" d="M967.71,717.12h-67.67v0c0-15.5,12.56-28.06,28.06-28.06h11.55C955.15,689.06,967.71,701.62,967.71,717.12
		L967.71,717.12z"/>
                                    <path className="st1" d="M1003.61,717.12h-44.12v0c0-10.1,8.19-18.29,18.29-18.29h7.53C995.42,698.83,1003.61,707.02,1003.61,717.12
		L1003.61,717.12z"/>
                                    <g>
                                        <path className="st1"
                                              d="M905.74,655.5c0-6.21,5.03-11.24,11.24-11.24h49.44c6.21,0,11.24,5.03,11.24,11.24H905.74z"/>
                                        <path className="st1" d="M942.98,646.31h-24.64v0c0-5.64,4.57-10.22,10.22-10.22h4.21C938.41,636.1,942.98,640.67,942.98,646.31
			L942.98,646.31z"/>
                                        <path className="st1" d="M956.05,646.31h-16.07v0c0-3.68,2.98-6.66,6.66-6.66h2.74C953.07,639.65,956.05,642.63,956.05,646.31
			L956.05,646.31z"/>
                                    </g>
                                    <g>
                                        <path className="st1"
                                              d="M704.72,656.2c0-6.21,5.03-11.24,11.24-11.24h49.44c6.21,0,11.24,5.03,11.24,11.24H704.72z"/>
                                        <path className="st1" d="M741.96,647.01h-24.64v0c0-5.64,4.57-10.22,10.22-10.22h4.21C737.38,636.8,741.96,641.37,741.96,647.01
			L741.96,647.01z"/>
                                        <path className="st1" d="M755.03,647.01h-16.07v0c0-3.68,2.98-6.66,6.66-6.66h2.74C752.04,640.35,755.03,643.33,755.03,647.01
			L755.03,647.01z"/>
                                    </g>
                                </g>
                                <g id="men">
                                    <g id="neck">
                                        <rect x="192.22" y="433.3" className="st17" width="35.14" height="38.36"/>
                                    </g>
                                    <g id="kapyushon">
                                        <path className="st2"
                                              d="M221.48,447.85l-2.14,2.14C220.12,449.35,220.84,448.63,221.48,447.85z"/>
                                        <path className="st18"
                                              d="M216.43,452.9l2.91-2.91c-1.11,0.91-2.34,1.66-3.67,2.22C215.92,452.43,216.18,452.65,216.43,452.9z"/>
                                        <g>
                                            <g>
                                                <path className="st11" d="M179.69,438.3v-0.57c-8.71,9.39-11.61,22.24-8.69,33.93h25c-2.57-2.58-3.73-5.7-3.66-8.86
					C184.69,457.34,179.69,448.4,179.69,438.3z"/>
                                                <path className="st18" d="M196.35,453.41c1.24-1.37,2.68-2.41,4.24-3.16c-3.58-2.76-5.89-7.08-5.89-11.94v-10.35
					c-5.14,1.76-9.96,4.67-14.05,8.76c-0.33,0.33-0.64,0.68-0.95,1.02v0.57c0,10.1,5,19.04,12.65,24.51
					C192.41,459.53,193.81,456.21,196.35,453.41z"/>
                                            </g>
                                            <g>
                                                <path className="st11" d="M239.95,438.3v-0.57c8.71,9.39,11.61,22.24,8.69,33.93h-25c2.57-2.58,3.73-5.7,3.66-8.86
					C234.95,457.34,239.95,448.4,239.95,438.3z"/>
                                                <path className="st18" d="M223.29,453.41c-1.24-1.37-2.68-2.41-4.24-3.16c3.58-2.76,5.89-7.08,5.89-11.94v-10.35
					c5.14,1.76,9.96,4.67,14.05,8.76c0.33,0.33,0.64,0.68,0.95,1.02v0.57c0,10.1-5,19.04-12.65,24.51
					C227.23,459.53,225.84,456.21,223.29,453.41z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="body_1_">
                                        <path className="st9" d="M157.87,634.91h51.84V471.66h-42.13c-9.82,0-17.79,7.96-17.79,17.79v137.38
			C149.79,631.29,153.41,634.91,157.87,634.91z"/>
                                        <rect x="149.79" y="492.23" className="st10" width="59.92" height="11.24"/>
                                        <path className="st10" d="M189.41,520.6h-19.32c-3.69,0-6.69-2.99-6.69-6.69v-11.56h32.69v11.56C196.1,517.6,193.1,520.6,189.41,520.6z
			"/>
                                        <path className="st13" d="M168.16,607.03h23.17c3.66,0,6.62-2.96,6.62-6.62v-32.7h-36.41v32.7
			C161.54,604.06,164.51,607.03,168.16,607.03z"/>
                                        <path className="st11" d="M166.81,581.79h25.87c2.91,0,5.27-2.36,5.27-5.27v-8.8h-36.41v8.8C161.54,579.43,163.9,581.79,166.81,581.79z
			"/>
                                        <rect x="170.33" y="508.83" className="st7" width="18.84" height="5.29"/>
                                        <path className="st1"
                                              d="M176.29,567.71h-26.5v-11.18h15.32C171.28,556.53,176.29,561.54,176.29,567.71L176.29,567.71z"/>
                                        <path className="st9" d="M261.55,634.91h-51.84V471.66h42.13c9.82,0,17.79,7.96,17.79,17.79v137.38
			C269.63,631.29,266.01,634.91,261.55,634.91z"/>

                                        <rect x="209.71" y="492.23"
                                              transform="matrix(-1 -4.489843e-11 4.489843e-11 -1 479.3407 995.6995)"
                                              className="st10" width="59.92" height="11.24"/>
                                        <path className="st10" d="M230.01,520.6h19.32c3.69,0,6.69-2.99,6.69-6.69v-11.56h-32.69v11.56C223.32,517.6,226.32,520.6,230.01,520.6
			z"/>
                                        <path className="st13" d="M251.26,607.03h-23.17c-3.66,0-6.62-2.96-6.62-6.62v-32.7h36.41v32.7
			C257.88,604.06,254.91,607.03,251.26,607.03z"/>
                                        <path className="st11" d="M252.61,581.79h-25.87c-2.91,0-5.27-2.36-5.27-5.27v-8.8h36.41v8.8
			C257.88,579.43,255.52,581.79,252.61,581.79z"/>

                                        <rect x="230.25" y="508.83"
                                              transform="matrix(-1 -4.488422e-11 4.488422e-11 -1 479.3407 1022.941)"
                                              className="st7" width="18.84" height="5.29"/>
                                        <path className="st1"
                                              d="M243.13,567.71h26.5v-11.18h-15.32C248.14,556.53,243.13,561.54,243.13,567.71L243.13,567.71z"/>
                                        <path className="st1"
                                              d="M176.29,625.66h-26.5v-11.18h15.32C171.28,614.48,176.29,619.48,176.29,625.66L176.29,625.66z"/>
                                        <path className="st1"
                                              d="M243.13,625.66h26.5v-11.18h-15.32C248.14,614.48,243.13,619.48,243.13,625.66L243.13,625.66z"/>
                                        <path className="st12" d="M230.13,479.39v-7.72h-3.41v7.72c0,1.61-1.31,2.92-2.92,2.92h0c-1.3,0-2.36,1.06-2.36,2.36v8.66
			c0,2.87,2.33,5.2,5.2,5.2h3.56c2.87,0,5.2-2.33,5.2-5.2v-8.66c0-1.3-1.06-2.36-2.36-2.36h0C231.44,482.3,230.13,481,230.13,479.39z
			"/>
                                        <path className="st14" d="M192.22,471.66v14.31c0,6.93-5.62,12.55-12.55,12.55h0c-2.65,0-4.8,2.15-4.8,4.8v16.52
			c0,5.59,4.53,10.12,10.12,10.12h0"/>
                                    </g>
                                    <g id="legs">
                                        <g>
                                            <rect x="162.01" y="634.24" className="st9" width="42.66" height="132.23"/>
                                            <rect x="162.01" y="738.03" className="st10" width="42.66" height="28.44"/>
                                            <rect x="162.01" y="698.46" className="st1" width="42.66" height="12.13"/>
                                            <rect x="162.01" y="662.35" className="st10" width="42.66" height="36.1"/>
                                            <rect x="162.01" y="634.24" className="st11" width="42.66" height="9.51"/>
                                        </g>
                                        <g>

                                            <rect x="214.91" y="634.24"
                                                  transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 472.4807 1400.7131)"
                                                  className="st9" width="42.66" height="132.23"/>

                                            <rect x="214.91" y="738.03"
                                                  transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 472.4807 1504.4944)"
                                                  className="st10" width="42.66" height="28.44"/>

                                            <rect x="214.91" y="698.46"
                                                  transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 472.4807 1409.0444)"
                                                  className="st1" width="42.66" height="12.13"/>

                                            <rect x="214.91" y="662.35"
                                                  transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 472.4807 1360.8103)"
                                                  className="st10" width="42.66" height="36.1"/>

                                            <rect x="214.91" y="634.24"
                                                  transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 472.4807 1277.9996)"
                                                  className="st11" width="42.66" height="9.51"/>
                                        </g>
                                    </g>
                                    <g id="shoes_1_">
                                        <g>
                                            <path className="st12"
                                                  d="M204.67,766.47h-42.66v-13.22h29.44C198.75,753.25,204.67,759.17,204.67,766.47L204.67,766.47z"/>
                                            <path className="st12" d="M165.29,747.97L165.29,747.97c-9.02,0-16.33,7.31-16.33,16.33v2.17h32.66v-2.17
				C181.62,755.28,174.31,747.97,165.29,747.97z"/>
                                        </g>
                                        <g>
                                            <path className="st12"
                                                  d="M214.91,766.47h42.66v-13.22h-29.44C220.83,753.25,214.91,759.17,214.91,766.47L214.91,766.47z"/>
                                            <path className="st12" d="M254.29,747.97L254.29,747.97c9.02,0,16.33,7.31,16.33,16.33v2.17h-32.66v-2.17
				C237.96,755.28,245.27,747.97,254.29,747.97z"/>
                                        </g>
                                    </g>
                                    <g id="hands">
                                        <g id="rightHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st16" cx="128.3" cy="581.46" r="20.34"/>
                                                </g>
                                                <path className="st9"
                                                      d="M148.72,527.86c0-9.97-8.08-18.05-18.05-18.05s-18.05,8.08-18.05,18.05v35.07h36.1V527.86z"/>
                                                <path className="st16" d="M130.66,598.22c9.97,0,18.05-8.08,18.05-18.05v-17.24h-36.1v17.24
					C112.61,590.14,120.69,598.22,130.66,598.22z"/>
                                                <path className="st1"
                                                      d="M139.11,560.09h-26.5v-11.18h15.32C134.11,548.91,139.11,553.92,139.11,560.09L139.11,560.09z"/>
                                                <rect x="112.56" y="559.82" className="st10" width="36.15"
                                                      height="3.11"/>
                                            </g>
                                            <g>
                                                <path className="st9" d="M117.31,539.47L117.31,539.47c7.05,7.05,18.48,7.05,25.53,0l36.99-36.99c7.05-7.05,7.05-18.48,0-25.53l0,0
					c-7.05-7.05-18.48-7.05-25.53,0l-36.99,36.99C110.26,521,110.26,532.42,117.31,539.47z"/>

                                                <rect x="133.54" y="485.82"
                                                      transform="matrix(0.7071 0.7071 -0.7071 0.7071 401.628 40.778)"
                                                      className="st10" width="36.1" height="38.76"/>
                                                <path className="st1" d="M135.95,532.78l-18.74-18.74l7.91-7.91l10.83,10.83C140.32,521.33,140.32,528.41,135.95,532.78
					L135.95,532.78z"/>
                                            </g>
                                        </g>
                                        <g id="leftHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st16" cx="291.28" cy="581.46" r="20.34"/>
                                                </g>
                                                <path className="st9"
                                                      d="M270.87,527.86c0-9.97,8.08-18.05,18.05-18.05s18.05,8.08,18.05,18.05v35.07h-36.1V527.86z"/>
                                                <path className="st16" d="M288.92,598.22c-9.97,0-18.05-8.08-18.05-18.05v-17.24h36.1v17.24
					C306.97,590.14,298.89,598.22,288.92,598.22z"/>
                                                <path className="st1"
                                                      d="M280.47,560.09h26.5v-11.18h-15.32C285.48,548.91,280.47,553.92,280.47,560.09L280.47,560.09z"/>

                                                <rect x="270.87" y="559.82"
                                                      transform="matrix(-1 -4.493174e-11 4.493174e-11 -1 577.8853 1122.7444)"
                                                      className="st10" width="36.15" height="3.11"/>
                                            </g>
                                            <g>
                                                <path className="st9" d="M302.27,539.47L302.27,539.47c-7.05,7.05-18.48,7.05-25.53,0l-36.99-36.99c-7.05-7.05-7.05-18.48,0-25.53
					l0,0c7.05-7.05,18.48-7.05,25.53,0l36.99,36.99C309.32,521,309.32,532.42,302.27,539.47z"/>

                                                <rect x="249.94" y="485.82"
                                                      transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 814.7178 672.9266)"
                                                      className="st10" width="36.1" height="38.76"/>
                                                <path className="st1" d="M283.63,532.78l18.74-18.74l-7.91-7.91l-10.83,10.83C279.26,521.33,279.26,528.41,283.63,532.78
					L283.63,532.78z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="vorotnik">
                                        <path className="st9" d="M239.95,472.8h-60.13v-3.22c0-6.69,5.43-12.12,12.12-12.12h35.9c6.69,0,12.12,5.43,12.12,12.12V472.8z"/>
                                        <rect x="200.43" y="457.47" className="st13" width="18.91" height="177.43"/>
                                        <g>
                                            <path className="st12" d="M193.83,466.8h-5.35c-0.74,0-1.33-0.6-1.33-1.33l0,0c0-0.74,0.6-1.33,1.33-1.33h5.35
				c0.74,0,1.33,0.6,1.33,1.33l0,0C195.17,466.21,194.57,466.8,193.83,466.8z"/>
                                            <path className="st12" d="M231.1,466.8h-5.35c-0.74,0-1.33-0.6-1.33-1.33l0,0c0-0.74,0.6-1.33,1.33-1.33h5.35
				c0.74,0,1.33,0.6,1.33,1.33l0,0C232.44,466.21,231.84,466.8,231.1,466.8z"/>
                                        </g>
                                    </g>
                                    <g id="helmet">
                                        <path className="st1" d="M209.79,358.41L209.79,358.41c-16.26,0-29.44,13.18-29.44,29.44v9.65h58.89v-9.65
			C239.23,371.59,226.05,358.41,209.79,358.41z"/>
                                        <path className="st4" d="M244.71,403.79h-69.85l0,0c0-4.02,3.26-7.28,7.28-7.28h55.28C241.45,396.51,244.71,399.77,244.71,403.79
			L244.71,403.79z"/>
                                        <path className="st5" d="M204.54,388.97l2.95,2.82c1.29,1.23,3.31,1.23,4.59,0l2.95-2.82c0.66-0.63,1.03-1.5,1.03-2.4v-27.47
			c-2.02-0.44-4.12-0.68-6.28-0.68s-4.26,0.24-6.28,0.68v27.47C203.51,387.47,203.88,388.34,204.54,388.97z"/>
                                        <path className="st5" d="M188.28,381.97l2.95,2.82c1.29,1.23,3.31,1.23,4.59,0l2.95-2.82c0.66-0.63,1.03-1.5,1.03-2.4v-19.42
			c-4.93,1.78-9.25,4.83-12.56,8.77v10.65C187.25,380.47,187.63,381.34,188.28,381.97z"/>
                                        <path className="st5" d="M220.8,381.97l2.95,2.82c1.29,1.23,3.31,1.23,4.59,0l2.95-2.82c0.66-0.63,1.03-1.5,1.03-2.4v-10.65
			c-3.31-3.93-7.63-6.99-12.56-8.77v19.42C219.77,380.47,220.14,381.34,220.8,381.97z"/>
                                    </g>
                                    <g id="head">
                                        <path className="st19" d="M183.54,426.65h52.5c2.76,0,5-2.24,5-5l0,0c0-2.76-2.24-5-5-5h-52.5c-2.76,0-5,2.24-5,5l0,0
			C178.54,424.41,180.78,426.65,183.54,426.65z"/>
                                        <path className="st20" d="M209.79,460.9L209.79,460.9c-12.48,0-22.6-10.12-22.6-22.6v-38.36h45.2v38.36
			C232.39,450.78,222.27,460.9,209.79,460.9z"/>
                                        <path className="st21" d="M218.8,423.14h-18.02c-2.89,0-5.22,2.34-5.22,5.22v27.49c3.89,3.16,8.84,5.05,14.24,5.05
			s10.35-1.89,14.24-5.05v-27.49C224.03,425.48,221.69,423.14,218.8,423.14z"/>
                                        <path className="st22" d="M202.78,417.9h14.02c1.17,0,2.11,0.95,2.11,2.11l0,0c0,1.17-0.95,2.11-2.11,2.11h-14.02
			c-1.17,0-2.11-0.95-2.11-2.11l0,0C200.67,418.84,201.61,417.9,202.78,417.9z"/>
                                        <path className="st23" d="M221.37,415.73L221.37,415.73c-0.78,0-1.42-0.63-1.42-1.42v-2.5c0-0.78,0.63-1.42,1.42-1.42h0
			c0.78,0,1.42,0.63,1.42,1.42v2.5C222.79,415.09,222.16,415.73,221.37,415.73z"/>
                                        <path className="st23" d="M198.12,415.73L198.12,415.73c-0.78,0-1.42-0.63-1.42-1.42v-2.5c0-0.78,0.63-1.42,1.42-1.42l0,0
			c0.78,0,1.42,0.63,1.42,1.42v2.5C199.54,415.09,198.91,415.73,198.12,415.73z"/>
                                        <g id="mouth">
                                            <path className="st24 throat" d="M213.96,428.63v0.84c0,1.9-1.54,3.43-3.43,3.43h-12.54c0.05,0.7,0.21,1.38,0.47,2h6.95
				c1.59,0,2.88,1.29,2.88,2.88v1.06h6.91c3.55,0,6.42-2.87,6.42-6.42v-3.8H213.96z"/>
                                            <path className="st1 teeth"
                                                  d="M213.96,429.46v-0.84h-16v3.8c0,0.16,0.01,0.32,0.02,0.47h12.54C212.42,432.9,213.96,431.36,213.96,429.46z"
                                            />
                                            <path className="st25 tongue"
                                                  d="M205.41,434.9h-6.95c0.97,2.32,3.26,3.95,5.92,3.95h3.91v-1.06C208.29,436.19,207,434.9,205.41,434.9z"/>
                                        </g>
                                        <g id="scare" style={{opacity: 0}}>
                                            <path className="throat st24" d="M209.89,445.38L209.89,445.38c-2.34,0-4.26-1.92-4.26-4.26v-9.56c0-2.34,1.92-4.26,4.26-4.26h0
				c2.34,0,4.26,1.92,4.26,4.26v9.56C214.15,443.46,212.23,445.38,209.89,445.38z"/>
                                            <path className="teeth st8"
                                                  d="M209.89,427.3c-2.34,0-4.26,1.92-4.26,4.26v0.11h8.52v-0.11C214.15,429.21,212.23,427.3,209.89,427.3z"/>
                                            <path className="lip st25"
                                                  d="M209.89,445.38c2.34,0,4.26-1.92,4.26-4.26h-8.52C205.63,443.46,207.54,445.38,209.89,445.38z"/>
                                        </g>
                                        <g id="upset" style={{opacity: 0}}>
                                            <path className="st24 lip" d="M217.94,437.07c-0.56,0-1.1-0.33-1.37-0.9c-0.97-2.06-3.59-3.39-6.69-3.39s-5.72,1.33-6.69,3.39
				c-0.38,0.8-1.29,1.13-2.05,0.73c-0.75-0.4-1.06-1.38-0.68-2.18c1.5-3.2,5.11-5.18,9.42-5.18s7.91,1.99,9.42,5.18
				c0.38,0.8,0.07,1.78-0.68,2.18C218.4,437.01,218.17,437.07,217.94,437.07z"/>
                                        </g>
                                        <rect x="182.79" y="406.4" className="st26" width="4.4" height="13.61"/>
                                        <rect x="232.2" y="406.4" className="st26" width="4.4" height="13.61"/>
                                        <path className="st26"
                                              d="M192.04,423.9h-9.25v-9.25h0C187.9,414.65,192.04,418.79,192.04,423.9L192.04,423.9z"/>
                                        <path className="st26"
                                              d="M227.35,423.9h9.25v-9.25h0C231.49,414.65,227.35,418.79,227.35,423.9L227.35,423.9z"/>
                                        <rect x="204.35" y="399.98" className="st27" width="10.89" height="22.14"/>
                                        <path className="st28" d="M237.5,407.65h-21.58c-3.33,0-6.04-2.7-6.04-6.04v-1.67h33.65v1.67C243.54,404.94,240.84,407.65,237.5,407.65
			z"/>
                                        <path className="st28" d="M195.97,407.65h-10.58c-3.33,0-6.04-2.7-6.04-6.04v-1.67h22.65v1.67
			C202.01,404.94,199.31,407.65,195.97,407.65z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <div id="level2" className="level">
                            <div id="scene21" className="scene">
                                <svg id="level2BgHelicopter" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                    <g id="bg">
                                        <rect x="-291.14" className="st0" width="1920" height="800"/>
                                    </g>
                                    <g id="sea">
                                        <rect x="-291.14" y="200" className="st1" width="1920" height="600"/>
                                    </g>
                                    <g id="ice_1_">
                                        <path className="st2" d="M573.38,773.39H389.66l0,0c0-9.61,7.79-17.39,17.39-17.39h148.93C565.59,756,573.38,763.79,573.38,773.39
		L573.38,773.39z"/>
                                        <path className="st2" d="M1009.08,662.88H870.81l0,0c0-7.23,5.86-13.09,13.09-13.09h112.09
		C1003.22,649.79,1009.08,655.65,1009.08,662.88L1009.08,662.88z"/>
                                        <path className="st2" d="M1294.06,585.63h-138.27v0c0-7.23,5.86-13.09,13.09-13.09h112.09C1288.2,572.54,1294.06,578.4,1294.06,585.63
		L1294.06,585.63z"/>
                                        <path className="st2" d="M1266.41,413.01h-110.62v0c0-5.78,4.69-10.47,10.47-10.47h89.68C1261.72,402.54,1266.41,407.23,1266.41,413.01
		L1266.41,413.01z"/>
                                        <path className="st2" d="M701.83,458.42H591.21l0,0c0-5.78,4.69-10.47,10.47-10.47h89.67C697.14,447.95,701.83,452.64,701.83,458.42
		L701.83,458.42z"/>
                                        <path className="st2" d="M452.37,418.25H341.75l0,0c0-5.78,4.69-10.47,10.47-10.47h89.68C447.68,407.78,452.37,412.47,452.37,418.25
		L452.37,418.25z"/>
                                        <path className="st2" d="M183.67,401.28H73.05v0c0-5.78,4.69-10.47,10.47-10.47h89.68C178.98,390.81,183.67,395.49,183.67,401.28
		L183.67,401.28z"/>
                                        <path className="st2" d="M118.77,271.8H63.46l0,0c0-2.89,2.34-5.24,5.24-5.24h44.84C116.43,266.56,118.77,268.91,118.77,271.8
		L118.77,271.8z"/>
                                        <path className="st2" d="M293.95,248.27h-55.31v0c0-2.89,2.34-5.24,5.24-5.24h44.84C291.6,243.03,293.95,245.37,293.95,248.27
		L293.95,248.27z"/>
                                        <path className="st2" d="M672.27,248.27h-55.31v0c0-2.89,2.34-5.24,5.24-5.24h44.84C669.93,243.03,672.27,245.37,672.27,248.27
		L672.27,248.27z"/>
                                        <path className="st3" d="M893.92,286.23h-55.31l0,0c0-2.89,2.34-5.24,5.24-5.24h44.84C891.58,280.99,893.92,283.33,893.92,286.23
		L893.92,286.23z"/>
                                        <path className="st2" d="M1155.79,286.23h-55.31l0,0c0-2.89,2.34-5.24,5.24-5.24h44.84C1153.45,280.99,1155.79,283.33,1155.79,286.23
		L1155.79,286.23z"/>
                                        <path className="st4" d="M1246.24,245.65h-55.31l0,0c0-2.89,2.34-5.24,5.24-5.24H1241C1243.89,240.41,1246.24,242.76,1246.24,245.65
		L1246.24,245.65z"/>
                                        <path className="st2" d="M563.65,656.34H425.38l0,0c0-7.23,5.86-13.09,13.09-13.09h112.09C557.79,643.25,563.65,649.11,563.65,656.34
		L563.65,656.34z"/>
                                        <path className="st2" d="M535.33,598.72H397.06v0c0-7.23,5.86-13.09,13.09-13.09h112.09C529.47,585.63,535.33,591.49,535.33,598.72
		L535.33,598.72z"/>
                                        <path className="st3" d="M197.49,519.22H59.22v0c0-7.23,5.86-13.09,13.09-13.09H184.4C191.63,506.14,197.49,512,197.49,519.22
		L197.49,519.22z"/>
                                        <path className="st2" d="M317.61,780.93h-39l0,0c0-4.16,3.37-7.54,7.54-7.54h23.93C314.23,773.39,317.61,776.77,317.61,780.93
		L317.61,780.93z"/>
                                        <path className="st3"
                                              d="M162.89,769.69H12.52v0c0-3.69,3-6.69,6.69-6.69H156.2C159.89,763,162.89,766,162.89,769.69L162.89,769.69z"/>
                                        <g>
                                            <path className="st2" d="M282.77,738.55h-63.91l0,0c0-6.13,4.97-11.1,11.1-11.1h41.72C277.8,727.45,282.77,732.42,282.77,738.55
			L282.77,738.55z"/>
                                            <path className="st2" d="M346.95,746.95H199.59l0,0c0-7.7,6.25-13.95,13.95-13.95H333C340.7,733,346.95,739.25,346.95,746.95
			L346.95,746.95z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M183.77,671.05h-63.91l0,0c0-6.13,4.97-11.1,11.1-11.1h41.72C178.8,659.95,183.77,664.92,183.77,671.05
			L183.77,671.05z"/>
                                            <path className="st3" d="M247.95,679.45H100.6v0c0-7.7,6.25-13.95,13.95-13.95H234C241.71,665.5,247.95,671.75,247.95,679.45
			L247.95,679.45z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M787.15,790.43h-98.11l0,0c0-9.41,7.63-17.03,17.03-17.03h64.04C779.52,773.39,787.15,781.02,787.15,790.43
			L787.15,790.43z"/>
                                            <path className="st2" d="M885.67,803.32H659.48v0c0-11.83,9.59-21.41,21.41-21.41h183.36C876.08,781.91,885.67,791.5,885.67,803.32
			L885.67,803.32z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M1076.02,742.04h98.11l0,0c0-9.41-7.63-17.03-17.03-17.03h-64.04
			C1083.64,725.01,1076.02,732.64,1076.02,742.04L1076.02,742.04z"/>
                                            <path className="st3" d="M977.5,754.94h226.19v0c0-11.83-9.59-21.41-21.41-21.41H998.91C987.09,733.53,977.5,743.11,977.5,754.94
			L977.5,754.94z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M930.54,233.76h17.75v0c0-1.7-1.38-3.08-3.08-3.08h-11.58C931.92,230.68,930.54,232.06,930.54,233.76
			L930.54,233.76z"/>
                                            <path className="st2" d="M912.72,236.1h40.91l0,0c0-2.14-1.73-3.87-3.87-3.87h-33.17C914.45,232.22,912.72,233.96,912.72,236.1
			L912.72,236.1z"/>
                                        </g>
                                        <g>
                                            <path className="st4" d="M1081.9,216.49h17.75l0,0c0-1.7-1.38-3.08-3.08-3.08h-11.58C1083.28,213.41,1081.9,214.79,1081.9,216.49
			L1081.9,216.49z"/>
                                            <path className="st4" d="M1064.08,218.82h40.91v0c0-2.14-1.73-3.87-3.87-3.87h-33.17C1065.81,214.95,1064.08,216.68,1064.08,218.82
			L1064.08,218.82z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M22.23,211.45H33.1l0,0c0-1.04-0.85-1.89-1.89-1.89h-7.1C23.07,209.56,22.23,210.4,22.23,211.45L22.23,211.45
			z"/>
                                            <path className="st3" d="M11.31,212.88h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37H13.68C12.37,210.5,11.31,211.57,11.31,212.88
			L11.31,212.88z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M366.3,221.26h10.87l0,0c0-1.04-0.85-1.89-1.89-1.89h-7.1C367.15,219.37,366.3,220.22,366.3,221.26
			L366.3,221.26z"/>
                                            <path className="st2" d="M355.38,222.69h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37h-20.32C356.45,220.32,355.38,221.38,355.38,222.69
			L355.38,222.69z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M997.38,221.26h10.87l0,0c0-1.04-0.85-1.89-1.89-1.89h-7.1C998.23,219.37,997.38,220.22,997.38,221.26
			L997.38,221.26z"/>
                                            <path className="st2" d="M986.46,222.69h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37h-20.32C987.53,220.32,986.46,221.38,986.46,222.69
			L986.46,222.69z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1162.43,227.34h10.87l0,0c0-1.04-0.85-1.89-1.89-1.89h-7.1C1163.28,225.46,1162.43,226.3,1162.43,227.34
			L1162.43,227.34z"/>
                                            <path className="st2" d="M1151.51,228.77h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37h-20.32C1152.58,226.4,1151.51,227.46,1151.51,228.77
			L1151.51,228.77z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M166.36,227.34h10.87l0,0c0-1.04-0.85-1.89-1.89-1.89h-7.1C167.2,225.46,166.36,226.3,166.36,227.34
			L166.36,227.34z"/>
                                            <path className="st2" d="M155.44,228.77h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37h-20.32C156.5,226.4,155.44,227.46,155.44,228.77
			L155.44,228.77z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M109.71,584.69h70.19l0,0c0-6.73-5.46-12.19-12.19-12.19H121.9C115.17,572.51,109.71,577.96,109.71,584.69
			L109.71,584.69z"/>
                                            <path className="st2" d="M39.22,593.92h161.83l0,0c0-8.46-6.86-15.32-15.32-15.32H54.54C46.08,578.6,39.22,585.46,39.22,593.92
			L39.22,593.92z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M312.02,324.87h45.79l0,0c0-4.39-3.56-7.95-7.95-7.95h-29.89C315.58,316.92,312.02,320.48,312.02,324.87
			L312.02,324.87z"/>
                                            <path className="st3" d="M266.04,330.89h105.58l0,0c0-5.52-4.47-10-10-10h-85.59C270.51,320.9,266.04,325.37,266.04,330.89
			L266.04,330.89z"/>
                                        </g>
                                        <g>
                                            <path className="st2"
                                                  d="M-26,324.87h45.79l0,0c0-4.39-3.56-7.95-7.95-7.95h-29.89C-22.44,316.92-26,320.48-26,324.87L-26,324.87z"/>
                                            <path className="st2" d="M-71.99,330.89H33.59l0,0c0-5.52-4.47-10-10-10h-85.59C-67.51,320.9-71.99,325.37-71.99,330.89L-71.99,330.89
			z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M480.07,371.56h45.79l0,0c0-4.39-3.56-7.95-7.95-7.95h-29.89C483.63,363.61,480.07,367.17,480.07,371.56
			L480.07,371.56z"/>
                                            <path className="st2" d="M434.09,377.58h105.58l0,0c0-5.52-4.47-10-10-10h-85.59C438.56,367.58,434.09,372.06,434.09,377.58
			L434.09,377.58z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1287.26,386.61h45.79v0c0-4.39-3.56-7.95-7.95-7.95h-29.89C1290.82,378.66,1287.26,382.22,1287.26,386.61
			L1287.26,386.61z"/>
                                            <path className="st2" d="M1241.27,392.63h105.58v0c0-5.52-4.47-10-10-10h-85.59C1245.75,382.63,1241.27,387.11,1241.27,392.63
			L1241.27,392.63z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M854.2,326.83h45.79l0,0c0-4.39-3.56-7.95-7.95-7.95h-29.89C857.76,318.88,854.2,322.44,854.2,326.83
			L854.2,326.83z"/>
                                            <path className="st2" d="M808.21,332.85h105.58l0,0c0-5.52-4.47-10-10-10H818.2C812.68,322.86,808.21,327.33,808.21,332.85
			L808.21,332.85z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M636.09,580.08h70.19v0c0-6.73-5.46-12.19-12.19-12.19h-45.82C641.55,567.89,636.09,573.35,636.09,580.08
			L636.09,580.08z"/>
                                            <path className="st2" d="M565.61,589.31h161.83l0,0c0-8.46-6.86-15.32-15.32-15.32H580.93C572.47,573.99,565.61,580.85,565.61,589.31
			L565.61,589.31z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M839.04,602.67h70.19l0,0c0-6.73-5.46-12.19-12.19-12.19h-45.82C844.5,590.48,839.04,595.94,839.04,602.67
			L839.04,602.67z"/>
                                            <path className="st3" d="M768.56,611.9h161.83l0,0c0-8.46-6.86-15.32-15.32-15.32H783.87C775.41,596.58,768.56,603.44,768.56,611.9
			L768.56,611.9z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M1028.98,574.02h70.19l0,0c0-6.73-5.46-12.19-12.19-12.19h-45.82
			C1034.44,561.84,1028.98,567.29,1028.98,574.02L1028.98,574.02z"/>
                                            <path className="st3" d="M958.5,583.25h161.83l0,0c0-8.46-6.86-15.32-15.32-15.32H973.82C965.36,567.93,958.5,574.79,958.5,583.25
			L958.5,583.25z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M385.77,529.8h61.8l0,0c0-5.93-4.8-10.73-10.73-10.73H396.5C390.57,519.07,385.77,523.87,385.77,529.8
			L385.77,529.8z"/>
                                            <path className="st2" d="M323.71,537.92h142.48v0c0-7.45-6.04-13.49-13.49-13.49H337.2C329.75,524.43,323.71,530.47,323.71,537.92
			L323.71,537.92z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M604.21,507.44h61.8l0,0c0-5.93-4.8-10.73-10.73-10.73h-40.34C609.02,496.71,604.21,501.51,604.21,507.44
			L604.21,507.44z"/>
                                            <path className="st2" d="M542.15,515.56h142.48l0,0c0-7.45-6.04-13.49-13.49-13.49H555.64C548.19,502.07,542.15,508.11,542.15,515.56
			L542.15,515.56z"/>
                                        </g>
                                        <g>
                                            <path className="st4" d="M1126.14,507.44h61.8l0,0c0-5.93-4.8-10.73-10.73-10.73h-40.34C1130.94,496.71,1126.14,501.51,1126.14,507.44
			L1126.14,507.44z"/>
                                            <path className="st4" d="M1064.08,515.56h142.48l0,0c0-7.45-6.04-13.49-13.49-13.49h-115.51
			C1070.12,502.07,1064.08,508.11,1064.08,515.56L1064.08,515.56z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M294.8,601.19h-51.93l0,0c0-4.98,4.04-9.02,9.01-9.02h33.9C290.77,592.18,294.8,596.21,294.8,601.19
			L294.8,601.19z"/>
                                            <path className="st3" d="M346.95,608.02H227.23l0,0c0-6.26,5.07-11.33,11.33-11.33h97.05C341.88,596.68,346.95,601.76,346.95,608.02
			L346.95,608.02z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M680.97,640.81h-51.93v0c0-4.98,4.04-9.01,9.02-9.01h33.9C676.93,631.8,680.97,635.83,680.97,640.81
			L680.97,640.81z"/>
                                            <path className="st2" d="M733.11,647.64H613.4l0,0c0-6.26,5.07-11.33,11.33-11.33h97.05C728.04,636.31,733.11,641.38,733.11,647.64
			L733.11,647.64z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1120.99,614.4h-51.93v0c0-4.98,4.04-9.01,9.02-9.01h33.9C1116.95,605.39,1120.99,609.43,1120.99,614.4
			L1120.99,614.4z"/>
                                            <path className="st2" d="M1173.14,621.23h-119.72l0,0c0-6.26,5.07-11.33,11.33-11.33h97.05
			C1168.06,609.9,1173.14,614.97,1173.14,621.23L1173.14,621.23z"/>
                                        </g>
                                        <path className="st3"
                                              d="M77.05,764.7H33.59v-2.3c0-3.18,2.58-5.76,5.76-5.76H71.3c3.18,0,5.76,2.58,5.76,5.76V764.7z"/>
                                        <g>
                                            <path className="st2" d="M151.7,721.05h-46.68l0,0c0-4.48,3.63-8.1,8.1-8.1h30.47C148.07,712.95,151.7,716.57,151.7,721.05
			L151.7,721.05z"/>
                                            <path className="st2" d="M167.97,727.45H94.3l0,0c0-3.85,3.12-6.97,6.97-6.97H161C164.85,720.48,167.97,723.6,167.97,727.45
			L167.97,727.45z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M545.93,456.56h-64.9l0,0c0-6.22,5.04-11.27,11.27-11.27h42.36C540.89,445.29,545.93,450.34,545.93,456.56
			L545.93,456.56z"/>
                                            <path className="st2" d="M568.56,465.46H466.13v0c0-5.36,4.34-9.7,9.7-9.7h83.04C564.22,455.76,568.56,460.11,568.56,465.46
			L568.56,465.46z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M965.71,439.45h-42.47l0,0c0-4.07,3.3-7.37,7.37-7.37h27.73C962.41,432.08,965.71,435.38,965.71,439.45
			L965.71,439.45z"/>
                                            <path className="st2" d="M980.52,445.28h-67.04l0,0c0-3.5,2.84-6.35,6.35-6.35h54.34C977.68,438.93,980.52,441.77,980.52,445.28
			L980.52,445.28z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M1113.74,350.87h-42.47l0,0c0-4.07,3.3-7.37,7.37-7.37h27.73C1110.43,343.5,1113.74,346.8,1113.74,350.87
			L1113.74,350.87z"/>
                                            <path className="st3" d="M1128.55,356.7h-67.04l0,0c0-3.5,2.84-6.35,6.35-6.35h54.34C1125.7,350.35,1128.55,353.19,1128.55,356.7
			L1128.55,356.7z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M59.03,248.37H35.37l0,0c0-2.27,1.84-4.11,4.11-4.11h15.44C57.19,244.26,59.03,246.1,59.03,248.37
			L59.03,248.37z"/>
                                            <path className="st3" d="M67.28,251.61H29.94l0,0c0-1.95,1.58-3.53,3.53-3.53h30.27C65.7,248.08,67.28,249.66,67.28,251.61
			L67.28,251.61z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M578.98,248.37h-23.66l0,0c0-2.27,1.84-4.11,4.11-4.11h15.44C577.14,244.26,578.98,246.1,578.98,248.37
			L578.98,248.37z"/>
                                            <path className="st2" d="M587.23,251.61h-37.34l0,0c0-1.95,1.58-3.53,3.53-3.53h30.27C585.65,248.08,587.23,249.66,587.23,251.61
			L587.23,251.61z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1081.68,248.37h-23.66l0,0c0-2.27,1.84-4.11,4.11-4.11h15.44C1079.84,244.26,1081.68,246.1,1081.68,248.37
			L1081.68,248.37z"/>
                                            <path className="st2" d="M1089.93,251.61h-37.34l0,0c0-1.95,1.58-3.53,3.53-3.53h30.27C1088.35,248.08,1089.93,249.66,1089.93,251.61
			L1089.93,251.61z"/>
                                        </g>
                                        <g>
                                            <path className="st2"
                                                  d="M47.18,683.66H0.5l0,0c0-4.48,3.63-8.1,8.1-8.1h30.47C43.55,675.56,47.18,679.19,47.18,683.66L47.18,683.66z"
                                            />
                                            <path className="st2" d="M63.46,690.06h-73.68l0,0c0-3.85,3.12-6.97,6.97-6.97h59.73C60.34,683.09,63.46,686.21,63.46,690.06
			L63.46,690.06z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M625.96,683.66h-46.68l0,0c0-4.48,3.63-8.1,8.1-8.1h30.47C622.33,675.56,625.96,679.19,625.96,683.66
			L625.96,683.66z"/>
                                            <path className="st3" d="M642.24,690.06h-73.68l0,0c0-3.85,3.12-6.97,6.97-6.97h59.73C639.12,683.09,642.24,686.21,642.24,690.06
			L642.24,690.06z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M466.19,255.98h-15.75l0,0c0-1.51,1.22-2.73,2.73-2.73h10.28C464.97,253.25,466.19,254.47,466.19,255.98
			L466.19,255.98z"/>
                                            <path className="st2" d="M471.68,258.14h-24.86l0,0c0-1.3,1.05-2.35,2.35-2.35h20.15C470.63,255.79,471.68,256.84,471.68,258.14
			L471.68,258.14z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M238.64,348.42h-34.9l0,0c0-3.35,2.71-6.06,6.06-6.06h22.78C235.93,342.36,238.64,345.07,238.64,348.42
			L238.64,348.42z"/>
                                            <path className="st2" d="M250.81,353.2h-55.09l0,0c0-2.88,2.33-5.22,5.22-5.22h44.66C248.48,347.99,250.81,350.32,250.81,353.2
			L250.81,353.2z"/>
                                        </g>
                                        <path className="st2" d="M1554.15,532.16h-138.27l0,0c0-7.23,5.86-13.09,13.09-13.09h112.09
		C1548.29,519.07,1554.15,524.93,1554.15,532.16L1554.15,532.16z"/>
                                        <path className="st2" d="M1526.5,359.54h-110.62l0,0c0-5.78,4.69-10.47,10.47-10.47h89.68C1521.81,349.06,1526.5,353.75,1526.5,359.54
		L1526.5,359.54z"/>
                                        <path className="st2" d="M1415.88,232.75h-55.31l0,0c0-2.89,2.34-5.24,5.24-5.24h44.84C1413.53,227.51,1415.88,229.86,1415.88,232.75
		L1415.88,232.75z"/>
                                        <path className="st4" d="M1525.24,265.3h-55.31l0,0c0-2.89,2.34-5.24,5.24-5.24H1520C1522.89,260.07,1525.24,262.41,1525.24,265.3
		L1525.24,265.3z"/>
                                        <g>
                                            <path className="st3" d="M1336.1,688.57h98.11l0,0c0-9.41-7.63-17.03-17.03-17.03h-64.04C1343.73,671.53,1336.1,679.16,1336.1,688.57
			L1336.1,688.57z"/>
                                            <path className="st3" d="M1237.58,701.46h226.19v0c0-11.83-9.59-21.41-21.41-21.41h-183.36
			C1247.17,680.05,1237.58,689.64,1237.58,701.46L1237.58,701.46z"/>
                                        </g>
                                        <g>
                                            <path className="st4" d="M1469.89,239.4h17.75l0,0c0-1.7-1.38-3.08-3.08-3.08h-11.58C1471.27,236.32,1469.89,237.7,1469.89,239.4
			L1469.89,239.4z"/>
                                            <path className="st4" d="M1452.07,241.73h40.91l0,0c0-2.14-1.73-3.87-3.87-3.87h-33.17C1453.8,237.86,1452.07,239.59,1452.07,241.73
			L1452.07,241.73z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1512.35,214.75h10.87v0c0-1.04-0.85-1.89-1.89-1.89h-7.1C1513.19,212.86,1512.35,213.7,1512.35,214.75
			L1512.35,214.75z"/>
                                            <path className="st2" d="M1501.43,216.18h25.07l0,0c0-1.31-1.06-2.37-2.37-2.37h-20.32C1502.49,213.8,1501.43,214.87,1501.43,216.18
			L1501.43,216.18z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1547.34,333.13h45.79l0,0c0-4.39-3.56-7.95-7.95-7.95h-29.89C1550.9,325.18,1547.34,328.74,1547.34,333.13
			L1547.34,333.13z"/>
                                            <path className="st2" d="M1501.36,339.15h105.58l0,0c0-5.52-4.47-10-10-10h-85.59C1505.83,329.16,1501.36,333.63,1501.36,339.15
			L1501.36,339.15z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M1289.07,520.55h70.19l0,0c0-6.73-5.46-12.19-12.19-12.19h-45.82
			C1294.52,508.36,1289.07,513.82,1289.07,520.55L1289.07,520.55z"/>
                                            <path className="st3" d="M1218.58,529.77h161.83v0c0-8.46-6.86-15.32-15.32-15.32H1233.9
			C1225.44,514.45,1218.58,521.31,1218.58,529.77L1218.58,529.77z"/>
                                        </g>
                                        <g>
                                            <path className="st4" d="M1386.22,453.96h61.8v0c0-5.93-4.8-10.73-10.73-10.73h-40.34C1391.03,443.23,1386.22,448.04,1386.22,453.96
			L1386.22,453.96z"/>
                                            <path className="st4" d="M1324.16,462.09h142.48l0,0c0-7.45-6.04-13.49-13.49-13.49h-115.51
			C1330.2,448.6,1324.16,454.64,1324.16,462.09L1324.16,462.09z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1381.07,560.93h-51.93l0,0c0-4.98,4.04-9.02,9.02-9.02h33.9C1377.04,551.91,1381.07,555.95,1381.07,560.93
			L1381.07,560.93z"/>
                                            <path className="st2" d="M1433.22,567.75H1313.5l0,0c0-6.26,5.07-11.33,11.33-11.33h97.05
			C1428.14,556.42,1433.22,561.49,1433.22,567.75L1433.22,567.75z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M1373.82,297.4h-42.47l0,0c0-4.07,3.3-7.37,7.37-7.37h27.73C1370.52,290.02,1373.82,293.32,1373.82,297.4
			L1373.82,297.4z"/>
                                            <path className="st3" d="M1388.63,303.22h-67.04l0,0c0-3.5,2.84-6.35,6.35-6.35h54.34C1385.79,296.87,1388.63,299.72,1388.63,303.22
			L1388.63,303.22z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M1319.17,231.69h-23.66l0,0c0-2.27,1.84-4.11,4.11-4.11h15.44C1317.34,227.59,1319.17,229.42,1319.17,231.69
			L1319.17,231.69z"/>
                                            <path className="st2" d="M1327.42,234.94h-37.34v0c0-1.95,1.58-3.53,3.53-3.53h30.27C1325.84,231.4,1327.42,232.99,1327.42,234.94
			L1327.42,234.94z"/>
                                        </g>
                                    </g>
                                    <g id="clouds">
                                        <g className="cloud big">
                                            <path className="st5" d="M726.34,74.61h-47.38c-8.14,0-14.73-6.6-14.73-14.73v0c0-8.14,6.6-14.73,14.73-14.73h47.38
			c8.14,0,14.73,6.6,14.73,14.73v0C741.07,68.01,734.47,74.61,726.34,74.61z"/>
                                            <circle className="st5" cx="698.79" cy="45.14" r="14.89"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st5" d="M69.34,131.87H21.96c-8.14,0-14.73-6.6-14.73-14.73v0c0-8.14,6.6-14.73,14.73-14.73h47.38
			c8.14,0,14.73,6.6,14.73,14.73v0C84.07,125.27,77.48,131.87,69.34,131.87z"/>
                                            <circle className="st5" cx="41.79" cy="102.4" r="14.89"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st5" d="M1026.51,96.78h-47.38c-8.14,0-14.73-6.6-14.73-14.73v0c0-8.14,6.6-14.73,14.73-14.73h47.38
			c8.14,0,14.73,6.6,14.73,14.73v0C1041.24,90.19,1034.64,96.78,1026.51,96.78z"/>
                                            <circle className="st5" cx="998.96" cy="67.32" r="14.89"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st2" d="M376.79,85.06h56.5c9.7,0,17.57-7.87,17.57-17.57v0c0-9.7-7.87-17.57-17.57-17.57h-56.5
            c-9.7,0-17.57,7.87-17.57,17.57v0C359.23,77.19,367.09,85.06,376.79,85.06z"/>
                                            <circle className="st2" cx="408.17" cy="50.26" r="19.24"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st5" d="M-180.01,75.59h-47.38c-8.14,0-14.73-6.6-14.73-14.73v0c0-8.14,6.6-14.73,14.73-14.73h47.38
			c8.14,0,14.73,6.6,14.73,14.73v0C-165.28,68.99-171.88,75.59-180.01,75.59z"/>
                                            <circle className="st5" cx="-207.56" cy="46.13" r="14.89"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st5" d="M1556.84,51.94h-47.38c-8.14,0-14.73-6.6-14.73-14.73v0c0-8.14,6.6-14.73,14.73-14.73h47.38
			c8.14,0,14.73,6.6,14.73,14.73v0C1571.58,45.34,1564.98,51.94,1556.84,51.94z"/>
                                            <circle className="st5" cx="1529.29" cy="22.48" r="14.89"/>
                                        </g>
                                        <g className="cloud big">
                                            <path className="st2" d="M1535.35,172.2h56.5c9.7,0,17.57-7.87,17.57-17.57v0c0-9.7-7.87-17.57-17.57-17.57h-56.5
            c-9.7,0-17.57,7.87-17.57,17.57v0C1517.78,164.33,1525.65,172.2,1535.35,172.2z"/>
                                            <circle className="st2" cx="1566.72" cy="137.4" r="19.24"/>
                                        </g>
                                        <g className="cloud middle">
                                            <path className="st5" d="M1324.03,153.55h-37.33c-6.41,0-11.61-5.2-11.61-11.61v0c0-6.41,5.2-11.61,11.61-11.61h37.33
			c6.41,0,11.61,5.2,11.61,11.61v0C1335.63,148.36,1330.44,153.55,1324.03,153.55z"/>
                                            <circle className="st5" cx="1302.32" cy="130.34" r="11.73"/>
                                        </g>
                                        <g className="cloud middle">
                                            <path className="st5" d="M490.35,134.49h-37.33c-6.41,0-11.61-5.2-11.61-11.61v0c0-6.41,5.2-11.61,11.61-11.61h37.33
			c6.41,0,11.61,5.2,11.61,11.61v0C501.95,129.29,496.76,134.49,490.35,134.49z"/>
                                            <circle className="st5" cx="468.64" cy="111.28" r="11.73"/>
                                        </g>
                                        <g className="cloud middle">
                                            <path className="st5" d="M1182.55,132.21h-45.69c-6.41,0-11.61-5.2-11.61-11.61v-3.21c0-6.41,5.2-11.61,11.61-11.61h45.69
			c6.41,0,11.61,5.2,11.61,11.61v3.21C1194.16,127.02,1188.96,132.21,1182.55,132.21z"/>
                                            <circle className="st5" cx="1156.25" cy="105.79" r="13.35"/>
                                        </g>
                                        <g className="cloud middle">
                                            <path className="st5" d="M269.9,162.65h-45.69c-6.41,0-11.61-5.2-11.61-11.61v-3.21c0-6.41,5.2-11.61,11.61-11.61h45.69
			c6.41,0,11.61,5.2,11.61,11.61v3.21C281.51,157.45,276.31,162.65,269.9,162.65z"/>
                                            <circle className="st5" cx="243.6" cy="136.23" r="13.35"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M888.52,35.05H918c5.06,0,9.17-4.1,9.17-9.17v0c0-5.06-4.1-9.17-9.17-9.17h-29.48c-5.06,0-9.17,4.1-9.17,9.17
            v0C879.36,30.94,883.46,35.05,888.52,35.05z"/>
                                            <circle className="st2" cx="904.89" cy="16.89" r="10.04"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M818.74,135.05h29.48c5.06,0,9.17-4.1,9.17-9.17v0c0-5.06-4.1-9.17-9.17-9.17h-29.48
            c-5.06,0-9.17,4.1-9.17,9.17v0C809.58,130.94,813.68,135.05,818.74,135.05z"/>
                                            <circle className="st2" cx="835.11" cy="116.89" r="10.04"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M260.28,50.65h25.69c4.41,0,7.99-3.58,7.99-7.99v0c0-4.41-3.58-7.99-7.99-7.99h-25.69
            c-4.41,0-7.99,3.58-7.99,7.99v0C252.29,47.08,255.87,50.65,260.28,50.65z"/>
                                            <circle className="st2" cx="274.54" cy="34.83" r="8.75"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M647.2,178.04h25.69c4.41,0,7.99-3.58,7.99-7.99v0c0-4.41-3.58-7.99-7.99-7.99H647.2
            c-4.41,0-7.99,3.58-7.99,7.99v0C639.22,174.46,642.79,178.04,647.2,178.04z"/>
                                            <circle className="st2" cx="661.47" cy="162.22" r="8.75"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M-17.83,36.03h29.48c5.06,0,9.17-4.1,9.17-9.17v0c0-5.06-4.1-9.17-9.17-9.17h-29.48
            c-5.06,0-9.17,4.1-9.17,9.17v0C-27,31.92-22.89,36.03-17.83,36.03z"/>
                                            <circle className="st2" cx="-1.46" cy="17.87" r="10.04"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M-87.61,168.03h29.48c5.06,0,9.17-4.1,9.17-9.17v0c0-5.06-4.1-9.17-9.17-9.17h-29.48
            c-5.06,0-9.17,4.1-9.17,9.17v0C-96.77,163.92-92.67,168.03-87.61,168.03z"/>
                                            <circle className="st2" cx="-71.24" cy="149.87" r="10.04"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M-259.15,179.02h25.69c4.41,0,7.99-3.58,7.99-7.99v0c0-4.41-3.58-7.99-7.99-7.99h-25.69
            c-4.41,0-7.99,3.58-7.99,7.99v0C-267.13,175.44-263.56,179.02-259.15,179.02z"/>
                                            <circle className="st2" cx="-244.89" cy="163.2" r="8.75"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M1161.31,188.43h29.48c5.06,0,9.17-4.1,9.17-9.17v0c0-5.06-4.1-9.17-9.17-9.17h-29.48
            c-5.06,0-9.17,4.1-9.17,9.17v0C1152.14,184.32,1156.25,188.43,1161.31,188.43z"/>
                                            <circle className="st2" cx="1177.68" cy="170.27" r="10.04"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M1208.79,57.43h25.69c4.41,0,7.99-3.58,7.99-7.99v0c0-4.41-3.58-7.99-7.99-7.99h-25.69
            c-4.41,0-7.99,3.58-7.99,7.99v0C1200.8,53.85,1204.38,57.43,1208.79,57.43z"/>
                                            <circle className="st2" cx="1223.05" cy="41.61" r="8.75"/>
                                        </g>
                                        <g className="cloud small">
                                            <path className="st2" d="M1477.71,155.37h25.69c4.41,0,7.99-3.58,7.99-7.99v0c0-4.41-3.58-7.99-7.99-7.99h-25.69
            c-4.41,0-7.99,3.58-7.99,7.99v0C1469.72,151.79,1473.3,155.37,1477.71,155.37z"/>
                                            <circle className="st2" cx="1491.97" cy="139.55" r="8.75"/>
                                        </g>
                                    </g>
                                    <g id="mlsp">
                                        <g>
                                            <rect x="965.54" y="360.82" className="st6" width="4.27" height="3"/>
                                            <line className="st7" x1="943.9" y1="397.23" x2="930.15" y2="407.85"/>
                                            <line className="st7" x1="945" y1="431.2" x2="981.5" y2="397.66"/>
                                            <line className="st7" x1="947" y1="421.78" x2="955.26" y2="421.78"/>
                                            <line className="st7" x1="947" y1="418.41" x2="981.5" y2="397.66"/>
                                            <line className="st7" x1="947" y1="415.28" x2="959.37" y2="397.66"/>
                                            <line className="st8" x1="929.51" y1="387.03" x2="943.26" y2="397.66"/>
                                            <line className="st8" x1="959.37" y1="385.78" x2="959.37" y2="397.66"/>
                                            <line className="st8" x1="961.62" y1="387.03" x2="975.37" y2="397.66"/>
                                            <line className="st8" x1="989.28" y1="387.03" x2="975.53" y2="397.66"/>
                                            <line className="st9" x1="957.16" y1="387.03" x2="943.41" y2="397.66"/>
                                            <line className="st10" x1="632.79" y1="353.2" x2="709" y2="364.03"/>
                                            <polygon className="st6"
                                                     points="723.9,405.53 701,405.53 701,384.78 701,367.03 723.9,367.03 		"/>
                                            <polygon className="st11"
                                                     points="717.47,367.03 707.43,367.03 709.17,344.28 715.73,344.28 		"/>
                                            <rect x="691.5" y="443.36" className="st6" width="246" height="25.33"/>
                                            <polygon className="st12"
                                                     points="937.5,443.36 691.5,443.36 682,430.7 947,430.7 		"/>
                                            <rect x="682" y="405.53" className="st6" width="265" height="25.17"/>
                                            <rect x="794.5" y="379.53" className="st6" width="66.5" height="33.17"/>
                                            <rect x="748.03" y="364.03" className="st6" width="46.5" height="41.5"/>
                                            <g>
                                                <rect x="757.28" y="305.03" className="st13" width="28" height="59"/>
                                                <polygon className="st13"
                                                         points="778.78,262.18 763.78,262.18 757.28,305.03 785.28,305.03 			"/>
                                                <rect x="763.78" y="236.1" className="st13" width="15" height="26.09"/>
                                                <rect x="763.78" y="236.1" className="st6" width="15" height="6.93"/>
                                                <rect x="763.78" y="249.6" className="st6" width="15" height="6.93"/>
                                                <polygon className="st6"
                                                         points="779.97,270.03 762.59,270.03 763.64,263.1 778.92,263.1 			"/>
                                                <polygon className="st6"
                                                         points="782.02,283.53 760.54,283.53 761.59,276.6 780.97,276.6 			"/>
                                            </g>
                                            <polygon className="st10"
                                                     points="618,346.35 701,384.78 701,396.04 618,350.53 		"/>
                                            <polygon className="st10"
                                                     points="618,346.35 580.17,370.03 580.17,374.03 618,350.53 		"/>
                                            <g>
                                                <g>
                                                    <rect x="794.5" y="372.36" className="st14" width="23.97"
                                                          height="7.17"/>
                                                    <g>
                                                        <rect x="798.11" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                        <rect x="805.05" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                        <rect x="811.98" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <rect x="796.46" y="365.95" className="st16" width="19.33"
                                                          height="6.42"/>

                                                    <rect x="804.69" y="361.5"
                                                          transform="matrix(6.123234e-17 -1 1 6.123234e-17 436.9688 1175.28)"
                                                          className="st14" width="2.88" height="15.31"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <rect x="825.5" y="372.36" className="st14" width="23.97"
                                                          height="7.17"/>
                                                    <g>
                                                        <rect x="829.11" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                        <rect x="836.05" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                        <rect x="842.98" y="374.41" className="st15" width="2.88"
                                                              height="2.88"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <rect x="827.46" y="365.95" className="st16" width="19.33"
                                                          height="6.42"/>

                                                    <rect x="835.69" y="361.5"
                                                          transform="matrix(6.123234e-17 -1 1 6.123234e-17 467.9688 1206.28)"
                                                          className="st14" width="2.88" height="15.31"/>
                                                </g>
                                            </g>
                                            <rect x="861" y="386.28" className="st17" width="54.5" height="26.42"/>
                                            <polygon className="st6"
                                                     points="929.62,379.53 921.5,379.53 923.5,362.03 927.62,362.03 		"/>
                                            <polygon className="st16"
                                                     points="911.75,386.28 901.44,386.28 903.75,359.03 909.44,359.03 		"/>
                                            <line className="st18" x1="938.15" y1="370.65" x2="933.56" y2="379.53"/>
                                            <line className="st18" x1="959.51" y1="329.37" x2="947.96" y2="351.69"/>
                                            <line className="st18" x1="967.81" y1="313.31" x2="962.45" y2="323.67"/>
                                            <line className="st18" x1="959.34" y1="322.08" x2="962.28" y2="316.39"/>
                                            <line className="st18" x1="944.85" y1="350.1" x2="956.39" y2="327.78"/>
                                            <line className="st18" x1="929.62" y1="379.53" x2="935.04" y2="369.06"/>
                                            <line className="st18" x1="944.32" y1="373.8" x2="938.7" y2="384.66"/>
                                            <line className="st18" x1="965.67" y1="332.52" x2="954.13" y2="354.83"/>
                                            <line className="st18" x1="951.01" y1="353.24" x2="962.55" y2="330.93"/>
                                            <line className="st18" x1="937.42" y1="379.53" x2="941.2" y2="372.21"/>
                                            <rect x="963.61" y="312.03" className="st11" width="8.13" height="4"/>
                                            <rect x="964.57" y="342.77" className="st6" width="6.22" height="7.33"/>
                                            <rect x="964.57" y="349.78" className="st11" width="6.22" height="11.38"/>
                                            <line className="st19" x1="947.96" y1="351.69" x2="938.15" y2="370.65"/>
                                            <line className="st19" x1="935.04" y1="369.06" x2="944.85" y2="350.1"/>
                                            <line className="st19" x1="954.13" y1="354.83" x2="944.32" y2="373.8"/>
                                            <line className="st19" x1="941.2" y1="372.21" x2="951.01" y2="353.24"/>
                                            <line className="st19" x1="962.45" y1="323.67" x2="959.51" y2="329.37"/>
                                            <line className="st19" x1="956.39" y1="327.78" x2="959.34" y2="322.08"/>
                                            <line className="st19" x1="962.55" y1="330.93" x2="965.5" y2="325.23"/>
                                            <rect x="957.86" y="315.42" className="st6" width="19.63" height="4.61"/>
                                            <rect x="962.28" y="319.24" className="st6" width="10.8" height="6.92"/>
                                            <rect x="964.57" y="325.87" className="st6" width="6.22" height="6.07"/>
                                            <rect x="964.57" y="331.62" className="st11" width="6.22" height="11.15"/>
                                            <line className="st18" x1="925.56" y1="362.72" x2="955.08" y2="330.32"/>
                                            <path className="st11"
                                                  d="M997,387.78h-58.85v-4H997c1.1,0,2,0.9,2,2l0,0C999,386.89,998.1,387.78,997,387.78z"/>
                                            <rect x="794.5" y="379.53" className="st12" width="28.25" height="33.17"/>
                                            <rect x="694.5" y="414.43" className="st17" width="206.94" height="16.27"/>
                                            <rect x="916.25" y="379.53" className="st6" width="22.75" height="10.25"/>
                                            <polyline className="st17"
                                                      points="901.44,430.7 901.44,420.03 909.83,420.03 		"/>
                                            <rect x="931.25" y="405.53" className="st20" width="15.75" height="25.17"/>
                                            <rect x="927.62" y="443.36" className="st20" width="9.88" height="25.33"/>
                                            <rect x="887.5" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="866.83" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="846.17" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="825.5" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="804.83" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="784.17" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="763.5" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="742.83" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="722.17" y="414.43" className="st20" width="1.67" height="16.27"/>
                                            <rect x="701.83" y="413.36" className="st6" width="6.67" height="10.67"/>
                                            <rect x="794.5" y="387.5" className="st17" width="8.09" height="25.19"/>
                                            <rect x="901.44" y="405.53" className="st6" width="22.56" height="12.47"/>
                                            <rect x="861" y="386.28" className="st20" width="40.44" height="26.42"/>
                                            <rect x="757.28" y="305.03" className="st21" width="27.97" height="0.75"/>
                                            <rect x="757.28" y="318.78" className="st21" width="27.72" height="2.25"/>
                                            <g>
                                                <rect x="760.32" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                                <rect x="764.41" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                                <rect x="768.49" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                                <rect x="772.57" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                                <rect x="776.66" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                                <rect x="780.74" y="305.34" className="st21" width="1.34"
                                                      height="14.5"/>
                                            </g>
                                            <polygon className="st11"
                                                     points="929.62,379.53 927.17,383.78 939,383.78 939,379.53 		"/>
                                            <line className="st8" x1="981.5" y1="397.66" x2="935.5" y2="397.66"/>
                                            <rect x="919.75" y="389.78" className="st11" width="15.75" height="15.75"/>
                                            <polygon className="st22"
                                                     points="935.5,392.34 919.75,389.78 919.75,389.78 935.5,389.78 		"/>
                                            <polygon className="st22"
                                                     points="935.5,399.49 919.75,396.93 919.75,396.93 935.5,396.93 		"/>
                                            <rect x="962.28" y="320.03" className="st17" width="10.8" height="1.74"/>
                                            <rect x="965.54" y="361.17" className="st17" width="4.27" height="0.86"/>
                                            <rect x="916.25" y="379.53" className="st23" width="22.75" height="1.52"/>
                                            <rect x="748.03" y="383.78" className="st17" width="46.5" height="3.72"/>
                                            <rect x="706.5" y="340.28" className="st6" width="11.9" height="7.5"/>
                                            <g>
                                                <line className="st24" x1="722.67" y1="233.2" x2="730.03" y2="221.03"/>
                                                <line className="st24" x1="737.39" y1="233.2" x2="730.03" y2="221.03"/>
                                                <line className="st24" x1="724.53" y1="211.22" x2="730.03" y2="204.7"/>
                                                <line className="st24" x1="735.53" y1="211.2" x2="730.03" y2="204.7"/>
                                                <line className="st24" x1="739.92" y1="263.03" x2="730.03" y2="248.36"/>
                                                <line className="st24" x1="720.15" y1="263.03" x2="730.03" y2="248.36"/>
                                                <line className="st24" x1="725.08" y1="204.74" x2="724.53" y2="211.22"/>
                                                <line className="st24" x1="735.53" y1="211.2" x2="734.98" y2="204.7"/>
                                                <line className="st24" x1="723.7" y1="221.03" x2="722.67" y2="233.2"/>
                                                <line className="st24" x1="737.39" y1="233.2" x2="736.37" y2="221.03"/>
                                                <line className="st24" x1="721.39" y1="248.36" x2="720.15" y2="263.03"/>
                                                <line className="st24" x1="739.92" y1="263.03" x2="738.68" y2="248.36"/>
                                                <polygon className="st24"
                                                         points="741.17,277.75 719.5,277.75 713.83,413.36 746.83,413.36 			"/>
                                                <line className="st24" x1="714.76" y1="391.04" x2="745.91" y2="391.04"/>
                                                <line className="st24" x1="715.7" y1="368.68" x2="744.98" y2="368.68"/>
                                                <line className="st24" x1="716.63" y1="346.35" x2="744.03" y2="346.35"/>
                                                <line className="st24" x1="717.47" y1="326.36" x2="743.24" y2="326.36"/>
                                                <line className="st24" x1="718.25" y1="307.7" x2="742.44" y2="307.7"/>
                                                <line className="st24" x1="718.9" y1="292.03" x2="741.78" y2="292.03"/>
                                                <line className="st24" x1="713.83" y1="413.36" x2="730.33" y2="391.04"/>
                                                <line className="st24" x1="746.83" y1="413.36" x2="730.33" y2="391.04"/>
                                                <line className="st24" x1="714.76" y1="391.04" x2="730.33" y2="368.68"/>
                                                <line className="st24" x1="745.91" y1="391.04" x2="730.33" y2="368.68"/>
                                                <line className="st24" x1="715.7" y1="368.68" x2="730.33" y2="346.35"/>
                                                <line className="st24" x1="744.98" y1="368.68" x2="730.33" y2="346.35"/>
                                                <line className="st24" x1="716.63" y1="346.35" x2="730.33" y2="326.36"/>
                                                <line className="st24" x1="744.03" y1="346.35" x2="730.33" y2="326.36"/>
                                                <line className="st24" x1="717.47" y1="326.36" x2="730.33" y2="307.7"/>
                                                <line className="st24" x1="743.24" y1="326.36" x2="730.33" y2="307.7"/>
                                                <line className="st24" x1="718.25" y1="307.7" x2="730.33" y2="292.03"/>
                                                <line className="st24" x1="742.44" y1="307.7" x2="730.33" y2="292.03"/>
                                                <line className="st24" x1="718.9" y1="292.03" x2="730.33" y2="277.75"/>
                                                <line className="st24" x1="741.78" y1="292.03" x2="730.33" y2="277.75"/>
                                                <polygon className="st10"
                                                         points="739.92,263.03 741.17,277.75 718.9,277.75 720.15,263.03 			"/>
                                                <polygon className="st10"
                                                         points="738.68,248.36 737.39,233.2 722.67,233.2 721.39,248.36 			"/>
                                                <polygon className="st10"
                                                         points="736.37,221.03 735.53,211.2 724.53,211.22 723.7,221.03 			"/>
                                                <polygon className="st10"
                                                         points="734.98,204.7 734.24,195.86 725.83,195.86 725.08,204.74 			"/>
                                                <line className="st10" x1="718.9" y1="277.75" x2="730.03" y2="263.03"/>
                                                <line className="st10" x1="741.17" y1="277.75" x2="730.03" y2="263.03"/>
                                                <line className="st10" x1="721.39" y1="248.36" x2="730.03" y2="233.2"/>
                                                <line className="st10" x1="738.68" y1="248.36" x2="730.03" y2="233.2"/>
                                                <line className="st10" x1="723.7" y1="221.03" x2="730.03" y2="211.2"/>
                                                <line className="st10" x1="736.37" y1="221.03" x2="730.03" y2="211.2"/>
                                                <line className="st10" x1="725.08" y1="204.74" x2="730.03" y2="195.86"/>
                                                <line className="st10" x1="734.98" y1="204.7" x2="730.03" y2="195.86"/>
                                            </g>
                                            <rect x="691.5" y="468.7" className="st13" width="246" height="4.11"/>
                                            <polygon className="st25"
                                                     points="941.5,480.57 687.5,480.57 691.5,472.81 937.5,472.81 		"/>
                                            <rect x="927.62" y="468.7" className="st13" width="9.88" height="4.11"/>
                                            <polygon className="st26"
                                                     points="941.5,480.57 927.33,480.57 927.62,472.81 937.5,472.81 		"/>
                                            <g>
                                                <path className="st5" d="M742.75,455.17c-0.05-1.03-0.12-2.28-0.11-3.2h-0.03c-0.25,0.87-0.56,1.79-0.94,2.82l-1.31,3.6h-0.73
				l-1.2-3.53c-0.35-1.04-0.65-2-0.86-2.88h-0.02c-0.02,0.92-0.08,2.17-0.14,3.28l-0.2,3.18h-0.91l0.52-7.42h1.22l1.27,3.59
				c0.31,0.91,0.56,1.73,0.75,2.5h0.03c0.19-0.75,0.45-1.56,0.78-2.5l1.32-3.59h1.22l0.46,7.42h-0.93L742.75,455.17z"/>
                                                <path className="st5" d="M752.54,451.01v7.42h-0.97v-6.61h-2.45v2.24c0,1.61-0.07,3.4-1.11,4.1c-0.27,0.18-0.7,0.35-1.13,0.35
				l-0.12-0.77c0.3-0.05,0.61-0.23,0.78-0.4c0.59-0.58,0.65-2.02,0.65-3.21v-3.12H752.54z"/>
                                                <path className="st5" d="M761.59,458.18c-0.35,0.18-1.06,0.35-1.96,0.35c-2.09,0-3.66-1.32-3.66-3.75c0-2.32,1.57-3.89,3.87-3.89
				c0.92,0,1.51,0.2,1.76,0.33l-0.23,0.78c-0.36-0.18-0.88-0.31-1.5-0.31c-1.74,0-2.89,1.11-2.89,3.06c0,1.82,1.04,2.98,2.85,2.98
				c0.58,0,1.18-0.12,1.56-0.31L761.59,458.18z"/>
                                                <path className="st5"
                                                      d="M770.34,451.01v7.42h-0.96v-6.61h-3.44v6.61h-0.96v-7.42H770.34z"/>
                                                <path className="st5" d="M778.99,455.65l1.47,2.02h-0.78l-1.45-2.02l1.45-2.02h0.78L778.99,455.65z M780.79,455.65l1.47,2.02h-0.78
				l-1.45-2.02l1.45-2.02h0.78L780.79,455.65z"/>
                                                <path className="st5"
                                                      d="M790.91,451.01v7.42h-0.96v-6.61h-3.44v6.61h-0.96v-7.42H790.91z"/>
                                                <path className="st5" d="M794.78,451.1c0.46-0.08,1.07-0.14,1.84-0.14c0.95,0,1.64,0.22,2.08,0.62c0.41,0.35,0.65,0.89,0.65,1.55
				c0,0.67-0.2,1.2-0.57,1.58c-0.51,0.54-1.33,0.81-2.27,0.81c-0.29,0-0.55-0.01-0.77-0.07v2.97h-0.96V451.1z M795.74,454.67
				c0.21,0.05,0.47,0.08,0.79,0.08c1.16,0,1.86-0.56,1.86-1.58c0-0.98-0.69-1.45-1.75-1.45c-0.42,0-0.74,0.03-0.9,0.08V454.67z"/>
                                                <path className="st5" d="M803.74,451.01v3.12c0,1.18-0.02,2.15-0.09,3.14l0.02,0.01c0.37-0.77,0.84-1.57,1.38-2.45l2.4-3.82h0.96
				v7.42h-0.9v-3.16c0-1.2,0.01-2.08,0.09-3.01l-0.03-0.01c-0.36,0.81-0.87,1.67-1.38,2.5l-2.32,3.69h-1.02v-7.42H803.74z"/>
                                                <path className="st5" d="M812.27,451.1c0.46-0.08,1.07-0.14,1.84-0.14c0.95,0,1.64,0.22,2.08,0.62c0.41,0.35,0.65,0.89,0.65,1.55
				c0,0.67-0.2,1.2-0.57,1.58c-0.51,0.54-1.33,0.81-2.27,0.81c-0.29,0-0.55-0.01-0.77-0.07v2.97h-0.96V451.1z M813.23,454.67
				c0.21,0.05,0.47,0.08,0.79,0.08c1.16,0,1.86-0.56,1.86-1.58c0-0.98-0.69-1.45-1.75-1.45c-0.42,0-0.74,0.03-0.9,0.08V454.67z"/>
                                                <path className="st5" d="M820.6,456.09l-0.77,2.33h-0.99l2.52-7.42h1.16l2.53,7.42h-1.02l-0.79-2.33H820.6z M823.03,455.34
				l-0.73-2.13c-0.17-0.48-0.27-0.92-0.38-1.35h-0.02c-0.11,0.44-0.23,0.89-0.37,1.34l-0.73,2.14H823.03z"/>
                                                <path className="st5" d="M828.16,451.45c0.51-0.33,1.16-0.56,1.96-0.56c1.29,0,2.18,0.67,2.18,1.82c0,0.91-0.66,1.61-1.53,1.8v0.02
				c1.04,0.11,1.8,0.79,1.8,1.86c0,1.54-1.33,2.16-2.68,2.16c-0.67,0-1.38-0.14-1.94-0.48l0.25-0.75c0.4,0.23,1.06,0.45,1.66,0.45
				c1.1,0,1.71-0.59,1.71-1.41c0-1.11-1.07-1.43-2.01-1.43h-0.44v-0.73h0.44c0.98,0,1.74-0.61,1.74-1.36c0-0.75-0.53-1.17-1.33-1.17
				c-0.56,0-1.14,0.23-1.54,0.48L828.16,451.45z"/>
                                                <path className="st5" d="M840.96,451.01v7.42h-0.97v-6.61h-2.45v2.24c0,1.61-0.07,3.4-1.11,4.1c-0.27,0.18-0.7,0.35-1.13,0.35
				l-0.12-0.77c0.3-0.05,0.61-0.23,0.78-0.4c0.59-0.58,0.65-2.02,0.65-3.21v-3.12H840.96z"/>
                                                <path className="st5" d="M851.16,454.64c0,2.55-1.55,3.91-3.44,3.91c-1.96,0-3.33-1.52-3.33-3.76c0-2.35,1.46-3.89,3.44-3.89
				C849.85,450.89,851.16,452.44,851.16,454.64z M845.4,454.76c0,1.58,0.86,3,2.37,3c1.52,0,2.38-1.4,2.38-3.08
				c0-1.47-0.77-3.01-2.37-3.01C846.2,451.67,845.4,453.13,845.4,454.76z"/>
                                                <path className="st5" d="M860.82,455.17c-0.05-1.03-0.12-2.28-0.11-3.2h-0.03c-0.25,0.87-0.56,1.79-0.94,2.82l-1.31,3.6h-0.73
				l-1.2-3.53c-0.35-1.04-0.65-2-0.86-2.88h-0.02c-0.02,0.92-0.08,2.17-0.14,3.28l-0.2,3.18h-0.91l0.52-7.42h1.22l1.27,3.59
				c0.31,0.91,0.56,1.73,0.75,2.5h0.03c0.19-0.75,0.45-1.56,0.78-2.5l1.32-3.59h1.22l0.46,7.42H861L860.82,455.17z"/>
                                                <path className="st5"
                                                      d="M866.57,451.01v3.1h3.59v-3.1h0.97v7.42h-0.97v-3.48h-3.59v3.48h-0.96v-7.42H866.57z"/>
                                                <path className="st5" d="M876.18,456.09l-0.77,2.33h-0.99l2.52-7.42h1.16l2.53,7.42h-1.02l-0.79-2.33H876.18z M878.61,455.34
				l-0.73-2.13c-0.17-0.48-0.27-0.92-0.38-1.35h-0.02c-0.11,0.44-0.23,0.89-0.37,1.34l-0.73,2.14H878.61z"/>
                                                <path className="st5" d="M883.24,458.42c0.11-0.2,0.21-0.43,0.3-0.67c0.32-0.85,0.52-1.88,1.12-2.49c0.18-0.18,0.4-0.31,0.62-0.38
				v-0.03c-0.91-0.15-1.64-0.81-1.64-1.83c0-0.65,0.26-1.17,0.68-1.51c0.46-0.37,1.19-0.57,2.12-0.57c0.63,0,1.29,0.06,1.77,0.15
				v7.32h-0.96v-3.21h-0.7c-0.46,0-0.8,0.1-1.07,0.36c-0.51,0.52-0.71,1.61-0.98,2.31c-0.08,0.19-0.13,0.34-0.24,0.54H883.24z
				 M887.26,451.78c-0.17-0.03-0.47-0.09-0.9-0.09c-0.95,0-1.75,0.36-1.75,1.39c0,0.89,0.79,1.41,1.84,1.41c0.32,0,0.63,0,0.81-0.01
				V451.78z"/>
                                                <path className="st5" d="M891.5,453.63h0.78l1.46,2.02l-1.46,2.02h-0.77l1.46-2.02L891.5,453.63z M893.3,453.63h0.78l1.46,2.02
				l-1.46,2.02h-0.77l1.46-2.02L893.3,453.63z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="ice">
                                        <g>
                                            <path className="st2" d="M924.89,486.27h55.17l0,0c0-5.29-4.29-9.58-9.58-9.58h-36.02C929.17,476.69,924.89,480.98,924.89,486.27
			L924.89,486.27z"/>
                                            <path className="st2" d="M869.48,493.52h127.21l0,0c0-6.65-5.39-12.04-12.04-12.04H881.52C874.87,481.48,869.48,486.87,869.48,493.52
			L869.48,493.52z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M762.74,483.37h38.7l0,0c0-3.71-3.01-6.72-6.72-6.72h-25.26C765.75,476.66,762.74,479.66,762.74,483.37
			L762.74,483.37z"/>
                                            <path className="st2" d="M723.88,488.46h89.22l0,0c0-4.66-3.78-8.45-8.45-8.45h-72.33C727.66,480.02,723.88,483.8,723.88,488.46
			L723.88,488.46z"/>
                                        </g>
                                    </g>
                                    <g id="helicopter">
                                        <g>
                                            <path className="st30" d="M166.87,61.14h-14.12c-5.19,0-9.4-4.21-9.4-9.4v0h18.9c3.87,0,7.01,3.14,7.01,7.01v0
			C169.26,60.07,168.19,61.14,166.87,61.14z"/>
                                            <path className="st4" d="M160.22,57.92h-0.96c-0.29,0-0.52-0.23-0.52-0.52v-2.97c0-0.29,0.23-0.52,0.52-0.52h0.96
			c0.29,0,0.52,0.23,0.52,0.52v2.97C160.75,57.68,160.51,57.92,160.22,57.92z"/>
                                            <path className="st4" d="M154.12,57.92h-0.96c-0.29,0-0.52-0.23-0.52-0.52v-2.97c0-0.29,0.23-0.52,0.52-0.52h0.96
			c0.29,0,0.52,0.23,0.52,0.52v2.97C154.64,57.68,154.4,57.92,154.12,57.92z"/>
                                            <circle className="st30" cx="163.57" cy="62.88" r="1.19"/>
                                            <circle className="st30" cx="152.46" cy="62.77" r="1.3"/>
                                            <path className="st4" d="M162.38,54.42v2.97c0,0.29,0.23,0.52,0.52,0.52h6.3c-0.18-1.55-0.87-2.95-1.9-4.02h-4.4
			C162.62,53.9,162.38,54.13,162.38,54.42z"/>
                                            <line className="st31" x1="152.93" y1="45.48" x2="152.93" y2="47.93"/>
                                            <path className="st31"
                                                  d="M132.06,47.31c0,0,9.05-2.09,20.87-2.09s20.87,2.09,20.87,2.09"/>
                                            <path className="st30"
                                                  d="M149.27,47.27c-1.04,0-1.88,0.84-1.88,1.88h9.2v-1.88H149.27z"/>
                                            <path className="st5"
                                                  d="M156.6,47.27v1.88h1.88C158.47,48.11,157.63,47.27,156.6,47.27z"/>
                                            <path className="st30" d="M127.72,49.14l-0.75-0.75c-0.05-0.05-0.1-0.1-0.16-0.15c-1.25-1.06-2.89,0.79-1.73,1.95l1.51,1.51v0.04h8.95
			l-2.61-2.61H127.72z"/>
                                            <path className="st5"
                                                  d="M160.08,49.14h-23.45h-3.7l2.61,2.61h3.7h23.45C162.69,50.31,161.53,49.14,160.08,49.14z"/>
                                            <g id="tail_rotor">
                                                <line className="st31" x1="123.28" y1="46.53" x2="127.76" y2="51"/>
                                                <line className="st31" x1="123.28" y1="51.4" x2="128.15" y2="46.53"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div id="scene22" className="scene">
                                <svg id="level2BgMen" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                    <g id="bg">
                                        <rect className="st0" x="-420" width="1920" height="1080"/>
                                    </g>
                                    <g id="ground">
                                        <rect y="721.73" className="st1" x="-420" width="1920" height="78.27"/>
                                    </g>
                                    <g id="legs">
	<g>
		<rect x="985.36" y="653.13" className="st2" width="34.73" height="107.64"/>
        <rect x="985.36" y="737.62" className="st3" width="34.73" height="23.15"/>
        <rect x="985.36" y="705.41" className="st4" width="34.73" height="9.88"/>
        <rect x="985.36" y="676.02" className="st3" width="34.73" height="29.39"/>
        <rect x="985.36" y="653.13" className="st5" width="34.73" height="7.74"/>
	</g>
                                        <g>

			<rect x="1028.43" y="653.13" transform="matrix(-1 -4.488466e-11 4.488466e-11 -1 2091.5825 1413.906)"
                  className="st2" width="34.73" height="107.64"/>

                                            <rect x="1028.43" y="737.62"
                                                  transform="matrix(-1 -4.488466e-11 4.488466e-11 -1 2091.5825 1498.3889)"
                                                  className="st3" width="34.73" height="23.15"/>

                                            <rect x="1028.43" y="705.41"
                                                  transform="matrix(-1 -4.488466e-11 4.488466e-11 -1 2091.5825 1420.6881)"
                                                  className="st4" width="34.73" height="9.88"/>

                                            <rect x="1028.43" y="676.02"
                                                  transform="matrix(-1 -4.488466e-11 4.488466e-11 -1 2091.5825 1381.4232)"
                                                  className="st3" width="34.73" height="29.39"/>

                                            <rect x="1028.43" y="653.13"
                                                  transform="matrix(-1 -4.483226e-11 4.483226e-11 -1 2091.5825 1314.0112)"
                                                  className="st5" width="34.73" height="7.74"/>
	</g>
</g>
                                    <g id="shoes_1_">
	<g>
		<path className="st6"
              d="M1019.26,760.77h-34.73v-10.76h23.97C1014.44,750.01,1019.26,754.83,1019.26,760.77L1019.26,760.77z"/>
        <path className="st6" d="M987.2,745.71L987.2,745.71c-7.34,0-13.29,5.95-13.29,13.29v1.77h26.58v-1.77
			C1000.49,751.66,994.54,745.71,987.2,745.71z"/>
	</g>
                                        <g>
		<path className="st6"
              d="M1027.59,760.77h34.73v-10.76h-23.97C1032.41,750.01,1027.59,754.83,1027.59,760.77L1027.59,760.77z"/>
                                            <path className="st6" d="M1059.65,745.71L1059.65,745.71c7.34,0,13.29,5.95,13.29,13.29v1.77h-26.58v-1.77
			C1046.36,751.66,1052.31,745.71,1059.65,745.71z"/>
	</g>
</g>
                                    <g id="body_1_">
	<path className="st2" d="M981.99,653.67h42.2V520.79h-34.3c-8,0-14.48,6.48-14.48,14.48V647.1
		C975.42,650.73,978.36,653.67,981.99,653.67z"/>
                                        <rect x="975.42" y="537.53" className="st3" width="48.78" height="9.15"/>
                                        <path className="st3" d="M1007.67,560.62h-15.73c-3.01,0-5.44-2.44-5.44-5.44v-9.41h26.61v9.41
		C1013.11,558.18,1010.68,560.62,1007.67,560.62z"/>
                                        <path className="st7" d="M990.37,630.98h18.86c2.98,0,5.39-2.41,5.39-5.39v-26.62h-29.64v26.62
		C984.98,628.57,987.4,630.98,990.37,630.98z"/>
                                        <path className="st5" d="M989.27,610.43h21.06c2.37,0,4.29-1.92,4.29-4.29v-7.17h-29.64v7.17
		C984.98,608.51,986.91,610.43,989.27,610.43z"/>
                                        <rect x="992.14" y="551.04" className="st8" width="15.34" height="4.3"/>
                                        <path className="st4"
                                              d="M996.99,598.97h-21.57v-9.1h12.47C992.91,589.87,996.99,593.95,996.99,598.97L996.99,598.97z"/>
                                        <path className="st2" d="M1066.4,653.67h-42.2V520.79h34.3c8,0,14.48,6.48,14.48,14.48V647.1
		C1072.97,650.73,1070.03,653.67,1066.4,653.67z"/>

                                        <rect x="1024.19" y="537.53"
                                              transform="matrix(-1 -4.486201e-11 4.486201e-11 -1 2097.1667 1084.2053)"
                                              className="st3" width="48.78" height="9.15"/>
                                        <path className="st3" d="M1040.72,560.62h15.73c3.01,0,5.44-2.44,5.44-5.44v-9.41h-26.61v9.41
		C1035.28,558.18,1037.71,560.62,1040.72,560.62z"/>
                                        <path className="st7" d="M1058.02,630.98h-18.86c-2.98,0-5.39-2.41-5.39-5.39v-26.62h29.64v26.62
		C1063.4,628.57,1060.99,630.98,1058.02,630.98z"/>
                                        <path className="st5" d="M1059.11,610.43h-21.06c-2.37,0-4.29-1.92-4.29-4.29v-7.17h29.64v7.17
		C1063.4,608.51,1061.48,610.43,1059.11,610.43z"/>

                                        <rect x="1040.91" y="551.04"
                                              transform="matrix(-1 -4.482116e-11 4.482116e-11 -1 2097.1667 1106.3813)"
                                              className="st8" width="15.34" height="4.3"/>
                                        <path className="st4"
                                              d="M1051.4,598.97h21.57v-9.1h-12.47C1055.48,589.87,1051.4,593.95,1051.4,598.97L1051.4,598.97z"/>
                                        <path className="st4"
                                              d="M996.99,646.15h-21.57v-9.1h12.47C992.91,637.04,996.99,641.12,996.99,646.15L996.99,646.15z"/>
                                        <path className="st4"
                                              d="M1051.4,646.15h21.57v-9.1l-12.47,0C1055.48,637.04,1051.4,641.12,1051.4,646.15L1051.4,646.15z"/>
                                        <path className="st6" d="M1040.82,527.07v-6.29h-2.77v6.29c0,1.31-1.06,2.38-2.38,2.38h0c-1.06,0-1.92,0.86-1.92,1.92v7.05
		c0,2.34,1.9,4.23,4.23,4.23h2.9c2.34,0,4.23-1.9,4.23-4.23v-7.05c0-1.06-0.86-1.92-1.92-1.92l0,0
		C1041.88,529.45,1040.82,528.38,1040.82,527.07z"/>
                                        <path className="st9" d="M1009.96,520.79v11.65c0,5.64-4.57,10.22-10.22,10.22l0,0c-2.16,0-3.91,1.75-3.91,3.91v13.45
		c0,4.55,3.69,8.24,8.24,8.24l0,0"/>
</g>
                                    <g id="neck">
	<rect x="1009.96" y="489.56" className="st10" width="28.61" height="31.23"/>
</g>
                                    <g id="kapyushon">
	<path className="st11" d="M1033.77,501.4l-1.74,1.74C1032.67,502.62,1033.25,502.04,1033.77,501.4z"/>
                                        <path className="st12"
                                              d="M1029.66,505.51l2.37-2.37c-0.9,0.74-1.91,1.35-2.99,1.81C1029.25,505.13,1029.46,505.31,1029.66,505.51z"/>
                                        <g>
		<g>
			<path className="st5" d="M999.76,493.63v-0.47c-7.09,7.64-9.45,18.1-7.07,27.62h20.35c-2.09-2.1-3.03-4.64-2.98-7.21
				C1003.83,509.13,999.76,501.85,999.76,493.63z"/>
            <path className="st12" d="M1013.32,505.92c1.01-1.11,2.18-1.96,3.45-2.57c-2.91-2.25-4.8-5.77-4.8-9.72v-8.43
				c-4.18,1.43-8.11,3.8-11.44,7.13c-0.27,0.27-0.52,0.55-0.78,0.83v0.47c0,8.22,4.07,15.5,10.3,19.95
				C1010.11,510.91,1011.25,508.21,1013.32,505.92z"/>
		</g>
                                            <g>
			<path className="st5" d="M1048.81,493.63v-0.47c7.09,7.64,9.45,18.1,7.07,27.62h-20.35c2.09-2.1,3.03-4.64,2.98-7.21
				C1044.74,509.13,1048.81,501.85,1048.81,493.63z"/>
                                                <path className="st12" d="M1035.25,505.92c-1.01-1.11-2.18-1.96-3.45-2.57c2.91-2.25,4.8-5.77,4.8-9.72v-8.43
				c4.18,1.43,8.11,3.8,11.44,7.13c0.27,0.27,0.52,0.55,0.78,0.83v0.47c0,8.22-4.07,15.5-10.3,19.95
				C1038.45,510.91,1037.32,508.21,1035.25,505.92z"/>
		</g>
	</g>
</g>
                                    <g id="hands__x28_копия_x29_">
	<g>
		<g>
			<g>
				<circle className="st13" cx="969.94" cy="604.82" r="9.74"/>
                <circle className="st14" cx="957.92" cy="610.16" r="16.55"/>
			</g>
            <path className="st2"
                  d="M974.54,566.53c0-8.12-6.58-14.69-14.69-14.69s-14.69,6.58-14.69,14.69v28.55h29.39V566.53z"/>
            <path className="st14" d="M959.85,623.81c8.12,0,14.69-6.58,14.69-14.69v-14.04h-29.39v14.04
				C945.15,617.23,951.73,623.81,959.85,623.81z"/>
            <path className="st4"
                  d="M966.72,592.77h-21.57v-9.1h12.47C962.65,583.67,966.72,587.75,966.72,592.77L966.72,592.77z"/>
            <rect x="945.11" y="592.55" className="st3" width="29.43" height="2.53"/>
		</g>
        <g>
			<path className="st2" d="M948.98,575.99L948.98,575.99c5.74,5.74,15.04,5.74,20.78,0l30.11-30.11c5.74-5.74,5.74-15.04,0-20.78l0,0
				c-5.74-5.74-15.04-5.74-20.78,0l-30.11,30.11C943.24,560.94,943.24,570.25,948.98,575.99z"/>

            <rect x="962.19" y="532.31" transform="matrix(0.7071 0.7071 -0.7071 0.7071 673.6758 -530.2301)"
                  className="st3" width="29.39" height="31.55"/>
            <path className="st4" d="M964.15,570.54l-15.25-15.25l6.44-6.44l8.82,8.82C967.71,561.22,967.71,566.98,964.15,570.54L964.15,570.54z
				"/>
		</g>
	</g>
                                        <g>
		<g>
			<g>
				<path className="st13" d="M1034.81,475.22c-2.53,4.75-0.73,10.65,4.02,13.18c4.75,2.53,10.65,0.73,13.18-4.02
					c2.53-4.75,0.73-10.65-4.02-13.18C1043.24,468.67,1037.34,470.47,1034.81,475.22z"/>

                <ellipse transform="matrix(0.9988 -0.049 0.049 0.9988 -21.5959 51.6888)" className="st14" cx="1044.34"
                         cy="466.69" rx="16.55" ry="16.55"/>
			</g>
            <path className="st2" d="M1075.05,501.85c7.16,3.81,16.06,1.1,19.88-6.07c3.81-7.17,1.1-16.06-6.07-19.88
				c-6.3-3.36-25.2-13.41-25.2-13.41c0,0-14.45,18.63-13.81,25.95C1049.85,488.44,1068.75,498.5,1075.05,501.85z"/>
            <path className="st14" d="M1031.39,461.97c-3.81,7.16-1.09,16.06,6.07,19.88c3.1,1.65,12.39,6.59,12.39,6.59
				c0,0,13.81-25.94,13.81-25.94c0,0-9.29-4.95-12.39-6.59C1044.1,452.09,1035.2,454.81,1031.39,461.97z"/>
            <path className="st4" d="M1055.64,482.67c-0.18-0.09,10.09-19.07,10.06-19.08c-0.02-0.01,8.02,4.27,8.03,4.27
				c0.07,0.04-4.5,8.2-5.84,11.01C1065.72,483.42,1059.63,484.79,1055.64,482.67C1055.28,482.48,1055.63,482.66,1055.64,482.67z"/>
            <polygon className="st3" points="1049.85,488.44 1063.67,462.46 1065.91,463.65 1052.09,489.63 			"/>
		</g>
                                            <g>
			<path className="st2" d="M1089.5,477.41C1091.03,478.23,1089.5,477.41,1089.5,477.41c6.87,3.66,10.25,12.91,6.3,20
				c-5.19,9.3-15.05,28.18-20.04,37.58c-3.81,7.17-12.98,9.74-19.77,6.12c-1.24-0.66,0,0,0,0c-6.8-3.62-10.25-12.91-6.27-19.98
				c5.22-9.28,15.02-28.19,20.06-37.57C1073.61,476.41,1082.63,473.76,1089.5,477.41z"/>
                                                <polygon className="st3"
                                                         points="1091.51,505.31 1065.56,491.5 1050.74,519.36 1076.68,533.17 			"/>
                                                <path className="st4" d="M1088.88,493.6c0,0-19.04-10.13-19.04-10.13c0.15,2.27-4.28,8.04-4.28,8.04c0,0,8.26,4.39,11.01,5.86
				C1081.01,499.72,1086.52,498.04,1088.88,493.6C1088.89,493.61,1088.88,493.6,1088.88,493.6z"/>
		</g>
	</g>
</g>
                                    <g id="head">
	<path className="st15" d="M1002.89,484.14h42.74c2.25,0,4.07-1.82,4.07-4.07v0c0-2.25-1.82-4.07-4.07-4.07h-42.74
		c-2.25,0-4.07,1.82-4.07,4.07v0C998.82,482.32,1000.64,484.14,1002.89,484.14z"/>
                                        <path className="st16" d="M1024.26,512.03L1024.26,512.03c-10.16,0-18.4-8.24-18.4-18.4V462.4h36.8v31.23
		C1042.66,503.79,1034.42,512.03,1024.26,512.03z"/>
                                        <path className="st17" d="M1031.6,481.28h-14.67c-2.35,0-4.25,1.9-4.25,4.25v22.38c3.16,2.57,7.2,4.11,11.59,4.11
		c4.39,0,8.43-1.54,11.59-4.11v-22.38C1035.85,483.19,1033.95,481.28,1031.6,481.28z"/>
                                        <path className="st18" d="M1018.55,477.02h11.41c0.95,0,1.72,0.77,1.72,1.72l0,0c0,0.95-0.77,1.72-1.72,1.72h-11.41
		c-0.95,0-1.72-0.77-1.72-1.72l0,0C1016.83,477.79,1017.6,477.02,1018.55,477.02z"/>
                                        <path className="st19" d="M1033.69,475.25L1033.69,475.25c-0.64,0-1.15-0.52-1.15-1.15v-2.04c0-0.64,0.52-1.15,1.15-1.15l0,0
		c0.64,0,1.15,0.52,1.15,1.15v2.04C1034.84,474.74,1034.33,475.25,1033.69,475.25z"/>
                                        <path className="st19" d="M1014.76,475.25L1014.76,475.25c-0.64,0-1.15-0.52-1.15-1.15v-2.04c0-0.64,0.52-1.15,1.15-1.15l0,0
		c0.64,0,1.15,0.52,1.15,1.15v2.04C1015.92,474.74,1015.4,475.25,1014.76,475.25z"/>
                                        <rect x="1002.28" y="467.65" className="st20" width="3.58" height="11.08"/>
                                        <rect x="1042.5" y="467.65" className="st20" width="3.58" height="11.08"/>
                                        <path className="st20"
                                              d="M1009.81,481.9h-7.53v-7.53l0,0C1006.44,474.37,1009.81,477.74,1009.81,481.9L1009.81,481.9z"/>
                                        <path className="st20"
                                              d="M1038.55,481.9h7.53v-7.53l0,0C1041.92,474.37,1038.55,477.74,1038.55,481.9L1038.55,481.9z"/>
                                        <rect x="1019.83" y="462.43" className="st21" width="8.87" height="18.02"/>
                                        <path className="st22" d="M1046.82,468.67h-17.57c-2.71,0-4.92-2.2-4.92-4.92v-1.36h27.4v1.36
		C1051.73,466.47,1049.53,468.67,1046.82,468.67z"/>
                                        <path className="st22" d="M1013.01,468.67h-8.61c-2.71,0-4.92-2.2-4.92-4.92v-1.36h18.44v1.36
		C1017.93,466.47,1015.73,468.67,1013.01,468.67z"/>
                                        <path className="st23" d="M1024.26,503.33L1024.26,503.33c-2.06,0-3.74-1.68-3.74-3.74v-8.4c0-2.06,1.68-3.74,3.74-3.74l0,0
		c2.06,0,3.74,1.68,3.74,3.74v8.4C1028,501.65,1026.32,503.33,1024.26,503.33z"/>
                                        <g>
		<defs>
			<path id="SVGID_1_" d="M1024.26,503.33L1024.26,503.33c-2.06,0-3.74-1.68-3.74-3.74v-8.4c0-2.06,1.68-3.74,3.74-3.74l0,0
				c2.06,0,3.74,1.68,3.74,3.74v8.4C1028,501.65,1026.32,503.33,1024.26,503.33z"/>
		</defs>
                                            <clipPath id="SVGID_2_">
			<use xlinkHref="#SVGID_1_" style={{overflow:'visible'}} />
		</clipPath>
                                            <path className="st24" d="M1028.5,491.29h-8.54c-1.12,0-2.04-0.92-2.04-2.04l0,0c0-1.12,0.92-2.04,2.04-2.04h8.54
			c1.12,0,2.04,0.92,2.04,2.04l0,0C1030.54,490.37,1029.62,491.29,1028.5,491.29z"/>
	</g>
                                        <g>
		<defs>
			<path id="SVGID_3_" d="M1024.26,503.33L1024.26,503.33c-2.06,0-3.74-1.68-3.74-3.74v-8.4c0-2.06,1.68-3.74,3.74-3.74l0,0
				c2.06,0,3.74,1.68,3.74,3.74v8.4C1028,501.65,1026.32,503.33,1024.26,503.33z"/>
		</defs>
                                            <clipPath id="SVGID_4_">
			<use xlinkHref="#SVGID_3_" style={{overflow:'visible'}} />
		</clipPath>
                                            <rect x="1020.52" y="499.59" className="st25" width="7.48" height="4.74"/>
	</g>
</g>
                                    <g id="vorotnik">
	<path className="st2"
          d="M1048.81,521.71h-48.95v-2.62c0-5.45,4.42-9.86,9.86-9.86h29.22c5.45,0,9.86,4.42,9.86,9.86V521.71z"/>
                                        <rect x="1016.64" y="509.23" className="st7" width="15.39" height="144.44"/>
                                        <g>
		<path className="st6" d="M1011.27,516.83h-4.36c-0.6,0-1.09-0.49-1.09-1.09l0,0c0-0.6,0.49-1.09,1.09-1.09h4.36
			c0.6,0,1.09,0.49,1.09,1.09l0,0C1012.35,516.34,1011.87,516.83,1011.27,516.83z"/>
                                            <path className="st6" d="M1041.61,516.83h-4.36c-0.6,0-1.09-0.49-1.09-1.09l0,0c0-0.6,0.49-1.09,1.09-1.09h4.36
			c0.6,0,1.09,0.49,1.09,1.09l0,0C1042.69,516.34,1042.21,516.83,1041.61,516.83z"/>
	</g>
</g>
                                    <g id="helmet">
	<path className="st4" d="M1024.26,428.59L1024.26,428.59c-13.24,0-23.97,10.73-23.97,23.97v7.86h47.94v-7.86
		C1048.23,439.32,1037.5,428.59,1024.26,428.59z"/>
                                        <path className="st26" d="M1052.69,465.54h-56.86l0,0c0-3.27,2.65-5.93,5.93-5.93h45C1050.03,459.61,1052.69,462.26,1052.69,465.54
		L1052.69,465.54z"/>
                                        <path className="st27" d="M1019.98,453.47l2.41,2.3c1.05,1,2.69,1,3.74,0l2.41-2.3c0.53-0.51,0.84-1.22,0.84-1.96v-22.37
		c-1.65-0.36-3.36-0.55-5.11-0.55c-1.76,0-3.46,0.19-5.11,0.55v22.37C1019.15,452.25,1019.45,452.96,1019.98,453.47z"/>
                                        <path className="st27" d="M1006.75,447.77l2.41,2.3c1.05,1,2.69,1,3.74,0l2.41-2.3c0.53-0.51,0.84-1.22,0.84-1.96v-15.81
		c-4.02,1.45-7.53,3.93-10.23,7.14v8.67C1005.91,446.55,1006.22,447.26,1006.75,447.77z"/>
                                        <path className="st27" d="M1033.22,447.77l2.41,2.3c1.05,1,2.69,1,3.74,0l2.41-2.3c0.53-0.51,0.84-1.22,0.84-1.96v-8.67
		c-2.69-3.2-6.21-5.69-10.23-7.14v15.81C1032.38,446.55,1032.68,447.26,1033.22,447.77z"/>
</g>
                                    <g id="helicopter">
	<g>
		<path className="st3" d="M-39.54,704.49H256.5c108.89,0,197.17-88.28,197.17-197.17v0H57.3c-81.19,0-147.01,65.82-147.01,147.01v0
			C-89.71,682.03-67.25,704.49-39.54,704.49z"/>
        <path className="st28" d="M99.75,636.89h20.21c6.06,0,10.98-4.91,10.98-10.98v-62.36c0-6.06-4.91-10.98-10.98-10.98H99.75
			c-6.06,0-10.98,4.91-10.98,10.98v62.36C88.77,631.98,93.69,636.89,99.75,636.89z"/>
        <path className="st28" d="M227.8,636.89h20.21c6.06,0,10.98-4.91,10.98-10.98v-62.36c0-6.06-4.91-10.98-10.98-10.98H227.8
			c-6.06,0-10.98,4.91-10.98,10.98v62.36C216.83,631.98,221.74,636.89,227.8,636.89z"/>
        <circle className="st3" cx="29.49" cy="741" r="24.94"/>
        <circle className="st3" cx="262.55" cy="738.7" r="27.25"/>
        <path className="st28" d="M54.43,563.56v62.36c0,6.06-4.91,10.98-10.98,10.98h-132.1c3.85-32.56,18.33-61.85,39.89-84.31h92.21
			C49.52,552.58,54.43,557.49,54.43,563.56z"/>
        <line className="st29" x1="252.58" y1="376.05" x2="252.58" y2="427.54"/>
        <path className="st29" d="M690.3,414.46c0,0-189.84-43.9-437.72-43.9s-437.72,43.9-437.72,43.9"/>
        <g>
			<line className="st29" x1="874.37" y1="398" x2="780.48" y2="491.89"/>
            <line className="st29" x1="874.37" y1="500.13" x2="772.24" y2="398"/>
		</g>
        <path className="st3" d="M329.41,413.55c21.72,0,39.33,17.61,39.33,39.33h-193v-39.33H329.41z"/>
        <path className="st4" d="M175.75,413.55v39.33h-39.33C136.42,431.16,154.03,413.55,175.75,413.55z"/>
        <path className="st3" d="M781.26,452.88l15.66-15.66c1.07-1.07,2.17-2.09,3.3-3.05c26.19-22.26,60.56,16.63,36.25,40.94l-31.75,31.75
			v0.78H617.15l54.76-54.76H781.26z"/>
        <path className="st4" d="M102.67,452.88H594.3h77.61l-54.76,54.76h-77.61H47.91C47.91,477.4,72.43,452.88,102.67,452.88z"/>
        <g>
            <line className="st29" x1="874.37" y1="500.13" x2="772.24" y2="398"/>
            <line className="st29" x1="772.24" y1="500.13" x2="874.37" y2="398"/>
        </g>
	</g>
</g>
</svg>
                                <div className="sceneContent">
                                    <div className="level">Уровень 2</div>
                                    <div className="question">Вы прибыли на<br/> «Приразломную». <br/> С чего начать?</div>
                                    <button className="goAhead">Далее</button>
                                </div>
                            </div>
                            <div id="scene23" className="scene">
                                <svg id="level2MLSP" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                    <g id="bg">
                                        <rect className="st0" width="2560" height="1440" x="-420" />
                                    </g>
                                    <g id="mlsp_structure">
                                        <g id="element1">
                                            <polyline className="st31" points="725.14,255.86 725.14,248.91 739.28,248.91 739.28,255.86 	"/>
                                            <g>
                                                <path className="st31" d="M757.96,397.66c0-0.06,0-0.11-0.01-0.17c-0.01-0.09-0.04-0.18-0.06-0.26c-0.01-0.02-0.01-0.04-0.02-0.06
                                                    c-0.08-0.22-0.19-0.42-0.36-0.6c0-0.01-0.01-0.01-0.02-0.01c-0.08-0.08-0.16-0.15-0.24-0.22c-0.06-0.04-0.13-0.08-0.19-0.11
                                                    c-0.03-0.02-0.07-0.04-0.1-0.06c-0.07-0.03-0.14-0.05-0.21-0.07c-0.04-0.01-0.07-0.03-0.11-0.04c-0.06-0.02-0.13-0.02-0.19-0.03
                                                    c-0.05-0.01-0.09-0.02-0.14-0.02c-0.06,0-0.12,0-0.18,0.01c-0.05,0-0.1,0-0.15,0.01c-0.08,0.01-0.17,0.04-0.25,0.06
                                                    c-0.03,0.01-0.05,0.01-0.08,0.02c-0.11,0.04-0.22,0.09-0.32,0.15l-59.96,36.07c-0.82,0.49-1.09,1.56-0.59,2.38
                                                    c0.33,0.54,0.9,0.84,1.49,0.84c0.3,0,0.61-0.08,0.89-0.25l45.25-27.22l-32.49,29.86h-13.66c-0.96,0-1.74,0.78-1.74,1.74
                                                    s0.78,1.74,1.74,1.74h9.88l-14.53,13.35c-0.71,0.65-0.75,1.75-0.1,2.46c0.34,0.37,0.81,0.56,1.28,0.56c0.42,0,0.84-0.15,1.18-0.46
                                                    l17.68-16.25c0.1-0.07,0.19-0.16,0.27-0.25l45.49-41.8c0.09-0.08,0.17-0.17,0.24-0.26c0.02-0.03,0.03-0.06,0.05-0.08
                                                    c0.04-0.07,0.09-0.13,0.12-0.21c0.02-0.05,0.04-0.11,0.06-0.16c0.02-0.05,0.04-0.1,0.05-0.16c0.01-0.05,0.02-0.11,0.02-0.17
                                                    c0.01-0.06,0.02-0.11,0.02-0.17C757.97,397.77,757.96,397.71,757.96,397.66z"/>
                                                <path className="st31" d="M694.84,427.38c-0.55,0.79-0.36,1.87,0.42,2.42c0.3,0.21,0.65,0.32,1,0.32c0.55,0,1.09-0.26,1.42-0.74
                                                    l21.51-30.64c0,0,0.01-0.01,0.01-0.01c0.04-0.06,0.07-0.12,0.11-0.19c0.02-0.04,0.04-0.07,0.06-0.11
                                                    c0.02-0.04,0.03-0.09,0.04-0.14c0.02-0.06,0.05-0.13,0.06-0.19c0.01-0.03,0.01-0.06,0.01-0.09c0.01-0.08,0.02-0.17,0.03-0.25
                                                    c0-0.01,0-0.01,0-0.01V377.1c0-0.96-0.78-1.74-1.74-1.74s-1.74,0.78-1.74,1.74v20.09L694.84,427.38z"/>
                                                <path className="st31" d="M771.13,378.21c-0.59-0.76-1.68-0.9-2.44-0.31l-22.97,17.75l-22.97-17.75c-0.76-0.59-1.85-0.45-2.44,0.31
                                                    c-0.59,0.76-0.45,1.85,0.31,2.44l23.9,18.47c0.32,0.24,0.69,0.36,1.06,0.36c0.04,0,0.09-0.01,0.13-0.01
                                                    c0.04,0,0.09,0.01,0.13,0.01c0.37,0,0.75-0.12,1.06-0.36l23.9-18.47C771.57,380.06,771.71,378.97,771.13,378.21z"/>
                                                <path className="st31" d="M691.1,399.12l23.9-18.47c0.76-0.59,0.9-1.68,0.31-2.44c-0.58-0.76-1.68-0.9-2.44-0.31l-22.65,17.5
                                                    c-0.13,0.05-0.26,0.12-0.38,0.21l-22.92-17.71c-0.76-0.59-1.85-0.45-2.44,0.31c-0.59,0.76-0.45,1.85,0.31,2.44L687,397.8
                                                    l-21.09,16.29c-0.76,0.59-0.9,1.68-0.31,2.44c0.34,0.44,0.86,0.68,1.38,0.68c0.37,0,0.75-0.12,1.06-0.36l22.68-17.52
                                                    C690.85,399.27,690.98,399.21,691.1,399.12z"/>
                                            </g>
                                            <path className="st31" d="M783.18,380.58H680.89v-6.95h102.29c1.92,0,3.48,1.56,3.48,3.48v0C786.65,379.02,785.1,380.58,783.18,380.58z
                                                "/>
                                            <g>
                                                <path className="st31" d="M756.23,399.48h-79.96c-0.96,0-1.74-0.78-1.74-1.74s0.78-1.74,1.74-1.74h79.96c0.96,0,1.74,0.78,1.74,1.74
                                                    S757.19,399.48,756.23,399.48z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M672.9,367.11c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l7.98-15.43
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-7.98,15.43C673.52,366.94,673.22,367.11,672.9,367.11z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M697.94,318.71c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l20.06-38.79
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-20.06,38.79C698.56,318.54,698.25,318.71,697.94,318.71z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M666.06,367.11c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l9.41-18.2
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-9.41,18.2C666.68,366.94,666.37,367.11,666.06,367.11z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M681.84,376.02c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l9.76-18.88
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-9.76,18.88C682.46,375.85,682.15,376.02,681.84,376.02z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M708.66,324.17c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l20.06-38.79
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-20.06,38.79C709.27,324,708.97,324.17,708.66,324.17z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M703.24,321.41c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l20.06-38.79
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-20.06,38.79C703.86,321.24,703.55,321.41,703.24,321.41z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M679.6,367.11c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l6.58-12.73
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-6.58,12.73C680.22,366.94,679.92,367.11,679.6,367.11z"/>
                                            </g>
                                            <path className="st31" d="M712.99,275.52c-0.43-0.22-0.95-0.05-1.17,0.37l-2.23,4.3l-51.23,56.25c-0.32,0.35-0.3,0.9,0.06,1.23
                                                c0.17,0.15,0.38,0.23,0.58,0.23c0.24,0,0.47-0.1,0.64-0.28l46.86-51.44l-14.75,28.51c-0.22,0.43-0.05,0.95,0.37,1.17
                                                c0.13,0.07,0.26,0.1,0.4,0.1c0.31,0,0.62-0.17,0.77-0.47l20.06-38.79C713.58,276.26,713.41,275.74,712.99,275.52z"/>
                                            <rect x="648.9" y="384.06" className="st32" width="27.38" height="29.87"/>
                                            <line className="st31" x1="676.27" y1="388.51" x2="648.9" y2="384.06"/>
                                            <polyline className="st33"
                                                      points="676.27,396.48 648.9,396.48 648.9,396.48 676.27,400.93 	"/>
                                            <polygon className="st31"
                                                     points="666.06,366.24 651.94,366.24 655.41,335.82 662.58,335.82 	"/>
                                            <rect x="642.81" y="366.24" className="st31" width="39.55" height="17.82"/>
                                            <polyline className="st34"
                                                      points="666.06,366.24 661.79,373.63 682.36,373.63 	"/>
                                            <line className="st31" x1="682.36" y1="368.87" x2="642.81" y2="368.87"/>
                                            <g>
                                                <path className="st31" d="M680.89,351.68c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l17.05-32.97
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-17.05,32.97C681.5,351.5,681.2,351.68,680.89,351.68z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M675.47,348.91c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l17.05-32.97
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-17.05,32.97C676.09,348.74,675.79,348.91,675.47,348.91z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M691.6,357.14c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l17.05-32.97
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-17.05,32.97C692.22,356.97,691.92,357.14,691.6,357.14z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M686.19,354.38c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l17.05-32.97
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-17.05,32.97C686.81,354.21,686.5,354.38,686.19,354.38z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M718,279.92c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l5.13-9.91
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-5.13,9.91C718.62,279.75,718.32,279.92,718,279.92z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M712.59,277.16c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l5.13-9.91
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-5.13,9.91C713.2,276.99,712.9,277.16,712.59,277.16z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M723.3,282.63c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l5.13-9.91
                                                    c0.22-0.42,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-5.13,9.91C723.92,282.45,723.62,282.63,723.3,282.63z"/>
                                            </g>
                                            <g>
                                                <path className="st31" d="M717.71,267.25c-0.13,0-0.27-0.03-0.4-0.1c-0.43-0.22-0.59-0.75-0.37-1.17l5.12-9.9
                                                    c0.22-0.43,0.74-0.59,1.17-0.37c0.43,0.22,0.59,0.75,0.37,1.17l-5.12,9.9C718.33,267.08,718.03,267.25,717.71,267.25z"/>
                                            </g>
                                            <rect x="728.5" y="333.72" className="st31" width="7.43" height="5.21"/>
                                            <line className="st31" x1="737.62" y1="334.32" x2="726.81" y2="334.32"/>
                                            <rect x="726.81" y="272.96" className="st35" width="10.81" height="61.36"/>
                                            <line className="st31" x1="726.81" y1="282.96" x2="737.62" y2="282.96"/>
                                            <line className="st31" x1="735.93" y1="335.82" x2="728.5" y2="335.82"/>
                                            <rect x="715.15" y="254.79" className="st31" width="34.13" height="8.02"/>
                                            <rect x="722.83" y="261.43" className="st31" width="18.77" height="12.03"/>
                                            <line className="st31" x1="741.6" y1="265.84" x2="722.83" y2="265.84"/>
                                            <line className="st31" x1="726.81" y1="302.34" x2="737.62" y2="302.34"/>
                                            <line className="st31" x1="726.81" y1="314.53" x2="737.62" y2="314.53"/>
                                        </g>
                                        <path className="st31" d="M282.8,337.57l-132.22-18.79l-25.47-11.79c-0.06-0.03-0.12-0.04-0.18-0.06c-0.06-0.02-0.12-0.05-0.19-0.06
                                            c-0.08-0.02-0.16-0.02-0.25-0.03c-0.07,0-0.13-0.01-0.2-0.01c-0.08,0-0.16,0.02-0.25,0.04c-0.06,0.01-0.13,0.02-0.19,0.04
                                            c-0.1,0.03-0.19,0.08-0.28,0.13c-0.04,0.02-0.08,0.03-0.12,0.05l-65.76,41.16c-0.51,0.32-0.82,0.87-0.82,1.47v6.95
                                            c0,0.63,0.34,1.21,0.89,1.52c0.26,0.15,0.55,0.22,0.84,0.22c0.32,0,0.64-0.09,0.92-0.26l64.9-40.31l143.38,78.62
                                            c0.26,0.14,0.55,0.21,0.84,0.21c0.31,0,0.61-0.08,0.89-0.24c0.53-0.31,0.85-0.88,0.85-1.5v-19.57c0-0.68-0.39-1.29-1.01-1.58
                                            l-107.86-49.94l120.79,17.17c0.08,0.01,0.17,0.02,0.25,0.02c0.85,0,1.6-0.63,1.72-1.49C284.41,338.59,283.75,337.71,282.8,337.57z
                                             M60.35,350.69l62.29-38.98v3.16l-62.29,38.69V350.69z M266.91,392l-140.8-77.2v-3.51l140.8,65.19V392z"/>
                                        <polyline className="st31"
                                                  points="279.83,344.51 282.85,304.97 294.26,304.97 297.28,344.51 	"/>
                                        <rect x="278.21" y="298.01" className="st31" width="20.69" height="13.04"/>
                                        <polyline className="st31" points="696.26,455.18 696.26,411.43 641.51,411.43 641.51,377.97 617.07,377.97 546.77,377.97
                                            546.77,366.24 431.23,366.24 431.23,339.3 350.4,339.3 350.4,411.43 308.46,411.43 308.46,344.51 268.65,344.51 268.65,375.36
                                            268.65,411.43 235.63,411.43 235.63,455.18 	"/>
                                        <polygon className="st31"
                                                 points="617.07,436.64 617.07,426.9 257.35,426.9 257.35,455.18 617.07,455.18 631.66,436.64 	"/>
                                        <g id="element3">
                                            <g>
                                                <g>
                                                    <line className="st36" x1="465.75" y1="686.56" x2="465.75" y2="721.2"/>
                                                    <path className="st37" d="M463.58,687.32c0,0,0-7.87,0,12.15c0,20.01-3.72,21.41-3.72,21.41"/>
                                                    <path className="st37" d="M461.41,687.32c0,0,0-7.87,0,12.15c0,20.01-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st37" d="M467.92,687.32c0,0,0-7.87,0,12.15c0,20.01,3.72,21.41,3.72,21.41"/>
                                                <path className="st37" d="M470.09,687.32c0,0,0-7.87,0,12.15c0,20.01,7.45,21.41,7.45,21.41"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <line className="st36" x1="602" y1="686.56" x2="602" y2="721.2"/>
                                                    <path className="st37" d="M599.83,687.32c0,0,0-7.87,0,12.15c0,20.01-3.72,21.41-3.72,21.41"/>
                                                    <path className="st37" d="M597.66,687.32c0,0,0-7.87,0,12.15c0,20.01-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st37"
                                                      d="M604.17,687.32c0,0,0-7.87,0,12.15c0,20.01,3.72,21.41,3.72,21.41"/>
                                                <path className="st37"
                                                      d="M606.34,687.32c0,0,0-7.87,0,12.15c0,20.01,7.45,21.41,7.45,21.41"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <line className="st38" x1="261.66" y1="688.44" x2="261.66" y2="744.27"/>
                                                        <path className="st39" d="M258.16,689.67c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st39" d="M254.66,689.67c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st39" d="M265.16,689.67c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st39" d="M268.66,689.67c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st38" x1="397.82" y1="688.44" x2="397.82" y2="744.27"/>
                                                        <path className="st39" d="M394.32,689.67c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st39" d="M390.82,689.67c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st39"
                                                          d="M401.32,689.67c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st39"
                                                          d="M404.82,689.67c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st38" x1="533.99" y1="688.44" x2="533.99" y2="744.27"/>
                                                        <path className="st39" d="M530.49,689.67c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st39" d="M526.99,689.67c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st39"
                                                          d="M537.49,689.67c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st39"
                                                          d="M540.99,689.67c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st38" x1="670.16" y1="688.44" x2="670.16" y2="744.27"/>
                                                        <path className="st39" d="M666.66,689.67c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st39" d="M663.16,689.67c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st39"
                                                          d="M673.66,689.67c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st39"
                                                          d="M677.16,689.67c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <line className="st36" x1="329.66" y1="686.56" x2="329.66" y2="721.2"/>
                                                    <path className="st37" d="M327.48,687.32c0,0,0-7.87,0,12.15c0,20.01-3.72,21.41-3.72,21.41"/>
                                                    <path className="st37" d="M325.31,687.32c0,0,0-7.87,0,12.15c0,20.01-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st37"
                                                      d="M331.83,687.32c0,0,0-7.87,0,12.15c0,20.01,3.72,21.41,3.72,21.41"/>
                                                <path className="st37"
                                                      d="M334,687.32c0,0,0-7.87,0,12.15c0,20.01,7.45,21.41,7.45,21.41"/>
                                            </g>
                                        </g>
                                        <polyline className="st33"
                                                  points="281.69,426.9 281.69,443.59 270.1,443.59 270.1,426.9 	"/>
                                        <line className="st31" x1="305.44" y1="455.18" x2="305.44" y2="426.9"/>
                                        <line className="st31" x1="452.04" y1="426.9" x2="452.04" y2="455.18"/>
                                        <line className="st31" x1="485.06" y1="455.18" x2="485.06" y2="426.9"/>
                                        <line className="st31" x1="487.96" y1="426.9" x2="487.96" y2="455.18"/>
                                        <line className="st31" x1="520.99" y1="455.18" x2="520.99" y2="426.9"/>
                                        <line className="st31" x1="380.19" y1="426.9" x2="380.19" y2="455.18"/>
                                        <line className="st31" x1="413.22" y1="455.18" x2="413.22" y2="426.9"/>
                                        <line className="st31" x1="344.27" y1="426.9" x2="344.27" y2="455.18"/>
                                        <line className="st31" x1="377.29" y1="455.18" x2="377.29" y2="426.9"/>
                                        <line className="st31" x1="416.11" y1="426.9" x2="416.11" y2="455.18"/>
                                        <line className="st31" x1="449.14" y1="455.18" x2="449.14" y2="426.9"/>
                                        <polyline className="st31"
                                                  points="341.37,455.18 341.37,426.9 308.34,426.9 308.34,455.18 	"/>
                                        <line className="st31" x1="559.81" y1="426.9" x2="559.81" y2="455.18"/>
                                        <line className="st31" x1="592.84" y1="455.18" x2="592.84" y2="426.9"/>
                                        <line className="st31" x1="523.89" y1="426.9" x2="523.89" y2="455.18"/>
                                        <line className="st31" x1="556.91" y1="455.18" x2="556.91" y2="426.9"/>
                                        <line className="st31" x1="595.73" y1="426.9" x2="595.73" y2="455.18"/>
                                        <line className="st31" x1="617.07" y1="455.18" x2="617.07" y2="426.9"/>
                                        <path className="st31" d="M349.72,426.07c0.03-0.04,0.06-0.08,0.09-0.12c0.04-0.06,0.06-0.12,0.09-0.18c0.02-0.04,0.04-0.08,0.06-0.12
                                            c0.02-0.07,0.04-0.14,0.05-0.2c0.01-0.04,0.02-0.08,0.03-0.13c0.02-0.1,0.02-0.2,0.02-0.29c0-0.02,0-0.03,0-0.04l0,0c0,0,0,0,0,0
                                            l-1.61-38.57c0-0.01,0-0.03,0-0.04c0-0.04,0.01-0.08,0.01-0.11c0,0,0-0.01,0-0.01c0-0.09-0.01-0.17-0.03-0.26
                                            c0-0.03,0-0.05-0.01-0.08l-1.6-38.35c0.01-0.05,0.01-0.11,0.02-0.16c0,0,0-0.01,0-0.01c0-0.04-0.01-0.07-0.01-0.11
                                            c0-0.07-0.01-0.15-0.02-0.22c0-0.01,0-0.02-0.01-0.03l-3.04-72.78c0.01-0.03,0.02-0.05,0.02-0.08c0.01-0.07,0.02-0.15,0.02-0.22
                                            c0-0.04,0.01-0.07,0.01-0.11c0,0,0-0.01,0-0.01c0-0.1-0.01-0.2-0.03-0.3c0-0.02,0-0.03-0.01-0.05c-0.01-0.05-0.03-0.1-0.05-0.16
                                            c0-0.01-0.01-0.02-0.01-0.03l-1.32-31.58c0-0.01,0-0.02,0-0.03c0.01-0.06,0.01-0.12,0.02-0.18c0-0.03,0.01-0.07,0.01-0.1
                                            c0-0.02,0-0.04-0.01-0.05c0-0.06-0.01-0.12-0.02-0.19c-0.01-0.05-0.01-0.11-0.03-0.16c-0.01-0.02-0.01-0.05-0.02-0.07l-1.11-26.57
                                            c0-0.04,0-0.08,0-0.11c0-0.03,0.01-0.06,0.01-0.09c0-0.02-0.01-0.04-0.01-0.07c0-0.06-0.01-0.12-0.02-0.19c0-0.03,0-0.07-0.01-0.1
                                            l-1.03-24.55c0,0,0-0.01,0-0.01c0-0.01,0-0.03,0-0.04c0-0.01,0-0.02,0-0.02l-2.17-25.58l-2.16-25.49l-2.23-26.36l-1.79-21.15
                                            l-1.45-17.09l-0.96-11.3l-1.3-15.35c-0.08-0.9-0.83-1.59-1.73-1.59h-14.61c-0.9,0-1.66,0.69-1.73,1.59l-1.3,15.38c0,0,0,0,0,0
                                            l0,0.04l-0.95,11.27l-1.44,17.06l-1.79,21.15l-2.23,26.36l-2.16,25.49l-2.17,25.58c0,0.01,0,0.02,0,0.02c-0.01,0.08,0,0.16,0,0.24
                                            c0,0.04,0,0.07,0,0.11c0.01,0.11,0.04,0.23,0.08,0.33c0,0,0,0,0,0c0.04,0.11,0.09,0.22,0.15,0.32c0.02,0.03,0.04,0.06,0.06,0.08
                                            c0.05,0.07,0.09,0.14,0.15,0.2c0,0,0.01,0.01,0.01,0.02c0.03,0.04,0.07,0.06,0.11,0.09c0.04,0.04,0.08,0.08,0.12,0.12
                                            c0,0,0.01,0,0.02,0.01c0.08,0.06,0.17,0.11,0.27,0.16l-9.78,234.11c0,0,0,0,0,0l0,0c0,0.02,0,0.03,0,0.04c0,0.1,0,0.2,0.02,0.29
                                            c0.01,0.04,0.02,0.08,0.03,0.13c0.02,0.07,0.03,0.14,0.05,0.2c0.02,0.04,0.04,0.08,0.06,0.12c0.03,0.06,0.06,0.13,0.09,0.18
                                            c0.03,0.04,0.06,0.08,0.09,0.12c0.04,0.05,0.08,0.11,0.12,0.16c0.01,0.01,0.01,0.02,0.02,0.02c0.03,0.04,0.08,0.06,0.11,0.1
                                            c0.04,0.03,0.07,0.07,0.11,0.1c0.01,0,0.01,0.01,0.02,0.01c0.13,0.1,0.28,0.17,0.43,0.22c0.04,0.01,0.07,0.02,0.11,0.03
                                            c0.15,0.04,0.31,0.07,0.47,0.07c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0h57.36c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
                                            c0.16,0,0.32-0.03,0.47-0.07c0.04-0.01,0.07-0.02,0.11-0.03c0.15-0.05,0.3-0.13,0.43-0.22c0.01,0,0.01-0.01,0.02-0.01
                                            c0.04-0.03,0.07-0.07,0.11-0.1c0.04-0.03,0.08-0.06,0.11-0.1c0.01-0.01,0.01-0.02,0.02-0.02
                                            C349.64,426.18,349.68,426.13,349.72,426.07z M295.91,384.51l23.73-34.09l23.75,34.09H295.91z M319.31,71.91c-0.01,0-0.01,0-0.02,0
                                            c-0.06-0.01-0.12-0.01-0.18-0.01c-0.05,0-0.1,0.01-0.15,0.01c-0.02,0-0.04,0-0.05,0l-5.61,0.01l5.82-6.9l5.82,6.87L319.31,71.91z
                                             M311.3,88.99l7.82-12.14l7.82,12.14H311.3z M309.41,110.14l9.71-16.05l9.71,16.05H309.41z M319.12,136.5
                                            C319.12,136.5,319.12,136.5,319.12,136.5C319.12,136.5,319.12,136.5,319.12,136.5h-12.03l12.03-21.11l12.04,21.11H319.12z
                                             M305.21,161.99l13.91-20.65l13.92,20.65H305.21z M303.27,187.58l15.85-20.96l15.86,20.96H303.27z M303.39,212.41l16.25-20.31
                                            l16.27,20.31H303.39z M302.17,239.64l17.47-22.65l17.51,22.65H302.17z M319.66,272.08C319.65,272.08,319.65,272.08,319.66,272.08
                                            h-0.03c0,0,0,0-0.01,0h-19.04l19.05-27.64l19.12,27.64H319.66z M319.62,306.83c0,0-0.01,0-0.01,0h-20.49l20.52-29.93l20.52,29.93
                                            H319.62z M297.42,345.64l22.22-33.9l22.24,33.9H297.42z M322.97,349.11h20.44l1.3,31.21L322.97,349.11z M322.86,310.31h18.93
                                            l1.29,30.81L322.86,310.31z M322.94,275.56h17.4l1.13,27.03L322.94,275.56z M322.96,243.12h16.02l1.03,24.66L322.96,243.12z
                                             M323.18,215.89h14.66l0.84,20.05L323.18,215.89z M323.26,191.05h13.54l0.75,17.84L323.26,191.05z M322.61,165.47h12.1l1.52,18
                                            L322.61,165.47z M322.39,139.98h10.16l1.46,17.24L322.39,139.98z M322.11,113.61h8.21l1.43,16.91L322.11,113.61z M322.2,92.46h6.33
                                            l1.03,12.16L322.2,92.46z M322.31,75.38l4.77-0.01l0.72,8.54L322.31,75.38z M322.88,64.09l3.24-0.01l0.36,4.27L322.88,64.09z
                                             M319.42,60.63c-0.05-0.01-0.09-0.02-0.14-0.02c-0.06-0.01-0.11,0-0.17,0c-0.05,0-0.11,0-0.16,0c-0.05,0-0.1,0.02-0.15,0.03
                                            l-5.33,0.02l5.64-10.11l5.64,10.07L319.42,60.63z M312.11,64.13l3.23-0.01l-3.59,4.25L312.11,64.13z M311.16,75.4l4.76-0.01
                                            l-5.48,8.51L311.16,75.4z M309.71,92.46h6.32l-7.35,12.15L309.71,92.46z M307.92,113.61h8.2l-9.63,16.91L307.92,113.61z
                                             M305.69,139.98h10.16l-11.62,17.24L305.69,139.98z M303.53,165.47h12.09l-13.61,18L303.53,165.47z M302.48,191.05h13.55
                                            l-14.29,17.86L302.48,191.05z M301.44,215.89h14.67l-15.51,20.1L301.44,215.89z M300.3,243.12h16.03l-17.06,24.75L300.3,243.12z
                                             M298.95,275.56h17.4l-18.53,27.03L298.95,275.56z M297.49,310.31h18.93l-20.22,30.85L297.49,310.31z M295.87,349.11h20.44
                                            l-21.75,31.24L295.87,349.11z M294.25,387.99h21.95l-23.26,31.47L294.25,387.99z M319.64,389.17l25.24,34.14h-50.47L319.64,389.17z
                                             M323.09,387.99h21.95l1.31,31.47L323.09,387.99z M325.32,54.48l-3.23-5.76h2.74L325.32,54.48z M316.16,48.72l-3.23,5.79l0.49-5.79
                                            H316.16z"/>
                                        <polyline className="st33" points="415.15,339.3 415.15,236.74 403.85,162.26 403.85,116.92 377.78,116.92 377.78,162.26
                                            366.48,236.74 366.48,339.3 	"/>
                                        <line className="st31" x1="403.85" y1="128.97" x2="377.78" y2="128.97"/>
                                        <line className="st31" x1="403.85" y1="152.43" x2="377.78" y2="152.43"/>
                                        <line className="st31" x1="377.78" y1="140.38" x2="403.85" y2="140.38"/>
                                        <line className="st31" x1="405.92" y1="175.9" x2="375.71" y2="175.9"/>
                                        <line className="st31" x1="377.54" y1="163.85" x2="404.09" y2="163.85"/>
                                        <line className="st31" x1="409.48" y1="199.37" x2="372.15" y2="199.37"/>
                                        <line className="st31" x1="373.98" y1="187.32" x2="407.65" y2="187.32"/>
                                        <path className="st33" d="M366.48,238.04h5.29v22.6h-5.29 M415.1,236.74h-48.62 M415.1,260.64h-5.51v-22.6h5.51 M366.48,264.55h48.62
                                     M388.3,238.04h4.77v22.6h-4.77V238.04z M385.97,260.64h-4.77v-22.6h4.77V260.64z M395.4,238.04h4.77v22.6h-4.77V238.04z
                                     M374.1,238.04h4.77v22.6h-4.77V238.04z M407.26,260.64h-4.77v-22.6h4.77V260.64z"/>
                                        <polyline className="st33"
                                                  points="480.28,366.24 480.28,423.89 431.18,423.89 431.18,366.24 	"/>
                                        <polyline className="st33"
                                                  points="431.18,380.1 445.24,380.1 445.24,423.89 431.18,423.89 	"/>
                                        <polyline className="st33"
                                                  points="431.18,353.78 472.85,353.78 472.85,366.24 	"/>
                                        <g>
                                            <rect x="437.45" y="357.33" className="st31" width="5" height="5"/>
                                            <rect x="449.51" y="357.33" className="st31" width="5" height="5"/>
                                            <rect x="461.57" y="357.33" className="st31" width="5" height="5"/>
                                        </g>
                                        <g>
                                            <polyline className="st33" points="434.58,353.78 434.58,342.63 468.19,342.63 468.19,353.78 		"/>

                                            <rect x="448.89" y="334.9"
                                                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 103.1828 799.5919)"
                                                  className="st33" width="5" height="26.62"/>
                                        </g>
                                        <polyline className="st33"
                                                  points="485.06,366.24 485.06,353.78 526.73,353.78 526.73,366.24 	"/>
                                        <g>
                                            <rect x="491.34" y="357.33" className="st31" width="5" height="5"/>
                                            <rect x="503.4" y="357.33" className="st31" width="5" height="5"/>
                                            <rect x="515.46" y="357.33" className="st31" width="5" height="5"/>
                                        </g>
                                        <g>
                                            <polyline className="st33" points="488.47,353.78 488.47,342.63 522.08,342.63 522.08,353.78 		"/>

                                            <rect x="502.77" y="334.9"
                                                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 157.0687 853.4777)"
                                                  className="st33" width="5" height="26.62"/>
                                        </g>
                                        <polyline className="st33"
                                                  points="617.07,377.97 621.08,330.6 630.97,330.6 634.99,377.97 	"/>
                                        <polyline className="st33"
                                                  points="617.07,377.97 617.07,423.89 546.77,423.89 546.77,377.97 	"/>
                                        <line className="st31" x1="668.89" y1="455.18" x2="668.89" y2="411.43"/>
                                        <polyline className="st31"
                                                  points="252.14,477.2 235.63,455.18 696.26,455.18 679.75,477.2 	"/>
                                        <rect x="252.14" y="477.2" className="st31" width="427.61" height="44.04"/>
                                        <polyline className="st31"
                                                  points="213.68,602.24 201.64,625.38 730.25,625.38 718.21,602.24 	"/>
                                        <line className="st31" x1="252.14" y1="521.23" x2="252.14" y2="528.38"/>
                                        <line className="st31" x1="679.75" y1="528.38" x2="679.75" y2="521.23"/>
                                        <g>
                                            <path className="st40" d="M341.23,497.71c-0.1-1.8-0.21-3.96-0.19-5.56h-0.06c-0.44,1.51-0.98,3.12-1.63,4.89l-2.27,6.25h-1.26
                                        l-2.08-6.14c-0.61-1.82-1.13-3.48-1.49-5.01h-0.04c-0.04,1.61-0.13,3.77-0.25,5.7l-0.34,5.53h-1.59l0.9-12.89h2.12l2.2,6.23
                                        c0.54,1.59,0.98,3,1.3,4.34h0.06c0.32-1.3,0.78-2.71,1.36-4.34l2.29-6.23h2.12l0.8,12.89h-1.63L341.23,497.71z"/>
                                            <path className="st40" d="M358.25,490.49v12.89h-1.68v-11.49h-4.26v3.9c0,2.79-0.11,5.91-1.93,7.13c-0.48,0.31-1.22,0.61-1.97,0.61
                                        l-0.21-1.34c0.52-0.1,1.05-0.4,1.36-0.69c1.03-1.01,1.13-3.52,1.13-5.58v-5.43H358.25z"/>
                                            <path className="st40" d="M373.96,502.95c-0.61,0.31-1.84,0.61-3.4,0.61c-3.63,0-6.37-2.29-6.37-6.52c0-4.03,2.73-6.77,6.73-6.77
                                        c1.61,0,2.62,0.34,3.06,0.57l-0.4,1.36c-0.63-0.31-1.53-0.54-2.6-0.54c-3.02,0-5.03,1.93-5.03,5.32c0,3.15,1.82,5.18,4.95,5.18
                                        c1.01,0,2.05-0.21,2.72-0.54L373.96,502.95z"/>
                                            <path className="st40"
                                                  d="M389.18,490.49v12.89h-1.66v-11.49h-5.98v11.49h-1.66v-12.89H389.18z"/>
                                            <path className="st40" d="M404.21,498.56l2.56,3.52h-1.36l-2.52-3.52l2.52-3.52h1.36L404.21,498.56z M407.35,498.56l2.56,3.52h-1.36
                                        l-2.52-3.52l2.52-3.52h1.36L407.35,498.56z"/>
                                            <path className="st40"
                                                  d="M424.94,490.49v12.89h-1.66v-11.49h-5.98v11.49h-1.66v-12.89H424.94z"/>
                                            <path className="st40" d="M431.67,490.64c0.8-0.13,1.85-0.25,3.19-0.25c1.64,0,2.85,0.38,3.61,1.07c0.71,0.61,1.13,1.55,1.13,2.7
                                        c0,1.17-0.34,2.08-0.99,2.75c-0.88,0.94-2.31,1.41-3.94,1.41c-0.5,0-0.96-0.02-1.34-0.11v5.16h-1.66V490.64z M433.33,496.85
                                        c0.36,0.1,0.82,0.13,1.38,0.13c2.01,0,3.23-0.98,3.23-2.75c0-1.7-1.2-2.52-3.04-2.52c-0.73,0-1.28,0.06-1.57,0.13V496.85z"/>
                                            <path className="st40" d="M447.24,490.49v5.43c0,2.04-0.04,3.73-0.15,5.45l0.04,0.02c0.65-1.34,1.45-2.73,2.39-4.26l4.17-6.63h1.66
                                        v12.89h-1.57v-5.49c0-2.08,0.02-3.61,0.15-5.24l-0.06-0.02c-0.63,1.42-1.51,2.91-2.39,4.34l-4.03,6.41h-1.78v-12.89H447.24z"/>
                                            <path className="st40" d="M462.07,490.64c0.8-0.13,1.85-0.25,3.19-0.25c1.64,0,2.85,0.38,3.61,1.07c0.71,0.61,1.13,1.55,1.13,2.7
                                        c0,1.17-0.34,2.08-0.99,2.75c-0.88,0.94-2.31,1.41-3.94,1.41c-0.5,0-0.96-0.02-1.34-0.11v5.16h-1.66V490.64z M463.74,496.85
                                        c0.36,0.1,0.82,0.13,1.38,0.13c2.01,0,3.23-0.98,3.23-2.75c0-1.7-1.2-2.52-3.04-2.52c-0.73,0-1.28,0.06-1.57,0.13V496.85z"/>
                                            <path className="st40" d="M476.55,499.32l-1.34,4.05h-1.72l4.38-12.89h2.01l4.4,12.89h-1.78l-1.38-4.05H476.55z M480.77,498.02
                                        l-1.26-3.71c-0.29-0.84-0.48-1.61-0.67-2.35h-0.04c-0.19,0.76-0.4,1.55-0.65,2.33l-1.26,3.73H480.77z"/>
                                            <path className="st40" d="M489.68,491.25c0.88-0.57,2.01-0.97,3.4-0.97c2.24,0,3.79,1.17,3.79,3.15c0,1.59-1.15,2.79-2.66,3.14v0.04
                                        c1.82,0.19,3.14,1.38,3.14,3.23c0,2.68-2.31,3.75-4.67,3.75c-1.17,0-2.39-0.25-3.36-0.84l0.44-1.3c0.69,0.4,1.84,0.78,2.89,0.78
                                        c1.91,0,2.96-1.03,2.96-2.45c0-1.93-1.85-2.49-3.5-2.49h-0.77v-1.26h0.77c1.7,0,3.02-1.05,3.02-2.37c0-1.3-0.92-2.03-2.31-2.03
                                        c-0.98,0-1.99,0.4-2.68,0.84L489.68,491.25z"/>
                                            <path className="st40" d="M511.94,490.49v12.89h-1.68v-11.49h-4.26v3.9c0,2.79-0.11,5.91-1.93,7.13c-0.48,0.31-1.22,0.61-1.97,0.61
                                        l-0.21-1.34c0.52-0.1,1.05-0.4,1.36-0.69c1.03-1.01,1.13-3.52,1.13-5.58v-5.43H511.94z"/>
                                            <path className="st40" d="M529.66,496.8c0,4.44-2.7,6.79-5.98,6.79c-3.4,0-5.79-2.64-5.79-6.54c0-4.09,2.54-6.77,5.98-6.77
                                        C527.39,490.28,529.66,492.97,529.66,496.8z M519.66,497.01c0,2.75,1.49,5.22,4.11,5.22c2.64,0,4.13-2.43,4.13-5.35
                                        c0-2.56-1.34-5.24-4.11-5.24C521.04,491.63,519.66,494.18,519.66,497.01z"/>
                                            <path className="st40" d="M546.45,497.71c-0.1-1.8-0.21-3.96-0.19-5.56h-0.06c-0.44,1.51-0.98,3.12-1.63,4.89l-2.27,6.25h-1.26
                                        l-2.08-6.14c-0.61-1.82-1.13-3.48-1.49-5.01h-0.04c-0.04,1.61-0.13,3.77-0.25,5.7l-0.34,5.53h-1.59l0.9-12.89h2.12l2.2,6.23
                                        c0.54,1.59,0.98,3,1.3,4.34h0.06c0.32-1.3,0.78-2.71,1.36-4.34l2.29-6.23h2.12l0.8,12.89h-1.63L546.45,497.71z"/>
                                            <path className="st40"
                                                  d="M556.45,490.49v5.39h6.23v-5.39h1.68v12.89h-1.68v-6.04h-6.23v6.04h-1.66v-12.89H556.45z"/>
                                            <path className="st40" d="M573.16,499.32l-1.34,4.05h-1.72l4.38-12.89h2.01l4.4,12.89h-1.78l-1.38-4.05H573.16z M577.39,498.02
                                        l-1.26-3.71c-0.29-0.84-0.48-1.61-0.67-2.35h-0.04c-0.19,0.76-0.4,1.55-0.65,2.33l-1.26,3.73H577.39z"/>
                                            <path className="st40" d="M585.44,503.37c0.19-0.34,0.36-0.75,0.52-1.17c0.55-1.47,0.9-3.27,1.95-4.32c0.31-0.31,0.69-0.54,1.07-0.65
                                        v-0.06c-1.59-0.27-2.85-1.42-2.85-3.17c0-1.13,0.46-2.03,1.19-2.62c0.8-0.65,2.06-1,3.69-1c1.09,0,2.24,0.1,3.08,0.27v12.71h-1.66
                                        v-5.58h-1.22c-0.8,0-1.4,0.17-1.85,0.63c-0.88,0.9-1.24,2.79-1.7,4.02c-0.13,0.33-0.23,0.59-0.42,0.94H585.44z M592.42,491.83
                                        c-0.29-0.06-0.82-0.15-1.57-0.15c-1.64,0-3.04,0.63-3.04,2.41c0,1.55,1.38,2.45,3.19,2.45c0.55,0,1.09,0,1.41-0.02V491.83z"/>
                                            <path className="st40" d="M599.8,495.04h1.36l2.54,3.52l-2.54,3.52h-1.34l2.54-3.52L599.8,495.04z M602.92,495.04h1.36l2.54,3.52
                                        l-2.54,3.52h-1.34l2.54-3.52L602.92,495.04z"/>
                                        </g>
                                        <rect x="201.64" y="625.38" className="st31" width="528.62" height="63.89"/>
                                        <g id="element2">
                                            <polygon className="st33" points="679.75,528.38 252.14,528.38 213.68,602.24 718.21,602.24"/>
                                            <line className="st33" x1="662.58" y1="528.38" x2="701.04" y2="602.24"/>
                                        </g>
                                        <line className="st31" x1="662.58" y1="521.23" x2="662.58" y2="477.2"/>
                                        <line className="st31" x1="662.58" y1="521.23" x2="662.58" y2="528.38"/>
                                        <line className="st31" x1="701.04" y1="602.24" x2="713.09" y2="625.38"/>
                                        <line className="st31" x1="713.13" y1="689.26" x2="713.09" y2="625.38"/>
                                        <line className="st31" x1="431.23" y1="380.1" x2="350.4" y2="380.1"/>
                                        <line className="st31" x1="350.4" y1="373.63" x2="431.23" y2="373.63"/>
                                    </g>
                                    <g id="mlsp">
                                        <g id="element3">
                                            <g>
                                                <g>
                                                    <line className="st1" x1="465.59" y1="686.22" x2="465.59" y2="720.86"/>
                                                    <path className="st2" d="M463.42,686.98c0,0,0-7.87,0,12.15s-3.72,21.41-3.72,21.41"/>
                                                    <path className="st2" d="M461.25,686.98c0,0,0-7.87,0,12.15s-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st2" d="M467.77,686.98c0,0,0-7.87,0,12.15s3.72,21.41,3.72,21.41"/>
                                                <path className="st2" d="M469.94,686.98c0,0,0-7.87,0,12.15s7.45,21.41,7.45,21.41"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <line className="st1" x1="601.84" y1="686.22" x2="601.84" y2="720.86"/>
                                                    <path className="st2" d="M599.67,686.98c0,0,0-7.87,0,12.15s-3.72,21.41-3.72,21.41"/>
                                                    <path className="st2" d="M597.5,686.98c0,0,0-7.87,0,12.15s-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st2" d="M604.02,686.98c0,0,0-7.87,0,12.15s3.72,21.41,3.72,21.41"/>
                                                <path className="st2" d="M606.19,686.98c0,0,0-7.87,0,12.15s7.45,21.41,7.45,21.41"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <line className="st3" x1="261.5" y1="688.11" x2="261.5" y2="743.94"/>
                                                        <path className="st4" d="M258,689.34c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st4" d="M254.5,689.34c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st4" d="M265,689.34c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st4" d="M268.5,689.34c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st3" x1="397.67" y1="688.11" x2="397.67" y2="743.94"/>
                                                        <path className="st4" d="M394.17,689.34c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st4" d="M390.67,689.34c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st4" d="M401.17,689.34c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st4" d="M404.67,689.34c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st3" x1="533.83" y1="688.11" x2="533.83" y2="743.94"/>
                                                        <path className="st4" d="M530.33,689.34c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st4" d="M526.83,689.34c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st4" d="M537.33,689.34c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st4" d="M540.83,689.34c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <line className="st3" x1="670" y1="688.11" x2="670" y2="743.94"/>
                                                        <path className="st4" d="M666.5,689.34c0,0,0-12.68,0,19.57s-6,34.5-6,34.5"/>
                                                        <path className="st4" d="M663,689.34c0,0,0-12.68,0,19.57s-12,34.5-12,34.5"/>
                                                    </g>
                                                    <path className="st4" d="M673.5,689.34c0,0,0-12.68,0,19.57s6,34.5,6,34.5"/>
                                                    <path className="st4" d="M677,689.34c0,0,0-12.68,0,19.57s12,34.5,12,34.5"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <line className="st1" x1="329.5" y1="686.22" x2="329.5" y2="720.86"/>
                                                    <path className="st2" d="M327.33,686.98c0,0,0-7.87,0,12.15s-3.72,21.41-3.72,21.41"/>
                                                    <path className="st2" d="M325.16,686.98c0,0,0-7.87,0,12.15s-7.45,21.41-7.45,21.41"/>
                                                </g>
                                                <path className="st2" d="M331.67,686.98c0,0,0-7.87,0,12.15s3.72,21.41,3.72,21.41"/>
                                                <path className="st2" d="M333.84,686.98c0,0,0-7.87,0,12.15s7.45,21.41,7.45,21.41"/>
                                            </g>
                                        </g>
                                        <polygon className="st5"
                                                 points="213.53,601.9 201.48,625.04 730.1,625.04 718.05,601.9 	"/>
                                        <line className="st10" x1="149.93" y1="320.13" x2="282.4" y2="338.96"/>
                                        <polygon className="st6"
                                                 points="308.3,411.1 268.5,411.1 268.5,375.03 268.5,344.18 308.3,344.18 	"/>
                                        <polygon className="st11"
                                                 points="297.12,344.18 279.68,344.18 282.69,304.63 294.11,304.63 	"/>
                                        <rect x="251.98" y="476.86" className="st6" width="427.61" height="44.04"/>
                                        <polygon className="st12"
                                                 points="679.59,476.86 251.98,476.86 235.47,454.84 696.11,454.84 	"/>
                                        <rect x="235.47" y="411.1" className="st6" width="460.64" height="43.75"/>
                                        <rect x="431.02" y="365.9" className="st6" width="115.59" height="57.65"/>
                                        <rect x="350.25" y="338.96" className="st6" width="80.83" height="72.14"/>
                                        <g>
                                            <rect x="366.33" y="236.4" className="st13" width="48.67" height="102.56"/>
                                            <polygon className="st13"
                                                     points="403.7,161.93 377.62,161.93 366.33,236.4 415,236.4 		"/>
                                            <rect x="377.62" y="116.58" className="st13" width="26.07" height="45.35"/>
                                            <rect x="377.62" y="116.58" className="st6" width="26.07" height="12.05"/>
                                            <rect x="377.62" y="140.05" className="st6" width="26.07" height="12.05"/>
                                            <polygon className="st6"
                                                     points="405.77,175.56 375.55,175.56 377.38,163.51 403.94,163.51 		"/>
                                            <polygon className="st6"
                                                     points="409.33,199.03 371.99,199.03 373.82,186.98 407.5,186.98 		"/>
                                        </g>
                                        <polygon className="st10"
                                                 points="124.22,308.23 268.5,375.03 268.5,394.6 124.22,315.49 	"/>
                                        <polygon className="st10"
                                                 points="124.22,308.23 58.46,349.39 58.46,356.34 124.22,315.49 	"/>
                                        <g>
                                            <g>
                                                <rect x="431.02" y="353.45" className="st14" width="41.67" height="12.46"/>
                                                <g>
                                                    <rect x="437.3" y="357" className="st15" width="5" height="5"/>
                                                    <rect x="449.36" y="357" className="st15" width="5" height="5"/>
                                                    <rect x="461.42" y="357" className="st15" width="5" height="5"/>
                                                </g>
                                            </g>
                                            <g>
                                                <rect x="434.43" y="342.29" className="st16" width="33.61" height="11.15"/>
                                                <rect x="448.73" y="334.56"
                                                      transform="matrix(6.123234e-17 -1 1 6.123234e-17 103.3623 799.1006)"
                                                      className="st14" width="5" height="26.62"/>
                                                </g>
                                            </g>
                                        <g>
                                        <g>
                                            <rect x="484.91" y="353.45" className="st14" width="41.67" height="12.46"/>
                                            <g>
                                                <rect x="491.18" y="357" className="st15" width="5" height="5"/>
                                                <rect x="503.24" y="357" className="st15" width="5" height="5"/>
                                                <rect x="515.3" y="357" className="st15" width="5" height="5"/>
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="488.31" y="342.29" className="st16" width="33.61" height="11.15"/>
                                            <rect x="502.62" y="334.56"
                                                      transform="matrix(6.123234e-17 -1 1 6.123234e-17 157.2482 852.9866)"
                                                      className="st14" width="5" height="26.62"/>
                                            </g>
                                        </g>
                                        <rect x="546.62" y="377.64" className="st17" width="94.73" height="45.92"/>
                                        <polygon className="st16" points="634.83,377.64 616.91,377.64 620.93,330.27 630.82,330.27 	"/>
                                        <g id="element1">
                                            <rect x="728.34" y="333.38" className="st6" width="7.43" height="5.21"/>
                                            <line className="st7" x1="690.72" y1="396.67" x2="666.82" y2="415.14"/>
                                            <line className="st7" x1="692.63" y1="455.71" x2="756.08" y2="397.41"/>
                                            <line className="st7" x1="696.11" y1="439.34" x2="710.47" y2="439.34"/>
                                            <line className="st7" x1="696.11" y1="433.48" x2="756.08" y2="397.41"/>
                                            <line className="st7" x1="696.11" y1="428.05" x2="717.62" y2="397.41"/>
                                            <line className="st8" x1="665.71" y1="378.94" x2="689.61" y2="397.41"/>
                                            <line className="st8" x1="717.62" y1="376.77" x2="717.62" y2="397.41"/>
                                            <line className="st8" x1="721.53" y1="378.94" x2="745.43" y2="397.41"/>
                                            <line className="st8" x1="769.6" y1="378.94" x2="745.7" y2="397.41"/>
                                            <line className="st9" x1="713.78" y1="378.94" x2="689.88" y2="397.41"/>
                                            <polygon className="st6" points="665.9,365.9 651.78,365.9 655.26,335.48 662.43,335.48 	"/>
                                            <line className="st18" x1="680.73" y1="350.47" x2="672.75" y2="365.9"/>
                                            <line className="st18" x1="717.85" y1="278.72" x2="697.78" y2="317.5"/>
                                            <line className="st18" x1="732.29" y1="250.8" x2="722.97" y2="268.81"/>
                                            <line className="st18" x1="717.56" y1="266.05" x2="722.68" y2="256.15"/>
                                            <line className="st18" x1="692.37" y1="314.74" x2="712.43" y2="275.96"/>
                                            <line className="st18" x1="665.9" y1="365.9" x2="675.32" y2="347.71"/>
                                            <line className="st18" x1="691.45" y1="355.94" x2="681.68" y2="374.81"/>
                                            <line className="st18" x1="728.56" y1="284.18" x2="708.5" y2="322.97"/>
                                            <line className="st18" x1="703.09" y1="320.21" x2="723.15" y2="281.42"/>
                                            <line className="st18" x1="679.45" y1="365.9" x2="686.03" y2="353.17"/>
                                            <rect x="724.99" y="248.57" className="st11" width="14.14" height="6.95"/>
                                            <rect x="726.65" y="302.01" className="st6" width="10.81" height="12.73"/>
                                            <rect x="726.65" y="314.2" className="st11" width="10.81" height="19.79"/>
                                            <line className="st19" x1="697.78" y1="317.5" x2="680.73" y2="350.47"/>
                                            <line className="st19" x1="675.32" y1="347.71" x2="692.37" y2="314.74"/>
                                            <line className="st19" x1="708.5" y1="322.97" x2="691.45" y2="355.94"/>
                                            <line className="st19" x1="686.03" y1="353.17" x2="703.09" y2="320.21"/>
                                            <line className="st19" x1="722.97" y1="268.81" x2="717.85" y2="278.72"/>
                                            <line className="st19" x1="712.43" y1="275.96" x2="717.56" y2="266.05"/>
                                            <line className="st19" x1="723.15" y1="281.42" x2="728.27" y2="271.51"/>
                                            <rect x="714.99" y="254.46" className="st6" width="34.13" height="8.02"/>
                                            <rect x="722.68" y="261.1" className="st6" width="18.77" height="12.03"/>
                                            <rect x="726.65" y="272.63" className="st6" width="10.81" height="10.55"/>
                                            <rect x="726.65" y="282.62" className="st11" width="10.81" height="19.39"/>
                                            <line className="st18" x1="658.84" y1="336.69" x2="710.15" y2="280.36"/>
                                            <path className="st11" d="M783.02,380.24H680.73v-6.95h102.29c1.92,0,3.48,1.56,3.48,3.48v0C786.5,378.69,784.94,380.24,783.02,380.24z"/>
                                            <rect x="642.66" y="365.9" className="st6" width="39.55" height="17.82"/>
                                            <polygon className="st11"
                                                     points="665.9,365.9 661.63,373.29 682.2,373.29 682.2,365.9 	"/>
                                            <line className="st8" x1="756.08" y1="397.41" x2="676.12" y2="397.41"/>
                                            <rect x="648.74" y="383.72" className="st11" width="27.38" height="27.38"/>
                                            <polygon className="st22"
                                                     points="676.12,388.17 648.74,383.72 648.74,383.72 676.12,383.72 	"/>
                                            <polygon className="st22"
                                                     points="676.12,400.6 648.74,396.14 648.74,396.14 676.12,396.14 	"/>
                                            <rect x="722.68" y="262.48" className="st17" width="18.77" height="3.03"/>
                                            <rect x="728.34" y="333.99" className="st17" width="7.43" height="1.5"/>
                                            <rect x="642.66" y="365.9" className="st23" width="39.55" height="2.63"/>
                                        </g>
                                        <rect x="431.02" y="365.9" className="st12" width="49.1" height="57.65"/>
                                        <rect x="257.2" y="426.56" className="st17" width="359.71" height="28.28"/>
                                        <polyline className="st17" points="616.91,454.84 616.91,436.3 631.5,436.3"/>
                                        <rect x="668.73" y="411.1" className="st20" width="27.38" height="43.75"/>
                                        <rect x="662.43" y="476.86" className="st20" width="17.17" height="44.04"/>
                                        <rect x="592.68" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="556.76" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="520.83" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="484.91" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="448.98" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="413.06" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="377.14" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="341.21" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="305.29" y="426.56" className="st20" width="2.9" height="28.28"/>
                                        <rect x="269.94" y="424.71" className="st6" width="11.59" height="18.54"/>
                                        <rect x="431.02" y="379.76" className="st17" width="14.06" height="43.79"/>
                                        <rect x="616.91" y="411.1" className="st6" width="39.22" height="21.68"/>
                                        <rect x="546.62" y="377.64" className="st20" width="70.29" height="45.92"/>
                                        <rect x="366.33" y="236.4" className="st21" width="48.62" height="1.3"/>
                                        <rect x="366.33" y="260.3" className="st21" width="48.18" height="3.91"/>
                                        <g>
                                            <rect x="371.62" y="236.95" className="st21" width="2.33" height="25.2"/>
                                            <rect x="378.71" y="236.95" className="st21" width="2.33" height="25.2"/>
                                            <rect x="385.81" y="236.95" className="st21" width="2.33" height="25.2"/>
                                            <rect x="392.91" y="236.95" className="st21" width="2.33" height="25.2"/>
                                            <rect x="400.01" y="236.95" className="st21" width="2.33" height="25.2"/>
                                            <rect x="407.11" y="236.95" className="st21" width="2.33" height="25.2"/>
                                        </g>
                                        <rect x="350.25" y="373.29" className="st17" width="80.83" height="6.47"/>
                                        <rect x="278.06" y="297.68" className="st6" width="20.69" height="13.04"/>
                                        <g id="element4">
                                            <line className="st24" x1="306.17" y1="111.54" x2="318.96" y2="90.39"/>
                                            <line className="st24" x1="331.76" y1="111.54" x2="318.96" y2="90.39"/>
                                            <line className="st24" x1="309.4" y1="73.33" x2="318.96" y2="62"/>
                                            <line className="st24" x1="328.53" y1="73.3" x2="318.96" y2="62"/>
                                            <line className="st24" x1="336.15" y1="163.4" x2="318.96" y2="137.9"/>
                                            <line className="st24" x1="301.78" y1="163.4" x2="318.96" y2="137.9"/>
                                            <line className="st24" x1="310.36" y1="62.07" x2="309.4" y2="73.33"/>
                                            <line className="st24" x1="328.53" y1="73.3" x2="327.57" y2="62"/>
                                            <line className="st24" x1="307.96" y1="90.39" x2="306.17" y2="111.54"/>
                                            <line className="st24" x1="331.76" y1="111.54" x2="329.97" y2="90.39"/>
                                            <line className="st24" x1="303.94" y1="137.9" x2="301.78" y2="163.4"/>
                                            <line className="st24" x1="336.15" y1="163.4" x2="333.99" y2="137.9"/>
                                            <polygon className="st24" points="338.32,188.98 300.65,188.98 290.8,424.71 348.17,424.71"/>
                                            <line className="st24" x1="292.43" y1="385.91" x2="346.57" y2="385.91"/>
                                            <line className="st24" x1="294.05" y1="347.04" x2="344.95" y2="347.04"/>
                                            <line className="st24" x1="295.67" y1="308.23" x2="343.3" y2="308.23"/>
                                            <line className="st24" x1="297.12" y1="273.48" x2="341.92" y2="273.48"/>
                                            <line className="st24" x1="298.48" y1="241.04" x2="340.54" y2="241.04"/>
                                            <line className="st24" x1="299.62" y1="213.81" x2="339.38" y2="213.81"/>
                                            <line className="st24" x1="290.8" y1="424.71" x2="319.49" y2="385.91"/>
                                            <line className="st24" x1="348.17" y1="424.71" x2="319.49" y2="385.91"/>
                                            <line className="st24" x1="292.43" y1="385.91" x2="319.49" y2="347.04"/>
                                            <line className="st24" x1="346.57" y1="385.91" x2="319.49" y2="347.04"/>
                                            <line className="st24" x1="294.05" y1="347.04" x2="319.49" y2="308.23"/>
                                            <line className="st24" x1="344.95" y1="347.04" x2="319.49" y2="308.23"/>
                                            <line className="st24" x1="295.67" y1="308.23" x2="319.49" y2="273.48"/>
                                            <line className="st24" x1="343.3" y1="308.23" x2="319.49" y2="273.48"/>
                                            <line className="st24" x1="297.12" y1="273.48" x2="319.49" y2="241.04"/>
                                            <line className="st24" x1="341.92" y1="273.48" x2="319.49" y2="241.04"/>
                                            <line className="st24" x1="298.48" y1="241.04" x2="319.49" y2="213.81"/>
                                            <line className="st24" x1="340.54" y1="241.04" x2="319.49" y2="213.81"/>
                                            <line className="st24" x1="299.62" y1="213.81" x2="319.49" y2="188.98"/>
                                            <line className="st24" x1="339.38" y1="213.81" x2="319.49" y2="188.98"/>
                                            <polygon className="st10"
                                                     points="336.15,163.4 338.32,188.98 299.62,188.98 301.78,163.4 		"/>
                                            <polygon className="st10"
                                                     points="333.99,137.9 331.76,111.54 306.17,111.54 303.94,137.9 		"/>
                                            <polygon className="st10"
                                                     points="329.97,90.39 328.53,73.3 309.4,73.33 307.96,90.39 		"/>
                                            <polygon className="st10"
                                                     points="327.57,62 326.27,46.64 311.66,46.64 310.36,62.07 		"/>
                                            <line className="st10" x1="299.62" y1="188.98" x2="318.96" y2="163.4"/>
                                            <line className="st10" x1="338.32" y1="188.98" x2="318.96" y2="163.4"/>
                                            <line className="st10" x1="303.94" y1="137.9" x2="318.96" y2="111.54"/>
                                            <line className="st10" x1="333.99" y1="137.9" x2="318.96" y2="111.54"/>
                                            <line className="st10" x1="307.96" y1="90.39" x2="318.96" y2="73.3"/>
                                            <line className="st10" x1="329.97" y1="90.39" x2="318.96" y2="73.3"/>
                                            <line className="st10" x1="310.36" y1="62.07" x2="318.96" y2="46.64"/>
                                            <line className="st10" x1="327.57" y1="62" x2="318.96" y2="46.64"/>
    </g>
                                        <rect x="251.98" y="520.9" className="st13" width="427.61" height="7.15"/>
                                        <rect x="662.43" y="520.9" className="st13" width="17.17" height="7.15"/>
                                        <g>
                                            <path className="st25" d="M341.07,497.38c-0.1-1.8-0.21-3.96-0.19-5.56h-0.06c-0.44,1.51-0.98,3.12-1.63,4.89l-2.27,6.25h-1.26
            l-2.08-6.14c-0.61-1.82-1.13-3.48-1.49-5.01h-0.04c-0.04,1.61-0.13,3.77-0.25,5.7l-0.34,5.53h-1.59l0.9-12.89h2.12l2.2,6.23
            c0.54,1.59,0.98,3,1.3,4.34h0.06c0.32-1.3,0.78-2.71,1.36-4.34l2.29-6.23h2.12l0.8,12.89h-1.63L341.07,497.38z"/>
                                            <path className="st25" d="M358.09,490.15v12.89h-1.68v-11.49h-4.26v3.9c0,2.79-0.11,5.91-1.93,7.13c-0.48,0.31-1.22,0.61-1.97,0.61
            l-0.21-1.34c0.52-0.1,1.05-0.4,1.36-0.69c1.03-1.01,1.13-3.52,1.13-5.58v-5.43H358.09z"/>
                                            <path className="st25" d="M373.81,502.62c-0.61,0.31-1.84,0.61-3.4,0.61c-3.63,0-6.37-2.29-6.37-6.52c0-4.03,2.73-6.77,6.73-6.77
            c1.61,0,2.62,0.34,3.06,0.57l-0.4,1.36c-0.63-0.31-1.53-0.54-2.6-0.54c-3.02,0-5.03,1.93-5.03,5.32c0,3.15,1.82,5.18,4.95,5.18
            c1.01,0,2.05-0.21,2.72-0.54L373.81,502.62z"/>
                                            <path className="st25"
                                                  d="M389.03,490.15v12.89h-1.66v-11.49h-5.98v11.49h-1.66v-12.89H389.03z"/>
                                            <path className="st25" d="M404.06,498.22l2.56,3.52h-1.36l-2.52-3.52l2.52-3.52h1.36L404.06,498.22z M407.19,498.22l2.56,3.52h-1.36
            l-2.52-3.52l2.52-3.52h1.36L407.19,498.22z"/>
                                            <path className="st25"
                                                  d="M424.78,490.15v12.89h-1.66v-11.49h-5.98v11.49h-1.66v-12.89H424.78z"/>
                                            <path className="st25" d="M431.52,490.3c0.8-0.13,1.85-0.25,3.19-0.25c1.64,0,2.85,0.38,3.61,1.07c0.71,0.61,1.13,1.55,1.13,2.7
            c0,1.17-0.34,2.08-0.99,2.75c-0.88,0.94-2.31,1.41-3.94,1.41c-0.5,0-0.96-0.02-1.34-0.11v5.16h-1.66V490.3z M433.18,496.52
            c0.36,0.1,0.82,0.13,1.38,0.13c2.01,0,3.23-0.98,3.23-2.75c0-1.7-1.2-2.52-3.04-2.52c-0.73,0-1.28,0.06-1.57,0.13V496.52z"/>
                                            <path className="st25" d="M447.08,490.15v5.43c0,2.04-0.04,3.73-0.15,5.45l0.04,0.02c0.65-1.34,1.45-2.73,2.39-4.26l4.17-6.63h1.66
            v12.89h-1.57v-5.49c0-2.08,0.02-3.61,0.15-5.24l-0.06-0.02c-0.63,1.42-1.51,2.91-2.39,4.34l-4.03,6.41h-1.78v-12.89H447.08z"/>
                                            <path className="st25" d="M461.92,490.3c0.8-0.13,1.85-0.25,3.19-0.25c1.64,0,2.85,0.38,3.61,1.07c0.71,0.61,1.13,1.55,1.13,2.7
            c0,1.17-0.34,2.08-0.99,2.75c-0.88,0.94-2.31,1.41-3.94,1.41c-0.5,0-0.96-0.02-1.34-0.11v5.16h-1.66V490.3z M463.58,496.52
            c0.36,0.1,0.82,0.13,1.38,0.13c2.01,0,3.23-0.98,3.23-2.75c0-1.7-1.2-2.52-3.04-2.52c-0.73,0-1.28,0.06-1.57,0.13V496.52z"/>
                                            <path className="st25" d="M476.39,498.98l-1.34,4.05h-1.72l4.38-12.89h2.01l4.4,12.89h-1.78l-1.38-4.05H476.39z M480.62,497.68
            l-1.26-3.71c-0.29-0.84-0.48-1.61-0.67-2.35h-0.04c-0.19,0.76-0.4,1.55-0.65,2.33l-1.26,3.73H480.62z"/>
                                            <path className="st25" d="M489.53,490.92c0.88-0.57,2.01-0.97,3.4-0.97c2.24,0,3.79,1.17,3.79,3.15c0,1.59-1.15,2.79-2.66,3.14v0.04
            c1.82,0.19,3.14,1.38,3.14,3.23c0,2.68-2.31,3.75-4.67,3.75c-1.17,0-2.39-0.25-3.36-0.84l0.44-1.3c0.69,0.4,1.84,0.78,2.89,0.78
            c1.91,0,2.96-1.03,2.96-2.45c0-1.93-1.85-2.49-3.5-2.49h-0.77v-1.26h0.77c1.7,0,3.02-1.05,3.02-2.37c0-1.3-0.92-2.03-2.31-2.03
            c-0.98,0-1.99,0.4-2.68,0.84L489.53,490.92z"/>
                                            <path className="st25" d="M511.78,490.15v12.89h-1.68v-11.49h-4.26v3.9c0,2.79-0.11,5.91-1.93,7.13c-0.48,0.31-1.22,0.61-1.97,0.61
            l-0.21-1.34c0.52-0.1,1.05-0.4,1.36-0.69c1.03-1.01,1.13-3.52,1.13-5.58v-5.43H511.78z"/>
                                            <path className="st25" d="M529.51,496.46c0,4.44-2.7,6.79-5.98,6.79c-3.4,0-5.79-2.64-5.79-6.54c0-4.09,2.54-6.77,5.98-6.77
            C527.23,489.94,529.51,492.64,529.51,496.46z M519.51,496.67c0,2.75,1.49,5.22,4.11,5.22c2.64,0,4.13-2.43,4.13-5.35
            c0-2.56-1.34-5.24-4.11-5.24C520.88,491.3,519.51,493.84,519.51,496.67z"/>
                                            <path className="st25" d="M546.3,497.38c-0.1-1.8-0.21-3.96-0.19-5.56h-0.06c-0.44,1.51-0.98,3.12-1.63,4.89l-2.27,6.25h-1.26
            l-2.08-6.14c-0.61-1.82-1.13-3.48-1.49-5.01h-0.04c-0.04,1.61-0.13,3.77-0.25,5.7l-0.34,5.53h-1.59l0.9-12.89h2.12l2.2,6.23
            c0.54,1.59,0.98,3,1.3,4.34h0.06c0.32-1.3,0.78-2.71,1.36-4.34l2.29-6.23h2.12l0.8,12.89h-1.63L546.3,497.38z"/>
                                            <path className="st25"
                                                  d="M556.3,490.15v5.39h6.23v-5.39h1.68v12.89h-1.68V497h-6.23v6.04h-1.66v-12.89H556.3z"/>
                                            <path className="st25" d="M573.01,498.98l-1.34,4.05h-1.72l4.38-12.89h2.01l4.4,12.89h-1.78l-1.38-4.05H573.01z M577.23,497.68
            l-1.26-3.71c-0.29-0.84-0.48-1.61-0.67-2.35h-0.04c-0.19,0.76-0.4,1.55-0.65,2.33l-1.26,3.73H577.23z"/>
                                            <path className="st25" d="M585.28,503.04c0.19-0.34,0.36-0.75,0.52-1.17c0.55-1.47,0.9-3.27,1.95-4.32c0.31-0.31,0.69-0.54,1.07-0.65
            v-0.06c-1.59-0.27-2.85-1.42-2.85-3.17c0-1.13,0.46-2.03,1.19-2.62c0.8-0.65,2.06-1,3.69-1c1.09,0,2.24,0.1,3.08,0.27v12.71h-1.66
            v-5.58h-1.22c-0.8,0-1.4,0.17-1.85,0.63c-0.88,0.9-1.24,2.79-1.7,4.02c-0.13,0.33-0.23,0.59-0.42,0.94H585.28z M592.26,491.49
            c-0.29-0.06-0.82-0.15-1.57-0.15c-1.64,0-3.04,0.63-3.04,2.41c0,1.55,1.38,2.45,3.19,2.45c0.55,0,1.09,0,1.41-0.02V491.49z"/>
                                            <path className="st25" d="M599.64,494.7H601l2.54,3.52l-2.54,3.52h-1.34l2.54-3.52L599.64,494.7z M602.76,494.7h1.36l2.54,3.52
            l-2.54,3.52h-1.34l2.54-3.52L602.76,494.7z"/>
                                        </g>
                                        <rect x="201.48" y="625.04" className="st26" width="528.62" height="63.89"/>
                                        <g id="element2">
                                            <polygon className="st27" points="679.59,528.04 251.98,528.04 213.53,601.9 718.05,601.9"/>
                                            <polygon className="st28" points="679.59,528.04 662.43,528.04 700.89,601.9 718.05,601.9"/>
                                        </g>
                                        <polygon className="st29"
                                                 points="718.05,601.9 700.89,601.9 712.93,625.04 730.1,625.04 	"/>
                                        <polygon className="st30"
                                                 points="730.1,688.93 712.97,688.93 712.93,625.04 730.1,625.04 	"/>
</g>
                                </svg>
                                <div className="sceneContent">
                                    <div className="question">Для начала давайте разберемся, <br/> как устроена платформа</div>
                                    <button className="goAhead">Далее</button>
                                    <div className="testMLSP">
                                        <form id="testMLSPT" className="testMLSPTForm">
                                            <div className="testMLSPTitle">Cоотнесите названия <br/> и подсвеченные области</div>
                                            <input type="hidden" name="number" value={1} />
                                            <label className="testMLSPContainer">
                                                <input value={1} type="radio" name="answer" />
                                                <span className="testMLSPCheckmark"></span>
                                                <span className="testMLSPText">Танки-хранилища нефти</span>
                                            </label>
                                            <label className="testMLSPContainer">
                                                <input value={2} type="radio" name="answer" />
                                                <span className="testMLSPCheckmark"></span>
                                                <span className="testMLSPText">Устройство отгрузки <br/> нефти на танкер</span>
                                            </label>
                                            <label className="testMLSPContainer">
                                                <input value={3} type="radio" name="answer" />
                                                <span className="testMLSPCheckmark"></span>
                                                <span className="testMLSPText">Фонд скважин</span>
                                            </label>
                                            <button type="submit" disabled>Ответить</button>
                                        </form>
                                        <form id="testSuccess" className="testMLSPTForm" onSubmit={this.startLevel31}>
                                            <p><span className="success">Поздравляем!</span><br/> Вы отлично<br/> разбираетесь<br/> в устройстве<br/> платформы</p>
                                            <button type="submit">Далее</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="level3" className="level">

                            <div id="scene31" className="scene">
                                <div className="sceneContent">
                                    <div className="level">Уровень 3</div>
                                    <div className="question">Вы хорошо <br/> разбираетесь <br/> в технологиях <br/> добычи нефти? <br/> Давайте проверим!</div>
                                    <button className="goAhead" onClick={this.goLevel31Scene1}>Далее</button>
                                </div>
                            </div>

                            <div id="scene32" className="scene">
                                <div className="sceneContent">

                                    <div className="question">Как происходит добыча нефти на платформе «Приразломная»?</div>

                                    <a id="level31Answer1" className="answer" href="#" onClick={this.doAnswerLevel31}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.95 216.95">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="Слой_34" data-name="Слой 34">
                                                    <circle className="cls-1" cx="108.48" cy="108.48" r="98"/>
                                                    <path className="cls-2"
                                                          d="M45.32,73.61c15.79,0,21.05-15.34,21.05-15.34s5.26,15.34,21,15.34"/>
                                                    <path className="cls-2"
                                                          d="M87.42,73.61c15.79,0,21.06-15.34,21.06-15.34s5.26,15.34,21,15.34"/>
                                                    <path className="cls-2"
                                                          d="M129.53,73.61c15.79,0,21.05-15.34,21.05-15.34s5.27,15.34,21.06,15.34"/>
                                                    <path className="cls-2"
                                                          d="M45.32,113.14c15.79,0,21.05-15.33,21.05-15.33s5.26,15.33,21,15.33"/>
                                                    <path className="cls-2"
                                                          d="M87.42,113.14c15.79,0,21.06-15.33,21.06-15.33s5.26,15.33,21,15.33"/>
                                                    <path className="cls-2"
                                                          d="M129.53,113.14c15.79,0,21.05-15.33,21.05-15.33s5.27,15.33,21.06,15.33"/>
                                                    <path className="cls-2"
                                                          d="M45.32,152.68c15.79,0,21.05-15.33,21.05-15.33s5.26,15.33,21,15.33"/>
                                                    <path className="cls-2"
                                                          d="M87.42,152.68c15.79,0,21.06-15.33,21.06-15.33s5.26,15.33,21,15.33"/>
                                                    <path className="cls-2"
                                                          d="M129.53,152.68c15.79,0,21.05-15.33,21.05-15.33s5.27,15.33,21.06,15.33"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Добыча ведется прямо с платформы: нефть извлекают из скважин без непосредственного контакта с водной средой</span>
                                        <div className="result">
                                            <p>
                                                <strong>Верно!</strong>
                                                <br/>
                                                <div className="hidden-xs">МЛСП «Приразломная» — уникальное сооружение размером с два футбольных поля и весом 500 тыс. тонн! </div>
                                                <div className="hidden-xs">Она обеспечивает выполнение всех технологических операций: бурение скважин, добычу, подготовку, хранение, отгрузку нефти на танкеры и даже выработку тепловой и электрической энергии. </div>
                                                <div>Платформа установлена прямо на морском дне (на глубине 19,2 м), за счет чего куст эксплуатационных скважин не имеет непосредственного контакта с водной средой.</div>
                                            </p>
                                            <button>Дальше</button>
                                        </div>
                                    </a>
                                    <a id="level31Answer2" className="answer" href="#" onClick={this.doAnswerLevel31}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.95 216.95">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="Слой_34" data-name="Слой 34">
                                                    <circle className="cls-1" cx="108.48" cy="108.48" r="98"/>
                                                    <path className="cls-2"
                                                          d="M73.71,65.73h71.14A21.37,21.37,0,0,1,166.22,87.1h0a21.38,21.38,0,0,1-21.37,21.38H72.1a21.37,21.37,0,0,0-21.37,21.37h0A21.37,21.37,0,0,0,72.1,151.22h71.15"/>
                                                    <rect className="cls-3" x="81.48" y="52.1" width="10" height="26.92"
                                                          rx="5"/>
                                                    <rect className="cls-3" x="125.48" y="137.77" width="10"
                                                          height="26.92" rx="5"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Добывающие скважины расположены на дне в открытом море, а нефть на платформу попадает по специальной трубопроводной системе</span>
                                        <div className="result">
                                            <p><strong>Увы, неверно.</strong><br/>Возможно Вы немного поторопились с ответом. Вы справитесь! Попробуйте еще раз! </p>
                                            <button>Заново</button>
                                        </div>
                                    </a>
                                    <a id="level31Answer3" className="answer" href="#" onClick={this.doAnswerLevel31}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.95 216.95">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="Слой_34" data-name="Слой 34">
                                                    <circle className="cls-1" cx="108.48" cy="108.48" r="98"/>
                                                    <line className="cls-2" x1="57.75" y1="80.75" x2="157.33"
                                                          y2="80.75"/>
                                                    <line className="cls-2" x1="157.33" y1="80.75" x2="134.96"
                                                          y2="61.5"/>
                                                    <line className="cls-2" x1="134.96" y1="100" x2="157.33"
                                                          y2="80.75"/>
                                                    <line className="cls-2" x1="157.33" y1="136.21" x2="57.75"
                                                          y2="136.21"/>
                                                    <line className="cls-2" x1="57.75" y1="136.21" x2="80.12"
                                                          y2="116.96"/>
                                                    <line className="cls-2" x1="80.12" y1="155.46" x2="57.75"
                                                          y2="136.21"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Добывающая платформа постоянно находится в движении, а нефтяники бурят новые и новые скважины, по которым нефть попадает в танк-хранилища</span>
                                        <div className="result">
                                            <p><strong>Увы, неверно.</strong><br/>Возможно Вы немного поторопились с ответом. Вы справитесь! Попробуйте еще раз! </p>
                                            <button>Заново</button>
                                        </div>
                                    </a>

                                </div>
                            </div>

                            <div id="scene33" className="scene">
                                <div className="sceneContent">
                                    <div className="question">
                                        Вы в Центральном <br/>
                                        пункте управления <br/>
                                        «Приразломной» – <br/>
                                        это «мозг платформы», <br/>
                                        где происходит сбор <br/>
                                        и анализ информации <br/>
                                        о производственных <br/>
                                        процессах и управление ими. <br/>
                                        <span>Начинаем добычу!</span>
                                    </div>
                                    <button className="goAhead" onClick={this.goLevel32Scene2}>Далее</button>
                                </div>
                            </div>

                            <div id="scene34" className="scene">
                                <div className="sceneContent">
                                    <div className="question">
                                        <div className="title">Качайте нефть!</div>
                                        <p>
                                            <span className="hidden-xs">Скрольте мышью или <br/> жмите пробел на клавиатуре.</span>
                                            <span className="hidden visible-xs">Касайтесь экрана.</span> С каждым шагом <br/> нефть будем подниматься со дна на платформу. <br/> Вам необходимо уложиться <br/> в отведенное время. Удачи!
                                        </p>
                                        <button className="goAhead" onClick={this.goLevel32Timer}>Далее</button>
                                    </div>
                                    <form id="formSuccess" className="loadingMLSPTForm" onSubmit={this.startLevel41}>
                                        <p><span className="success">Это успех!</span> <br/> Партия нефти <br/>находится <br/>в танк-хранилище <br/>и готова к отгрузке!</p>
                                        <button type="submit">Далее</button>
                                    </form>
                                    <form id="formFail" className="loadingMLSPTForm" onSubmit={this.goLevel32Timer}>
                                        <p><span className="fail">Увы.</span> <br/> Партия нефти <br/>не готова к отгрузке!</p>
                                        <button type="submit">Ещё раз!</button>
                                    </form>
                                </div>
                                <div id="timerCounter">00:20</div>
                                <div id="shadeBg"></div>
                            </div>

                            <svg id="level3Bg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect className="st0" width="1280" height="800"/>
                                </g>
                                <g id="men">
                                    <g id="legs">
                                        <g>
                                            <rect x="800.9" y="835.1" className="st1" width="123.4" height="382.6"/>
                                            <rect x="800.9" y="1135.4" className="st2" width="123.4" height="82.3"/>
                                            <rect x="800.9" y="1020.9" className="st3" width="123.4" height="35.1"/>
                                            <rect x="800.9" y="916.5" className="st2" width="123.4" height="104.5"/>
                                            <rect x="800.9" y="835.1" className="st4" width="123.4" height="27.5"/>
                                        </g>
                                        <g>

                                            <rect x="954" y="835.1" transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 2031.4413 2052.821)"
                                                  className="st1" width="123.4" height="382.6"/>
                                            <rect x="954" y="1135.4"
                                                  transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 2031.4413 2353.1072)"
                                                  className="st2" width="123.4" height="82.3"/>

                                            <rect x="954" y="1020.9"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 2076.9272)"
                                                  className="st3" width="123.4" height="35.1"/>

                                            <rect x="954" y="916.5"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 1937.364)"
                                                  className="st2" width="123.4" height="104.5"/>

                                            <rect x="954" y="835.1"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 1697.7552)"
                                                  className="st4" width="123.4" height="27.5"/>
                                        </g>
                                    </g>
                                    <g id="shoes_1_">
                                        <g>
                                            <path className="st5" d="M924.4,1217.7H800.9v-38.3h85.2C907.3,1179.5,924.4,1196.6,924.4,1217.7L924.4,1217.7z"/>
                                            <path className="st5" d="M810.4,1164.2L810.4,1164.2c-26.1,0-47.2,21.2-47.2,47.2v6.3h94.5v-6.3
                                            C857.7,1185.3,836.5,1164.2,810.4,1164.2z"/>
                                        </g>
                                        <g>
                                            <path className="st5" d="M954,1217.7h123.4v-38.3h-85.2C971.1,1179.5,954,1196.6,954,1217.7L954,1217.7z"/>
                                            <path className="st5" d="M1068,1164.2L1068,1164.2c26.1,0,47.2,21.2,47.2,47.2v6.3h-94.5v-6.3
                                            C1020.7,1185.3,1041.9,1164.2,1068,1164.2z"/>
                                        </g>
                                    </g>
                                    <g id="body_1_">
                                        <path className="st1" d="M788.9,837h150V364.7H817c-28.4,0-51.5,23-51.5,51.5v397.5C765.6,826.6,776,837,788.9,837z"/>
                                        <rect x="765.6" y="424.2" className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M880.2,506.3h-55.9c-10.7,0-19.3-8.7-19.3-19.3v-33.5h94.6v33.5C899.6,497.6,890.9,506.3,880.2,506.3z"/>
                                        <path className="st6"
                                              d="M818.7,756.4h67c10.6,0,19.2-8.6,19.2-19.2v-94.6H799.6v94.6C799.6,747.8,808.2,756.4,818.7,756.4z"/>
                                        <path className="st4"
                                              d="M814.8,683.3h74.9c8.4,0,15.2-6.8,15.2-15.2v-25.5H799.6v25.5C799.6,676.5,806.4,683.3,814.8,683.3z"/>
                                        <rect x="825" y="472.2" className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M842.3,642.6h-76.7v-32.4h44.3C827.8,610.3,842.3,624.7,842.3,642.6L842.3,642.6z"/>
                                        <path className="st1"
                                              d="M1089,837H939V364.7h121.9c28.4,0,51.5,23,51.5,51.5v397.5C1112.3,826.6,1101.9,837,1089,837z"/>

                                        <rect x="939" y="424.2"
                                              transform="matrix(-1 -4.488333e-11 4.488333e-11 -1 2051.2903 880.933)"
                                              className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M997.7,506.3h55.9c10.7,0,19.3-8.7,19.3-19.3v-33.5h-94.6v33.5C978.3,497.6,987,506.3,997.7,506.3z"/>
                                        <path className="st6"
                                              d="M1059.2,756.4h-67c-10.6,0-19.2-8.6-19.2-19.2v-94.6h105.4v94.6C1078.3,747.8,1069.7,756.4,1059.2,756.4z"/>
                                        <path className="st4"
                                              d="M1063.1,683.3h-74.9c-8.4,0-15.2-6.8-15.2-15.2v-25.5h105.4v25.5C1078.3,676.5,1071.5,683.3,1063.1,683.3z"/>

                                        <rect x="998.4" y="472.2"
                                              transform="matrix(-1 -4.490243e-11 4.490243e-11 -1 2051.2903 959.7552)"
                                              className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M1035.7,642.6h76.7v-32.4H1068C1050.1,610.3,1035.7,624.7,1035.7,642.6L1035.7,642.6z"/>
                                        <path className="st3"
                                              d="M842.3,810.3h-76.7v-32.4h44.3C827.8,777.9,842.3,792.4,842.3,810.3L842.3,810.3z"/>
                                        <path className="st3"
                                              d="M1035.7,810.3h76.7v-32.4H1068C1050.1,777.9,1035.7,792.4,1035.7,810.3L1035.7,810.3z"/>
                                        <path className="st5" d="M998,387v-22.3h-9.9V387c0,4.7-3.8,8.4-8.4,8.4h0c-3.8,0-6.8,3.1-6.8,6.8v25.1c0,8.3,6.7,15,15,15h10.3
                                        c8.3,0,15-6.7,15-15v-25.1c0-3.8-3.1-6.8-6.8-6.8l0,0C1001.8,395.5,998,391.7,998,387z"/>
                                        <path className="st8" d="M888.4,364.7v41.4c0,20.1-16.3,36.3-36.3,36.3h0c-7.7,0-13.9,6.2-13.9,13.9v47.8c0,16.2,13.1,29.3,29.3,29.3h0
                                        "/>
                                    </g>
                                    <g id="neck">
                                        <rect x="888.4" y="253.7" className="st9" width="101.7" height="111"/>
                                    </g>
                                    <g id="kapyushon">
                                        <path className="st10" d="M973,295.8l-6.2,6.2C969.1,300.1,971.2,298.1,973,295.8z"/>
                                        <path className="st11"
                                              d="M958.4,310.4l8.4-8.4c-3.2,2.6-6.8,4.8-10.6,6.4C956.9,309.1,957.7,309.7,958.4,310.4z"/>
                                        <g>
                                            <g>
                                                <path className="st4" d="M852.1,268.2v-1.7c-25.2,27.2-33.6,64.3-25.1,98.2h72.3c-7.4-7.5-10.8-16.5-10.6-25.6
                                                C866.6,323.3,852.1,297.4,852.1,268.2z"/>
                                                <path className="st11" d="M900.3,311.9c3.6-4,7.8-7,12.3-9.1c-10.4-8-17.1-20.5-17.1-34.6v-30c-14.9,5.1-28.8,13.5-40.6,25.4
                                                c-1,1-1.8,2-2.8,2.9v1.7c0,29.2,14.5,55.1,36.6,70.9C888.9,329.6,892.9,320,900.3,311.9z"/>
                                            </g>
                                            <g>
                                                <path className="st4" d="M1026.5,268.2v-1.7c25.2,27.2,33.6,64.3,25.1,98.2h-72.3c7.4-7.5,10.8-16.5,10.6-25.6
                                                C1012,323.3,1026.5,297.4,1026.5,268.2z"/>
                                                <path className="st11" d="M978.3,311.9c-3.6-4-7.8-7-12.3-9.1c10.4-8,17.1-20.5,17.1-34.6v-30c14.9,5.1,28.8,13.5,40.6,25.4
                                                c1,1,1.8,2,2.8,2.9v1.7c0,29.2-14.5,55.1-36.6,70.9C989.6,329.6,985.6,320,978.3,311.9z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="hands">
                                        <g id="rightHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="703.4" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1"
                                                      d="M762.5,527.3c0-28.8-23.4-52.2-52.2-52.2c-28.8,0-52.2,23.4-52.2,52.2v101.5h104.5V527.3z"/>
                                                <path className="st13"
                                                      d="M710.2,730.9c28.8,0,52.2-23.4,52.2-52.2v-49.9H658v49.9C658,707.5,681.4,730.9,710.2,730.9z"/>
                                                <path className="st3" d="M734.7,620.6H658v-32.4h44.3C720.2,588.2,734.7,602.7,734.7,620.6L734.7,620.6z"/>
                                                <rect x="657.9" y="619.8" className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M671.6,560.9L671.6,560.9c20.4,20.4,53.5,20.4,73.9,0l107-107c20.4-20.4,20.4-53.5,0-73.9l0,0
                                                c-20.4-20.4-53.5-20.4-73.9,0l-107,107C651.2,507.4,651.2,540.5,671.6,560.9z"/>

                                                <rect x="718.6" y="405.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 552.2482 -409.795)" className="st2"
                                                      width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M725.5,541.5l-54.2-54.2l22.9-22.9l31.3,31.3C738.2,508.4,738.2,528.9,725.5,541.5L725.5,541.5z"/>
                                            </g>
                                        </g>
                                        <g id="leftHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="1175" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1"
                                                      d="M1115.9,527.3c0-28.8,23.4-52.2,52.2-52.2c28.8,0,52.2,23.4,52.2,52.2v101.5h-104.5V527.3z"/>
                                                <path className="st13"
                                                      d="M1168.1,730.9c-28.8,0-52.2-23.4-52.2-52.2v-49.9h104.5v49.9C1220.4,707.5,1197,730.9,1168.1,730.9z"/>
                                                <path className="st3"
                                                      d="M1143.7,620.6h76.7v-32.4h-44.3C1158.2,588.2,1143.7,602.7,1143.7,620.6L1143.7,620.6z"/>

                                                <rect x="1115.9" y="619.8" transform="matrix(-1 -4.493396e-11 4.493396e-11 -1 2336.4246 1248.5316)"
                                                      className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M1206.8,560.9L1206.8,560.9c-20.4,20.4-53.5,20.4-73.9,0l-107-107c-20.4-20.4-20.4-53.5,0-73.9l0,0
                                                c20.4-20.4,53.5-20.4,73.9,0l107,107C1227.2,507.4,1227.2,540.5,1206.8,560.9z"/>

                                                <rect x="1055.4" y="405.6"
                                                      transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2217.2639 5.0293)"
                                                      className="st2" width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M1152.8,541.5l54.2-54.2l-22.9-22.9l-31.3,31.3C1140.2,508.4,1140.2,528.9,1152.8,541.5L1152.8,541.5z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="head_1_">
                                        <path className="st14" d="M863.2,234.4h151.9c8,0,14.5-6.5,14.5-14.5v0c0-8-6.5-14.5-14.5-14.5H863.2c-8,0-14.5,6.5-14.5,14.5v0
                                        C848.8,228,855.2,234.4,863.2,234.4z"/>
                                        <path className="st15" d="M939.2,333.6L939.2,333.6c-36.1,0-65.4-29.3-65.4-65.4v-111h130.8v111C1004.6,304.3,975.3,333.6,939.2,333.6z
                                        "/>
                                        <path className="st16" d="M965.3,224.3h-52.2c-8.3,0-15.1,6.8-15.1,15.1v79.5c11.2,9.1,25.6,14.6,41.2,14.6s29.9-5.5,41.2-14.6v-79.5
                                        C980.4,231.1,973.6,224.3,965.3,224.3z"/>
                                        <path className="st17" d="M918.9,209.1h40.6c3.4,0,6.1,2.7,6.1,6.1v0c0,3.4-2.7,6.1-6.1,6.1h-40.6c-3.4,0-6.1-2.7-6.1-6.1v0
                                        C912.8,211.9,915.5,209.1,918.9,209.1z"/>
                                        <path className="st18" d="M972.7,202.9L972.7,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
                                        C976.8,201,975,202.9,972.7,202.9z"/>
                                        <path className="st18" d="M905.4,202.9L905.4,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
                                        C909.5,201,907.7,202.9,905.4,202.9z"/>
                                        <g id="mouth">
                                            <path className="st19"
                                                  d="M951.2,240.2v2.4c0,5.5-4.4,9.9-9.9,9.9H905c0.1,2,0.6,4,1.4,5.8h20.1c4.6,0,8.3,3.7,8.3,8.3v3.1h20 c10.3,0,18.6-8.3,18.6-18.6v-11H951.2z"/>
                                            <path className="st3"
                                                  d="M951.2,242.6v-2.4H905v11c0,0.5,0,0.9,0.1,1.4h36.3C946.8,252.5,951.2,248.1,951.2,242.6z"/>
                                            <path className="st20"
                                                  d="M926.5,258.3h-20.1c2.8,6.7,9.4,11.4,17.1,11.4h11.3v-3.1C934.9,262,931.1,258.3,926.5,258.3z"/>
                                        </g>
                                        <g id="upset" style={{opacity: 0}}>
                                            <path className="st19" d="M959.2,259.1c-1.5,0-2.9-0.8-3.6-2.3c-2.6-5.2-9.6-8.5-17.9-8.5s-15.3,3.3-17.9,8.5c-1,2-3.5,2.8-5.5,1.8	c-2-1-2.8-3.5-1.8-5.5c4-8,13.6-13,25.1-13c11.5,0,21.1,5,25.1,13c1,2,0.2,4.5-1.8,5.5C960.4,258.9,959.8,259.1,959.2,259.1z"/>
                                        </g>
                                        <rect x="861.1" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <rect x="1004" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <path className="st21"
                                              d="M887.8,226.5h-26.8v-26.8l0,0C875.8,199.7,887.8,211.7,887.8,226.5L887.8,226.5z"/>
                                        <path className="st21"
                                              d="M990,226.5h26.8v-26.8l0,0C1002,199.7,990,211.7,990,226.5L990,226.5z"/>
                                        <rect x="923.4" y="157.3" className="st22" width="31.5" height="64.1"/>
                                        <path className="st23"
                                              d="M1019.4,179.5h-62.4c-9.6,0-17.5-7.8-17.5-17.5v-4.8h97.4v4.8C1036.8,171.6,1029,179.5,1019.4,179.5z"/>
                                        <path className="st23"
                                              d="M899.2,179.5h-30.6c-9.6,0-17.5-7.8-17.5-17.5v-4.8h65.5v4.8C916.7,171.6,908.9,179.5,899.2,179.5z"/>
                                    </g>
                                    <g id="vorotnik">
                                        <path className="st1" d="M1026.5,368h-174v-9.3c0-19.4,15.7-35.1,35.1-35.1h103.9c19.4,0,35.1,15.7,35.1,35.1V368z"/>
                                        <rect x="912.1" y="323.6" className="st6" width="54.7" height="513.4"/>
                                        <g>
                                            <path className="st5" d="M893,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9H893c2.1,0,3.9,1.7,3.9,3.9l0,0
                                            C896.9,348.9,895.1,350.6,893,350.6z"/>
                                            <path className="st5" d="M1000.9,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9h15.5c2.1,0,3.9,1.7,3.9,3.9l0,0
                                            C1004.7,348.9,1003,350.6,1000.9,350.6z"/>
                                        </g>
                                    </g>
                                    <g id="helmet">
                                        <path className="st3"
                                              d="M939.2,37L939.2,37c-47.1,0-85.2,38.1-85.2,85.2v27.9h170.4v-27.9C1024.4,75.2,986.2,37,939.2,37z"/>
                                        <path className="st24" d="M1040.2,168.3H838.1v0c0-11.6,9.4-21.1,21.1-21.1h159.9C1030.8,147.2,1040.2,156.7,1040.2,168.3L1040.2,168.3
                                        z"/>
                                        <path className="st25" d="M924,125.4l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V39c-5.9-1.3-11.9-2-18.2-2
                                        s-12.3,0.7-18.2,2v79.5C921,121.1,922.1,123.6,924,125.4z"/>
                                        <path className="st25" d="M877,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V42c-14.3,5.1-26.8,14-36.3,25.4v30.8
                                        C874,100.8,875.1,103.4,877,105.2z"/>
                                        <path className="st25" d="M971,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V67.4C994.8,56,982.3,47.2,968.1,42
                                        v56.2C968.1,100.8,969.1,103.4,971,105.2z"/>
                                    </g>
                                </g>
                            </svg>

                            <svg id="level3Bg2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect className="st0" width="1280" height="800"/>
                                </g>
                                <g id="center">
	                                <g>
		<g>
			<g>
				<path className="st1" d="M1079.1,601l-5.7-24.9h-15.5l-5.7,24.9c-1.6,6.9-7.7,11.8-14.8,11.8l0,0v4.7h56.6v-4.7l0,0
					C1086.9,612.8,1080.7,607.9,1079.1,601z"/>
                <rect x="1037.4" y="612.8" className="st2" width="56.6" height="4.7"/>
                <path className="st3" d="M1153.9,584.7H977.5c-6.2,0-11.1-5-11.1-11.1v-95.4c0-6.2,5-11.1,11.1-11.1h176.4c6.2,0,11.1,5,11.1,11.1
					v95.4C1165,579.7,1160,584.7,1153.9,584.7z"/>
                <path className="st4" d="M1154.6,577.7H976.8c-2.1,0-3.8-1.7-3.8-3.8v-96.3c0-2.1,1.7-3.8,3.8-3.8h177.8c2.1,0,3.8,1.7,3.8,3.8v96.3
					C1158.3,576,1156.7,577.7,1154.6,577.7z"/>
			</g>
            <g>
				<path className="st1" d="M833.1,601l-5.7-24.9h-15.5l-5.7,24.9c-1.6,6.9-7.7,11.8-14.8,11.8l0,0v4.7h56.6v-4.7l0,0
					C840.9,612.8,834.7,607.9,833.1,601z"/>
                <rect x="791.4" y="612.8" className="st2" width="56.6" height="4.7"/>
                <path className="st3" d="M907.8,584.7H731.5c-6.2,0-11.1-5-11.1-11.1v-95.4c0-6.2,5-11.1,11.1-11.1h176.4c6.2,0,11.1,5,11.1,11.1
					v95.4C919,579.7,914,584.7,907.8,584.7z"/>
                <path className="st5" d="M908.6,577.7H730.8c-2.1,0-3.8-1.7-3.8-3.8v-96.3c0-2.1,1.7-3.8,3.8-3.8h177.8c2.1,0,3.8,1.7,3.8,3.8v96.3
					C912.3,576,910.6,577.7,908.6,577.7z"/>
			</g>
		</g>
        <g>
			<rect x="659.2" y="624.9" className="st6" width="17.8" height="145.6"/>
            <rect x="1208.4" y="624.9" className="st6" width="17.8" height="145.6"/>
            <rect x="659.2" y="617.5" className="st6" width="567" height="15"/>
		</g>
	</g>
                                    <g>
		<rect x="34.8" y="153.3" className="st7" width="72.8" height="49.3"/>
                                        <rect x="107.6" y="153.3" className="st3" width="72.8" height="49.3"/>
                                        <rect x="180.4" y="153.3" className="st1" width="72.8" height="49.3"/>
                                        <rect x="253.2" y="153.3" className="st2" width="72.8" height="49.3"/>
                                        <rect x="326" y="153.3" className="st4" width="72.8" height="49.3"/>
                                        <rect x="34.8" y="202.6" className="st1" width="72.8" height="49.3"/>
                                        <rect x="107.6" y="202.6" className="st2" width="72.8" height="49.3"/>
                                        <rect x="180.4" y="202.6" className="st7" width="72.8" height="49.3"/>
                                        <rect x="253.2" y="202.6" className="st5" width="72.8" height="49.3"/>
                                        <rect x="326" y="202.6" className="st2" width="72.8" height="49.3"/>
                                        <rect x="34.8" y="252" className="st7" width="72.8" height="49.3"/>
                                        <rect x="107.6" y="252" className="st1" width="72.8" height="49.3"/>
                                        <rect x="180.4" y="252" className="st3" width="72.8" height="49.3"/>
                                        <rect x="253.2" y="252" className="st1" width="72.8" height="49.3"/>
                                        <rect x="326" y="252" className="st7" width="72.8" height="49.3"/>
                                        <rect x="34.8" y="301.3" className="st5" width="72.8" height="49.3"/>
                                        <rect x="107.6" y="301.3" className="st8" width="72.8" height="49.3"/>
                                        <rect x="180.4" y="301.3" className="st1" width="72.8" height="49.3"/>
                                        <rect x="253.2" y="301.3" className="st7" width="72.8" height="49.3"/>
                                        <rect x="326" y="301.3" className="st3" width="72.8" height="49.3"/>
                                        <rect x="34.8" y="350.7" className="st2" width="72.8" height="49.3"/>
                                        <rect x="107.6" y="350.7" className="st7" width="72.8" height="49.3"/>
                                        <rect x="180.4" y="350.7" className="st3" width="72.8" height="49.3"/>
                                        <rect x="253.2" y="350.7" className="st4" width="72.8" height="49.3"/>
                                        <rect x="326" y="350.7" className="st5" width="72.8" height="49.3"/>
                                        <path className="st9" d="M386.8,400H46.8c-6.6,0-12-5.4-12-12V165.3c0-6.6,5.4-12,12-12h340.1c6.6,0,12,5.4,12,12V388
			C398.8,394.6,393.4,400,386.8,400z"/>
                                        <g>
			<line className="st10" x1="107.6" y1="153.3" x2="107.6" y2="400"/>
                                            <line className="st10" x1="180.4" y1="153.3" x2="180.4" y2="400"/>
                                            <line className="st10" x1="253.2" y1="153.3" x2="253.2" y2="400"/>
                                            <line className="st10" x1="326" y1="153.3" x2="326" y2="400"/>
		</g>
                                        <g>
			<line className="st10" x1="34.8" y1="202.6" x2="398.8" y2="202.6"/>
                                            <line className="st10" x1="34.8" y1="252" x2="398.8" y2="252"/>
                                            <line className="st10" x1="34.8" y1="301.3" x2="398.8" y2="301.3"/>
                                            <line className="st10" x1="34.8" y1="350.7" x2="398.8" y2="350.7"/>
		</g>
	</g>
                                    <g>
		<polyline className="st11" points="114.9,164.3 114.9,196.6 173,196.6 		"/>
                                        <g>
			<g>
				<g>
					<circle className="st7" cx="123.2" cy="177.5" r="2.6"/>
                    <circle className="st7" cx="133.7" cy="172.2" r="2.6"/>
                    <circle className="st7" cx="144.2" cy="164" r="2.6"/>
                    <circle className="st7" cx="154.7" cy="170.9" r="2.6"/>
                    <circle className="st7" cx="165.2" cy="164" r="2.6"/>
				</g>
                <polyline className="st11"
                          points="123.2,177.5 133.7,172.2 144.2,164 154.7,170.9 165.2,164 				"/>
			</g>
                                            <g>
				<line className="st12" x1="123.2" y1="191.7" x2="123.2" y2="183.5"/>
                                                <line className="st12" x1="133.7" y1="191.7" x2="133.7" y2="178.2"/>
                                                <line className="st12" x1="144.2" y1="191.7" x2="144.2" y2="170"/>
                                                <line className="st12" x1="154.7" y1="191.7" x2="154.7" y2="177"/>
                                                <line className="st12" x1="165.2" y1="191.7" x2="165.2" y2="170"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="45.7" y1="167.8" x2="45.7" y2="197"/>
            <line className="st13" x1="55" y1="176.2" x2="55" y2="197"/>
            <line className="st13" x1="64.2" y1="171.5" x2="64.2" y2="197"/>
            <line className="st13" x1="73.4" y1="167.5" x2="73.4" y2="197"/>
		</g>
                                        <g>
			<line className="st14" x1="82.1" y1="163.3" x2="100.6" y2="163.3"/>
                                            <line className="st14" x1="85.1" y1="166.6" x2="100.6" y2="166.6"/>
                                            <line className="st14" x1="84.1" y1="169.9" x2="100.6" y2="169.9"/>
                                            <line className="st14" x1="87.1" y1="173.2" x2="100.6" y2="173.2"/>
                                            <line className="st14" x1="89.1" y1="176.5" x2="100.6" y2="176.5"/>
                                            <line className="st14" x1="84.1" y1="179.8" x2="100.6" y2="179.8"/>
                                            <line className="st14" x1="82.1" y1="183.1" x2="100.6" y2="183.1"/>
                                            <line className="st14" x1="85.1" y1="186.4" x2="100.6" y2="186.4"/>
                                            <line className="st14" x1="83.1" y1="189.7" x2="100.6" y2="189.7"/>
                                            <line className="st14" x1="82.1" y1="193" x2="100.6" y2="193"/>
                                            <line className="st14" x1="89.1" y1="196.2" x2="100.6" y2="196.2"/>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="263.2" y1="367.1" x2="263.2" y2="391.2"/>
            <line className="st13" x1="272.4" y1="363.4" x2="272.4" y2="391.2"/>
            <line className="st13" x1="281.6" y1="372.7" x2="281.6" y2="391.2"/>
            <line className="st13" x1="290.8" y1="358.7" x2="290.8" y2="391.2"/>
		</g>
                                        <g>
			<line className="st14" x1="299.5" y1="357.6" x2="318" y2="357.6"/>
                                            <line className="st14" x1="300.5" y1="360.9" x2="318" y2="360.9"/>
                                            <line className="st14" x1="308.5" y1="364.2" x2="318" y2="364.2"/>
                                            <line className="st14" x1="305.5" y1="367.5" x2="318" y2="367.5"/>
                                            <line className="st14" x1="301.5" y1="370.7" x2="318" y2="370.7"/>
                                            <line className="st14" x1="313.5" y1="374" x2="318" y2="374"/>
                                            <line className="st14" x1="304.5" y1="377.3" x2="318" y2="377.3"/>
                                            <line className="st14" x1="300.5" y1="380.6" x2="318" y2="380.6"/>
                                            <line className="st14" x1="306.5" y1="383.9" x2="318" y2="383.9"/>
                                            <line className="st14" x1="299.5" y1="387.2" x2="318" y2="387.2"/>
                                            <line className="st14" x1="301.5" y1="390.5" x2="318" y2="390.5"/>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st12" x1="189.4" y1="279.3" x2="189.4" y2="293.5"/>
            <line className="st12" x1="198.6" y1="267.6" x2="198.6" y2="293.5"/>
            <line className="st12" x1="207.8" y1="273" x2="207.8" y2="293.5"/>
            <line className="st12" x1="217" y1="268" x2="217" y2="293.5"/>
		</g>
                                        <g>
			<line className="st11" x1="231.7" y1="259.8" x2="244.2" y2="259.8"/>
                                            <line className="st11" x1="229.7" y1="263.1" x2="244.2" y2="263.1"/>
                                            <line className="st11" x1="236.7" y1="266.4" x2="244.2" y2="266.4"/>
                                            <line className="st11" x1="226.7" y1="269.7" x2="244.2" y2="269.7"/>
                                            <line className="st11" x1="229.7" y1="273" x2="244.2" y2="273"/>
                                            <line className="st11" x1="233.7" y1="276.3" x2="244.2" y2="276.3"/>
                                            <line className="st11" x1="230.7" y1="279.5" x2="244.2" y2="279.5"/>
                                            <line className="st11" x1="225.7" y1="282.8" x2="244.2" y2="282.8"/>
                                            <line className="st11" x1="236.7" y1="286.1" x2="244.2" y2="286.1"/>
                                            <line className="st11" x1="225.7" y1="289.4" x2="244.2" y2="289.4"/>
                                            <line className="st11" x1="227.7" y1="292.7" x2="244.2" y2="292.7"/>
		</g>
	</g>
                                    <g>
		<line className="st14" x1="382.9" y1="192.1" x2="339.7" y2="192.1"/>
                                        <line className="st14" x1="373.4" y1="188.8" x2="339.7" y2="188.8"/>
                                        <line className="st14" x1="385.2" y1="185.5" x2="339.7" y2="185.5"/>
                                        <line className="st14" x1="377.7" y1="182.2" x2="339.7" y2="182.2"/>
                                        <line className="st14" x1="380.9" y1="178.9" x2="339.7" y2="178.9"/>
                                        <line className="st14" x1="369.2" y1="175.6" x2="339.7" y2="175.6"/>
                                        <line className="st14" x1="382.9" y1="172.3" x2="339.7" y2="172.3"/>
                                        <line className="st14" x1="371.4" y1="169.1" x2="339.7" y2="169.1"/>
                                        <line className="st14" x1="373.2" y1="165.8" x2="339.7" y2="165.8"/>
	</g>
                                    <g>
		<line className="st11" x1="241.6" y1="387.5" x2="193.4" y2="387.5"/>
                                        <line className="st11" x1="239.1" y1="384.2" x2="193.4" y2="384.2"/>
                                        <line className="st11" x1="231.9" y1="380.9" x2="193.4" y2="380.9"/>
                                        <line className="st11" x1="223.4" y1="377.6" x2="193.4" y2="377.6"/>
                                        <line className="st11" x1="223.6" y1="374.3" x2="193.4" y2="374.3"/>
                                        <line className="st11" x1="203.9" y1="371" x2="193.4" y2="371"/>
                                        <line className="st11" x1="211.6" y1="367.7" x2="193.4" y2="367.7"/>
                                        <line className="st11" x1="230.1" y1="364.5" x2="193.4" y2="364.5"/>
                                        <line className="st11" x1="236.9" y1="361.2" x2="193.4" y2="361.2"/>
	</g>
                                    <g>
		<line className="st11" x1="354" y1="387.5" x2="385.5" y2="387.5"/>
                                        <line className="st11" x1="337.3" y1="384.2" x2="385.5" y2="384.2"/>
                                        <line className="st11" x1="343" y1="380.9" x2="385.5" y2="380.9"/>
                                        <line className="st11" x1="341.5" y1="377.6" x2="385.5" y2="377.6"/>
                                        <line className="st11" x1="367.3" y1="374.3" x2="385.5" y2="374.3"/>
                                        <line className="st11" x1="362" y1="371" x2="385.5" y2="371"/>
                                        <line className="st11" x1="371.3" y1="367.7" x2="385.5" y2="367.7"/>
                                        <line className="st11" x1="358.8" y1="364.5" x2="385.5" y2="364.5"/>
                                        <line className="st11" x1="340" y1="361.2" x2="385.5" y2="361.2"/>
	</g>
                                    <g>
		<circle className="st14" cx="216.8" cy="177.9" r="16.1"/>
                                        <g>
			<polyline className="st15" points="204.2,167.8 216.8,177.9 228.3,166.6 			"/>
                                            <line className="st14" x1="216.8" y1="177.5" x2="216.8" y2="161.8"/>
		</g>
	</g>
                                    <g>
		<circle className="st11" cx="72.4" cy="326" r="16.1"/>
                                        <g>
			<polyline className="st11" points="88.4,324.2 72.4,326 61,337.4 			"/>
                                            <line className="st11" x1="72.7" y1="326.3" x2="83.8" y2="337.4"/>
		</g>
	</g>
                                    <g>
		<circle className="st11" cx="361.4" cy="326" r="16.1"/>
                                        <g>
			<polyline className="st11" points="370.6,339.2 361.4,326 372.8,314.6 			"/>
                                            <line className="st11" x1="361.4" y1="326" x2="361.4" y2="309.9"/>
		</g>
	</g>
                                    <g>
		<polyline className="st16" points="268.9,172.5 279.4,175.2 289.9,169 300.4,166 310.9,165 		"/>
                                        <g>
			<polyline className="st16" points="260.6,164.3 260.6,196.6 318.6,196.6 			"/>
                                            <g>
				<line className="st17" x1="268.9" y1="191.7" x2="268.9" y2="178.5"/>
                                                <line className="st17" x1="279.4" y1="191.7" x2="279.4" y2="181.2"/>
                                                <line className="st17" x1="289.9" y1="191.7" x2="289.9" y2="175"/>
                                                <line className="st17" x1="300.4" y1="191.7" x2="300.4" y2="172"/>
                                                <line className="st17" x1="310.9" y1="191.7" x2="310.9" y2="171"/>
			</g>
		</g>
                                        <g>
			<circle className="st5" cx="268.9" cy="172.5" r="2.6"/>
                                            <circle className="st5" cx="279.4" cy="175.2" r="2.6"/>
                                            <circle className="st5" cx="289.9" cy="169" r="2.6"/>
                                            <circle className="st5" cx="300.4" cy="166" r="2.6"/>
                                            <circle className="st5" cx="310.9" cy="165" r="2.6"/>
		</g>
	</g>
                                    <g>
		<polyline className="st14" points="196.1,313.8 206.6,318.4 217.1,313.3 227.6,318.4 238.1,312.3 		"/>
                                        <g>
			<g>
				<polyline className="st14" points="187.8,310.7 187.8,343 245.8,343 				"/>
                <g>
					<line className="st13" x1="196.1" y1="338.1" x2="196.1" y2="319.8"/>
                    <line className="st13" x1="206.6" y1="338.1" x2="206.6" y2="324.4"/>
                    <line className="st13" x1="217.1" y1="338.1" x2="217.1" y2="319.3"/>
                    <line className="st13" x1="227.6" y1="338.1" x2="227.6" y2="324.4"/>
                    <line className="st13" x1="238.1" y1="338.1" x2="238.1" y2="318.3"/>
				</g>
			</g>
                                            <g>
				<circle className="st5" cx="196.1" cy="313.8" r="2.6"/>
                                                <circle className="st5" cx="206.6" cy="318.4" r="2.6"/>
                                                <circle className="st5" cx="217.1" cy="313.3" r="2.6"/>
                                                <circle className="st5" cx="227.6" cy="318.4" r="2.6"/>
                                                <circle className="st5" cx="238.1" cy="312.3" r="2.6"/>
			</g>
		</g>
	</g>
                                    <path className="st5"
                                          d="M43.8,242.8c-0.1-0.1-0.1-0.2-0.2-0.3C43.7,242.5,43.8,242.6,43.8,242.8z"/>
                                    <path className="st5"
                                          d="M43.1,238.5c0-0.1,0-0.2,0.1-0.4C43.1,238.3,43.1,238.4,43.1,238.5z"/>
                                    <path className="st5"
                                          d="M43.2,240.5c0-0.1,0-0.2-0.1-0.4C43.1,240.2,43.1,240.4,43.2,240.5z"/>
                                    <path className="st5"
                                          d="M43.3,241.3c0-0.1-0.1-0.3-0.1-0.4C43.3,241,43.3,241.2,43.3,241.3z"/>
                                    <path className="st5"
                                          d="M44.9,234.3c0.2-0.2,0.3-0.4,0.5-0.6C45.2,233.8,45.1,234,44.9,234.3z"/>
                                    <path className="st5"
                                          d="M43.2,237.8c0-0.1,0.1-0.3,0.1-0.4C43.3,237.5,43.3,237.6,43.2,237.8z"/>
                                    <path className="st5"
                                          d="M43.4,237c0-0.1,0.1-0.3,0.1-0.4C43.5,236.7,43.5,236.9,43.4,237z"/>
                                    <path className="st5"
                                          d="M44,235.6c0.1-0.1,0.1-0.2,0.2-0.3C44.1,235.3,44.1,235.4,44,235.6z"/>
                                    <path className="st5"
                                          d="M44.4,234.9c0.1-0.1,0.2-0.2,0.3-0.3C44.6,234.6,44.5,234.8,44.4,234.9z"/>
                                    <path className="st5"
                                          d="M43.7,236.3c0-0.1,0.1-0.2,0.2-0.3C43.8,236,43.7,236.1,43.7,236.3z"/>
                                    <path className="st5"
                                          d="M43.6,242c0-0.1-0.1-0.3-0.1-0.4C43.5,241.8,43.5,241.9,43.6,242z"/>
                                    <path className="st5"
                                          d="M48,246.7c-0.1,0-0.2-0.1-0.3-0.2C47.8,246.6,47.9,246.7,48,246.7z"/>
                                    <path className="st5"
                                          d="M44.2,243.4c-0.1-0.1-0.1-0.2-0.2-0.3C44.1,243.2,44.1,243.3,44.2,243.4z"/>
                                    <path className="st5"
                                          d="M48.8,247c-0.1,0-0.3-0.1-0.4-0.1C48.5,246.9,48.6,247,48.8,247z"/>
                                    <path className="st5"
                                          d="M49.5,247.2c-0.1,0-0.3-0.1-0.4-0.1C49.3,247.1,49.4,247.2,49.5,247.2z"/>
                                    <path className="st5"
                                          d="M47.3,246.4c-0.1-0.1-0.2-0.1-0.3-0.2C47.1,246.3,47.2,246.3,47.3,246.4z"/>
                                    <path className="st5"
                                          d="M44.7,244.1c-0.1-0.1-0.2-0.2-0.3-0.3C44.5,243.9,44.6,244,44.7,244.1z"/>
                                    <path className="st5"
                                          d="M45.4,245c-0.2-0.2-0.4-0.4-0.5-0.6C45.1,244.6,45.2,244.8,45.4,245z"/>
                                    <path className="st5"
                                          d="M46,245.5c-0.2-0.2-0.4-0.3-0.6-0.5C45.6,245.2,45.8,245.4,46,245.5z"/>
                                    <path className="st5"
                                          d="M46.6,246c-0.1-0.1-0.2-0.2-0.3-0.3C46.4,245.8,46.5,245.9,46.6,246z"/>
                                    <path className="st5"
                                          d="M50.3,247.3c-0.1,0-0.2,0-0.4-0.1C50.1,247.3,50.2,247.3,50.3,247.3z"/>
                                    <path className="st5"
                                          d="M54.5,246.6c-0.1,0.1-0.2,0.1-0.3,0.2C54.3,246.7,54.4,246.6,54.5,246.6z"/>
                                    <path className="st5"
                                          d="M53.8,246.9c-0.1,0-0.3,0.1-0.4,0.1C53.5,247,53.7,246.9,53.8,246.9z"/>
                                    <path className="st5"
                                          d="M51.9,247.3c0.1,0,0.2,0,0.3-0.1C52.1,247.3,52,247.3,51.9,247.3z"/>
                                    <path className="st5"
                                          d="M53.1,247.1c-0.1,0-0.3,0.1-0.4,0.1C52.8,247.2,52.9,247.1,53.1,247.1z"/>
                                    <path className="st5"
                                          d="M55.2,246.2c-0.1,0.1-0.2,0.1-0.3,0.2C55,246.3,55.1,246.3,55.2,246.2z"/>
                                    <path className="st7"
                                          d="M46.3,245.8c-0.1-0.1-0.2-0.1-0.3-0.2C46.1,245.6,46.2,245.7,46.3,245.8z"/>
                                    <path className="st7"
                                          d="M47,246.2c-0.1-0.1-0.2-0.1-0.4-0.2C46.8,246.1,46.9,246.1,47,246.2z"/>
                                    <path className="st7"
                                          d="M43.8,235.9c0.1-0.1,0.1-0.2,0.2-0.4C44,235.7,43.9,235.8,43.8,235.9z"/>
                                    <path className="st7"
                                          d="M44.4,243.8c-0.1-0.1-0.1-0.2-0.2-0.3C44.3,243.6,44.3,243.7,44.4,243.8z"/>
                                    <path className="st7"
                                          d="M44,243.1c-0.1-0.1-0.1-0.2-0.2-0.4C43.9,242.9,44,243,44,243.1z"/>
                                    <path className="st7"
                                          d="M47.7,246.6c-0.1-0.1-0.2-0.1-0.4-0.2C47.4,246.5,47.6,246.5,47.7,246.6z"/>
                                    <path className="st7"
                                          d="M50.3,247.3c0.5,0.1,1.1,0.1,1.6,0c-0.3,0-0.5,0-0.8,0C50.8,247.4,50.6,247.3,50.3,247.3z"/>
                                    <path className="st7"
                                          d="M49.9,247.3c-0.1,0-0.3,0-0.4-0.1C49.7,247.2,49.8,247.2,49.9,247.3z"/>
                                    <path className="st7"
                                          d="M49.1,247.1c-0.1,0-0.2-0.1-0.4-0.1C48.9,247,49,247.1,49.1,247.1z"/>
                                    <path className="st7"
                                          d="M48.4,246.9c-0.1,0-0.2-0.1-0.4-0.1C48.1,246.8,48.3,246.8,48.4,246.9z"/>
                                    <path className="st7"
                                          d="M43.7,242.4c0-0.1-0.1-0.2-0.1-0.4C43.6,242.2,43.6,242.3,43.7,242.4z"/>
                                    <path className="st7"
                                          d="M44.2,235.2c0.1-0.1,0.1-0.2,0.2-0.3C44.3,235,44.3,235.1,44.2,235.2z"/>
                                    <path className="st7"
                                          d="M44.9,244.4c-0.1-0.1-0.1-0.2-0.2-0.3C44.7,244.2,44.8,244.3,44.9,244.4z"/>
                                    <path className="st7"
                                          d="M53.4,247c-0.1,0-0.2,0.1-0.4,0.1C53.2,247.1,53.3,247,53.4,247z"/>
                                    <path className="st7"
                                          d="M55.2,246.2c0.1,0,0.1-0.1,0.2-0.1h0C55.3,246.2,55.2,246.2,55.2,246.2z"/>
                                    <path className="st7"
                                          d="M43.1,239.3c0-0.3,0-0.5,0-0.8C43.1,238.8,43.1,239.1,43.1,239.3z"/>
                                    <path className="st7"
                                          d="M43.1,240.1c0-0.3,0-0.5,0-0.8C43.1,239.6,43.1,239.9,43.1,240.1z"/>
                                    <path className="st7"
                                          d="M54.2,246.7c-0.1,0-0.2,0.1-0.4,0.1C53.9,246.8,54,246.8,54.2,246.7z"/>
                                    <path className="st7"
                                          d="M43.6,236.6c0-0.1,0.1-0.2,0.1-0.4C43.6,236.4,43.6,236.5,43.6,236.6z"/>
                                    <path className="st7"
                                          d="M54.9,246.4c-0.1,0.1-0.2,0.1-0.4,0.2C54.6,246.5,54.8,246.5,54.9,246.4z"/>
                                    <path className="st7"
                                          d="M44.7,234.5c0.1-0.1,0.1-0.2,0.2-0.3C44.8,234.3,44.7,234.4,44.7,234.5z"/>
                                    <path className="st7"
                                          d="M43.3,237.4c0-0.1,0.1-0.2,0.1-0.4C43.4,237.1,43.4,237.2,43.3,237.4z"/>
                                    <path className="st7"
                                          d="M43.2,238.2c0-0.1,0-0.3,0.1-0.4C43.2,237.9,43.2,238,43.2,238.2z"/>
                                    <path className="st7"
                                          d="M43.4,241.7c0-0.1-0.1-0.2-0.1-0.4C43.4,241.4,43.4,241.5,43.4,241.7z"/>
                                    <path className="st7"
                                          d="M52.7,247.2c-0.1,0-0.3,0-0.4,0.1C52.4,247.2,52.5,247.2,52.7,247.2z"/>
                                    <path className="st7"
                                          d="M43.2,240.9c0-0.1,0-0.3-0.1-0.4C43.2,240.6,43.2,240.8,43.2,240.9z"/>
                                    <path className="st7"
                                          d="M56.8,245c-0.2,0.2-0.4,0.4-0.6,0.5C56.4,245.4,56.6,245.2,56.8,245z"/>
                                    <path className="st7"
                                          d="M55.9,245.7c-0.1,0.1-0.2,0.2-0.3,0.3C55.7,245.9,55.8,245.8,55.9,245.7z"/>
                                    <path className="st7"
                                          d="M55.3,246.1L55.3,246.1c0.1,0,0.1-0.1,0.2-0.1C55.5,246,55.4,246.1,55.3,246.1z"/>
                                    <path className="st7"
                                          d="M56.2,245.5c-0.1,0.1-0.2,0.1-0.3,0.2C56,245.7,56.1,245.6,56.2,245.5z"/>
                                    <g>
		<g>
			<g>
				<circle className="st5" cx="52.1" cy="217" r="8"/>
                <path className="st7" d="M57.8,222.6c-3.1,3.1-8.2,3.1-11.3,0s-3.1-8.2,0-11.3l5.7,5.7L57.8,222.6z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="72.2" cy="217" r="8"/>
				</g>
                <path className="st7" d="M77.8,211.3c3.1,3.1,3.1,8.2,0,11.3l-5.7-5.7L77.8,211.3z"/>
			</g>
            <g>
				<circle className="st7" cx="92.2" cy="217" r="8"/>
                <path className="st5" d="M86.6,211.3c3.1-3.1,8.2-3.1,11.3,0l-5.7,5.7L86.6,211.3z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st5" cx="72.2" cy="237.6" r="8"/>
                <path className="st7" d="M72.2,245.6c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8v8V245.6z"/>
			</g>
                                            <g>
				<circle className="st3" cx="52.1" cy="237.6" r="8"/>
                                                <path className="st7" d="M52.1,237.6l-5.7-5.7c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
					c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.4
					c0,0.3,0,0.5,0,0.8s0,0.5,0,0.8c0,0.1,0,0.2,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
					c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.5
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
					c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.4-0.1
					c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2
					c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c1.5-1.5,2.3-3.5,2.3-5.7H52.1z"/>
			</g>
                                            <g>
				<circle className="st7" cx="92.2" cy="237.6" r="8"/>
                                                <path className="st3" d="M92.2,237.6l-5.7,5.7c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1
					c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1
					c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4
					c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
					c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.6c-1.5-1.5-3.5-2.3-5.7-2.3V237.6z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<circle className="st5" cx="269.5" cy="287" r="8"/>
                <path className="st7"
                      d="M275.2,281.3c-3.1-3.1-8.2-3.1-11.3,0c-3.1,3.1-3.1,8.2,0,11.3l5.7-5.7L275.2,281.3z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="289.6" cy="287" r="8"/>
				</g>
                <path className="st7" d="M295.3,292.6c3.1-3.1,3.1-8.2,0-11.3l-5.7,5.7L295.3,292.6z"/>
			</g>
            <g>
				<circle className="st7" cx="309.7" cy="287" r="8"/>
                <path className="st3" d="M304,292.6c3.1,3.1,8.2,3.1,11.3,0l-5.7-5.7L304,292.6z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st3" cx="289.6" cy="266.3" r="8"/>
                <path className="st7" d="M289.6,258.3c-4.4,0-8,3.6-8,8s3.6,8,8,8v-8V258.3z"/>
			</g>
                                            <g>
				<circle className="st7" cx="269.5" cy="266.3" r="8"/>
                                                <path className="st5" d="M269.5,266.3l-5.7,5.7c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.4
					c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8c0-0.1,0-0.2,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4
					c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.6-0.5
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1
					c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3,0,0.4,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
					c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3
					c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c1.5,1.5,2.3,3.5,2.3,5.7H269.5z"/>
			</g>
                                            <g>
				<circle className="st7" cx="309.7" cy="266.3" r="8"/>
                                                <path className="st5" d="M309.7,266.3l-5.7-5.7c0.2-0.2,0.4-0.4,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1
					c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1
					c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.4,0.5,0.6
					c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.4c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.3-0.1,0.4
					c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c-1.5,1.5-3.5,2.3-5.7,2.3V266.3z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<circle className="st3" cx="164" cy="364" r="8"/>
                <path className="st2" d="M158.4,369.7c3.1,3.1,8.2,3.1,11.3,0s3.1-8.2,0-11.3L164,364L158.4,369.7z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="144" cy="364" r="8"/>
				</g>
                <path className="st2" d="M138.3,358.3c-3.1,3.1-3.1,8.2,0,11.3l5.7-5.7L138.3,358.3z"/>
			</g>
            <g>
				<circle className="st2" cx="123.9" cy="364" r="8"/>
                <path className="st5" d="M129.6,358.3c-3.1-3.1-8.2-3.1-11.3,0l5.7,5.7L129.6,358.3z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st5" cx="144" cy="384.7" r="8"/>
                <path className="st2" d="M144,376.6c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8v-8V376.6z"/>
			</g>
                                            <g>
				<circle className="st2" cx="123.9" cy="384.7" r="8"/>
                                                <path className="st5" d="M123.9,384.7l-5.7,5.7c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.4
					c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8c0-0.1,0-0.2,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4
					c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.6-0.5
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1
					c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3,0,0.4,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
					c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3
					c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c1.5,1.5,2.3,3.5,2.3,5.7H123.9z"/>
			</g>
                                            <g>
				<circle className="st2" cx="164" cy="384.7" r="8"/>
                                                <path className="st3" d="M164,384.7l-5.7-5.7c0.2-0.2,0.4-0.4,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1
					c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1
					c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.4,0.5,0.6
					c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.4c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.3-0.1,0.4
					c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c-1.5,1.5-3.5,2.3-5.7,2.3V384.7z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st14" x1="144" y1="217.7" x2="144" y2="223.7"/>
                <line className="st14" x1="144" y1="231.2" x2="144" y2="237.2"/>
			</g>
            <g>
				<line className="st14" x1="133.5" y1="227.3" x2="140.2" y2="227.3"/>
                <line className="st14" x1="147.7" y1="227.3" x2="154.5" y2="227.3"/>
			</g>
            <g>
				<line className="st14" x1="119.2" y1="240.9" x2="126" y2="240.9"/>
                <line className="st14" x1="133.5" y1="240.9" x2="140.2" y2="240.9"/>
                <line className="st14" x1="147.7" y1="240.9" x2="154.5" y2="240.9"/>
                <line className="st14" x1="161.9" y1="240.9" x2="168.7" y2="240.9"/>
			</g>
            <g>
				<line className="st14" x1="129.7" y1="231.2" x2="129.7" y2="237.2"/>
                <line className="st14" x1="158.2" y1="231.2" x2="158.2" y2="237.2"/>
			</g>
		</g>
                                        <rect x="140.2" y="210.3" className="st16" width="7.4" height="7.4"/>
                                        <g>
			<rect x="140.2" y="223.7" className="st16" width="7.4" height="7.4"/>
                                            <rect x="126" y="223.7" className="st18" width="7.4" height="7.4"/>
                                            <rect x="154.5" y="223.7" className="st16" width="7.4" height="7.4"/>
		</g>
                                        <g>
			<rect x="140.2" y="237.2" className="st16" width="7.4" height="7.4"/>
                                            <rect x="126" y="237.2" className="st16" width="7.4" height="7.4"/>
                                            <rect x="111.8" y="237.2" className="st18" width="7.4" height="7.4"/>
                                            <rect x="154.5" y="237.2" className="st18" width="7.4" height="7.4"/>
                                            <rect x="168.7" y="237.2" className="st16" width="7.4" height="7.4"/>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st14" x1="361.4" y1="266.9" x2="361.4" y2="272.9"/>
                <line className="st14" x1="361.4" y1="280.4" x2="361.4" y2="286.4"/>
			</g>
            <g>
				<line className="st14" x1="350.9" y1="276.4" x2="357.7" y2="276.4"/>
                <line className="st14" x1="365.1" y1="276.4" x2="371.9" y2="276.4"/>
			</g>
            <g>
				<line className="st14" x1="350.9" y1="290.1" x2="357.7" y2="290.1"/>
                <line className="st14" x1="365.1" y1="290.1" x2="371.9" y2="290.1"/>
			</g>
            <g>
				<line className="st14" x1="347.2" y1="280.4" x2="347.2" y2="286.4"/>
                <line className="st14" x1="375.6" y1="280.4" x2="375.6" y2="286.4"/>
			</g>
		</g>
                                        <g>
			<rect x="357.7" y="259.4" className="st19" width="7.4" height="7.4"/>
                                            <g>
				<rect x="357.7" y="272.9" className="st14" width="7.4" height="7.4"/>
                                                <rect x="343.5" y="272.9" className="st19" width="7.4" height="7.4"/>
                                                <rect x="371.9" y="272.9" className="st14" width="7.4" height="7.4"/>
			</g>
                                            <g>
				<rect x="357.7" y="286.4" className="st14" width="7.4" height="7.4"/>
                                                <rect x="343.5" y="286.4" className="st14" width="7.4" height="7.4"/>
                                                <rect x="371.9" y="286.4" className="st19" width="7.4" height="7.4"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="197.9" y1="227.3" x2="197.9" y2="213"/>
            <line className="st13" x1="207.6" y1="227.3" x2="207.6" y2="218.2"/>
            <line className="st13" x1="217.3" y1="227.3" x2="217.3" y2="210.8"/>
            <line className="st13" x1="226.9" y1="227.3" x2="226.9" y2="216.7"/>
            <line className="st13" x1="236.6" y1="227.3" x2="236.6" y2="213.5"/>
		</g>
                                        <g>
			<line className="st17" x1="194.9" y1="230" x2="194.9" y2="227.3"/>
                                            <line className="st17" x1="204.6" y1="229.3" x2="204.6" y2="227.3"/>
                                            <line className="st17" x1="214.3" y1="236" x2="214.3" y2="227.3"/>
                                            <line className="st17" x1="223.9" y1="234.5" x2="223.9" y2="227.3"/>
                                            <line className="st17" x1="233.6" y1="241.7" x2="233.6" y2="227.3"/>
		</g>
	</g>
                                    <g>
		<g>
			<rect x="261.3" y="214.1" className="st11" width="16" height="5"/>
            <g>
				<line className="st11" x1="282.5" y1="214.1" x2="308.6" y2="214.1"/>
                <line className="st11" x1="282.5" y1="219.1" x2="305.1" y2="219.1"/>
                <line className="st11" x1="282.5" y1="216.6" x2="317.9" y2="216.6"/>
			</g>
		</g>
                                        <g>
			<rect x="261.3" y="224.8" className="st11" width="16" height="5"/>
                                            <g>
				<line className="st11" x1="282.5" y1="224.8" x2="312" y2="224.8"/>
                                                <line className="st11" x1="282.5" y1="229.8" x2="302.8" y2="229.8"/>
                                                <line className="st11" x1="282.5" y1="227.3" x2="299.3" y2="227.3"/>
			</g>
		</g>
                                        <g>
			<rect x="261.3" y="235.5" className="st11" width="16" height="5"/>
                                            <g>
				<line className="st11" x1="282.5" y1="235.5" x2="314.7" y2="235.5"/>
                                                <line className="st11" x1="282.5" y1="240.5" x2="316.5" y2="240.5"/>
                                                <line className="st11" x1="282.5" y1="238" x2="312" y2="238"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<rect x="115.7" y="312.8" className="st19" width="16" height="5"/>
            <g>
				<line className="st14" x1="136.9" y1="312.8" x2="168" y2="312.8"/>
                <line className="st14" x1="136.9" y1="317.8" x2="169.5" y2="317.8"/>
                <line className="st14" x1="136.9" y1="315.3" x2="172.3" y2="315.3"/>
			</g>
		</g>
                                        <g>
			<rect x="115.7" y="323.5" className="st14" width="16" height="5"/>
                                            <g>
				<line className="st14" x1="136.9" y1="323.5" x2="166.4" y2="323.5"/>
                                                <line className="st14" x1="136.9" y1="328.5" x2="170.2" y2="328.5"/>
                                                <line className="st14" x1="136.9" y1="326" x2="168.7" y2="326"/>
			</g>
		</g>
                                        <g>
			<rect x="115.7" y="334.2" className="st19" width="16" height="5"/>
                                            <g>
				<line className="st14" x1="136.9" y1="334.2" x2="162" y2="334.2"/>
                                                <line className="st14" x1="136.9" y1="339.2" x2="167.9" y2="339.2"/>
                                                <line className="st14" x1="136.9" y1="336.7" x2="171.4" y2="336.7"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<polygon className="st16" points="390.4,214.6 332.4,214.6 332.4,214.6 390.4,214.6 			"/>
            <polygon className="st16" points="390.4,223 332.4,223 332.4,223 390.4,223 			"/>
            <polygon className="st16" points="390.4,231.5 332.4,231.5 332.4,231.5 390.4,231.5 			"/>
            <polygon className="st16" points="390.4,240 332.4,240 332.4,240 390.4,240 			"/>
		</g>
                                        <g>
			<circle className="st5" cx="339.7" cy="214.6" r="2.6"/>
                                            <circle className="st5" cx="363" cy="223" r="2.6"/>
                                            <circle className="st5" cx="350.2" cy="231.5" r="2.6"/>
                                            <circle className="st5" cx="379.3" cy="240" r="2.6"/>
		</g>
	</g>
                                    <g>
		<g>
			<polygon className="st11" points="101.2,361.6 43.1,361.6 43.1,361.6 101.2,361.6 			"/>
            <polygon className="st11" points="101.2,370.1 43.1,370.1 43.1,370.1 101.2,370.1 			"/>
            <polygon className="st11" points="101.2,378.6 43.1,378.6 43.1,378.6 101.2,378.6 			"/>
            <polygon className="st11" points="101.2,387 43.1,387 43.1,387 101.2,387 			"/>
		</g>
                                        <g>
			<circle className="st3" cx="94.4" cy="361.6" r="2.6"/>
                                            <circle className="st3" cx="80.7" cy="370.1" r="2.6"/>
                                            <circle className="st3" cx="49.9" cy="378.6" r="2.6"/>
                                            <circle className="st3" cx="67" cy="387" r="2.6"/>
		</g>
	</g>
                                    <g>
		<polygon className="st14" points="173,263.9 114.9,263.9 114.9,263.9 173,263.9 		"/>
                                        <polygon className="st14"
                                                 points="173,272.4 114.9,272.4 114.9,272.4 173,272.4 		"/>
                                        <polygon className="st14"
                                                 points="173,280.9 114.9,280.9 114.9,280.9 173,280.9 		"/>
                                        <polygon className="st14"
                                                 points="173,289.3 114.9,289.3 114.9,289.3 173,289.3 		"/>
	</g>
                                    <circle className="st8" cx="161.2" cy="263.9" r="2.6"/>
                                    <circle className="st8" cx="145.6" cy="272.4" r="2.6"/>
                                    <circle className="st8" cx="122.7" cy="280.9" r="2.6"/>
                                    <circle className="st8" cx="150.8" cy="289.3" r="2.6"/>
                                    <g>
		<g>
			<g>
				<line className="st17" x1="53.3" y1="276.5" x2="53.3" y2="264.2"/>
                <line className="st17" x1="63" y1="276.5" x2="63" y2="263.4"/>
                <line className="st17" x1="72.6" y1="276.5" x2="72.6" y2="265"/>
                <line className="st17" x1="82.3" y1="276.5" x2="82.3" y2="260.9"/>
                <line className="st17" x1="92" y1="276.5" x2="92" y2="267.7"/>
			</g>
            <g>
				<line className="st20" x1="50.3" y1="281.2" x2="50.3" y2="276.5"/>
                <line className="st20" x1="60" y1="282.5" x2="60" y2="276.5"/>
                <line className="st20" x1="69.6" y1="279.2" x2="69.6" y2="276.5"/>
                <line className="st20" x1="79.3" y1="277.7" x2="79.3" y2="276.5"/>
                <line className="st20" x1="89" y1="285.9" x2="89" y2="276.5"/>
			</g>
		</g>
                                        <g>
			<line className="st16" x1="43.1" y1="259.5" x2="43.1" y2="293.9"/>
                                            <line className="st16" x1="43.1" y1="276.5" x2="101.2" y2="276.5"/>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st17" x1="270.7" y1="325.8" x2="270.7" y2="316.5"/>
                <line className="st17" x1="280.4" y1="325.8" x2="280.4" y2="317.7"/>
                <line className="st17" x1="290.1" y1="325.8" x2="290.1" y2="312.3"/>
                <line className="st17" x1="299.7" y1="325.8" x2="299.7" y2="312.2"/>
                <line className="st17" x1="309.4" y1="325.8" x2="309.4" y2="314"/>
			</g>
            <g>
				<line className="st20" x1="267.7" y1="328.5" x2="267.7" y2="325.8"/>
                <line className="st20" x1="277.4" y1="333.8" x2="277.4" y2="325.8"/>
                <line className="st20" x1="287.1" y1="330.5" x2="287.1" y2="325.8"/>
                <line className="st20" x1="296.7" y1="328" x2="296.7" y2="325.8"/>
                <line className="st20" x1="306.4" y1="328.2" x2="306.4" y2="325.8"/>
			</g>
		</g>
                                        <g>
			<line className="st16" x1="260.6" y1="308.8" x2="260.6" y2="343.2"/>
                                            <line className="st16" x1="260.6" y1="325.8" x2="318.6" y2="325.8"/>
		</g>
	</g>
                                    <g>
		<line className="st14" x1="187.8" y1="210.3" x2="187.8" y2="244.7"/>
                                        <line className="st14" x1="187.8" y1="227.3" x2="245.8" y2="227.3"/>
	</g>
                                    <g id="centerScreen">
		<g>
			<rect x="458" y="350.7" className="st7" width="72.8" height="49.3"/>
            <rect x="530.8" y="350.7" className="st3" width="72.8" height="49.3"/>
            <rect x="603.6" y="350.7" className="st1" width="72.8" height="49.3"/>
            <rect x="676.4" y="350.7" className="st2" width="72.8" height="49.3"/>
            <rect x="749.2" y="350.7" className="st4" width="72.8" height="49.3"/>
            <rect x="458" y="301.3" className="st1" width="72.8" height="49.3"/>
            <rect x="530.8" y="301.3" className="st2" width="72.8" height="49.3"/>
            <rect x="603.6" y="301.3" className="st7" width="72.8" height="49.3"/>
            <rect x="676.4" y="301.3" className="st5" width="72.8" height="49.3"/>
            <rect x="749.2" y="301.3" className="st2" width="72.8" height="49.3"/>
            <rect x="458" y="252" className="st7" width="72.8" height="49.3"/>
            <rect x="530.8" y="252" className="st1" width="72.8" height="49.3"/>
            <rect x="603.6" y="252" className="st3" width="72.8" height="49.3"/>
            <rect x="676.4" y="252" className="st1" width="72.8" height="49.3"/>
            <rect x="749.2" y="252" className="st7" width="72.8" height="49.3"/>
            <rect x="458" y="202.6" className="st5" width="72.8" height="49.3"/>
            <rect x="530.8" y="202.6" className="st8" width="72.8" height="49.3"/>
            <rect x="603.6" y="202.6" className="st1" width="72.8" height="49.3"/>
            <rect x="676.4" y="202.6" className="st7" width="72.8" height="49.3"/>
            <rect x="749.2" y="202.6" className="st3" width="72.8" height="49.3"/>
            <rect x="458" y="153.3" className="st2" width="72.8" height="49.3"/>
            <rect x="530.8" y="153.3" className="st7" width="72.8" height="49.3"/>
            <rect x="603.6" y="153.3" className="st3" width="72.8" height="49.3"/>
            <rect x="676.4" y="153.3" className="st4" width="72.8" height="49.3"/>
            <rect x="749.2" y="153.3" className="st5" width="72.8" height="49.3"/>
            <path className="st9" d="M810,153.3H470c-6.6,0-12,5.4-12,12V388c0,6.6,5.4,12,12,12H810c6.6,0,12-5.4,12-12V165.3
				C822,158.6,816.7,153.3,810,153.3z"/>
            <g>
				<line className="st10" x1="530.8" y1="400" x2="530.8" y2="153.3"/>
                <line className="st10" x1="603.6" y1="400" x2="603.6" y2="153.3"/>
                <line className="st10" x1="676.4" y1="400" x2="676.4" y2="153.3"/>
                <line className="st10" x1="749.2" y1="400" x2="749.2" y2="153.3"/>
			</g>
            <g>
				<line className="st10" x1="458" y1="350.7" x2="822" y2="350.7"/>
                <line className="st10" x1="458" y1="301.3" x2="822" y2="301.3"/>
                <line className="st10" x1="458" y1="252" x2="822" y2="252"/>
                <line className="st10" x1="458" y1="202.6" x2="822" y2="202.6"/>
			</g>
		</g>
                                        <g>
			<polyline className="st11" points="538.2,359.7 538.2,391.9 596.2,391.9 			"/>
                                            <g>
				<g>
					<g>
						<circle className="st7" cx="546.5" cy="372.8" r="2.6"/>
                        <circle className="st7" cx="557" cy="367.5" r="2.6"/>
                        <circle className="st7" cx="567.5" cy="359.3" r="2.6"/>
                        <circle className="st7" cx="578" cy="366.2" r="2.6"/>
                        <circle className="st7" cx="588.5" cy="359.3" r="2.6"/>
					</g>
                    <polyline className="st11"
                              points="546.5,372.8 557,367.5 567.5,359.3 578,366.2 588.5,359.3 					"/>
				</g>
                                                <g>
					<line className="st12" x1="546.5" y1="387" x2="546.5" y2="378.8"/>
                                                    <line className="st12" x1="557" y1="387" x2="557" y2="373.5"/>
                                                    <line className="st12" x1="567.5" y1="387" x2="567.5" y2="365.3"/>
                                                    <line className="st12" x1="578" y1="387" x2="578" y2="372.4"/>
                                                    <line className="st12" x1="588.5" y1="387" x2="588.5" y2="365.3"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<line className="st13" x1="469" y1="360.8" x2="469" y2="389.9"/>
                <line className="st13" x1="478.2" y1="369.1" x2="478.2" y2="389.9"/>
                <line className="st13" x1="487.4" y1="364.4" x2="487.4" y2="389.9"/>
                <line className="st13" x1="496.6" y1="360.4" x2="496.6" y2="389.9"/>
			</g>
                                            <g>
				<line className="st14" x1="505.3" y1="356.3" x2="523.8" y2="356.3"/>
                                                <line className="st14" x1="508.3" y1="359.6" x2="523.8" y2="359.6"/>
                                                <line className="st14" x1="507.3" y1="362.8" x2="523.8" y2="362.8"/>
                                                <line className="st14" x1="510.3" y1="366.1" x2="523.8" y2="366.1"/>
                                                <line className="st14" x1="512.3" y1="369.4" x2="523.8" y2="369.4"/>
                                                <line className="st14" x1="507.3" y1="372.7" x2="523.8" y2="372.7"/>
                                                <line className="st14" x1="505.3" y1="376" x2="523.8" y2="376"/>
                                                <line className="st14" x1="508.3" y1="379.3" x2="523.8" y2="379.3"/>
                                                <line className="st14" x1="506.3" y1="382.6" x2="523.8" y2="382.6"/>
                                                <line className="st14" x1="505.3" y1="385.9" x2="523.8" y2="385.9"/>
                                                <line className="st14" x1="512.3" y1="389.2" x2="523.8" y2="389.2"/>
			</g>
		</g>
                                        <g>
			<g>
				<line className="st13" x1="686.4" y1="171.5" x2="686.4" y2="195.7"/>
                <line className="st13" x1="695.6" y1="167.9" x2="695.6" y2="195.7"/>
                <line className="st13" x1="704.8" y1="177.2" x2="704.8" y2="195.7"/>
                <line className="st13" x1="714.1" y1="163.2" x2="714.1" y2="195.7"/>
			</g>
                                            <g>
				<line className="st14" x1="722.7" y1="162" x2="741.2" y2="162"/>
                                                <line className="st14" x1="723.7" y1="165.3" x2="741.2" y2="165.3"/>
                                                <line className="st14" x1="731.7" y1="168.6" x2="741.2" y2="168.6"/>
                                                <line className="st14" x1="728.7" y1="171.9" x2="741.2" y2="171.9"/>
                                                <line className="st14" x1="724.7" y1="175.2" x2="741.2" y2="175.2"/>
                                                <line className="st14" x1="736.7" y1="178.5" x2="741.2" y2="178.5"/>
                                                <line className="st14" x1="727.7" y1="181.8" x2="741.2" y2="181.8"/>
                                                <line className="st14" x1="723.7" y1="185.1" x2="741.2" y2="185.1"/>
                                                <line className="st14" x1="729.7" y1="188.4" x2="741.2" y2="188.4"/>
                                                <line className="st14" x1="722.7" y1="191.6" x2="741.2" y2="191.6"/>
                                                <line className="st14" x1="724.7" y1="194.9" x2="741.2" y2="194.9"/>
			</g>
		</g>
                                        <g>
			<g>
				<line className="st12" x1="612.6" y1="279.3" x2="612.6" y2="293.5"/>
                <line className="st12" x1="621.8" y1="267.6" x2="621.8" y2="293.5"/>
                <line className="st12" x1="631" y1="273" x2="631" y2="293.5"/>
                <line className="st12" x1="640.3" y1="268" x2="640.3" y2="293.5"/>
			</g>
                                            <g>
				<line className="st11" x1="654.9" y1="259.8" x2="667.4" y2="259.8"/>
                                                <line className="st11" x1="652.9" y1="263.1" x2="667.4" y2="263.1"/>
                                                <line className="st11" x1="659.9" y1="266.4" x2="667.4" y2="266.4"/>
                                                <line className="st11" x1="649.9" y1="269.7" x2="667.4" y2="269.7"/>
                                                <line className="st11" x1="652.9" y1="273" x2="667.4" y2="273"/>
                                                <line className="st11" x1="656.9" y1="276.3" x2="667.4" y2="276.3"/>
                                                <line className="st11" x1="653.9" y1="279.5" x2="667.4" y2="279.5"/>
                                                <line className="st11" x1="648.9" y1="282.8" x2="667.4" y2="282.8"/>
                                                <line className="st11" x1="659.9" y1="286.1" x2="667.4" y2="286.1"/>
                                                <line className="st11" x1="648.9" y1="289.4" x2="667.4" y2="289.4"/>
                                                <line className="st11" x1="650.9" y1="292.7" x2="667.4" y2="292.7"/>
			</g>
		</g>
                                        <g>
			<line className="st14" x1="806.1" y1="361.2" x2="762.9" y2="361.2"/>
                                            <line className="st14" x1="796.6" y1="364.5" x2="762.9" y2="364.5"/>
                                            <line className="st14" x1="808.4" y1="367.7" x2="762.9" y2="367.7"/>
                                            <line className="st14" x1="800.9" y1="371" x2="762.9" y2="371"/>
                                            <line className="st14" x1="804.1" y1="374.3" x2="762.9" y2="374.3"/>
                                            <line className="st14" x1="792.4" y1="377.6" x2="762.9" y2="377.6"/>
                                            <line className="st14" x1="806.1" y1="380.9" x2="762.9" y2="380.9"/>
                                            <line className="st14" x1="794.6" y1="384.2" x2="762.9" y2="384.2"/>
                                            <line className="st14" x1="796.4" y1="387.5" x2="762.9" y2="387.5"/>
		</g>
                                        <g>
			<line className="st11" x1="664.8" y1="165.8" x2="616.6" y2="165.8"/>
                                            <line className="st11" x1="662.3" y1="169.1" x2="616.6" y2="169.1"/>
                                            <line className="st11" x1="655.1" y1="172.4" x2="616.6" y2="172.4"/>
                                            <line className="st11" x1="646.6" y1="175.6" x2="616.6" y2="175.6"/>
                                            <line className="st11" x1="646.8" y1="178.9" x2="616.6" y2="178.9"/>
                                            <line className="st11" x1="627.1" y1="182.2" x2="616.6" y2="182.2"/>
                                            <line className="st11" x1="634.8" y1="185.5" x2="616.6" y2="185.5"/>
                                            <line className="st11" x1="653.3" y1="188.8" x2="616.6" y2="188.8"/>
                                            <line className="st11" x1="660.1" y1="192.1" x2="616.6" y2="192.1"/>
		</g>
                                        <g>
			<line className="st11" x1="777.2" y1="165.8" x2="808.8" y2="165.8"/>
                                            <line className="st11" x1="760.5" y1="169.1" x2="808.8" y2="169.1"/>
                                            <line className="st11" x1="766.3" y1="172.4" x2="808.8" y2="172.4"/>
                                            <line className="st11" x1="764.8" y1="175.6" x2="808.8" y2="175.6"/>
                                            <line className="st11" x1="790.5" y1="178.9" x2="808.8" y2="178.9"/>
                                            <line className="st11" x1="785.3" y1="182.2" x2="808.8" y2="182.2"/>
                                            <line className="st11" x1="794.5" y1="185.5" x2="808.8" y2="185.5"/>
                                            <line className="st11" x1="782" y1="188.8" x2="808.8" y2="188.8"/>
                                            <line className="st11" x1="763.3" y1="192.1" x2="808.8" y2="192.1"/>
		</g>
                                        <g>
			<circle className="st14" cx="640" cy="375.3" r="16.1"/>
                                            <g>
				<polyline className="st15" points="627.4,365.2 640,375.3 651.5,364 				"/>
                                                <line className="st14" x1="640" y1="374.9" x2="640" y2="359.2"/>
			</g>
		</g>
                                        <g>
			<circle className="st11" cx="495.6" cy="227.3" r="16.1"/>
                                            <g>
				<polyline className="st11" points="511.6,229 495.6,227.3 484.2,215.9 				"/>
                                                <line className="st11" x1="495.9" y1="227" x2="507" y2="215.9"/>
			</g>
		</g>
                                        <g>
			<circle className="st11" cx="784.6" cy="227.3" r="16.1"/>
                                            <g>
				<polyline className="st11" points="793.8,214.1 784.6,227.3 796,238.7 				"/>
                                                <line className="st11" x1="784.6" y1="227.3" x2="784.6" y2="243.4"/>
			</g>
		</g>
                                        <g>
			<polyline className="st16"
                      points="692.1,366.8 702.6,369.5 713.1,363.3 723.6,360.4 734.1,359.3 			"/>
                                            <g>
				<polyline className="st16" points="683.8,358.7 683.8,390.9 741.8,390.9 				"/>
                                                <g>
					<line className="st17" x1="692.1" y1="386" x2="692.1" y2="372.8"/>
                                                    <line className="st17" x1="702.6" y1="386" x2="702.6" y2="375.5"/>
                                                    <line className="st17" x1="713.1" y1="386" x2="713.1" y2="369.3"/>
                                                    <line className="st17" x1="723.6" y1="386" x2="723.6" y2="366.4"/>
                                                    <line className="st17" x1="734.1" y1="386" x2="734.1" y2="365.3"/>
				</g>
			</g>
                                            <g>
				<circle className="st5" cx="692.1" cy="366.8" r="2.6"/>
                                                <circle className="st5" cx="702.6" cy="369.5" r="2.6"/>
                                                <circle className="st5" cx="713.1" cy="363.3" r="2.6"/>
                                                <circle className="st5" cx="723.6" cy="360.4" r="2.6"/>
                                                <circle className="st5" cx="734.1" cy="359.3" r="2.6"/>
			</g>
		</g>
                                        <g>
			<polyline className="st14" points="619.3,214.4 629.8,219 640.3,213.9 650.8,218.9 661.3,212.9 			"/>
                                            <g>
				<g>
					<polyline className="st14" points="611,211.3 611,243.5 669,243.5 					"/>
                    <g>
						<line className="st13" x1="619.3" y1="238.7" x2="619.3" y2="220.4"/>
                        <line className="st13" x1="629.8" y1="238.7" x2="629.8" y2="225"/>
                        <line className="st13" x1="640.3" y1="238.7" x2="640.3" y2="219.9"/>
                        <line className="st13" x1="650.8" y1="238.7" x2="650.8" y2="225"/>
                        <line className="st13" x1="661.3" y1="238.7" x2="661.3" y2="218.9"/>
					</g>
				</g>
                                                <g>
					<circle className="st5" cx="619.3" cy="214.4" r="2.6"/>
                                                    <circle className="st5" cx="629.8" cy="219" r="2.6"/>
                                                    <circle className="st5" cx="640.3" cy="213.9" r="2.6"/>
                                                    <circle className="st5" cx="650.8" cy="219" r="2.6"/>
                                                    <circle className="st5" cx="661.3" cy="212.9" r="2.6"/>
				</g>
			</g>
		</g>
                                        <path className="st5"
                                              d="M467.1,310.5c-0.1,0.1-0.1,0.2-0.2,0.3C467,310.7,467,310.6,467.1,310.5z"/>
                                        <path className="st5"
                                              d="M466.3,314.7c0,0.1,0,0.2,0.1,0.4C466.4,315,466.3,314.8,466.3,314.7z"/>
                                        <path className="st5"
                                              d="M466.4,312.8c0,0.1,0,0.2-0.1,0.4C466.3,313,466.4,312.9,466.4,312.8z"/>
                                        <path className="st5"
                                              d="M466.5,312c0,0.1-0.1,0.3-0.1,0.4C466.5,312.2,466.5,312.1,466.5,312z"/>
                                        <path className="st5"
                                              d="M468.1,319c0.2,0.2,0.3,0.4,0.5,0.6C468.5,319.4,468.3,319.2,468.1,319z"/>
                                        <path className="st5"
                                              d="M466.4,315.5c0,0.1,0.1,0.3,0.1,0.4C466.5,315.8,466.5,315.6,466.4,315.5z"/>
                                        <path className="st5"
                                              d="M466.6,316.3c0,0.1,0.1,0.3,0.1,0.4C466.7,316.5,466.7,316.4,466.6,316.3z"/>
                                        <path className="st5"
                                              d="M467.2,317.7c0.1,0.1,0.1,0.2,0.2,0.3C467.4,317.9,467.3,317.8,467.2,317.7z"/>
                                        <path className="st5"
                                              d="M467.6,318.4c0.1,0.1,0.2,0.2,0.3,0.3C467.8,318.6,467.7,318.5,467.6,318.4z"/>
                                        <path className="st5"
                                              d="M466.9,317c0,0.1,0.1,0.2,0.2,0.3C467,317.2,467,317.1,466.9,317z"/>
                                        <path className="st5"
                                              d="M466.8,311.2c0,0.1-0.1,0.3-0.1,0.4C466.7,311.5,466.7,311.3,466.8,311.2z"/>
                                        <path className="st5"
                                              d="M471.2,306.5c-0.1,0-0.2,0.1-0.3,0.2C471,306.6,471.1,306.6,471.2,306.5z"/>
                                        <path className="st5"
                                              d="M467.4,309.8c-0.1,0.1-0.1,0.2-0.2,0.3C467.3,310,467.4,309.9,467.4,309.8z"/>
                                        <path className="st5"
                                              d="M472,306.3c-0.1,0-0.3,0.1-0.4,0.1C471.7,306.3,471.9,306.3,472,306.3z"/>
                                        <path className="st5"
                                              d="M472.7,306.1c-0.1,0-0.3,0.1-0.4,0.1C472.5,306.1,472.6,306.1,472.7,306.1z"/>
                                        <path className="st5"
                                              d="M470.5,306.9c-0.1,0.1-0.2,0.1-0.3,0.2C470.3,307,470.4,306.9,470.5,306.9z"/>
                                        <path className="st5"
                                              d="M467.9,309.1c-0.1,0.1-0.2,0.2-0.3,0.3C467.7,309.4,467.8,309.2,467.9,309.1z"/>
                                        <path className="st5"
                                              d="M468.6,308.3c-0.2,0.2-0.4,0.4-0.5,0.6C468.3,308.6,468.5,308.4,468.6,308.3z"/>
                                        <path className="st5"
                                              d="M469.2,307.7c-0.2,0.2-0.4,0.3-0.6,0.5C468.8,308.1,469,307.9,469.2,307.7z"/>
                                        <path className="st5"
                                              d="M469.9,307.3c-0.1,0.1-0.2,0.2-0.3,0.3C469.6,307.4,469.7,307.3,469.9,307.3z"/>
                                        <path className="st5"
                                              d="M473.5,305.9c-0.1,0-0.2,0-0.4,0.1C473.3,306,473.4,306,473.5,305.9z"/>
                                        <path className="st5"
                                              d="M477.7,306.7c-0.1-0.1-0.2-0.1-0.3-0.2C477.5,306.6,477.6,306.6,477.7,306.7z"/>
                                        <path className="st5"
                                              d="M477,306.4c-0.1,0-0.3-0.1-0.4-0.1C476.8,306.3,476.9,306.3,477,306.4z"/>
                                        <path className="st5"
                                              d="M475.1,305.9c0.1,0,0.2,0,0.3,0.1C475.3,306,475.2,306,475.1,305.9z"/>
                                        <path className="st5"
                                              d="M476.3,306.2c-0.1,0-0.3-0.1-0.4-0.1C476,306.1,476.1,306.1,476.3,306.2z"/>
                                        <path className="st5"
                                              d="M478.4,307c-0.1-0.1-0.2-0.1-0.3-0.2C478.2,306.9,478.3,307,478.4,307z"/>
                                        <path className="st7"
                                              d="M469.5,307.5c-0.1,0.1-0.2,0.1-0.3,0.2C469.3,307.7,469.4,307.6,469.5,307.5z"/>
                                        <path className="st7"
                                              d="M470.2,307c-0.1,0.1-0.2,0.1-0.4,0.2C470,307.2,470.1,307.1,470.2,307z"/>
                                        <path className="st7"
                                              d="M467.1,317.3c0.1,0.1,0.1,0.2,0.2,0.4C467.2,317.6,467.1,317.5,467.1,317.3z"/>
                                        <path className="st7"
                                              d="M467.6,309.5c-0.1,0.1-0.1,0.2-0.2,0.3C467.5,309.7,467.6,309.6,467.6,309.5z"/>
                                        <path className="st7"
                                              d="M467.2,310.1c-0.1,0.1-0.1,0.2-0.2,0.4C467.1,310.4,467.2,310.3,467.2,310.1z"/>
                                        <path className="st7"
                                              d="M470.9,306.7c-0.1,0.1-0.2,0.1-0.4,0.2C470.6,306.8,470.8,306.7,470.9,306.7z"/>
                                        <path className="st7"
                                              d="M473.5,305.9c0.5-0.1,1.1-0.1,1.6,0c-0.3,0-0.5,0-0.8,0C474,305.9,473.8,305.9,473.5,305.9z"/>
                                        <path className="st7"
                                              d="M473.2,306c-0.1,0-0.3,0-0.4,0.1C472.9,306,473,306,473.2,306z"/>
                                        <path className="st7"
                                              d="M472.3,306.2c-0.1,0-0.2,0.1-0.4,0.1C472.1,306.2,472.2,306.2,472.3,306.2z"/>
                                        <path className="st7"
                                              d="M471.6,306.4c-0.1,0-0.2,0.1-0.4,0.1C471.4,306.5,471.5,306.4,471.6,306.4z"/>
                                        <path className="st7"
                                              d="M466.9,310.9c0,0.1-0.1,0.2-0.1,0.4C466.8,311.1,466.9,311,466.9,310.9z"/>
                                        <path className="st7"
                                              d="M467.4,318c0.1,0.1,0.1,0.2,0.2,0.3C467.6,318.3,467.5,318.1,467.4,318z"/>
                                        <path className="st7"
                                              d="M468.1,308.8c-0.1,0.1-0.1,0.2-0.2,0.3C468,309,468,308.9,468.1,308.8z"/>
                                        <path className="st7"
                                              d="M476.6,306.3c-0.1,0-0.2-0.1-0.4-0.1C476.4,306.2,476.5,306.2,476.6,306.3z"/>
                                        <path className="st7"
                                              d="M478.4,307c0.1,0,0.1,0.1,0.2,0.1h0C478.5,307.1,478.5,307.1,478.4,307z"/>
                                        <path className="st7"
                                              d="M466.3,313.9c0,0.3,0,0.5,0,0.8C466.3,314.5,466.3,314.2,466.3,313.9z"/>
                                        <path className="st7"
                                              d="M466.3,313.1c0,0.3,0,0.5,0,0.8C466.3,313.7,466.3,313.4,466.3,313.1z"/>
                                        <path className="st7"
                                              d="M477.4,306.5c-0.1,0-0.2-0.1-0.4-0.1C477.1,306.4,477.3,306.5,477.4,306.5z"/>
                                        <path className="st7"
                                              d="M466.8,316.6c0,0.1,0.1,0.2,0.1,0.4C466.9,316.9,466.8,316.8,466.8,316.6z"/>
                                        <path className="st7"
                                              d="M478.1,306.9c-0.1-0.1-0.2-0.1-0.4-0.2C477.8,306.7,478,306.8,478.1,306.9z"/>
                                        <path className="st7"
                                              d="M467.9,318.7c0.1,0.1,0.1,0.2,0.2,0.3C468,318.9,468,318.8,467.9,318.7z"/>
                                        <path className="st7"
                                              d="M466.5,315.9c0,0.1,0.1,0.2,0.1,0.4C466.6,316.1,466.6,316,466.5,315.9z"/>
                                        <path className="st7"
                                              d="M466.4,315.1c0,0.1,0,0.3,0.1,0.4C466.4,315.4,466.4,315.2,466.4,315.1z"/>
                                        <path className="st7"
                                              d="M466.6,311.6c0,0.1-0.1,0.2-0.1,0.4C466.6,311.8,466.6,311.7,466.6,311.6z"/>
                                        <path className="st7"
                                              d="M475.9,306.1c-0.1,0-0.3,0-0.4-0.1C475.6,306,475.7,306,475.9,306.1z"/>
                                        <path className="st7"
                                              d="M466.4,312.4c0,0.1,0,0.3-0.1,0.4C466.4,312.6,466.4,312.5,466.4,312.4z"/>
                                        <path className="st7"
                                              d="M480,308.3c-0.2-0.2-0.4-0.4-0.6-0.5C479.6,307.9,479.8,308.1,480,308.3z"/>
                                        <path className="st7"
                                              d="M479.1,307.5c-0.1-0.1-0.2-0.2-0.3-0.3C478.9,307.3,479,307.4,479.1,307.5z"/>
                                        <path className="st7"
                                              d="M478.6,307.1L478.6,307.1c0.1,0,0.1,0.1,0.2,0.1C478.7,307.2,478.6,307.2,478.6,307.1z"/>
                                        <path className="st7"
                                              d="M479.4,307.7c-0.1-0.1-0.2-0.1-0.3-0.2C479.2,307.6,479.3,307.7,479.4,307.7z"/>
                                        <g>
			<g>
				<g>
					<circle className="st5" cx="475.3" cy="336.3" r="8"/>
                    <path className="st7"
                          d="M481,330.6c-3.1-3.1-8.2-3.1-11.3,0c-3.1,3.1-3.1,8.2,0,11.3l5.7-5.7L481,330.6z"/>
				</g>
                <g>
					<g>
						<circle className="st3" cx="495.4" cy="336.3" r="8"/>
					</g>
                    <path className="st7" d="M501,342c3.1-3.1,3.1-8.2,0-11.3l-5.7,5.7L501,342z"/>
				</g>
                <g>
					<circle className="st7" cx="515.4" cy="336.3" r="8"/>
                    <path className="st5" d="M509.8,342c3.1,3.1,8.2,3.1,11.3,0l-5.7-5.7L509.8,342z"/>
				</g>
			</g>
                                            <g>
				<g>
					<circle className="st5" cx="495.4" cy="315.6" r="8"/>
                    <path className="st7" d="M495.4,307.6c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8v-8V307.6z"/>
				</g>
                                                <g>
					<circle className="st3" cx="475.3" cy="315.6" r="8"/>
                                                    <path className="st7" d="M475.3,315.6l-5.7,5.7c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
						c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.4
						c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.4
						c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8c0-0.1,0-0.2,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4
						c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
						c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.6-0.5
						c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2
						c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1
						c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3,0,0.4,0.1
						c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
						c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3
						c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c1.5,1.5,2.3,3.5,2.3,5.7H475.3z"/>
				</g>
                                                <g>
					<circle className="st7" cx="515.4" cy="315.6" r="8"/>
                                                    <path className="st3" d="M515.4,315.6l-5.7-5.7c0.2-0.2,0.4-0.4,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
						c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1
						c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.4-0.1
						c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
						c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.4,0.5,0.6
						c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
						c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
						c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.4c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.3-0.1,0.4
						c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
						c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
						c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c-1.5,1.5-3.5,2.3-5.7,2.3V315.6z"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<circle className="st5" cx="692.7" cy="266.3" r="8"/>
                    <path className="st7"
                          d="M698.4,272c-3.1,3.1-8.2,3.1-11.3,0c-3.1-3.1-3.1-8.2,0-11.3l5.7,5.7L698.4,272z"/>
				</g>
                <g>
					<g>
						<circle className="st3" cx="712.8" cy="266.3" r="8"/>
					</g>
                    <path className="st7" d="M718.5,260.6c3.1,3.1,3.1,8.2,0,11.3l-5.7-5.7L718.5,260.6z"/>
				</g>
                <g>
					<circle className="st7" cx="732.9" cy="266.3" r="8"/>
                    <path className="st3" d="M727.2,260.6c3.1-3.1,8.2-3.1,11.3,0l-5.7,5.7L727.2,260.6z"/>
				</g>
			</g>
                                            <g>
				<g>
					<circle className="st3" cx="712.8" cy="287" r="8"/>
                    <path className="st7" d="M712.8,295c-4.4,0-8-3.6-8-8s3.6-8,8-8v8V295z"/>
				</g>
                                                <g>
					<circle className="st7" cx="692.7" cy="287" r="8"/>
                                                    <path className="st5" d="M692.7,287l-5.7-5.7c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
						c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
						c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.4
						c0,0.3,0,0.5,0,0.8s0,0.5,0,0.8c0,0.1,0,0.2,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4
						c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
						c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.5
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
						c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.4-0.1
						c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2
						c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
						c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c1.5-1.5,2.3-3.5,2.3-5.7H692.7z"/>
				</g>
                                                <g>
					<circle className="st7" cx="732.9" cy="287" r="8"/>
                                                    <path className="st5" d="M732.9,287l-5.7,5.7c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1
						c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1
						c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1
						c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2
						c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6
						c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4
						c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
						c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4
						c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
						c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
						c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.6c-1.5-1.5-3.5-2.3-5.7-2.3V287z"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<circle className="st3" cx="587.3" cy="189.3" r="8"/>
                    <path className="st2"
                          d="M581.6,183.6c3.1-3.1,8.2-3.1,11.3,0c3.1,3.1,3.1,8.2,0,11.3l-5.7-5.7L581.6,183.6z"/>
				</g>
                <g>
					<g>
						<circle className="st3" cx="567.2" cy="189.3" r="8"/>
					</g>
                    <path className="st2" d="M561.5,194.9c-3.1-3.1-3.1-8.2,0-11.3l5.7,5.7L561.5,194.9z"/>
				</g>
                <g>
					<circle className="st2" cx="547.1" cy="189.3" r="8"/>
                    <path className="st5" d="M552.8,194.9c-3.1,3.1-8.2,3.1-11.3,0l5.7-5.7L552.8,194.9z"/>
				</g>
			</g>
                                            <g>
				<g>
					<circle className="st5" cx="567.2" cy="168.6" r="8"/>
                    <path className="st2" d="M567.2,176.6c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8v8V176.6z"/>
				</g>
                                                <g>
					<circle className="st2" cx="547.1" cy="168.6" r="8"/>
                                                    <path className="st5" d="M547.1,168.6l-5.7-5.7c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
						c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
						c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.4
						c0,0.3,0,0.5,0,0.8s0,0.5,0,0.8c0,0.1,0,0.2,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4
						c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
						c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.5
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
						c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.4-0.1
						c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2
						c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
						c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c1.5-1.5,2.3-3.5,2.3-5.7H547.1z"/>
				</g>
                                                <g>
					<circle className="st2" cx="587.3" cy="168.6" r="8"/>
                                                    <path className="st3" d="M587.3,168.6l-5.7,5.7c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
						c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1
						c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1
						c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1
						c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2
						c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6
						c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4
						c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
						c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4
						c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
						c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
						c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.6c-1.5-1.5-3.5-2.3-5.7-2.3V168.6z"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<line className="st14" x1="567.2" y1="316" x2="567.2" y2="322.1"/>
                    <line className="st14" x1="567.2" y1="329.5" x2="567.2" y2="335.5"/>
				</g>
                <g>
					<line className="st14" x1="556.7" y1="325.6" x2="563.5" y2="325.6"/>
                    <line className="st14" x1="570.9" y1="325.6" x2="577.7" y2="325.6"/>
				</g>
                <g>
					<line className="st14" x1="542.5" y1="339.3" x2="549.2" y2="339.3"/>
                    <line className="st14" x1="556.7" y1="339.3" x2="563.5" y2="339.3"/>
                    <line className="st14" x1="570.9" y1="339.3" x2="577.7" y2="339.3"/>
                    <line className="st14" x1="585.1" y1="339.3" x2="591.9" y2="339.3"/>
				</g>
                <g>
					<line className="st14" x1="553" y1="329.5" x2="553" y2="335.5"/>
                    <line className="st14" x1="581.4" y1="329.5" x2="581.4" y2="335.5"/>
				</g>
			</g>
                                            <rect x="563.5" y="308.6" className="st16" width="7.4" height="7.4"/>
                                            <g>
				<rect x="563.5" y="322.1" className="st16" width="7.4" height="7.4"/>
                                                <rect x="549.2" y="322.1" className="st18" width="7.4" height="7.4"/>
                                                <rect x="577.7" y="322.1" className="st16" width="7.4" height="7.4"/>
			</g>
                                            <g>
				<rect x="563.5" y="335.5" className="st16" width="7.4" height="7.4"/>
                                                <rect x="549.2" y="335.5" className="st16" width="7.4" height="7.4"/>
                                                <rect x="535" y="335.5" className="st18" width="7.4" height="7.4"/>
                                                <rect x="577.7" y="335.5" className="st18" width="7.4" height="7.4"/>
                                                <rect x="591.9" y="335.5" className="st16" width="7.4" height="7.4"/>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<line className="st14" x1="784.6" y1="266.9" x2="784.6" y2="272.9"/>
                    <line className="st14" x1="784.6" y1="280.4" x2="784.6" y2="286.4"/>
				</g>
                <g>
					<line className="st14" x1="774.1" y1="276.4" x2="780.9" y2="276.4"/>
                    <line className="st14" x1="788.3" y1="276.4" x2="795.1" y2="276.4"/>
				</g>
                <g>
					<line className="st14" x1="774.1" y1="290.1" x2="780.9" y2="290.1"/>
                    <line className="st14" x1="788.3" y1="290.1" x2="795.1" y2="290.1"/>
				</g>
                <g>
					<line className="st14" x1="770.4" y1="280.4" x2="770.4" y2="286.4"/>
                    <line className="st14" x1="798.8" y1="280.4" x2="798.8" y2="286.4"/>
				</g>
			</g>
                                            <g>
				<rect x="780.9" y="259.4" className="st19" width="7.4" height="7.4"/>
                                                <g>
					<rect x="780.9" y="272.9" className="st14" width="7.4" height="7.4"/>
                                                    <rect x="766.7" y="272.9" className="st19" width="7.4"
                                                          height="7.4"/>
                                                    <rect x="795.1" y="272.9" className="st14" width="7.4"
                                                          height="7.4"/>
				</g>
                                                <g>
					<rect x="780.9" y="286.4" className="st14" width="7.4" height="7.4"/>
                                                    <rect x="766.7" y="286.4" className="st14" width="7.4"
                                                          height="7.4"/>
                                                    <rect x="795.1" y="286.4" className="st19" width="7.4"
                                                          height="7.4"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<line className="st13" x1="621.1" y1="326" x2="621.1" y2="340.3"/>
                <line className="st13" x1="630.8" y1="326" x2="630.8" y2="335.1"/>
                <line className="st13" x1="640.5" y1="326" x2="640.5" y2="342.4"/>
                <line className="st13" x1="650.1" y1="326" x2="650.1" y2="336.6"/>
                <line className="st13" x1="659.8" y1="326" x2="659.8" y2="339.8"/>
			</g>
                                            <g>
				<line className="st17" x1="618.1" y1="323.3" x2="618.1" y2="326"/>
                                                <line className="st17" x1="627.8" y1="323.9" x2="627.8" y2="326"/>
                                                <line className="st17" x1="637.5" y1="317.3" x2="637.5" y2="326"/>
                                                <line className="st17" x1="647.1" y1="318.8" x2="647.1" y2="326"/>
                                                <line className="st17" x1="656.8" y1="311.6" x2="656.8" y2="326"/>
			</g>
		</g>
                                        <g>
			<g>
				<rect x="684.5" y="334.2" className="st11" width="16" height="5"/>
                <g>
					<line className="st11" x1="705.8" y1="339.2" x2="731.8" y2="339.2"/>
                    <line className="st11" x1="705.8" y1="334.2" x2="728.3" y2="334.2"/>
                    <line className="st11" x1="705.8" y1="336.7" x2="741.1" y2="336.7"/>
				</g>
			</g>
                                            <g>
				<rect x="684.5" y="323.5" className="st11" width="16" height="5"/>
                                                <g>
					<line className="st11" x1="705.8" y1="328.5" x2="735.2" y2="328.5"/>
                                                    <line className="st11" x1="705.8" y1="323.5" x2="726.1" y2="323.5"/>
                                                    <line className="st11" x1="705.8" y1="326" x2="722.6" y2="326"/>
				</g>
			</g>
                                            <g>
				<rect x="684.5" y="312.8" className="st11" width="16" height="5"/>
                                                <g>
					<line className="st11" x1="705.8" y1="317.8" x2="737.9" y2="317.8"/>
                                                    <line className="st11" x1="705.8" y1="312.8" x2="739.7" y2="312.8"/>
                                                    <line className="st11" x1="705.8" y1="315.3" x2="735.2" y2="315.3"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<rect x="538.9" y="235.5" className="st19" width="16" height="5"/>
                <g>
					<line className="st14" x1="560.1" y1="240.5" x2="591.2" y2="240.5"/>
                    <line className="st14" x1="560.1" y1="235.5" x2="592.7" y2="235.5"/>
                    <line className="st14" x1="560.1" y1="238" x2="595.5" y2="238"/>
				</g>
			</g>
                                            <g>
				<rect x="538.9" y="224.8" className="st14" width="16" height="5"/>
                                                <g>
					<line className="st14" x1="560.1" y1="229.8" x2="589.6" y2="229.8"/>
                                                    <line className="st14" x1="560.1" y1="224.8" x2="593.4" y2="224.8"/>
                                                    <line className="st14" x1="560.1" y1="227.3" x2="591.9" y2="227.3"/>
				</g>
			</g>
                                            <g>
				<rect x="538.9" y="214.1" className="st19" width="16" height="5"/>
                                                <g>
					<line className="st14" x1="560.1" y1="219.1" x2="585.3" y2="219.1"/>
                                                    <line className="st14" x1="560.1" y1="214.1" x2="591.1" y2="214.1"/>
                                                    <line className="st14" x1="560.1" y1="216.6" x2="594.6" y2="216.6"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<polygon className="st16" points="813.6,338.7 755.6,338.7 755.6,338.7 813.6,338.7 				"/>
                <polygon className="st16" points="813.6,330.2 755.6,330.2 755.6,330.2 813.6,330.2 				"/>
                <polygon className="st16" points="813.6,321.7 755.6,321.7 755.6,321.7 813.6,321.7 				"/>
                <polygon className="st16" points="813.6,313.3 755.6,313.3 755.6,313.3 813.6,313.3 				"/>
			</g>
                                            <g>
				<circle className="st5" cx="762.9" cy="338.7" r="2.6"/>
                                                <circle className="st5" cx="786.2" cy="330.2" r="2.6"/>
                                                <circle className="st5" cx="773.4" cy="321.7" r="2.6"/>
                                                <circle className="st5" cx="802.5" cy="313.3" r="2.6"/>
			</g>
		</g>
                                        <g>
			<g>
				<polygon className="st11" points="524.4,191.6 466.4,191.6 466.4,191.6 524.4,191.6 				"/>
                <polygon className="st11" points="524.4,183.2 466.4,183.2 466.4,183.2 524.4,183.2 				"/>
                <polygon className="st11" points="524.4,174.7 466.4,174.7 466.4,174.7 524.4,174.7 				"/>
                <polygon className="st11" points="524.4,166.2 466.4,166.2 466.4,166.2 524.4,166.2 				"/>
			</g>
                                            <g>
				<circle className="st3" cx="517.6" cy="191.6" r="2.6"/>
                                                <circle className="st3" cx="504" cy="183.2" r="2.6"/>
                                                <circle className="st3" cx="473.1" cy="174.7" r="2.6"/>
                                                <circle className="st3" cx="490.2" cy="166.2" r="2.6"/>
			</g>
		</g>
                                        <g>
			<polygon className="st14" points="596.2,289.3 538.2,289.3 538.2,289.3 596.2,289.3 			"/>
                                            <polygon className="st14"
                                                     points="596.2,280.9 538.2,280.9 538.2,280.9 596.2,280.9 			"/>
                                            <polygon className="st14"
                                                     points="596.2,272.4 538.2,272.4 538.2,272.4 596.2,272.4 			"/>
                                            <polygon className="st14"
                                                     points="596.2,263.9 538.2,263.9 538.2,263.9 596.2,263.9 			"/>
		</g>
                                        <circle className="st8" cx="584.4" cy="289.3" r="2.6"/>
                                        <circle className="st8" cx="568.8" cy="280.9" r="2.6"/>
                                        <circle className="st8" cx="545.9" cy="272.4" r="2.6"/>
                                        <circle className="st8" cx="574" cy="263.9" r="2.6"/>
                                        <g>
			<g>
				<g>
					<line className="st17" x1="476.5" y1="276.4" x2="476.5" y2="264.1"/>
                    <line className="st17" x1="486.2" y1="276.4" x2="486.2" y2="263.3"/>
                    <line className="st17" x1="495.9" y1="276.4" x2="495.9" y2="264.9"/>
                    <line className="st17" x1="505.5" y1="276.4" x2="505.5" y2="260.8"/>
                    <line className="st17" x1="515.2" y1="276.4" x2="515.2" y2="267.6"/>
				</g>
                <g>
					<line className="st20" x1="473.5" y1="281.1" x2="473.5" y2="276.4"/>
                    <line className="st20" x1="483.2" y1="282.4" x2="483.2" y2="276.4"/>
                    <line className="st20" x1="492.9" y1="279.1" x2="492.9" y2="276.4"/>
                    <line className="st20" x1="502.5" y1="277.6" x2="502.5" y2="276.4"/>
                    <line className="st20" x1="512.2" y1="285.8" x2="512.2" y2="276.4"/>
				</g>
			</g>
                                            <g>
				<line className="st16" x1="466.4" y1="259.4" x2="466.4" y2="293.8"/>
                                                <line className="st16" x1="466.4" y1="276.4" x2="524.4" y2="276.4"/>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<line className="st17" x1="694" y1="227.5" x2="694" y2="236.8"/>
                    <line className="st17" x1="703.6" y1="227.5" x2="703.6" y2="235.6"/>
                    <line className="st17" x1="713.3" y1="227.5" x2="713.3" y2="240.9"/>
                    <line className="st17" x1="723" y1="227.5" x2="723" y2="241.1"/>
                    <line className="st17" x1="732.6" y1="227.5" x2="732.6" y2="239.3"/>
				</g>
                <g>
					<line className="st20" x1="691" y1="224.8" x2="691" y2="227.5"/>
                    <line className="st20" x1="700.6" y1="219.4" x2="700.6" y2="227.5"/>
                    <line className="st20" x1="710.3" y1="222.8" x2="710.3" y2="227.5"/>
                    <line className="st20" x1="720" y1="225.3" x2="720" y2="227.5"/>
                    <line className="st20" x1="729.6" y1="225.1" x2="729.6" y2="227.5"/>
				</g>
			</g>
                                            <g>
				<line className="st16" x1="683.8" y1="244.5" x2="683.8" y2="210.1"/>
                                                <line className="st16" x1="683.8" y1="227.5" x2="741.8" y2="227.5"/>
			</g>
		</g>
                                        <g>
			<line className="st14" x1="611" y1="343" x2="611" y2="308.6"/>
                                            <line className="st14" x1="611" y1="326" x2="669" y2="326"/>
		</g>
	</g>
                                    <g>

			<rect x="1171.9" y="153.3" transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2416.5237 355.8717)"
                  className="st7" width="72.8" height="49.3"/>

                                        <rect x="1099" y="153.3"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2270.8984 355.8717)"
                                              className="st3" width="72.8" height="49.3"/>

                                        <rect x="1026.2" y="153.3"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2125.2732 355.8717)"
                                              className="st1" width="72.8" height="49.3"/>

                                        <rect x="953.4" y="153.3"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1979.6481 355.8717)"
                                              className="st2" width="72.8" height="49.3"/>

                                        <rect x="880.6" y="153.3"
                                              transform="matrix(-1 -4.487978e-11 4.487978e-11 -1 1834.0228 355.8717)"
                                              className="st4" width="72.8" height="49.3"/>

                                        <rect x="1171.9" y="202.6"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2416.5237 454.5669)"
                                              className="st1" width="72.8" height="49.3"/>

                                        <rect x="1099" y="202.6"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2270.8984 454.5669)"
                                              className="st2" width="72.8" height="49.3"/>

                                        <rect x="1026.2" y="202.6"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2125.2732 454.5669)"
                                              className="st7" width="72.8" height="49.3"/>

                                        <rect x="953.4" y="202.6"
                                              transform="matrix(-1 -4.487978e-11 4.487978e-11 -1 1979.6481 454.5669)"
                                              className="st5" width="72.8" height="49.3"/>

                                        <rect x="880.6" y="202.6"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 1834.0228 454.5669)"
                                              className="st2" width="72.8" height="49.3"/>

                                        <rect x="1171.9" y="252"
                                              transform="matrix(-1 -4.491752e-11 4.491752e-11 -1 2416.5237 553.262)"
                                              className="st7" width="72.8" height="49.3"/>

                                        <rect x="1099" y="252"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2270.8984 553.262)"
                                              className="st1" width="72.8" height="49.3"/>

                                        <rect x="1026.2" y="252"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2125.2732 553.262)"
                                              className="st3" width="72.8" height="49.3"/>

                                        <rect x="953.4" y="252"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 1979.6481 553.262)"
                                              className="st1" width="72.8" height="49.3"/>

                                        <rect x="880.6" y="252"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1834.0228 553.262)"
                                              className="st7" width="72.8" height="49.3"/>

                                        <rect x="1171.9" y="301.3"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2416.5237 651.9572)"
                                              className="st5" width="72.8" height="49.3"/>

                                        <rect x="1099" y="301.3"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2270.8984 651.9572)"
                                              className="st8" width="72.8" height="49.3"/>

                                        <rect x="1026.2" y="301.3"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2125.2732 651.9572)"
                                              className="st1" width="72.8" height="49.3"/>

                                        <rect x="953.4" y="301.3"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1979.6481 651.9572)"
                                              className="st7" width="72.8" height="49.3"/>

                                        <rect x="880.6" y="301.3"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1834.0228 651.9572)"
                                              className="st3" width="72.8" height="49.3"/>

                                        <rect x="1171.9" y="350.7"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2416.5237 750.6524)"
                                              className="st2" width="72.8" height="49.3"/>

                                        <rect x="1099" y="350.7"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 2270.8984 750.6524)"
                                              className="st7" width="72.8" height="49.3"/>

                                        <rect x="1026.2" y="350.7"
                                              transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 2125.2732 750.6524)"
                                              className="st3" width="72.8" height="49.3"/>

                                        <rect x="953.4" y="350.7"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1979.6481 750.6524)"
                                              className="st4" width="72.8" height="49.3"/>

                                        <rect x="880.6" y="350.7"
                                              transform="matrix(-1 -4.489221e-11 4.489221e-11 -1 1834.0228 750.6524)"
                                              className="st5" width="72.8" height="49.3"/>
                                        <path className="st9" d="M892.6,400h340.1c6.6,0,12-5.4,12-12V165.3c0-6.6-5.4-12-12-12H892.6c-6.6,0-12,5.4-12,12V388
			C880.6,394.6,886,400,892.6,400z"/>
                                        <g>
			<line className="st10" x1="1171.9" y1="153.3" x2="1171.9" y2="400"/>
                                            <line className="st10" x1="1099" y1="153.3" x2="1099" y2="400"/>
                                            <line className="st10" x1="1026.2" y1="153.3" x2="1026.2" y2="400"/>
                                            <line className="st10" x1="953.4" y1="153.3" x2="953.4" y2="400"/>
		</g>
                                        <g>
			<line className="st10" x1="1244.7" y1="202.6" x2="880.6" y2="202.6"/>
                                            <line className="st10" x1="1244.7" y1="252" x2="880.6" y2="252"/>
                                            <line className="st10" x1="1244.7" y1="301.3" x2="880.6" y2="301.3"/>
                                            <line className="st10" x1="1244.7" y1="350.7" x2="880.6" y2="350.7"/>
		</g>
	</g>
                                    <g>
		<polyline className="st11" points="1106.4,164.3 1106.4,196.6 1164.5,196.6 		"/>
                                        <g>
			<g>
				<g>
					<circle className="st7" cx="1114.7" cy="177.5" r="2.6"/>
                    <circle className="st7" cx="1125.2" cy="172.2" r="2.6"/>
                    <circle className="st7" cx="1135.7" cy="164" r="2.6"/>
                    <circle className="st7" cx="1146.2" cy="170.9" r="2.6"/>
                    <circle className="st7" cx="1156.7" cy="164" r="2.6"/>
				</g>
                <polyline className="st11"
                          points="1114.7,177.5 1125.2,172.2 1135.7,164 1146.2,170.9 1156.7,164 				"/>
			</g>
                                            <g>
				<line className="st12" x1="1114.7" y1="191.7" x2="1114.7" y2="183.5"/>
                                                <line className="st12" x1="1125.2" y1="191.7" x2="1125.2" y2="178.2"/>
                                                <line className="st12" x1="1135.7" y1="191.7" x2="1135.7" y2="170"/>
                                                <line className="st12" x1="1146.2" y1="191.7" x2="1146.2" y2="177"/>
                                                <line className="st12" x1="1156.7" y1="191.7" x2="1156.7" y2="170"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="1233.7" y1="167.8" x2="1233.7" y2="197"/>
            <line className="st13" x1="1224.5" y1="176.2" x2="1224.5" y2="197"/>
            <line className="st13" x1="1215.2" y1="171.5" x2="1215.2" y2="197"/>
            <line className="st13" x1="1206" y1="167.5" x2="1206" y2="197"/>
		</g>
                                        <g>
			<line className="st14" x1="1197.3" y1="163.3" x2="1178.8" y2="163.3"/>
                                            <line className="st14" x1="1194.3" y1="166.6" x2="1178.8" y2="166.6"/>
                                            <line className="st14" x1="1195.3" y1="169.9" x2="1178.8" y2="169.9"/>
                                            <line className="st14" x1="1192.3" y1="173.2" x2="1178.8" y2="173.2"/>
                                            <line className="st14" x1="1190.3" y1="176.5" x2="1178.8" y2="176.5"/>
                                            <line className="st14" x1="1195.3" y1="179.8" x2="1178.8" y2="179.8"/>
                                            <line className="st14" x1="1197.3" y1="183.1" x2="1178.8" y2="183.1"/>
                                            <line className="st14" x1="1194.3" y1="186.4" x2="1178.8" y2="186.4"/>
                                            <line className="st14" x1="1196.3" y1="189.7" x2="1178.8" y2="189.7"/>
                                            <line className="st14" x1="1197.3" y1="193" x2="1178.8" y2="193"/>
                                            <line className="st14" x1="1190.3" y1="196.2" x2="1178.8" y2="196.2"/>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="961.4" y1="367.1" x2="961.4" y2="391.2"/>
            <line className="st13" x1="970.6" y1="363.4" x2="970.6" y2="391.2"/>
            <line className="st13" x1="979.9" y1="372.7" x2="979.9" y2="391.2"/>
            <line className="st13" x1="989.1" y1="358.7" x2="989.1" y2="391.2"/>
		</g>
                                        <g>
			<line className="st14" x1="997.7" y1="357.6" x2="1016.2" y2="357.6"/>
                                            <line className="st14" x1="998.7" y1="360.9" x2="1016.2" y2="360.9"/>
                                            <line className="st14" x1="1006.7" y1="364.2" x2="1016.2" y2="364.2"/>
                                            <line className="st14" x1="1003.7" y1="367.5" x2="1016.2" y2="367.5"/>
                                            <line className="st14" x1="999.7" y1="370.7" x2="1016.2" y2="370.7"/>
                                            <line className="st14" x1="1011.7" y1="374" x2="1016.2" y2="374"/>
                                            <line className="st14" x1="1002.7" y1="377.3" x2="1016.2" y2="377.3"/>
                                            <line className="st14" x1="998.7" y1="380.6" x2="1016.2" y2="380.6"/>
                                            <line className="st14" x1="1004.7" y1="383.9" x2="1016.2" y2="383.9"/>
                                            <line className="st14" x1="997.7" y1="387.2" x2="1016.2" y2="387.2"/>
                                            <line className="st14" x1="999.7" y1="390.5" x2="1016.2" y2="390.5"/>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st12" x1="1035.2" y1="279.3" x2="1035.2" y2="293.5"/>
            <line className="st12" x1="1044.4" y1="267.6" x2="1044.4" y2="293.5"/>
            <line className="st12" x1="1053.7" y1="273" x2="1053.7" y2="293.5"/>
            <line className="st12" x1="1062.9" y1="268" x2="1062.9" y2="293.5"/>
		</g>
                                        <g>
			<line className="st11" x1="1077.6" y1="259.8" x2="1090.1" y2="259.8"/>
                                            <line className="st11" x1="1075.6" y1="263.1" x2="1090.1" y2="263.1"/>
                                            <line className="st11" x1="1082.6" y1="266.4" x2="1090.1" y2="266.4"/>
                                            <line className="st11" x1="1072.6" y1="269.7" x2="1090.1" y2="269.7"/>
                                            <line className="st11" x1="1075.6" y1="273" x2="1090.1" y2="273"/>
                                            <line className="st11" x1="1079.6" y1="276.3" x2="1090.1" y2="276.3"/>
                                            <line className="st11" x1="1076.6" y1="279.5" x2="1090.1" y2="279.5"/>
                                            <line className="st11" x1="1071.6" y1="282.8" x2="1090.1" y2="282.8"/>
                                            <line className="st11" x1="1082.6" y1="286.1" x2="1090.1" y2="286.1"/>
                                            <line className="st11" x1="1071.6" y1="289.4" x2="1090.1" y2="289.4"/>
                                            <line className="st11" x1="1073.6" y1="292.7" x2="1090.1" y2="292.7"/>
		</g>
	</g>
                                    <g>
		<line className="st14" x1="896.5" y1="192.1" x2="939.8" y2="192.1"/>
                                        <line className="st14" x1="906" y1="188.8" x2="939.8" y2="188.8"/>
                                        <line className="st14" x1="894.3" y1="185.5" x2="939.8" y2="185.5"/>
                                        <line className="st14" x1="901.8" y1="182.2" x2="939.8" y2="182.2"/>
                                        <line className="st14" x1="898.5" y1="178.9" x2="939.8" y2="178.9"/>
                                        <line className="st14" x1="910.3" y1="175.6" x2="939.8" y2="175.6"/>
                                        <line className="st14" x1="896.5" y1="172.3" x2="939.8" y2="172.3"/>
                                        <line className="st14" x1="908" y1="169.1" x2="939.8" y2="169.1"/>
                                        <line className="st14" x1="906.3" y1="165.8" x2="939.8" y2="165.8"/>
	</g>
                                    <g>
		<line className="st11" x1="1037.8" y1="387.5" x2="1086" y2="387.5"/>
                                        <line className="st11" x1="1040.3" y1="384.2" x2="1086" y2="384.2"/>
                                        <line className="st11" x1="1047.5" y1="380.9" x2="1086" y2="380.9"/>
                                        <line className="st11" x1="1056" y1="377.6" x2="1086" y2="377.6"/>
                                        <line className="st11" x1="1055.8" y1="374.3" x2="1086" y2="374.3"/>
                                        <line className="st11" x1="1075.5" y1="371" x2="1086" y2="371"/>
                                        <line className="st11" x1="1067.8" y1="367.7" x2="1086" y2="367.7"/>
                                        <line className="st11" x1="1049.3" y1="364.5" x2="1086" y2="364.5"/>
                                        <line className="st11" x1="1042.5" y1="361.2" x2="1086" y2="361.2"/>
	</g>
                                    <g>
		<line className="st11" x1="925.4" y1="387.5" x2="893.9" y2="387.5"/>
                                        <line className="st11" x1="942.1" y1="384.2" x2="893.9" y2="384.2"/>
                                        <line className="st11" x1="936.4" y1="380.9" x2="893.9" y2="380.9"/>
                                        <line className="st11" x1="937.9" y1="377.6" x2="893.9" y2="377.6"/>
                                        <line className="st11" x1="912.1" y1="374.3" x2="893.9" y2="374.3"/>
                                        <line className="st11" x1="917.4" y1="371" x2="893.9" y2="371"/>
                                        <line className="st11" x1="908.1" y1="367.7" x2="893.9" y2="367.7"/>
                                        <line className="st11" x1="920.6" y1="364.5" x2="893.9" y2="364.5"/>
                                        <line className="st11" x1="939.4" y1="361.2" x2="893.9" y2="361.2"/>
	</g>
                                    <g>
		<circle className="st14" cx="1062.6" cy="177.9" r="16.1"/>
                                        <g>
			<polyline className="st15" points="1075.2,167.8 1062.6,177.9 1051.2,166.6 			"/>
                                            <line className="st14" x1="1062.6" y1="177.5" x2="1062.6" y2="161.8"/>
		</g>
	</g>
                                    <g>
		<circle className="st11" cx="1207" cy="326" r="16.1"/>
                                        <g>
			<polyline className="st11" points="1191,324.2 1207,326 1218.4,337.4 			"/>
                                            <line className="st11" x1="1206.7" y1="326.3" x2="1195.6" y2="337.4"/>
		</g>
	</g>
                                    <g>
		<circle className="st11" cx="918" cy="326" r="16.1"/>
                                        <g>
			<polyline className="st11" points="908.8,339.2 918,326 906.6,314.6 			"/>
                                            <line className="st11" x1="918" y1="326" x2="918" y2="309.9"/>
		</g>
	</g>
                                    <g>
		<polyline className="st16" points="969.1,172.5 979.6,175.2 990.1,169 1000.6,166 1011.1,165 		"/>
                                        <g>
			<polyline className="st16" points="960.8,164.3 960.8,196.6 1018.8,196.6 			"/>
                                            <g>
				<line className="st17" x1="969.1" y1="191.7" x2="969.1" y2="178.5"/>
                                                <line className="st17" x1="979.6" y1="191.7" x2="979.6" y2="181.2"/>
                                                <line className="st17" x1="990.1" y1="191.7" x2="990.1" y2="175"/>
                                                <line className="st17" x1="1000.6" y1="191.7" x2="1000.6" y2="172"/>
                                                <line className="st17" x1="1011.1" y1="191.7" x2="1011.1" y2="171"/>
			</g>
		</g>
                                        <g>
			<circle className="st5" cx="969.1" cy="172.5" r="2.6"/>
                                            <circle className="st5" cx="979.6" cy="175.2" r="2.6"/>
                                            <circle className="st5" cx="990.1" cy="169" r="2.6"/>
                                            <circle className="st5" cx="1000.6" cy="166" r="2.6"/>
                                            <circle className="st5" cx="1011.1" cy="165" r="2.6"/>
		</g>
	</g>
                                    <g>
		<polyline className="st14" points="1041.9,313.8 1052.4,318.4 1062.9,313.3 1073.4,318.4 1083.9,312.3 		"/>
                                        <g>
			<g>
				<polyline className="st14" points="1033.6,310.7 1033.6,343 1091.7,343 				"/>
                <g>
					<line className="st13" x1="1041.9" y1="338.1" x2="1041.9" y2="319.8"/>
                    <line className="st13" x1="1052.4" y1="338.1" x2="1052.4" y2="324.4"/>
                    <line className="st13" x1="1062.9" y1="338.1" x2="1062.9" y2="319.3"/>
                    <line className="st13" x1="1073.4" y1="338.1" x2="1073.4" y2="324.4"/>
                    <line className="st13" x1="1083.9" y1="338.1" x2="1083.9" y2="318.3"/>
				</g>
			</g>
                                            <g>
				<circle className="st5" cx="1041.9" cy="313.8" r="2.6"/>
                                                <circle className="st5" cx="1052.4" cy="318.4" r="2.6"/>
                                                <circle className="st5" cx="1062.9" cy="313.3" r="2.6"/>
                                                <circle className="st5" cx="1073.4" cy="318.4" r="2.6"/>
                                                <circle className="st5" cx="1083.9" cy="312.3" r="2.6"/>
			</g>
		</g>
	</g>
                                    <path className="st5"
                                          d="M1235.6,242.8c0.1-0.1,0.1-0.2,0.2-0.3C1235.7,242.5,1235.6,242.6,1235.6,242.8z"/>
                                    <path className="st5"
                                          d="M1236.3,238.5c0-0.1,0-0.2-0.1-0.4C1236.3,238.3,1236.3,238.4,1236.3,238.5z"/>
                                    <path className="st5"
                                          d="M1236.3,240.5c0-0.1,0-0.2,0.1-0.4C1236.3,240.2,1236.3,240.4,1236.3,240.5z"/>
                                    <path className="st5"
                                          d="M1236.1,241.3c0-0.1,0.1-0.3,0.1-0.4C1236.2,241,1236.1,241.2,1236.1,241.3z"/>
                                    <path className="st5"
                                          d="M1234.5,234.3c-0.2-0.2-0.3-0.4-0.5-0.6C1234.2,233.8,1234.4,234,1234.5,234.3z"/>
                                    <path className="st5"
                                          d="M1236.2,237.8c0-0.1-0.1-0.3-0.1-0.4C1236.1,237.5,1236.2,237.6,1236.2,237.8z"/>
                                    <path className="st5"
                                          d="M1236,237c0-0.1-0.1-0.3-0.1-0.4C1235.9,236.7,1236,236.9,1236,237z"/>
                                    <path className="st5"
                                          d="M1235.4,235.6c-0.1-0.1-0.1-0.2-0.2-0.3C1235.3,235.3,1235.3,235.4,1235.4,235.6z"/>
                                    <path className="st5"
                                          d="M1235,234.9c-0.1-0.1-0.2-0.2-0.3-0.3C1234.8,234.6,1234.9,234.8,1235,234.9z"/>
                                    <path className="st5"
                                          d="M1235.7,236.3c0-0.1-0.1-0.2-0.2-0.3C1235.6,236,1235.7,236.1,1235.7,236.3z"/>
                                    <path className="st5"
                                          d="M1235.9,242c0-0.1,0.1-0.3,0.1-0.4C1236,241.8,1235.9,241.9,1235.9,242z"/>
                                    <path className="st5"
                                          d="M1231.4,246.7c0.1,0,0.2-0.1,0.3-0.2C1231.6,246.6,1231.5,246.7,1231.4,246.7z"/>
                                    <path className="st5"
                                          d="M1235.2,243.4c0.1-0.1,0.1-0.2,0.2-0.3C1235.3,243.2,1235.3,243.3,1235.2,243.4z"/>
                                    <path className="st5"
                                          d="M1230.7,247c0.1,0,0.3-0.1,0.4-0.1C1230.9,246.9,1230.8,247,1230.7,247z"/>
                                    <path className="st5"
                                          d="M1229.9,247.2c0.1,0,0.3-0.1,0.4-0.1C1230.2,247.1,1230,247.2,1229.9,247.2z"/>
                                    <path className="st5"
                                          d="M1232.1,246.4c0.1-0.1,0.2-0.1,0.3-0.2C1232.3,246.3,1232.2,246.3,1232.1,246.4z"/>
                                    <path className="st5"
                                          d="M1234.7,244.1c0.1-0.1,0.2-0.2,0.3-0.3C1234.9,243.9,1234.8,244,1234.7,244.1z"/>
                                    <path className="st5"
                                          d="M1234,245c0.2-0.2,0.4-0.4,0.5-0.6C1234.4,244.6,1234.2,244.8,1234,245z"/>
                                    <path className="st5"
                                          d="M1233.4,245.5c0.2-0.2,0.4-0.3,0.6-0.5C1233.8,245.2,1233.6,245.4,1233.4,245.5z"/>
                                    <path className="st5"
                                          d="M1232.8,246c0.1-0.1,0.2-0.2,0.3-0.3C1233,245.8,1232.9,245.9,1232.8,246z"/>
                                    <path className="st5"
                                          d="M1229.1,247.3c0.1,0,0.2,0,0.4-0.1C1229.4,247.3,1229.2,247.3,1229.1,247.3z"/>
                                    <path className="st5"
                                          d="M1224.9,246.6c0.1,0.1,0.2,0.1,0.3,0.2C1225.1,246.7,1225,246.6,1224.9,246.6z"/>
                                    <path className="st5"
                                          d="M1225.6,246.9c0.1,0,0.3,0.1,0.4,0.1C1225.9,247,1225.7,246.9,1225.6,246.9z"/>
                                    <path className="st5"
                                          d="M1227.5,247.3c-0.1,0-0.2,0-0.3-0.1C1227.3,247.3,1227.4,247.3,1227.5,247.3z"/>
                                    <path className="st5"
                                          d="M1226.4,247.1c0.1,0,0.3,0.1,0.4,0.1C1226.6,247.2,1226.5,247.1,1226.4,247.1z"/>
                                    <path className="st5"
                                          d="M1224.2,246.2c0.1,0.1,0.2,0.1,0.3,0.2C1224.4,246.3,1224.3,246.3,1224.2,246.2z"/>
                                    <path className="st7"
                                          d="M1233.1,245.8c0.1-0.1,0.2-0.1,0.3-0.2C1233.3,245.6,1233.2,245.7,1233.1,245.8z"/>
                                    <path className="st7"
                                          d="M1232.4,246.2c0.1-0.1,0.2-0.1,0.4-0.2C1232.7,246.1,1232.5,246.1,1232.4,246.2z"/>
                                    <path className="st7"
                                          d="M1235.6,235.9c-0.1-0.1-0.1-0.2-0.2-0.4C1235.5,235.7,1235.5,235.8,1235.6,235.9z"/>
                                    <path className="st7"
                                          d="M1235,243.8c0.1-0.1,0.1-0.2,0.2-0.3C1235.1,243.6,1235.1,243.7,1235,243.8z"/>
                                    <path className="st7"
                                          d="M1235.4,243.1c0.1-0.1,0.1-0.2,0.2-0.4C1235.5,242.9,1235.5,243,1235.4,243.1z"/>
                                    <path className="st7"
                                          d="M1231.7,246.6c0.1-0.1,0.2-0.1,0.4-0.2C1232,246.5,1231.9,246.5,1231.7,246.6z"/>
                                    <path className="st7"
                                          d="M1229.1,247.3c-0.5,0.1-1.1,0.1-1.6,0c0.3,0,0.5,0,0.8,0C1228.6,247.4,1228.9,247.3,1229.1,247.3z"/>
                                    <path className="st7"
                                          d="M1229.5,247.3c0.1,0,0.3,0,0.4-0.1C1229.8,247.2,1229.6,247.2,1229.5,247.3z"/>
                                    <path className="st7"
                                          d="M1230.3,247.1c0.1,0,0.2-0.1,0.4-0.1C1230.5,247,1230.4,247.1,1230.3,247.1z"/>
                                    <path className="st7"
                                          d="M1231,246.9c0.1,0,0.2-0.1,0.4-0.1C1231.3,246.8,1231.2,246.8,1231,246.9z"/>
                                    <path className="st7"
                                          d="M1235.7,242.4c0-0.1,0.1-0.2,0.1-0.4C1235.8,242.2,1235.8,242.3,1235.7,242.4z"/>
                                    <path className="st7"
                                          d="M1235.2,235.2c-0.1-0.1-0.1-0.2-0.2-0.3C1235.1,235,1235.1,235.1,1235.2,235.2z"/>
                                    <path className="st7"
                                          d="M1234.5,244.4c0.1-0.1,0.1-0.2,0.2-0.3C1234.7,244.2,1234.6,244.3,1234.5,244.4z"/>
                                    <path className="st7"
                                          d="M1226,247c0.1,0,0.2,0.1,0.4,0.1C1226.2,247.1,1226.1,247,1226,247z"/>
                                    <path className="st7"
                                          d="M1224.2,246.2c-0.1,0-0.1-0.1-0.2-0.1h0C1224.1,246.2,1224.2,246.2,1224.2,246.2z"/>
                                    <path className="st7"
                                          d="M1236.3,239.3c0-0.3,0-0.5,0-0.8C1236.3,238.8,1236.3,239.1,1236.3,239.3z"/>
                                    <path className="st7"
                                          d="M1236.3,240.1c0-0.3,0-0.5,0-0.8C1236.3,239.6,1236.3,239.9,1236.3,240.1z"/>
                                    <path className="st7"
                                          d="M1225.3,246.7c0.1,0,0.2,0.1,0.4,0.1C1225.5,246.8,1225.4,246.8,1225.3,246.7z"/>
                                    <path className="st7"
                                          d="M1235.9,236.6c0-0.1-0.1-0.2-0.1-0.4C1235.8,236.4,1235.8,236.5,1235.9,236.6z"/>
                                    <path className="st7"
                                          d="M1224.5,246.4c0.1,0.1,0.2,0.1,0.4,0.2C1224.8,246.5,1224.7,246.5,1224.5,246.4z"/>
                                    <path className="st7"
                                          d="M1234.7,234.5c-0.1-0.1-0.1-0.2-0.2-0.3C1234.6,234.3,1234.7,234.4,1234.7,234.5z"/>
                                    <path className="st7"
                                          d="M1236.1,237.4c0-0.1-0.1-0.2-0.1-0.4C1236,237.1,1236.1,237.2,1236.1,237.4z"/>
                                    <path className="st7"
                                          d="M1236.3,238.2c0-0.1,0-0.3-0.1-0.4C1236.2,237.9,1236.2,238,1236.3,238.2z"/>
                                    <path className="st7"
                                          d="M1236,241.7c0-0.1,0.1-0.2,0.1-0.4C1236.1,241.4,1236,241.5,1236,241.7z"/>
                                    <path className="st7"
                                          d="M1226.8,247.2c0.1,0,0.3,0,0.4,0.1C1227,247.2,1226.9,247.2,1226.8,247.2z"/>
                                    <path className="st7"
                                          d="M1236.2,240.9c0-0.1,0-0.3,0.1-0.4C1236.2,240.6,1236.2,240.8,1236.2,240.9z"/>
                                    <path className="st7"
                                          d="M1222.7,245c0.2,0.2,0.4,0.4,0.6,0.5C1223,245.4,1222.8,245.2,1222.7,245z"/>
                                    <path className="st7"
                                          d="M1223.5,245.7c0.1,0.1,0.2,0.2,0.3,0.3C1223.8,245.9,1223.6,245.8,1223.5,245.7z"/>
                                    <path className="st7"
                                          d="M1224.1,246.1L1224.1,246.1c-0.1,0-0.1-0.1-0.2-0.1C1223.9,246,1224,246.1,1224.1,246.1z"/>
                                    <path className="st7"
                                          d="M1223.2,245.5c0.1,0.1,0.2,0.1,0.3,0.2C1223.4,245.7,1223.3,245.6,1223.2,245.5z"/>
                                    <g>
		<g>
			<g>
				<circle className="st5" cx="1227.3" cy="217" r="8"/>
                <path className="st7"
                      d="M1221.7,222.6c3.1,3.1,8.2,3.1,11.3,0c3.1-3.1,3.1-8.2,0-11.3l-5.7,5.7L1221.7,222.6z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="1207.3" cy="217" r="8"/>
				</g>
                <path className="st7" d="M1201.6,211.3c-3.1,3.1-3.1,8.2,0,11.3l5.7-5.7L1201.6,211.3z"/>
			</g>
            <g>
				<circle className="st7" cx="1187.2" cy="217" r="8"/>
                <path className="st5" d="M1192.9,211.3c-3.1-3.1-8.2-3.1-11.3,0l5.7,5.7L1192.9,211.3z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st5" cx="1207.3" cy="237.6" r="8"/>
                <path className="st7" d="M1207.3,245.6c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8v8V245.6z"/>
			</g>
                                            <g>
				<circle className="st3" cx="1227.3" cy="237.6" r="8"/>
                                                <path className="st7" d="M1227.3,237.6l5.7-5.7c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3
					c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.4
					c0,0.3,0,0.5,0,0.8s0,0.5,0,0.8c0,0.1,0,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4
					c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6
					c-0.2,0.2-0.4,0.4-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1
					c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0-0.4,0.1c-0.3,0-0.5,0-0.8,0
					c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1
					c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
					c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.4-0.3-0.6-0.5
					c-1.5-1.5-2.3-3.5-2.3-5.7H1227.3z"/>
			</g>
                                            <g>
				<circle className="st7" cx="1187.2" cy="237.6" r="8"/>
                                                <path className="st3" d="M1187.2,237.6l5.7,5.7c-0.2,0.2-0.4,0.4-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1
					c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0-0.4,0.1
					c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
					c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.4
					c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4
					c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
					c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6
					c1.5-1.5,3.5-2.3,5.7-2.3V237.6z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<circle className="st5" cx="1009.9" cy="287" r="8"/>
                <path className="st7"
                      d="M1004.2,281.3c3.1-3.1,8.2-3.1,11.3,0c3.1,3.1,3.1,8.2,0,11.3l-5.7-5.7L1004.2,281.3z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="989.8" cy="287" r="8"/>
				</g>
                <path className="st7" d="M984.2,292.6c-3.1-3.1-3.1-8.2,0-11.3l5.7,5.7L984.2,292.6z"/>
			</g>
            <g>
				<circle className="st7" cx="969.8" cy="287" r="8"/>
                <path className="st3" d="M975.4,292.6c-3.1,3.1-8.2,3.1-11.3,0l5.7-5.7L975.4,292.6z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st3" cx="989.8" cy="266.3" r="8"/>
                <path className="st7" d="M989.8,258.3c4.4,0,8,3.6,8,8s-3.6,8-8,8v-8V258.3z"/>
			</g>
                                            <g>
				<circle className="st7" cx="1009.9" cy="266.3" r="8"/>
                                                <path className="st5" d="M1009.9,266.3l5.7,5.7c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4
					c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4
					c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.6
					c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.4-0.2
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.4-0.1c-0.3,0-0.5,0-0.8,0
					c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1
					c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
					c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.5
					c-1.5,1.5-2.3,3.5-2.3,5.7H1009.9z"/>
			</g>
                                            <g>
				<circle className="st7" cx="969.8" cy="266.3" r="8"/>
                                                <path className="st5" d="M969.8,266.3l5.7-5.7c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1
					c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.4-0.1
					c-0.3,0-0.5,0-0.8,0s-0.5,0-0.8,0c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1
					c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.4
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4
					c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.4c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8
					c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.1
					c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c1.5,1.5,3.5,2.3,5.7,2.3
					V266.3z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<circle className="st3" cx="1115.4" cy="364" r="8"/>
                <path className="st2"
                      d="M1121.1,369.7c-3.1,3.1-8.2,3.1-11.3,0c-3.1-3.1-3.1-8.2,0-11.3l5.7,5.7L1121.1,369.7z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="1135.4" cy="364" r="8"/>
				</g>
                <path className="st2" d="M1141.1,358.3c3.1,3.1,3.1,8.2,0,11.3l-5.7-5.7L1141.1,358.3z"/>
			</g>
            <g>
				<circle className="st2" cx="1155.5" cy="364" r="8"/>
                <path className="st5" d="M1149.8,358.3c3.1-3.1,8.2-3.1,11.3,0l-5.7,5.7L1149.8,358.3z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st5" cx="1135.4" cy="384.7" r="8"/>
                <path className="st2" d="M1135.4,376.6c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8v-8V376.6z"/>
			</g>
                                            <g>
				<circle className="st2" cx="1155.5" cy="384.7" r="8"/>
                                                <path className="st5" d="M1155.5,384.7l5.7,5.7c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4
					c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4
					c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4
					c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.5-0.6
					c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.4-0.2
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.4-0.1c-0.3,0-0.5,0-0.8,0
					c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1
					c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
					c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.5
					c-1.5,1.5-2.3,3.5-2.3,5.7H1155.5z"/>
			</g>
                                            <g>
				<circle className="st2" cx="1115.4" cy="384.7" r="8"/>
                                                <path className="st3" d="M1115.4,384.7l5.7-5.7c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1
					c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.4-0.1
					c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1
					c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
					c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.4
					c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
					c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6
					c1.5,1.5,3.5,2.3,5.7,2.3V384.7z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st14" x1="1135.4" y1="217.7" x2="1135.4" y2="223.7"/>
                <line className="st14" x1="1135.4" y1="231.2" x2="1135.4" y2="237.2"/>
			</g>
            <g>
				<line className="st14" x1="1145.9" y1="227.3" x2="1139.2" y2="227.3"/>
                <line className="st14" x1="1131.7" y1="227.3" x2="1124.9" y2="227.3"/>
			</g>
            <g>
				<line className="st14" x1="1160.2" y1="240.9" x2="1153.4" y2="240.9"/>
                <line className="st14" x1="1145.9" y1="240.9" x2="1139.2" y2="240.9"/>
                <line className="st14" x1="1131.7" y1="240.9" x2="1124.9" y2="240.9"/>
                <line className="st14" x1="1117.5" y1="240.9" x2="1110.7" y2="240.9"/>
			</g>
            <g>
				<line className="st14" x1="1149.7" y1="231.2" x2="1149.7" y2="237.2"/>
                <line className="st14" x1="1121.2" y1="231.2" x2="1121.2" y2="237.2"/>
			</g>
		</g>

                                        <rect x="1131.7" y="210.3"
                                              transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2270.8984 428)"
                                              className="st16" width="7.4" height="7.4"/>
                                        <g>

				<rect x="1131.7" y="223.7" transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2270.8984 454.938)"
                      className="st16" width="7.4" height="7.4"/>

                                            <rect x="1145.9" y="223.7"
                                                  transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2299.3389 454.938)"
                                                  className="st18" width="7.4" height="7.4"/>

                                            <rect x="1117.5" y="223.7"
                                                  transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 2242.458 454.938)"
                                                  className="st16" width="7.4" height="7.4"/>
		</g>
                                        <g>

				<rect x="1131.7" y="237.2" transform="matrix(-1 -4.510493e-11 4.510493e-11 -1 2270.8984 481.876)"
                      className="st16" width="7.4" height="7.4"/>

                                            <rect x="1145.9" y="237.2"
                                                  transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2299.3389 481.876)"
                                                  className="st16" width="7.4" height="7.4"/>

                                            <rect x="1160.2" y="237.2"
                                                  transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2327.7795 481.876)"
                                                  className="st18" width="7.4" height="7.4"/>

                                            <rect x="1117.5" y="237.2"
                                                  transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2242.458 481.876)"
                                                  className="st18" width="7.4" height="7.4"/>

                                            <rect x="1103.3" y="237.2"
                                                  transform="matrix(-1 -4.498281e-11 4.498281e-11 -1 2214.0176 481.876)"
                                                  className="st16" width="7.4" height="7.4"/>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st14" x1="918" y1="266.9" x2="918" y2="272.9"/>
                <line className="st14" x1="918" y1="280.4" x2="918" y2="286.4"/>
			</g>
            <g>
				<line className="st14" x1="928.5" y1="276.4" x2="921.7" y2="276.4"/>
                <line className="st14" x1="914.3" y1="276.4" x2="907.5" y2="276.4"/>
			</g>
            <g>
				<line className="st14" x1="928.5" y1="290.1" x2="921.7" y2="290.1"/>
                <line className="st14" x1="914.3" y1="290.1" x2="907.5" y2="290.1"/>
			</g>
            <g>
				<line className="st14" x1="932.2" y1="280.4" x2="932.2" y2="286.4"/>
                <line className="st14" x1="903.8" y1="280.4" x2="903.8" y2="286.4"/>
			</g>
		</g>
                                        <g>

				<rect x="914.3" y="259.4" transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1836.0228 526.324)"
                      className="st19" width="7.4" height="7.4"/>
                                            <g>

					<rect x="914.3" y="272.9" transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1836.0228 553.262)"
                          className="st14" width="7.4" height="7.4"/>

                                                <rect x="928.5" y="272.9"
                                                      transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1864.4633 553.262)"
                                                      className="st19" width="7.4" height="7.4"/>

                                                <rect x="900.1" y="272.9"
                                                      transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1807.5823 553.262)"
                                                      className="st14" width="7.4" height="7.4"/>
			</g>
                                            <g>

					<rect x="914.3" y="286.4" transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1836.0228 580.2001)"
                          className="st14" width="7.4" height="7.4"/>

                                                <rect x="928.5" y="286.4"
                                                      transform="matrix(-1 -4.486068e-11 4.486068e-11 -1 1864.4633 580.2001)"
                                                      className="st14" width="7.4" height="7.4"/>

                                                <rect x="900.1" y="286.4"
                                                      transform="matrix(-1 -4.473856e-11 4.473856e-11 -1 1807.5823 580.2001)"
                                                      className="st19" width="7.4" height="7.4"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<line className="st13" x1="1081.5" y1="227.3" x2="1081.5" y2="213"/>
            <line className="st13" x1="1071.8" y1="227.3" x2="1071.8" y2="218.2"/>
            <line className="st13" x1="1062.2" y1="227.3" x2="1062.2" y2="210.8"/>
            <line className="st13" x1="1052.5" y1="227.3" x2="1052.5" y2="216.7"/>
            <line className="st13" x1="1042.8" y1="227.3" x2="1042.8" y2="213.5"/>
		</g>
                                        <g>
			<line className="st17" x1="1084.5" y1="230" x2="1084.5" y2="227.3"/>
                                            <line className="st17" x1="1074.8" y1="229.3" x2="1074.8" y2="227.3"/>
                                            <line className="st17" x1="1065.2" y1="236" x2="1065.2" y2="227.3"/>
                                            <line className="st17" x1="1055.5" y1="234.5" x2="1055.5" y2="227.3"/>
                                            <line className="st17" x1="1045.8" y1="241.7" x2="1045.8" y2="227.3"/>
		</g>
	</g>
                                    <g>
		<g>
			<rect x="961.5" y="214.1" className="st11" width="16" height="5"/>
            <g>
				<line className="st11" x1="982.8" y1="214.1" x2="1008.8" y2="214.1"/>
                <line className="st11" x1="982.8" y1="219.1" x2="1005.3" y2="219.1"/>
                <line className="st11" x1="982.8" y1="216.6" x2="1018.1" y2="216.6"/>
			</g>
		</g>
                                        <g>
			<rect x="961.5" y="224.8" className="st11" width="16" height="5"/>
                                            <g>
				<line className="st11" x1="982.8" y1="224.8" x2="1012.2" y2="224.8"/>
                                                <line className="st11" x1="982.8" y1="229.8" x2="1003.1" y2="229.8"/>
                                                <line className="st11" x1="982.8" y1="227.3" x2="999.6" y2="227.3"/>
			</g>
		</g>
                                        <g>
			<rect x="961.5" y="235.5" className="st11" width="16" height="5"/>
                                            <g>
				<line className="st11" x1="982.8" y1="235.5" x2="1014.9" y2="235.5"/>
                                                <line className="st11" x1="982.8" y1="240.5" x2="1016.7" y2="240.5"/>
                                                <line className="st11" x1="982.8" y1="238" x2="1012.2" y2="238"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>

				<rect x="1147.8" y="312.8" transform="matrix(-1 -4.484958e-11 4.484958e-11 -1 2311.5134 630.5092)"
                      className="st19" width="16" height="5"/>
            <g>
				<line className="st14" x1="1142.5" y1="312.8" x2="1111.4" y2="312.8"/>
                <line className="st14" x1="1142.5" y1="317.8" x2="1109.9" y2="317.8"/>
                <line className="st14" x1="1142.5" y1="315.3" x2="1107.1" y2="315.3"/>
			</g>
		</g>
                                        <g>

				<rect x="1147.8" y="323.5" transform="matrix(-1 -4.484958e-11 4.484958e-11 -1 2311.5134 651.9593)"
                      className="st14" width="16" height="5"/>
                                            <g>
				<line className="st14" x1="1142.5" y1="323.5" x2="1113" y2="323.5"/>
                                                <line className="st14" x1="1142.5" y1="328.5" x2="1109.2" y2="328.5"/>
                                                <line className="st14" x1="1142.5" y1="326" x2="1110.7" y2="326"/>
			</g>
		</g>
                                        <g>

				<rect x="1147.8" y="334.2" transform="matrix(-1 -4.490642e-11 4.490642e-11 -1 2311.5134 673.4094)"
                      className="st19" width="16" height="5"/>
                                            <g>
				<line className="st14" x1="1142.5" y1="334.2" x2="1117.4" y2="334.2"/>
                                                <line className="st14" x1="1142.5" y1="339.2" x2="1111.5" y2="339.2"/>
                                                <line className="st14" x1="1142.5" y1="336.7" x2="1108" y2="336.7"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<polygon className="st16" points="889,214.6 947,214.6 947,214.6 889,214.6 			"/>
            <polygon className="st16" points="889,223 947,223 947,223 889,223 			"/>
            <polygon className="st16" points="889,231.5 947,231.5 947,231.5 889,231.5 			"/>
            <polygon className="st16" points="889,240 947,240 947,240 889,240 			"/>
		</g>
                                        <g>
			<circle className="st5" cx="939.8" cy="214.6" r="2.6"/>
                                            <circle className="st5" cx="916.4" cy="223" r="2.6"/>
                                            <circle className="st5" cx="929.3" cy="231.5" r="2.6"/>
                                            <circle className="st5" cx="900.2" cy="240" r="2.6"/>
		</g>
	</g>
                                    <g>
		<g>
			<polygon className="st11" points="1178.2,361.6 1236.3,361.6 1236.3,361.6 1178.2,361.6 			"/>
            <polygon className="st11" points="1178.2,370.1 1236.3,370.1 1236.3,370.1 1178.2,370.1 			"/>
            <polygon className="st11" points="1178.2,378.6 1236.3,378.6 1236.3,378.6 1178.2,378.6 			"/>
            <polygon className="st11" points="1178.2,387 1236.3,387 1236.3,387 1178.2,387 			"/>
		</g>
                                        <g>
			<circle className="st3" cx="1185" cy="361.6" r="2.6"/>
                                            <circle className="st3" cx="1198.7" cy="370.1" r="2.6"/>
                                            <circle className="st3" cx="1229.5" cy="378.6" r="2.6"/>
                                            <circle className="st3" cx="1212.4" cy="387" r="2.6"/>
		</g>
	</g>
                                    <g>
		<polygon className="st14" points="1106.4,263.9 1164.5,263.9 1164.5,263.9 1106.4,263.9 		"/>
                                        <polygon className="st14"
                                                 points="1106.4,272.4 1164.5,272.4 1164.5,272.4 1106.4,272.4 		"/>
                                        <polygon className="st14"
                                                 points="1106.4,280.9 1164.5,280.9 1164.5,280.9 1106.4,280.9 		"/>
                                        <polygon className="st14"
                                                 points="1106.4,289.3 1164.5,289.3 1164.5,289.3 1106.4,289.3 		"/>
	</g>
                                    <circle className="st8" cx="1118.2" cy="263.9" r="2.6"/>
                                    <circle className="st8" cx="1133.9" cy="272.4" r="2.6"/>
                                    <circle className="st8" cx="1156.7" cy="280.9" r="2.6"/>
                                    <circle className="st8" cx="1128.6" cy="289.3" r="2.6"/>
                                    <g>
		<g>
			<g>
				<line className="st17" x1="1188.4" y1="276.5" x2="1188.4" y2="264.2"/>
                <line className="st17" x1="1198.1" y1="276.5" x2="1198.1" y2="263.4"/>
                <line className="st17" x1="1207.7" y1="276.5" x2="1207.7" y2="265"/>
                <line className="st17" x1="1217.4" y1="276.5" x2="1217.4" y2="260.9"/>
                <line className="st17" x1="1227.1" y1="276.5" x2="1227.1" y2="267.7"/>
			</g>
            <g>
				<line className="st20" x1="1185.4" y1="281.2" x2="1185.4" y2="276.5"/>
                <line className="st20" x1="1195.1" y1="282.5" x2="1195.1" y2="276.5"/>
                <line className="st20" x1="1204.7" y1="279.2" x2="1204.7" y2="276.5"/>
                <line className="st20" x1="1214.4" y1="277.7" x2="1214.4" y2="276.5"/>
                <line className="st20" x1="1224.1" y1="285.9" x2="1224.1" y2="276.5"/>
			</g>
		</g>
                                        <g>
			<line className="st16" x1="1178.2" y1="259.5" x2="1178.2" y2="293.9"/>
                                            <line className="st16" x1="1178.2" y1="276.5" x2="1236.3" y2="276.5"/>
		</g>
	</g>
                                    <g>
		<g>
			<g>
				<line className="st17" x1="971" y1="325.8" x2="971" y2="316.5"/>
                <line className="st17" x1="980.6" y1="325.8" x2="980.6" y2="317.7"/>
                <line className="st17" x1="990.3" y1="325.8" x2="990.3" y2="312.3"/>
                <line className="st17" x1="1000" y1="325.8" x2="1000" y2="312.2"/>
                <line className="st17" x1="1009.6" y1="325.8" x2="1009.6" y2="314"/>
			</g>
            <g>
				<line className="st20" x1="968" y1="328.5" x2="968" y2="325.8"/>
                <line className="st20" x1="977.6" y1="333.8" x2="977.6" y2="325.8"/>
                <line className="st20" x1="987.3" y1="330.5" x2="987.3" y2="325.8"/>
                <line className="st20" x1="997" y1="328" x2="997" y2="325.8"/>
                <line className="st20" x1="1006.6" y1="328.2" x2="1006.6" y2="325.8"/>
			</g>
		</g>
                                        <g>
			<line className="st16" x1="960.8" y1="308.8" x2="960.8" y2="343.2"/>
                                            <line className="st16" x1="960.8" y1="325.8" x2="1018.8" y2="325.8"/>
		</g>
	</g>
                                    <g>
		<line className="st14" x1="1033.6" y1="210.3" x2="1033.6" y2="244.7"/>
                                        <line className="st14" x1="1033.6" y1="227.3" x2="1091.7" y2="227.3"/>
	</g>
                                    <g>
		<g>
			<g>
				<g>
					<path className="st1" d="M474.6,601l-5.7-24.9h-15.5l-5.7,24.9c-1.6,6.9-7.7,11.8-14.8,11.8l0,0v4.7h56.6v-4.7h0
						C482.3,612.8,476.2,607.9,474.6,601z"/>
                    <rect x="432.8" y="612.8" className="st2" width="56.6" height="4.7"/>
                    <path className="st3" d="M549.3,584.7H372.9c-6.2,0-11.1-5-11.1-11.1v-95.4c0-6.2,5-11.1,11.1-11.1h176.4c6.2,0,11.1,5,11.1,11.1
						v95.4C560.5,579.7,555.5,584.7,549.3,584.7z"/>
                    <path className="st2" d="M550,577.7H372.2c-2.1,0-3.8-1.7-3.8-3.8v-96.3c0-2.1,1.7-3.8,3.8-3.8H550c2.1,0,3.8,1.7,3.8,3.8v96.3
						C553.8,576,552.1,577.7,550,577.7z"/>
				</g>
                <g>
					<path className="st1" d="M228.6,601l-5.7-24.9h-15.5l-5.7,24.9c-1.6,6.9-7.7,11.8-14.8,11.8h0v4.7h56.6v-4.7h0
						C236.3,612.8,230.2,607.9,228.6,601z"/>
                    <rect x="186.8" y="612.8" className="st2" width="56.6" height="4.7"/>
                    <path className="st3" d="M303.3,584.7H126.9c-6.2,0-11.1-5-11.1-11.1v-95.4c0-6.2,5-11.1,11.1-11.1h176.4c6.2,0,11.1,5,11.1,11.1
						v95.4C314.4,579.7,309.4,584.7,303.3,584.7z"/>
                    <path className="st5" d="M304,577.7H126.2c-2.1,0-3.8-1.7-3.8-3.8v-96.3c0-2.1,1.7-3.8,3.8-3.8H304c2.1,0,3.8,1.7,3.8,3.8v96.3
						C307.8,576,306.1,577.7,304,577.7z"/>
				</g>
			</g>
            <g>
				<rect x="54.6" y="624.9" className="st6" width="17.8" height="145.6"/>
                <rect x="603.8" y="624.9" className="st6" width="17.8" height="145.6"/>
                <rect x="54.6" y="617.5" className="st6" width="567" height="15"/>
			</g>
		</g>
                                        <g>
			<polyline className="st21" points="151.5,493.2 151.5,563.9 278.6,563.9 			"/>
                                            <g>
				<g>
					<g>
						<circle className="st7" cx="169.6" cy="522" r="5.8"/>
                        <circle className="st7" cx="192.6" cy="510.5" r="5.8"/>
                        <circle className="st7" cx="215.6" cy="492.4" r="5.8"/>
                        <circle className="st7" cx="238.6" cy="507.7" r="5.8"/>
                        <circle className="st7" cx="261.6" cy="492.4" r="5.8"/>
					</g>
                    <polyline className="st21"
                              points="169.6,522 192.6,510.5 215.6,492.4 238.6,507.7 261.6,492.4 					"/>
				</g>
                                                <g>
					<line className="st22" x1="169.6" y1="553.2" x2="169.6" y2="535.1"/>
                                                    <line className="st22" x1="192.6" y1="553.2" x2="192.6" y2="523.6"/>
                                                    <line className="st22" x1="215.6" y1="553.2" x2="215.6" y2="505.6"/>
                                                    <line className="st22" x1="238.6" y1="553.2" x2="238.6" y2="521"/>
                                                    <line className="st22" x1="261.6" y1="553.2" x2="261.6" y2="505.6"/>
				</g>
			</g>
		</g>
                                        <g>
			<g>
				<g>
					<line className="st23" x1="461.2" y1="503" x2="461.2" y2="516.9"/>
                    <line className="st23" x1="461.2" y1="533.9" x2="461.2" y2="547.8"/>
				</g>
                <g>
					<line className="st23" x1="437.1" y1="525" x2="452.7" y2="525"/>
                    <line className="st23" x1="469.8" y1="525" x2="485.4" y2="525"/>
				</g>
                <g>
					<line className="st23" x1="404.5" y1="556.3" x2="420" y2="556.3"/>
                    <line className="st23" x1="437.1" y1="556.3" x2="452.7" y2="556.3"/>
                    <line className="st23" x1="469.8" y1="556.3" x2="485.4" y2="556.3"/>
                    <line className="st23" x1="502.5" y1="556.3" x2="518" y2="556.3"/>
				</g>
                <g>
					<line className="st23" x1="428.6" y1="533.9" x2="428.6" y2="547.8"/>
                    <line className="st23" x1="493.9" y1="533.9" x2="493.9" y2="547.8"/>
				</g>
			</g>
                                            <rect x="452.7" y="485.9" className="st24" width="17.1" height="17.1"/>
                                            <g>
				<rect x="452.7" y="516.9" className="st24" width="17.1" height="17.1"/>
                                                <rect x="420" y="516.9" className="st25" width="17.1" height="17.1"/>
                                                <rect x="485.4" y="516.9" className="st24" width="17.1" height="17.1"/>
			</g>
                                            <g>
				<rect x="452.7" y="547.8" className="st24" width="17.1" height="17.1"/>
                                                <rect x="420" y="547.8" className="st24" width="17.1" height="17.1"/>
                                                <rect x="387.4" y="547.8" className="st25" width="17.1" height="17.1"/>
                                                <rect x="485.4" y="547.8" className="st25" width="17.1" height="17.1"/>
                                                <rect x="518" y="547.8" className="st24" width="17.1" height="17.1"/>
			</g>
		</g>
	</g>
                                    <g>
		<line className="st21" x1="866.8" y1="556.3" x2="749.6" y2="556.3"/>
                                        <line className="st21" x1="860.7" y1="548.3" x2="749.6" y2="548.3"/>
                                        <line className="st21" x1="843.1" y1="540.4" x2="749.6" y2="540.4"/>
                                        <line className="st21" x1="822.5" y1="532.4" x2="749.6" y2="532.4"/>
                                        <line className="st21" x1="823.1" y1="524.4" x2="749.6" y2="524.4"/>
                                        <line className="st21" x1="775.1" y1="516.4" x2="749.6" y2="516.4"/>
                                        <line className="st21" x1="793.9" y1="508.4" x2="749.6" y2="508.4"/>
                                        <line className="st21" x1="838.8" y1="500.4" x2="749.6" y2="500.4"/>
                                        <line className="st21" x1="855.2" y1="492.4" x2="749.6" y2="492.4"/>
	</g>
                                    <g>
		<g>
			<g>
				<circle className="st5" cx="1023.1" cy="546.9" r="17"/>
                <path className="st7" d="M1035.2,534.9c-6.6-6.6-17.4-6.6-24,0s-6.6,17.4,0,24l12-12L1035.2,534.9z"/>
			</g>
            <g>
				<g>
					<circle className="st3" cx="1065.7" cy="546.9" r="17"/>
				</g>
                <path className="st7" d="M1077.7,558.9c6.6-6.6,6.6-17.4,0-24l-12,12L1077.7,558.9z"/>
			</g>
            <g>
				<circle className="st7" cx="1108.2" cy="546.9" r="17"/>
                <path className="st3" d="M1096.2,558.9c6.6,6.6,17.4,6.6,24,0l-12-12L1096.2,558.9z"/>
			</g>
		</g>
                                        <g>
			<g>
				<circle className="st3" cx="1065.7" cy="503.1" r="17"/>
                <path className="st7" d="M1065.7,486.1c-9.4,0-17,7.6-17,17c0,9.4,7.6,17,17,17v-17V486.1z"/>
			</g>
                                            <g>
				<circle className="st7" cx="1023.1" cy="503.1" r="17"/>
                                                <path className="st5" d="M1023.1,503.1l-12,12c-0.4-0.4-0.8-0.8-1.1-1.2c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.5-0.5-0.7
					c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.4-0.8c-0.1-0.2-0.2-0.5-0.3-0.7
					c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.2-0.6-0.2-0.8
					c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.1-1.7s0-1.1,0.1-1.7c0-0.3,0.1-0.5,0.1-0.8
					c0-0.3,0.1-0.6,0.1-0.9c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.5,0.3-0.8
					c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.3-0.5,0.4-0.7c0.2-0.3,0.4-0.5,0.5-0.7
					c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.7-0.8,1.1-1.2c0.4-0.4,0.8-0.8,1.2-1.1c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.5-0.4,0.7-0.5
					c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.3,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.5-0.2,0.8-0.3
					c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.1,0.8-0.2c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.5-0.1,0.7-0.1
					c0.6-0.1,1.1-0.1,1.7-0.1c0.6,0,1.1,0,1.7,0.1c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.6,0.1,0.9,0.1c0.3,0.1,0.6,0.1,0.8,0.2
					c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.2,0.8,0.4
					c0.2,0.1,0.5,0.3,0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.4,0.3,0.6,0.5
					c0.4,0.4,0.8,0.7,1.2,1.1c3.1,3.1,5,7.3,5,12H1023.1z"/>
			</g>
                                            <g>
				<circle className="st7" cx="1108.2" cy="503.1" r="17"/>
                                                <path className="st5" d="M1108.2,503.1l-12-12c0.4-0.4,0.8-0.8,1.2-1.1c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.5-0.4,0.7-0.5
					c0.2-0.2,0.5-0.3,0.7-0.4c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.5-0.2,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.8-0.3
					c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.2,0.8-0.2c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.5-0.1,0.8-0.1
					c0.6-0.1,1.1-0.1,1.7-0.1c0.6,0,1.1,0,1.7,0.1c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,0.9,0.1c0.3,0.1,0.6,0.1,0.8,0.2
					c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.2,0.8,0.4
					c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.5,0.3,0.7,0.4c0.3,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.4,0.4,0.8,0.7,1.2,1.1
					c0.4,0.4,0.8,0.8,1.1,1.2c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.5,0.5,0.7c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.2,0.3,0.4,0.4,0.7
					c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.5,0.2,0.8
					c0.1,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0.1,0.5,0.1,0.7c0.1,0.6,0.1,1.1,0.1,1.7c0,0.6,0,1.1-0.1,1.7
					c0,0.2-0.1,0.5-0.1,0.7c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8
					c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.2-0.3,0.5-0.4,0.7
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.2-0.3,0.4-0.5,0.6
					c-0.4,0.4-0.7,0.8-1.1,1.2c-3.1,3.1-7.3,5-12,5V503.1z"/>
			</g>
		</g>
	</g>
                                    <g>
		<g>
			<path className="st26" d="M339.6,522.5h-35c-4.3,0-7.8-3.5-7.8-7.8V483c0-10,8.1-18.1,18.1-18.1h14.5c10,0,18.1,8.1,18.1,18.1v31.7
				C347.5,519,343.9,522.5,339.6,522.5z"/>
            <path className="st26"
                  d="M347.5,508.9L347.5,508.9l0-18.8h0c3.6,0,6.5,2.9,6.5,6.5v5.7C354,506,351.1,508.9,347.5,508.9z"/>
            <path className="st27"
                  d="M296.9,490.1L296.9,490.1v18.8l0,0c-3.6,0-6.5-2.9-6.5-6.5v-5.7C290.3,493,293.3,490.1,296.9,490.1z"/>
            <path className="st28" d="M348.5,482h-24.3c-5.7,0-10.3-4.6-10.3-10.3V471c0-5.7,4.6-10.3,10.3-10.3h24.3c5.7,0,10.3,4.6,10.3,10.3
				v0.7C358.8,477.4,354.2,482,348.5,482z"/>
            <path className="st28" d="M329.8,512.7h-24.3c-9.8,0-17.7-7.9-17.7-17.7V483c0-9.8,7.9-17.7,17.7-17.7h24.3c9.8,0,17.7,7.9,17.7,17.7
				v12.1C347.5,504.8,339.5,512.7,329.8,512.7z"/>
            <path className="st29"
                  d="M297,521.4h50.3c5.1,0,9.2,4.1,9.2,9.2v7.4h-68.6v-7.4C287.8,525.5,291.9,521.4,297,521.4z"/>
		</g>
                                        <g>
			<g>
				<g>
					<path d="M304.3,770.6h-38.6v-12h26.7C298.9,758.6,304.3,764,304.3,770.6L304.3,770.6z"/>
                    <path
                        d="M268.6,753.8L268.6,753.8c-8.2,0-14.8,6.6-14.8,14.8v2h29.6v-2C283.4,760.4,276.8,753.8,268.6,753.8z"/>
				</g>
                <polygon className="st29" points="310.3,770.6 276.2,770.6 271.2,706.9 305.3,706.9 				"/>
			</g>
                                            <g>
				<g>
					<path d="M340.6,770.6h38.6v-12h-26.7C346,758.6,340.6,764,340.6,770.6L340.6,770.6z"/>
                    <path
                        d="M376.3,753.8L376.3,753.8c8.2,0,14.8,6.6,14.8,14.8v2h-29.6v-2C361.5,760.4,368.1,753.8,376.3,753.8z"/>
				</g>
                                                <polygon className="st29"
                                                         points="334.7,770.6 368.8,770.6 373.8,706.9 339.7,706.9 				"/>
			</g>
		</g>
                                        <path className="st30"
                                              d="M380.2,716.5H264.3c-6.3,0-11.4-5.1-11.4-11.4v0h138.6v0C391.6,711.4,386.5,716.5,380.2,716.5z"/>
                                        <g>
			<g>
				<circle className="st2" cx="371.8" cy="769.8" r="9"/>
                <path className="st30" d="M364,770.7h15.6c0.6,0,1.1-0.5,1.1-1.1V761c0-1.3-1-2.3-2.3-2.3h-13.3c-1.3,0-2.3,1-2.3,2.3v8.6
					C362.9,770.2,363.4,770.7,364,770.7z"/>
			</g>
                                            <line className="st31" x1="371.8" y1="760.9" x2="322.3" y2="748.9"/>
		</g>
                                        <rect x="316.3" y="680.1" className="st30" width="12" height="71.5"/>
                                        <g>
			<circle className="st2" cx="272.7" cy="769.8" r="9"/>
                                            <line className="st31" x1="272.7" y1="760.9" x2="322.3" y2="748.9"/>
                                            <path className="st30" d="M280.5,770.7h-15.6c-0.6,0-1.1-0.5-1.1-1.1V761c0-1.3,1-2.3,2.3-2.3h13.3c1.3,0,2.3,1,2.3,2.3v8.6
				C281.6,770.2,281.1,770.7,280.5,770.7z"/>
		</g>
                                        <g>
			<g>
				<circle className="st2" cx="322.3" cy="764.5" r="7.3"/>
                <path className="st30" d="M315.9,765.2h12.7c0.5,0,0.9-0.4,0.9-0.9v-7c0-1-0.8-1.9-1.9-1.9h-10.8c-1,0-1.9,0.8-1.9,1.9v7
					C315,764.8,315.4,765.2,315.9,765.2z"/>
			</g>
                                            <line className="st32" x1="322.3" y1="736.9" x2="322.3" y2="760.3"/>
		</g>
                                        <g>
			<path className="st3" d="M259.3,647.6h-15.6c-5.7,0-10.3-4.6-10.3-10.3l0,0h25.9V647.6z"/>
                                            <polygon className="st1"
                                                     points="248,637.2 233.5,637.2 247.2,624.6 261.7,624.6 			"/>
		</g>
                                        <g>
			<path className="st3" d="M385.2,647.6h15.6c5.7,0,10.3-4.6,10.3-10.3l0,0h-25.9V647.6z"/>
                                            <polygon className="st1"
                                                     points="396.6,637.2 411.1,637.2 397.4,624.6 382.8,624.6 			"/>
		</g>
                                        <g>
			<path className="st29" d="M281.6,611.9l-20,20c-7,7-18.4,7-25.5,0h0c-7-7-7-18.4,0-25.5l20-20c7-7,18.4-7,25.5,0h0
				C288.7,593.5,288.7,604.9,281.6,611.9z"/>
                                            <path className="st29" d="M362.6,611.9l20,20c7,7,18.4,7,25.5,0h0c7-7,7-18.4,0-25.5l-20-20c-7-7-18.4-7-25.5,0h0
				C355.6,593.5,355.6,604.9,362.6,611.9z"/>
		</g>
                                        <path className="st3"
                                              d="M391.6,705.1H253l-12-138.4c-1.6-17.9,12.6-33.3,30.6-33.3H373c18,0,32.1,15.4,30.6,33.3L391.6,705.1z"/>
	</g>
                                    <path className="st26" d="M959.8,522.5h-35c-4.3,0-7.8-3.5-7.8-7.8V483c0-10,8.1-18.1,18.1-18.1h14.5c10,0,18.1,8.1,18.1,18.1v31.7
		C967.7,519,964.2,522.5,959.8,522.5z"/>
                                    <path className="st26"
                                          d="M967.7,508.9L967.7,508.9v-18.8l0,0c3.6,0,6.5,2.9,6.5,6.5v5.7C974.2,506,971.3,508.9,967.7,508.9z"/>
                                    <path className="st26"
                                          d="M917.1,490.1L917.1,490.1l0,18.8h0c-3.6,0-6.5-2.9-6.5-6.5v-5.7C910.6,493,913.5,490.1,917.1,490.1z"/>
                                    <path className="st33" d="M952.2,516.9h-19.4c-9.8,0-17.7-7.9-17.7-17.7v-16.5c0-9.8,7.9-17.7,17.7-17.7h19.4c9.8,0,17.7,7.9,17.7,17.7
		v16.5C969.9,508.9,962,516.9,952.2,516.9z"/>
                                    <path className="st29"
                                          d="M917.2,521.4h50.3c5.1,0,9.2,4.1,9.2,9.2v7.4h-68.6v-7.4C908.1,525.5,912.2,521.4,917.2,521.4z"/>
                                    <g>
		<g>
			<g>
				<path d="M924.5,770.6h-38.6v-12h26.7C919.2,758.6,924.5,764,924.5,770.6L924.5,770.6z"/>
                <path
                    d="M888.9,753.8L888.9,753.8c-8.2,0-14.8,6.6-14.8,14.8v2h29.6v-2C903.7,760.4,897,753.8,888.9,753.8z"/>
			</g>
            <polygon className="st29" points="930.5,770.6 896.4,770.6 891.4,706.9 925.5,706.9 			"/>
		</g>
                                        <g>
			<g>
				<path d="M960.9,770.6h38.6v-12h-26.7C966.2,758.6,960.9,764,960.9,770.6L960.9,770.6z"/>
                <path
                    d="M996.5,753.8L996.5,753.8c8.2,0,14.8,6.6,14.8,14.8v2h-29.6v-2C981.8,760.4,988.4,753.8,996.5,753.8z"/>
			</g>
                                            <polygon className="st29"
                                                     points="954.9,770.6 989,770.6 994,706.9 959.9,706.9 			"/>
		</g>
	</g>
                                    <path className="st30"
                                          d="M1000.4,716.5H884.5c-6.3,0-11.4-5.1-11.4-11.4v0h138.6v0C1011.8,711.4,1006.7,716.5,1000.4,716.5z"/>
                                    <g>
		<g>
			<circle className="st2" cx="992.1" cy="769.8" r="9"/>
            <path className="st30" d="M984.3,770.7h15.6c0.6,0,1.1-0.5,1.1-1.1V761c0-1.3-1-2.3-2.3-2.3h-13.3c-1.3,0-2.3,1-2.3,2.3v8.6
				C983.1,770.2,983.6,770.7,984.3,770.7z"/>
		</g>
                                        <line className="st31" x1="992.1" y1="760.9" x2="942.5" y2="748.9"/>
	</g>
                                    <rect x="936.5" y="680.1" className="st30" width="12" height="71.5"/>
                                    <g>
		<circle className="st2" cx="892.9" cy="769.8" r="9"/>
                                        <line className="st31" x1="892.9" y1="760.9" x2="942.5" y2="748.9"/>
                                        <path className="st30" d="M900.7,770.7h-15.6c-0.6,0-1.1-0.5-1.1-1.1V761c0-1.3,1-2.3,2.3-2.3h13.3c1.3,0,2.3,1,2.3,2.3v8.6
			C901.9,770.2,901.4,770.7,900.7,770.7z"/>
	</g>
                                    <g>
		<g>
			<circle className="st2" cx="942.5" cy="764.5" r="7.3"/>
            <path className="st30" d="M936.2,765.2h12.7c0.5,0,0.9-0.4,0.9-0.9v-7c0-1-0.8-1.9-1.9-1.9h-10.8c-1,0-1.9,0.8-1.9,1.9v7
				C935.2,764.8,935.6,765.2,936.2,765.2z"/>
		</g>
                                        <line className="st32" x1="942.5" y1="736.9" x2="942.5" y2="760.3"/>
	</g>
                                    <g>
		<path className="st3" d="M879.6,647.6H864c-5.7,0-10.3-4.6-10.3-10.3l0,0h25.9V647.6z"/>
                                        <polygon className="st1"
                                                 points="868.2,637.2 853.7,637.2 867.4,624.6 881.9,624.6 		"/>
	</g>
                                    <g>
		<path className="st3" d="M1005.4,647.6h15.6c5.7,0,10.3-4.6,10.3-10.3l0,0h-25.9V647.6z"/>
                                        <polygon className="st1"
                                                 points="1016.8,637.2 1031.3,637.2 1017.6,624.6 1003.1,624.6 		"/>
	</g>
                                    <g>
		<path className="st29" d="M901.9,611.9l-20,20c-7,7-18.4,7-25.5,0l0,0c-7-7-7-18.4,0-25.5l20-20c7-7,18.4-7,25.5,0l0,0
			C908.9,593.5,908.9,604.9,901.9,611.9z"/>
                                        <path className="st29" d="M982.8,611.9l20,20c7,7,18.4,7,25.5,0h0c7-7,7-18.4,0-25.5l-20-20c-7-7-18.4-7-25.5,0l0,0
			C975.8,593.5,975.8,604.9,982.8,611.9z"/>
	</g>
                                    <path className="st3"
                                          d="M1011.8,705.1H873.2l-12-138.4c-1.6-17.9,12.6-33.3,30.6-33.3h101.5c18,0,32.1,15.4,30.6,33.3L1011.8,705.1z"
                                    />
</g>
                                <g id="text" className="st34">
                                    <g className="st35">
                                        <rect x="433.1" y="58" className="st36" width="413.9" height="388"/>
                                        <text transform="matrix(1 0 0 1 543.6295 74.9973)"><tspan x="0" y="0" className="st37 st38 st39">Вы в
                                            Центральном </tspan>
                                            <tspan x="-3.6" y="36" className="st37 st38 st39">пункте управления </tspan>
                                            <tspan x="-33.7" y="72" className="st37 st38 st39">МЛСП «Приразломная» – </tspan>
                                            <tspan x="-22.4" y="108" className="st37 st38 st39">это «мозг платформы»,</tspan>
                                            <tspan x="-12.9" y="144" className="st37 st38 st39">где происходит сбор</tspan>
                                            <tspan x="-19" y="180" className="st37 st38 st39">и анализ информации</tspan>
                                            <tspan x="-12.4" y="216" className="st37 st38 st39">о производственных</tspan>
                                            <tspan x="-58" y="252" className="st37 st38 st39">процессах и управление ими.   </tspan>
                                            <tspan x="-0.5" y="324" className="st40 st38 st39">Начинаем добычу!</tspan></text>
                                    </g>
                                </g>
                            </svg>

                            <svg id="level3Bg3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect x="-1280" className="st0" width="3840" height="800"/>
                                </g>
                                <g id="clouds">
                                    <g>
                                        <path className="st1" d="M1279.5,191.8h-61.7c-10.6,0-19.2-8.6-19.2-19.2v0c0-10.6,8.6-19.2,19.2-19.2h61.7c10.6,0,19.2,8.6,19.2,19.2
			v0C1298.7,183.2,1290.1,191.8,1279.5,191.8z"/>
                                        <circle className="st1" cx="1243.6" cy="153.4" r="19.4"/>
                                    </g>
                                    <path className="st2" d="M56.7,62H35.8c0-13.6-11-24.6-24.6-24.6s-24.6,11-24.6,24.6h-8.1c-13.4,0-24.3,10.9-24.3,24.3v0
		c0,13.4,10.9,24.3,24.3,24.3h78.2c13.4,0,24.3-10.9,24.3-24.3v0C81,72.9,70.1,62,56.7,62z"/>
                                    <g>
                                        <path className="st1" d="M1020.4,180.2h-65c-11.2,0-20.2-9-20.2-20.2v0c0-11.2,9-20.2,20.2-20.2h65c11.2,0,20.2,9,20.2,20.2v0
			C1040.6,171.1,1031.5,180.2,1020.4,180.2z"/>
                                        <circle className="st1" cx="982.6" cy="139.7" r="20.4"/>
                                    </g>
                                    <g>
                                        <path className="st1" d="M227,200.2h89.9c15.4,0,28-12.5,28-28v0c0-15.4-12.5-28-28-28H227c-15.4,0-28,12.5-28,28v0
			C199,187.7,211.6,200.2,227,200.2z"/>
                                        <circle className="st1" cx="276.9" cy="144.8" r="30.6"/>
                                    </g>
                                    <g>
                                        <path className="st1" d="M746.4,76.1H802c9.6,0,17.3-7.7,17.3-17.3v0c0-9.6-7.7-17.3-17.3-17.3h-55.6c-9.6,0-17.3,7.7-17.3,17.3v0
			C729.1,68.4,736.8,76.1,746.4,76.1z"/>
                                        <circle className="st1" cx="778.7" cy="41.5" r="17.5"/>
                                    </g>
                                </g>
                                <g id="MLSP_x5F_color">
                                    <polygon className="st3 fillOil" points="497.8,414.9 491.1,427.8 787.2,427.8 780.5,414.9 	"/>
                                    <rect x="786.2" y="264.4" className="st4" width="4.2" height="2.9"/>
                                    <line className="st5" x1="765.2" y1="299.9" x2="751.8" y2="310.2"/>
                                    <line className="st5" x1="766.2" y1="333" x2="801.8" y2="300.3"/>
                                    <line className="st5" x1="768.2" y1="323.8" x2="776.2" y2="323.8"/>
                                    <line className="st5" x1="768.2" y1="320.5" x2="801.8" y2="300.3"/>
                                    <line className="st5" x1="768.2" y1="317.5" x2="780.2" y2="300.3"/>
                                    <line className="st6" x1="751.1" y1="290" x2="764.5" y2="300.3"/>
                                    <line className="st6" x1="780.2" y1="288.8" x2="780.2" y2="300.3"/>
                                    <line className="st6" x1="782.4" y1="290" x2="795.8" y2="300.3"/>
                                    <line className="st6" x1="680.5" y1="290" x2="667.1" y2="300.3"/>
                                    <line className="st7" x1="778.1" y1="290" x2="764.7" y2="300.3"/>
                                    <line className="st8" x1="462.2" y1="257" x2="536.4" y2="267.6"/>
                                    <polygon className="st4"
                                             points="550.9,308 528.6,308 528.6,287.8 528.6,270.5 550.9,270.5 	"/>
                                    <polygon className="st9"
                                             points="544.7,270.5 534.9,270.5 536.6,248.3 543,248.3 	"/>
                                    <rect x="519.4" y="344.8" className="st4" width="239.6" height="24.7"/>
                                    <polygon className="st10"
                                             points="758.9,344.8 519.4,344.8 510.1,332.5 768.2,332.5 	"/>
                                    <rect x="510.1" y="308" className="st4" width="258.1" height="24.5"/>
                                    <rect x="619.7" y="282.7" className="st4" width="64.8" height="32.3"/>
                                    <rect x="574.4" y="267.6" className="st4" width="45.3" height="40.4"/>
                                    <g>
                                        <rect x="583.4" y="210.1" className="st11" width="27.3" height="57.5"/>
                                        <polygon className="st11"
                                                 points="604.4,168.4 589.8,168.4 583.4,210.1 610.7,210.1 		"/>
                                        <rect x="589.8" y="143" className="st11" width="14.6" height="25.4"/>
                                        <rect x="589.8" y="143" className="st4" width="14.6" height="6.8"/>
                                        <rect x="589.8" y="156.1" className="st4" width="14.6" height="6.8"/>
                                        <polygon className="st4"
                                                 points="605.5,176 588.6,176 589.6,169.3 604.5,169.3 		"/>
                                        <polygon className="st4"
                                                 points="607.5,189.2 586.6,189.2 587.6,182.4 606.5,182.4 		"/>
                                    </g>
                                    <polygon className="st8"
                                             points="447.8,250.4 528.6,287.8 528.6,298.7 447.8,254.4 	"/>
                                    <polygon className="st8" points="447.8,250.4 411,273.4 411,277.3 447.8,254.4 	"/>
                                    <g>
                                        <g>
                                            <rect x="619.7" y="275.7" className="st12" width="23.3" height="7"/>
                                            <g>
                                                <rect x="623.2" y="277.7" className="st13" width="2.8" height="2.8"/>
                                                <rect x="629.9" y="277.7" className="st13" width="2.8" height="2.8"/>
                                                <rect x="636.7" y="277.7" className="st13" width="2.8" height="2.8"/>
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="621.6" y="269.4" className="st14" width="18.8" height="6.2"/>

                                            <rect x="629.6" y="265.1"
                                                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 358.4349 903.5579)"
                                                  className="st12" width="2.8" height="14.9"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <rect x="649.9" y="275.7" className="st12" width="23.3" height="7"/>
                                            <g>
                                                <rect x="653.4" y="277.7" className="st13" width="2.8" height="2.8"/>
                                                <rect x="660.1" y="277.7" className="st13" width="2.8" height="2.8"/>
                                                <rect x="666.9" y="277.7" className="st13" width="2.8" height="2.8"/>
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="651.8" y="269.4" className="st14" width="18.8" height="6.2"/>

                                            <rect x="659.8" y="265.1"
                                                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 388.6223 933.7452)"
                                                  className="st12" width="2.8" height="14.9"/>
                                        </g>
                                    </g>
                                    <rect x="684.4" y="289.2" className="st15" width="53.1" height="25.7"/>
                                    <polygon className="st4"
                                             points="751.3,282.7 743.3,282.7 745.3,265.6 749.3,265.6 	"/>
                                    <polygon className="st14"
                                             points="733.9,289.2 723.8,289.2 726.1,262.7 731.6,262.7 	"/>
                                    <line className="st16" x1="759.6" y1="274" x2="755.1" y2="282.7"/>
                                    <line className="st16" x1="780.4" y1="233.8" x2="769.1" y2="255.6"/>
                                    <line className="st16" x1="788.4" y1="218.2" x2="783.2" y2="228.3"/>
                                    <line className="st16" x1="780.2" y1="226.7" x2="783.1" y2="221.2"/>
                                    <line className="st16" x1="766.1" y1="254" x2="777.3" y2="232.3"/>
                                    <line className="st16" x1="751.3" y1="282.7" x2="756.5" y2="272.5"/>
                                    <line className="st16" x1="765.6" y1="277.1" x2="760.1" y2="287.7"/>
                                    <line className="st16" x1="786.4" y1="236.9" x2="775.1" y2="258.6"/>
                                    <line className="st16" x1="772.1" y1="257.1" x2="783.3" y2="235.3"/>
                                    <line className="st16" x1="758.8" y1="282.7" x2="762.5" y2="275.5"/>
                                    <rect x="784.4" y="216.9" className="st9" width="7.9" height="3.9"/>
                                    <rect x="785.3" y="246.9" className="st4" width="6.1" height="7.1"/>
                                    <rect x="785.3" y="253.7" className="st9" width="6.1" height="11.1"/>
                                    <line className="st17" x1="769.1" y1="255.6" x2="759.6" y2="274"/>
                                    <line className="st17" x1="756.5" y1="272.5" x2="766.1" y2="254"/>
                                    <line className="st17" x1="775.1" y1="258.6" x2="765.6" y2="277.1"/>
                                    <line className="st17" x1="762.5" y1="275.5" x2="772.1" y2="257.1"/>
                                    <line className="st17" x1="783.2" y1="228.3" x2="780.4" y2="233.8"/>
                                    <line className="st17" x1="777.3" y1="232.3" x2="780.2" y2="226.7"/>
                                    <line className="st17" x1="783.3" y1="235.3" x2="786.2" y2="229.8"/>
                                    <rect x="778.8" y="220.2" className="st4" width="19.1" height="4.5"/>
                                    <rect x="783.1" y="224" className="st4" width="10.5" height="6.7"/>
                                    <rect x="785.3" y="230.4" className="st4" width="6.1" height="5.9"/>
                                    <rect x="785.3" y="236" className="st9" width="6.1" height="10.9"/>
                                    <line className="st16" x1="747.3" y1="266.3" x2="776" y2="234.7"/>
                                    <path className="st9"
                                          d="M816.9,290.7h-57.3v-3.9h57.3c1.1,0,1.9,0.9,1.9,1.9l0,0C818.8,289.8,817.9,290.7,816.9,290.7z"/>
                                    <rect x="619.7" y="282.7" className="st10" width="27.5" height="32.3"/>
                                    <rect x="522.3" y="316.6" className="st15" width="201.5" height="15.8"/>
                                    <rect x="738.2" y="282.7" className="st4" width="22.2" height="10"/>
                                    <polyline className="st15" points="723.8,332.5 723.8,322.1 732,322.1 	"/>
                                    <rect x="752.8" y="308" className="st18" width="15.3" height="24.5"/>
                                    <rect x="749.3" y="344.8" className="st18" width="9.6" height="24.7"/>
                                    <rect x="710.2" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="690.1" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="670" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="649.9" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="629.7" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="609.6" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="589.5" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="569.4" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="549.2" y="316.6" className="st18" width="1.6" height="15.8"/>
                                    <rect x="529.4" y="315.6" className="st4" width="6.5" height="10.4"/>
                                    <rect x="619.7" y="290.4" className="st15" width="7.9" height="24.5"/>
                                    <rect x="723.8" y="308" className="st4" width="22" height="12.1"/>
                                    <rect x="684.4" y="289.2" className="st18" width="39.4" height="25.7"/>
                                    <rect x="583.4" y="210.1" className="st19" width="27.2" height="0.7"/>
                                    <rect x="583.4" y="223.5" className="st19" width="27" height="2.2"/>
                                    <g>
                                        <rect x="586.4" y="210.4" className="st19" width="1.3" height="14.1"/>
                                        <rect x="590.4" y="210.4" className="st19" width="1.3" height="14.1"/>
                                        <rect x="594.3" y="210.4" className="st19" width="1.3" height="14.1"/>
                                        <rect x="598.3" y="210.4" className="st19" width="1.3" height="14.1"/>
                                        <rect x="602.3" y="210.4" className="st19" width="1.3" height="14.1"/>
                                        <rect x="606.3" y="210.4" className="st19" width="1.3" height="14.1"/>
                                    </g>
                                    <polygon className="st9"
                                             points="751.3,282.7 748.9,286.8 760.4,286.8 760.4,282.7 	"/>
                                    <line className="st6" x1="801.8" y1="300.3" x2="757" y2="300.3"/>
                                    <rect x="741.6" y="292.6" className="st9" width="15.3" height="15.3"/>
                                    <polygon className="st20"
                                             points="757,295.1 741.6,292.6 741.6,292.6 757,292.6 	"/>
                                    <polygon className="st20"
                                             points="757,302.1 741.6,299.6 741.6,299.6 757,299.6 	"/>
                                    <rect x="783.1" y="224.7" className="st15" width="10.5" height="1.7"/>
                                    <rect x="786.2" y="264.8" className="st15" width="4.2" height="0.8"/>
                                    <rect x="738.2" y="282.7" className="st21" width="22.2" height="1.5"/>
                                    <rect x="574.4" y="286.8" className="st15" width="45.3" height="3.6"/>
                                    <rect x="534" y="244.4" className="st4" width="11.6" height="7.3"/>
                                    <g>
                                        <line className="st22" x1="549.7" y1="140.2" x2="556.9" y2="128.3"/>
                                        <line className="st22" x1="564.1" y1="140.2" x2="556.9" y2="128.3"/>
                                        <line className="st22" x1="551.5" y1="118.8" x2="556.9" y2="112.4"/>
                                        <line className="st22" x1="562.3" y1="118.7" x2="556.9" y2="112.4"/>
                                        <line className="st22" x1="566.5" y1="169.2" x2="556.9" y2="154.9"/>
                                        <line className="st22" x1="547.3" y1="169.2" x2="556.9" y2="154.9"/>
                                        <line className="st22" x1="552.1" y1="112.5" x2="551.5" y2="118.8"/>
                                        <line className="st22" x1="562.3" y1="118.7" x2="561.7" y2="112.4"/>
                                        <line className="st22" x1="550.7" y1="128.3" x2="549.7" y2="140.2"/>
                                        <line className="st22" x1="564.1" y1="140.2" x2="563.1" y2="128.3"/>
                                        <line className="st22" x1="548.5" y1="154.9" x2="547.3" y2="169.2"/>
                                        <line className="st22" x1="566.5" y1="169.2" x2="565.3" y2="154.9"/>
                                        <polygon className="st22"
                                                 points="567.7,183.5 546.6,183.5 541.1,315.6 573.3,315.6 		"/>
                                        <line className="st22" x1="542" y1="293.9" x2="572.4" y2="293.9"/>
                                        <line className="st22" x1="542.9" y1="272.1" x2="571.5" y2="272.1"/>
                                        <line className="st22" x1="543.8" y1="250.4" x2="570.5" y2="250.4"/>
                                        <line className="st22" x1="544.7" y1="230.9" x2="569.8" y2="230.9"/>
                                        <line className="st22" x1="545.4" y1="212.7" x2="569" y2="212.7"/>
                                        <line className="st22" x1="546.1" y1="197.5" x2="568.3" y2="197.5"/>
                                        <line className="st22" x1="541.1" y1="315.6" x2="557.2" y2="293.9"/>
                                        <line className="st22" x1="573.3" y1="315.6" x2="557.2" y2="293.9"/>
                                        <line className="st22" x1="542" y1="293.9" x2="557.2" y2="272.1"/>
                                        <line className="st22" x1="572.4" y1="293.9" x2="557.2" y2="272.1"/>
                                        <line className="st22" x1="542.9" y1="272.1" x2="557.2" y2="250.4"/>
                                        <line className="st22" x1="571.5" y1="272.1" x2="557.2" y2="250.4"/>
                                        <line className="st22" x1="543.8" y1="250.4" x2="557.2" y2="230.9"/>
                                        <line className="st22" x1="570.5" y1="250.4" x2="557.2" y2="230.9"/>
                                        <line className="st22" x1="544.7" y1="230.9" x2="557.2" y2="212.7"/>
                                        <line className="st22" x1="569.8" y1="230.9" x2="557.2" y2="212.7"/>
                                        <line className="st22" x1="545.4" y1="212.7" x2="557.2" y2="197.5"/>
                                        <line className="st22" x1="569" y1="212.7" x2="557.2" y2="197.5"/>
                                        <line className="st22" x1="546.1" y1="197.5" x2="557.2" y2="183.5"/>
                                        <line className="st22" x1="568.3" y1="197.5" x2="557.2" y2="183.5"/>
                                        <polygon className="st8"
                                                 points="566.5,169.2 567.7,183.5 546.1,183.5 547.3,169.2 		"/>
                                        <polygon className="st8"
                                                 points="565.3,154.9 564.1,140.2 549.7,140.2 548.5,154.9 		"/>
                                        <polygon className="st8"
                                                 points="563.1,128.3 562.3,118.7 551.5,118.8 550.7,128.3 		"/>
                                        <polygon className="st8"
                                                 points="561.7,112.4 561,103.8 552.8,103.8 552.1,112.5 		"/>
                                        <line className="st8" x1="546.1" y1="183.5" x2="556.9" y2="169.2"/>
                                        <line className="st8" x1="567.7" y1="183.5" x2="556.9" y2="169.2"/>
                                        <line className="st8" x1="548.5" y1="154.9" x2="556.9" y2="140.2"/>
                                        <line className="st8" x1="565.3" y1="154.9" x2="556.9" y2="140.2"/>
                                        <line className="st8" x1="550.7" y1="128.3" x2="556.9" y2="118.7"/>
                                        <line className="st8" x1="563.1" y1="128.3" x2="556.9" y2="118.7"/>
                                        <line className="st8" x1="552.1" y1="112.5" x2="556.9" y2="103.8"/>
                                        <line className="st8" x1="561.7" y1="112.4" x2="556.9" y2="103.8"/>
                                    </g>
                                    <rect x="519.4" y="369.5" className="st11" width="239.6" height="4"/>
                                    <rect x="749.3" y="369.5" className="st11" width="9.6" height="4"/>
                                    <g>
                                        <path className="st1" d="M569.3,356.3c-0.1-1-0.1-2.2-0.1-3.1h0c-0.2,0.8-0.5,1.7-0.9,2.7l-1.3,3.5h-0.7l-1.2-3.4
			c-0.3-1-0.6-1.9-0.8-2.8h0c0,0.9-0.1,2.1-0.1,3.2l-0.2,3.1H563l0.5-7.2h1.2l1.2,3.5c0.3,0.9,0.5,1.7,0.7,2.4h0
			c0.2-0.7,0.4-1.5,0.8-2.4l1.3-3.5h1.2l0.4,7.2h-0.9L569.3,356.3z"/>
                                        <path className="st1" d="M578.8,352.3v7.2h-0.9v-6.4h-2.4v2.2c0,1.6-0.1,3.3-1.1,4c-0.3,0.2-0.7,0.3-1.1,0.3l-0.1-0.8
			c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.6,0.6-2,0.6-3.1v-3H578.8z"/>
                                        <path className="st1" d="M587.6,359.3c-0.3,0.2-1,0.3-1.9,0.3c-2,0-3.6-1.3-3.6-3.7c0-2.3,1.5-3.8,3.8-3.8c0.9,0,1.5,0.2,1.7,0.3
			l-0.2,0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-1.7,0-2.8,1.1-2.8,3c0,1.8,1,2.9,2.8,2.9c0.6,0,1.1-0.1,1.5-0.3L587.6,359.3z"/>
                                        <path className="st1" d="M596.2,352.3v7.2h-0.9v-6.4h-3.4v6.4h-0.9v-7.2H596.2z"/>
                                        <path className="st1" d="M604.6,356.8l1.4,2h-0.8l-1.4-2l1.4-2h0.8L604.6,356.8z M606.3,356.8l1.4,2H607l-1.4-2l1.4-2h0.8L606.3,356.8
			z"/>
                                        <path className="st1" d="M616.2,352.3v7.2h-0.9v-6.4h-3.4v6.4H611v-7.2H616.2z"/>
                                        <path className="st1" d="M620,352.4c0.5-0.1,1-0.1,1.8-0.1c0.9,0,1.6,0.2,2,0.6c0.4,0.3,0.6,0.9,0.6,1.5c0,0.7-0.2,1.2-0.6,1.5
			c-0.5,0.5-1.3,0.8-2.2,0.8c-0.3,0-0.5,0-0.7-0.1v2.9H620V352.4z M620.9,355.8c0.2,0.1,0.5,0.1,0.8,0.1c1.1,0,1.8-0.5,1.8-1.5
			c0-1-0.7-1.4-1.7-1.4c-0.4,0-0.7,0-0.9,0.1V355.8z"/>
                                        <path className="st1" d="M628.7,352.3v3c0,1.1,0,2.1-0.1,3.1l0,0c0.4-0.7,0.8-1.5,1.3-2.4l2.3-3.7h0.9v7.2h-0.9v-3.1
			c0-1.2,0-2,0.1-2.9l0,0c-0.4,0.8-0.8,1.6-1.3,2.4l-2.3,3.6h-1v-7.2H628.7z"/>
                                        <path className="st1" d="M637,352.4c0.5-0.1,1-0.1,1.8-0.1c0.9,0,1.6,0.2,2,0.6c0.4,0.3,0.6,0.9,0.6,1.5c0,0.7-0.2,1.2-0.6,1.5
			c-0.5,0.5-1.3,0.8-2.2,0.8c-0.3,0-0.5,0-0.7-0.1v2.9H637V352.4z M637.9,355.8c0.2,0.1,0.5,0.1,0.8,0.1c1.1,0,1.8-0.5,1.8-1.5
			c0-1-0.7-1.4-1.7-1.4c-0.4,0-0.7,0-0.9,0.1V355.8z"/>
                                        <path className="st1" d="M645.1,357.2l-0.7,2.3h-1l2.5-7.2h1.1l2.5,7.2h-1l-0.8-2.3H645.1z M647.5,356.5l-0.7-2.1
			c-0.2-0.5-0.3-0.9-0.4-1.3h0c-0.1,0.4-0.2,0.9-0.4,1.3l-0.7,2.1H647.5z"/>
                                        <path className="st1" d="M652.5,352.7c0.5-0.3,1.1-0.5,1.9-0.5c1.3,0,2.1,0.7,2.1,1.8c0,0.9-0.6,1.6-1.5,1.8v0c1,0.1,1.8,0.8,1.8,1.8
			c0,1.5-1.3,2.1-2.6,2.1c-0.7,0-1.3-0.1-1.9-0.5l0.2-0.7c0.4,0.2,1,0.4,1.6,0.4c1.1,0,1.7-0.6,1.7-1.4c0-1.1-1-1.4-2-1.4h-0.4v-0.7
			h0.4c1,0,1.7-0.6,1.7-1.3c0-0.7-0.5-1.1-1.3-1.1c-0.5,0-1.1,0.2-1.5,0.5L652.5,352.7z"/>
                                        <path className="st1" d="M664.9,352.3v7.2H664v-6.4h-2.4v2.2c0,1.6-0.1,3.3-1.1,4c-0.3,0.2-0.7,0.3-1.1,0.3l-0.1-0.8
			c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.6,0.6-2,0.6-3.1v-3H664.9z"/>
                                        <path className="st1" d="M674.8,355.8c0,2.5-1.5,3.8-3.4,3.8c-1.9,0-3.2-1.5-3.2-3.7c0-2.3,1.4-3.8,3.4-3.8
			C673.6,352.2,674.8,353.7,674.8,355.8z M669.2,355.9c0,1.5,0.8,2.9,2.3,2.9c1.5,0,2.3-1.4,2.3-3c0-1.4-0.8-2.9-2.3-2.9
			C670,352.9,669.2,354.3,669.2,355.9z"/>
                                        <path className="st1" d="M684.3,356.3c-0.1-1-0.1-2.2-0.1-3.1h0c-0.2,0.8-0.5,1.7-0.9,2.7l-1.3,3.5h-0.7l-1.2-3.4
			c-0.3-1-0.6-1.9-0.8-2.8h0c0,0.9-0.1,2.1-0.1,3.2l-0.2,3.1H678l0.5-7.2h1.2l1.2,3.5c0.3,0.9,0.5,1.7,0.7,2.4h0
			c0.2-0.7,0.4-1.5,0.8-2.4l1.3-3.5h1.2l0.5,7.2h-0.9L684.3,356.3z"/>
                                        <path className="st1"
                                              d="M689.9,352.3v3h3.5v-3h0.9v7.2h-0.9v-3.4h-3.5v3.4h-0.9v-7.2H689.9z"/>
                                        <path className="st1" d="M699.2,357.2l-0.8,2.3h-1l2.5-7.2h1.1l2.5,7.2h-1l-0.8-2.3H699.2z M701.6,356.5l-0.7-2.1
			c-0.2-0.5-0.3-0.9-0.4-1.3h0c-0.1,0.4-0.2,0.9-0.4,1.3l-0.7,2.1H701.6z"/>
                                        <path className="st1" d="M706.1,359.5c0.1-0.2,0.2-0.4,0.3-0.7c0.3-0.8,0.5-1.8,1.1-2.4c0.2-0.2,0.4-0.3,0.6-0.4v0
			c-0.9-0.1-1.6-0.8-1.6-1.8c0-0.6,0.3-1.1,0.7-1.5c0.5-0.4,1.2-0.6,2.1-0.6c0.6,0,1.3,0.1,1.7,0.2v7.1H710v-3.1h-0.7
			c-0.5,0-0.8,0.1-1,0.4c-0.5,0.5-0.7,1.6-1,2.2c-0.1,0.2-0.1,0.3-0.2,0.5H706.1z M710,353c-0.2,0-0.5-0.1-0.9-0.1
			c-0.9,0-1.7,0.4-1.7,1.3c0,0.9,0.8,1.4,1.8,1.4c0.3,0,0.6,0,0.8,0V353z"/>
                                        <path className="st1" d="M714.1,354.8h0.8l1.4,2l-1.4,2h-0.8l1.4-2L714.1,354.8z M715.9,354.8h0.8l1.4,2l-1.4,2h-0.8l1.4-2
			L715.9,354.8z"/>
                                    </g>
                                    <rect x="491.1" y="427.8" className="st23 fillOil" width="296.1" height="35.8"/>
                                    <g>
                                        <polygon className="st24"
                                                 points="758.9,373.5 519.4,373.5 497.8,414.9 780.5,414.9 		"/>
                                        <polygon className="st25"
                                                 points="758.9,373.5 749.3,373.5 770.9,414.9 780.5,414.9 		"/>
                                    </g>
                                    <polygon className="st26 fillOil"
                                             points="780.5,414.9 770.9,414.9 777.6,427.8 787.2,427.8 	"/>
                                    <polygon className="st27 fillOil"
                                             points="787.2,463.6 777.6,463.6 777.6,427.8 787.2,427.8 	"/>
                                </g>
                                <g id="fill_with_oil_x5F_01" className="st28">
                                    <polygon className="st29"
                                             points="758.9,373.5 749.3,373.5 519.4,373.5 497.8,414.9 770.9,414.9 780.5,414.9 	"/>
                                </g>
                                <g id="fill_with_oil_x5F_02">
                                    <polygon className="st30"
                                             points="770.9,414.9 780.5,414.9 767.7,390.3 510.6,390.3 497.8,414.9 	"/>
                                </g>
                                <g id="ground_1_">
                                    <rect x="-1280" y="463.6" className="st11" width="3840" height="242.7"/>
                                    <rect x="-1280" y="706.3" className="st31" width="3840" height="93.7"/>
                                </g>
                                <g id="tubes">
                                    <path className="st32" d="M630.9,463.6v229.5c0,33.1-26.9,60-60,60H-1280"/>
                                    <path className="st32" d="M649.8,463.6v244.5c0,33.1,26.9,60,60,60H2560"/>
                                </g>
                                <g id="oil">
                                    <path id="oil1" className="st33" d="M649.8,463.6v244.5c0,33.1,26.9,60,60,60H2560" style={{strokeDasharray: '1800 1800', strokeDashoffset: -1800}}/>
                                    <path id="oil2" className="st33" d="M630.9,463.6v229.5c0,33.1-26.9,60-60,60H-1280" style={{strokeDasharray: '1600 1600', strokeDashoffset: -1600}}/>
                                </g>
                                <g id="water">
                                    <rect x="-1280" y="382.1" className="st34" width="3840" height="81.5"/>
                                </g>
                            </svg>

                        </div>

                        <div id="level4" className="level">

                            <div id="scene41" className="scene">
                                <div className="sceneContent">
                                    <div className="level">Уровень 4</div>
                                    <div className="question">Как нефть <br/>попадает <br/>с платформы<br/> «Приразломная»<br/> на берег? <br/> Вы должны быть <br/> в курсе! </div>
                                    <button className="goAhead" onClick={this.goLevel41Scene1}>Далее</button>
                                </div>
                            </div>

                            <div id="scene42" className="scene">
                                <div className="sceneContent">

                                    <a id="level41Answer1" className="answer" href="#" onClick={this.doAnswerLevel41}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="options">
                                                    <circle className="cls-1" cx="130" cy="130" r="120"/>
                                                    <line className="cls-2" x1="229.96" y1="166.3" x2="30.04"
                                                          y2="166.3"/>
                                                    <line className="cls-2" x1="24.54" y1="145.9" x2="235.46"
                                                          y2="145.9"/>
                                                    <rect className="cls-3" x="187.87" y="139.95" width="12"
                                                          height="32.3" rx="6"/>
                                                    <rect className="cls-3" x="124" y="139.95" width="12" height="32.3"
                                                          rx="6"/>
                                                    <rect className="cls-3" x="60.13" y="139.95" width="12"
                                                          height="32.3" rx="6"/>
                                                    <path className="cls-2"
                                                          d="M194.15,62.35c0,10.19-9.6,10.19-9.6,20.38s9.6,10.2,9.6,20.39-9.6,10.19-9.6,20.39"/>
                                                    <path className="cls-2"
                                                          d="M175,50.35c0,10.19-9.6,10.19-9.6,20.38s9.6,10.2,9.6,20.39-9.6,10.19-9.6,20.39"/>
                                                    <path className="cls-2"
                                                          d="M155.75,62.35c0,10.19-9.6,10.19-9.6,20.38s9.6,10.2,9.6,20.39-9.6,10.19-9.6,20.39"/>
                                                    <path className="cls-2"
                                                          d="M136.33,217.06a19.61,19.61,0,0,1,39.22,0Z"/>
                                                    <path className="cls-2"
                                                          d="M119.74,89.33s-12,14.35-26.81,14.35-26.8-14.35-26.8-14.35S78.13,75,92.93,75,119.74,89.33,119.74,89.33Z"/>
                                                    <path className="cls-2"
                                                          d="M65.79,89.33S57.74,99,47.81,99V79.7C57.74,79.7,65.79,89.33,65.79,89.33Z"/>
                                                    <path className="cls-2"
                                                          d="M105.11,100.42s-8.39-3-8.39-11.22S105.11,78,105.11,78"/>
                                                    <line className="cls-2" x1="72.13" y1="197.45" x2="117.35"
                                                          y2="197.45"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>По подводным трубопроводам нефть поступает в береговые нефтехранилища, откуда потом направляется на реализацию</span>
                                        <div className="result">
                                            <p><strong>Увы, неверно.</strong><br/>Возможно Вы немного поторопились с ответом. Вы справитесь! Попробуйте еще раз! </p>
                                            <button>Заново</button>
                                        </div>
                                    </a>
                                    <a id="level41Answer2" className="answer" href="#" onClick={this.doAnswerLevel41}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="options">
                                                    <circle className="cls-1" cx="130" cy="130" r="120"/>
                                                    <circle className="cls-1" cx="130" cy="130" r="120"/>
                                                    <polygon className="cls-2"
                                                             points="200.89 178.82 56.3 178.82 44.3 146.42 176.89 146.42 191.29 130 222.49 130 222.49 146.42 200.8 166.55 200.89 178.82"/>
                                                    <polyline className="cls-2"
                                                              points="61.6 122.58 61.6 94.7 76.6 94.7 76.6 146.42"/>
                                                    <polyline className="cls-2"
                                                              points="101.53 146.42 101.53 133.1 121.93 133.1 121.93 146.42"/>
                                                    <polyline className="cls-2"
                                                              points="127.37 146.42 127.37 133.1 147.77 133.1 147.77 146.42"/>
                                                    <polyline className="cls-2"
                                                              points="153.2 146.42 153.2 133.1 173.6 133.1 173.6 146.42"/>
                                                    <polyline className="cls-2"
                                                              points="92 146.42 92 119.91 103.8 108.1 103.8 94.7 42.62 94.7 42.62 108.1 56.72 120.2 61.6 120.2 61.6 146.42"/>
                                                    <polyline className="cls-2"
                                                              points="222.45 77.04 174.4 62.35 174.4 73.9 231.53 96.5"/>
                                                    <line className="cls-2" x1="181.76" y1="76.92" x2="181.76"
                                                          y2="140.87"/>
                                                    <path className="cls-2"
                                                          d="M52,192.65c13.23,0,13.23,9.6,26.46,9.6s13.22-9.6,26.45-9.6,13.23,9.6,26.46,9.6,13.23-9.6,26.46-9.6,13.23,9.6,26.46,9.6,13.23-9.6,26.46-9.6"/>
                                                    <path className="cls-2"
                                                          d="M138.67,48.08H109.31a7.8,7.8,0,0,0-7,11.26H86.81a7.82,7.82,0,0,0,0,15.63h29.36a7.8,7.8,0,0,0,7-11.26h15.49a7.82,7.82,0,0,0,0-15.63Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Перекачка нефти из нефтехранилища платформы происходит с помощью комплексов устройств прямой отгрузки нефти прямо на танкеры</span>
                                        <div className="result">
                                            <p><strong>Верно!</strong><br/>Перекачка нефти из нефтехранилища платформы происходит с помощью двух комплексов устройств прямой отгрузки нефти (КУПОН) сразу на танкеры. Система КУПОН закачивает нефть насосом по большому прочному шлангу. Крановая система позволяет отгружать нефть на танкер, находящийся на расстоянии 80 ±6 м от борта платформы. Для безопасности танкеры оснащены системой динамического позиционирования, которая, несмотря на ветер и волны, позволяет удерживать их на месте</p>
                                            <button>Дальше</button>
                                        </div>
                                    </a>

                                </div>
                            </div>

                            <div id="scene43" className="scene">
                                <div className="sceneContent">
                                    <form id="level41Success" className="levelSuccess" onSubmit={this.startLevel42}>
                                        <p><span className="success">Поздравляем!</span><br/> Вы отлично <br/> справились! <br/> Играем <br/> дальше!</p>
                                        <button type="submit">Далее</button>
                                    </form>
                                </div>
                            </div>

                            <div id="scene44" className="scene">
                                <div className="sceneContent">
                                    <div className="question">
                                        Полностью загрузить танкер нефтью можно <br/>
                                        за 8-9 часов. Все это время работы рядом с <br/>
                                        платформой дежурят многофункциональные <br/>
                                        ледокольные суда обеспечения, <br/>
                                        оборудованные системой аварийного <br/>
                                        сбора нефти в случае ЧП.
                                    </div>
                                    <button className="goAhead" onClick={this.goLevel41Scene2}>Далее</button>
                                </div>
                            </div>

                            <div id="scene45" className="scene">
                                <div className="sceneContent">
                                    <div className="shipNavigation">
                                        <button className="goUp" onClick={this.goUp}></button>
                                        <button className="goDown" onClick={this.goDown}></button>
                                        <button className="goLeft" onClick={this.goLeft}></button>
                                        <button className="goRight" onClick={this.goRight}></button>
                                    </div>
                                </div>
                            </div>

                            <div id="scene46" className="scene">
                                <div className="sceneContent">
                                    <form id="level41Success" className="levelSuccess" onSubmit={this.startLevel51}>
                                        <p><span className="success">Поздравляем!</span><br/> Вы отлично <br/> справились!</p>
                                        <button type="submit">Далее</button>
                                    </form>
                                </div>
                            </div>

                            <svg id="level4Bg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect className="st0" width="1280" height="800"/>
                                </g>
                                <g id="men">
                                    <g id="legs">
                                        <g>
                                            <rect x="211.7" y="835.1" className="st1" width="123.4" height="382.6"/>
                                            <rect x="211.7" y="1135.4" className="st2" width="123.4" height="82.3"/>
                                            <rect x="211.7" y="1020.9" className="st3" width="123.4" height="35.1"/>
                                            <rect x="211.7" y="916.5" className="st2" width="123.4" height="104.5"/>
                                            <rect x="211.7" y="835.1" className="st4" width="123.4" height="27.5"/>
                                        </g>
                                        <g>

                                            <rect x="364.7" y="835.1" transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 852.9443 2052.821)"
                                                  className="st1" width="123.4" height="382.6"/>

                                            <rect x="364.7" y="1135.4"
                                                  transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 852.9443 2353.1072)"
                                                  className="st2" width="123.4" height="82.3"/>

                                            <rect x="364.7" y="1020.9"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 852.9443 2076.9272)"
                                                  className="st3" width="123.4" height="35.1"/>

                                            <rect x="364.7" y="916.5"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 852.9443 1937.364)"
                                                  className="st2" width="123.4" height="104.5"/>

                                            <rect x="364.7" y="835.1"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 852.9443 1697.7552)"
                                                  className="st4" width="123.4" height="27.5"/>
                                        </g>
                                    </g>
                                    <g id="shoes_1_">
                                        <g>
                                            <path className="st5" d="M335.1,1217.7H211.7v-38.3h85.2C318,1179.5,335.1,1196.6,335.1,1217.7L335.1,1217.7z"/>
                                            <path className="st5" d="M221.2,1164.2L221.2,1164.2c-26.1,0-47.2,21.2-47.2,47.2v6.3h94.5v-6.3
                C268.4,1185.3,247.3,1164.2,221.2,1164.2z"/>
                                        </g>
                                        <g>
                                            <path className="st5" d="M364.7,1217.7h123.4v-38.3H403C381.9,1179.5,364.7,1196.6,364.7,1217.7L364.7,1217.7z"/>
                                            <path className="st5" d="M478.7,1164.2L478.7,1164.2c26.1,0,47.2,21.2,47.2,47.2v6.3h-94.5v-6.3
                C431.5,1185.3,452.6,1164.2,478.7,1164.2z"/>
                                        </g>
                                    </g>
                                    <g id="body_1_">
                                        <path className="st1"
                                              d="M199.7,837h150V364.7H227.8c-28.4,0-51.5,23-51.5,51.5v397.5C176.3,826.6,186.8,837,199.7,837z"/>
                                        <rect x="176.3" y="424.2" className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M291,506.3h-55.9c-10.7,0-19.3-8.7-19.3-19.3v-33.5h94.6v33.5C310.3,497.6,301.7,506.3,291,506.3z"/>
                                        <path className="st6"
                                              d="M229.5,756.4h67c10.6,0,19.2-8.6,19.2-19.2v-94.6H210.3v94.6C210.3,747.8,218.9,756.4,229.5,756.4z"/>
                                        <path className="st4"
                                              d="M225.6,683.3h74.9c8.4,0,15.2-6.8,15.2-15.2v-25.5H210.3v25.5C210.3,676.5,217.2,683.3,225.6,683.3z"/>
                                        <rect x="235.8" y="472.2" className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M253,642.6h-76.7v-32.4h44.3C238.5,610.3,253,624.7,253,642.6L253,642.6z"/>
                                        <path className="st1"
                                              d="M499.7,837h-150V364.7h121.9c28.4,0,51.5,23,51.5,51.5v397.5C523.1,826.6,512.6,837,499.7,837z"/>

                                        <rect x="349.7" y="424.2"
                                              transform="matrix(-1 -4.488333e-11 4.488333e-11 -1 872.7933 880.933)"
                                              className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M408.4,506.3h55.9c10.7,0,19.3-8.7,19.3-19.3v-33.5h-94.6v33.5C389.1,497.6,397.8,506.3,408.4,506.3z"/>
                                        <path className="st6"
                                              d="M469.9,756.4h-67c-10.6,0-19.2-8.6-19.2-19.2v-94.6h105.4v94.6C489.1,747.8,480.5,756.4,469.9,756.4z"/>
                                        <path className="st4"
                                              d="M473.8,683.3H399c-8.4,0-15.2-6.8-15.2-15.2v-25.5h105.4v25.5C489.1,676.5,482.3,683.3,473.8,683.3z"/>

                                        <rect x="409.1" y="472.2"
                                              transform="matrix(-1 -4.490243e-11 4.490243e-11 -1 872.7933 959.7552)"
                                              className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M446.4,642.6h76.7v-32.4h-44.3C460.9,610.3,446.4,624.7,446.4,642.6L446.4,642.6z"/>
                                        <path className="st3"
                                              d="M253,810.3h-76.7v-32.4h44.3C238.5,777.9,253,792.4,253,810.3L253,810.3z"/>
                                        <path className="st3"
                                              d="M446.4,810.3h76.7v-32.4h-44.3C460.9,777.9,446.4,792.4,446.4,810.3L446.4,810.3z"/>
                                        <path className="st5" d="M408.8,387v-22.3h-9.9V387c0,4.7-3.8,8.4-8.4,8.4h0c-3.8,0-6.8,3.1-6.8,6.8v25.1c0,8.3,6.7,15,15,15H409
            c8.3,0,15-6.7,15-15v-25.1c0-3.8-3.1-6.8-6.8-6.8h0C412.6,395.5,408.8,391.7,408.8,387z"/>
                                        <path className="st8" d="M299.1,364.7v41.4c0,20.1-16.3,36.3-36.3,36.3h0c-7.7,0-13.9,6.2-13.9,13.9v47.8c0,16.2,13.1,29.3,29.3,29.3h0
            "/>
                                    </g>
                                    <g id="neck">
                                        <rect x="299.1" y="253.7" className="st9" width="101.7" height="111"/>
                                    </g>
                                    <g id="kapyushon">
                                        <path className="st10" d="M383.8,295.8l-6.2,6.2C379.8,300.1,381.9,298.1,383.8,295.8z"/>
                                        <path className="st11"
                                              d="M369.1,310.4l8.4-8.4c-3.2,2.6-6.8,4.8-10.6,6.4C367.7,309.1,368.4,309.7,369.1,310.4z"/>
                                        <g>
                                            <g>
                                                <path className="st4" d="M262.8,268.2v-1.7c-25.2,27.2-33.6,64.3-25.1,98.2H310c-7.4-7.5-10.8-16.5-10.6-25.6
                    C277.3,323.3,262.8,297.4,262.8,268.2z"/>
                                                <path className="st11" d="M311,311.9c3.6-4,7.8-7,12.3-9.1c-10.4-8-17.1-20.5-17.1-34.6v-30c-14.9,5.1-28.8,13.5-40.6,25.4
                    c-1,1-1.8,2-2.8,2.9v1.7c0,29.2,14.5,55.1,36.6,70.9C299.7,329.6,303.7,320,311,311.9z"/>
                                            </g>
                                            <g>
                                                <path className="st4" d="M437.2,268.2v-1.7c25.2,27.2,33.6,64.3,25.1,98.2H390c7.4-7.5,10.8-16.5,10.6-25.6
                    C422.7,323.3,437.2,297.4,437.2,268.2z"/>
                                                <path className="st11" d="M389,311.9c-3.6-4-7.8-7-12.3-9.1c10.4-8,17.1-20.5,17.1-34.6v-30c14.9,5.1,28.8,13.5,40.6,25.4
                    c1,1,1.8,2,2.8,2.9v1.7c0,29.2-14.5,55.1-36.6,70.9C400.4,329.6,396.4,320,389,311.9z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="hands">
                                        <g id="rightHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="114.2" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1" d="M173.2,527.3c0-28.8-23.4-52.2-52.2-52.2s-52.2,23.4-52.2,52.2v101.5h104.5V527.3z"/>
                                                <path className="st13"
                                                      d="M121,730.9c28.8,0,52.2-23.4,52.2-52.2v-49.9H68.8v49.9C68.8,707.5,92.1,730.9,121,730.9z"/>
                                                <path className="st3" d="M145.4,620.6H68.8v-32.4h44.3C130.9,588.2,145.4,602.7,145.4,620.6L145.4,620.6z"/>
                                                <rect x="68.6" y="619.8" className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M82.4,560.9L82.4,560.9c20.4,20.4,53.5,20.4,73.9,0l107-107c20.4-20.4,20.4-53.5,0-73.9l0,0
                    c-20.4-20.4-53.5-20.4-73.9,0l-107,107C62,507.4,62,540.5,82.4,560.9z"/>

                                                <rect x="129.3" y="405.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 379.6613 6.8666)" className="st2"
                                                      width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M136.3,541.5l-54.2-54.2l22.9-22.9l31.3,31.3C148.9,508.4,148.9,528.9,136.3,541.5L136.3,541.5z"/>
                                            </g>
                                        </g>
                                        <g id="leftHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="585.7" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1" d="M526.7,527.3c0-28.8,23.4-52.2,52.2-52.2s52.2,23.4,52.2,52.2v101.5H526.7V527.3z"/>
                                                <path className="st13"
                                                      d="M578.9,730.9c-28.8,0-52.2-23.4-52.2-52.2v-49.9h104.5v49.9C631.1,707.5,607.7,730.9,578.9,730.9z"/>
                                                <path className="st3" d="M554.4,620.6h76.7v-32.4h-44.3C568.9,588.2,554.4,602.7,554.4,620.6L554.4,620.6z"/>

                                                <rect x="526.7" y="619.8" transform="matrix(-1 -4.493396e-11 4.493396e-11 -1 1157.9275 1248.5316)"
                                                      className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M617.5,560.9L617.5,560.9c-20.4,20.4-53.5,20.4-73.9,0l-107-107c-20.4-20.4-20.4-53.5,0-73.9l0,0
                    c20.4-20.4,53.5-20.4,73.9,0l107,107C637.9,507.4,637.9,540.5,617.5,560.9z"/>

                                                <rect x="466.1" y="405.6"
                                                      transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1211.3538 421.6909)"
                                                      className="st2" width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M563.6,541.5l54.2-54.2l-22.9-22.9l-31.3,31.3C551,508.4,551,528.9,563.6,541.5L563.6,541.5z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="head_1_">
                                        <path className="st14" d="M274,234.4h151.9c8,0,14.5-6.5,14.5-14.5v0c0-8-6.5-14.5-14.5-14.5H274c-8,0-14.5,6.5-14.5,14.5v0
            C259.5,228,266,234.4,274,234.4z"/>
                                        <path className="st15"
                                              d="M349.9,333.6L349.9,333.6c-36.1,0-65.4-29.3-65.4-65.4v-111h130.8v111C415.3,304.3,386.1,333.6,349.9,333.6z"
                                        />
                                        <path className="st16" d="M376,224.3h-52.2c-8.3,0-15.1,6.8-15.1,15.1v79.5c11.2,9.1,25.6,14.6,41.2,14.6s29.9-5.5,41.2-14.6v-79.5
            C391.1,231.1,384.4,224.3,376,224.3z"/>
                                        <path className="st17" d="M329.7,209.1h40.6c3.4,0,6.1,2.7,6.1,6.1v0c0,3.4-2.7,6.1-6.1,6.1h-40.6c-3.4,0-6.1-2.7-6.1-6.1v0
            C323.5,211.9,326.3,209.1,329.7,209.1z"/>
                                        <path className="st18" d="M383.5,202.9L383.5,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
            C387.6,201,385.7,202.9,383.5,202.9z"/>
                                        <path className="st18" d="M316.2,202.9L316.2,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
            C320.3,201,318.4,202.9,316.2,202.9z"/>
                                        <g id="mouth">
                                            <path className="st19" d="M362,240.2v2.4c0,5.5-4.4,9.9-9.9,9.9h-36.3c0.1,2,0.6,4,1.4,5.8h20.1c4.6,0,8.3,3.7,8.3,8.3v3.1h20
                c10.3,0,18.6-8.3,18.6-18.6v-11H362z"/>
                                            <path className="st3"
                                                  d="M362,242.6v-2.4h-46.3v11c0,0.5,0,0.9,0.1,1.4h36.3C357.6,252.5,362,248.1,362,242.6z"/>
                                            <path className="st20"
                                                  d="M337.3,258.3h-20.1c2.8,6.7,9.4,11.4,17.1,11.4h11.3v-3.1C345.6,262,341.9,258.3,337.3,258.3z"/>
                                        </g>
                                        <g id="upset" style={{opacity: 0}}>
                                            <path className="st19" d="M371.7,268.2c-1.5,0-2.9-0.8-3.6-2.3c-2.6-5.2-9.6-8.5-17.9-8.5s-15.3,3.3-17.9,8.5c-1,2-3.5,2.8-5.5,1.8
			c-2-1-2.8-3.5-1.8-5.5c4-8,13.6-13,25.1-13s21.1,5,25.1,13c1,2,0.2,4.5-1.8,5.5C373,268,372.3,268.2,371.7,268.2z"/>
                                        </g>
                                        <rect x="271.8" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <rect x="414.8" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <path className="st21"
                                              d="M298.6,226.5h-26.8v-26.8h0C286.6,199.7,298.6,211.7,298.6,226.5L298.6,226.5z"/>
                                        <path className="st21"
                                              d="M400.7,226.5h26.8v-26.8h0C412.7,199.7,400.7,211.7,400.7,226.5L400.7,226.5z"/>
                                        <rect x="334.2" y="157.3" className="st22" width="31.5" height="64.1"/>
                                        <path className="st23"
                                              d="M430.1,179.5h-62.4c-9.6,0-17.5-7.8-17.5-17.5v-4.8h97.4v4.8C447.6,171.6,439.8,179.5,430.1,179.5z"/>
                                        <path className="st23"
                                              d="M310,179.5h-30.6c-9.6,0-17.5-7.8-17.5-17.5v-4.8h65.5v4.8C327.4,171.6,319.6,179.5,310,179.5z"/>
                                    </g>
                                    <g id="vorotnik">
                                        <path className="st1" d="M437.2,368h-174v-9.3c0-19.4,15.7-35.1,35.1-35.1h103.9c19.4,0,35.1,15.7,35.1,35.1V368z"/>
                                        <rect x="322.9" y="323.6" className="st6" width="54.7" height="513.4"/>
                                        <g>
                                            <path className="st5" d="M303.8,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9h15.5c2.1,0,3.9,1.7,3.9,3.9l0,0
                C307.6,348.9,305.9,350.6,303.8,350.6z"/>
                                            <path className="st5" d="M411.6,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9h15.5c2.1,0,3.9,1.7,3.9,3.9l0,0
                C415.5,348.9,413.7,350.6,411.6,350.6z"/>
                                        </g>
                                    </g>
                                    <g id="helmet">
                                        <path className="st3"
                                              d="M349.9,37L349.9,37c-47.1,0-85.2,38.1-85.2,85.2v27.9h170.4v-27.9C435.1,75.2,397,37,349.9,37z"/>
                                        <path className="st24"
                                              d="M451,168.3H248.9v0c0-11.6,9.4-21.1,21.1-21.1h159.9C441.6,147.2,451,156.7,451,168.3L451,168.3z"/>
                                        <path className="st25" d="M334.7,125.4l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V39c-5.9-1.3-11.9-2-18.2-2
            c-6.2,0-12.3,0.7-18.2,2v79.5C331.8,121.1,332.8,123.6,334.7,125.4z"/>
                                        <path className="st25" d="M287.7,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V42c-14.3,5.1-26.8,14-36.3,25.4v30.8
            C284.7,100.8,285.8,103.4,287.7,105.2z"/>
                                        <path className="st25" d="M381.8,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V67.4C405.6,56,393.1,47.2,378.8,42
            v56.2C378.8,100.8,379.9,103.4,381.8,105.2z"/>
                                    </g>
                                </g>
                            </svg>

                            <svg id="level4Bg2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect className="st0" x="-420" width="1920" height="1080"/>
                                </g>
                                <g id="sea">
                                    <rect x="0" y="602.1" className="st1" x="-420" width="1920" height="197.9"/>
                                </g>
                                <g id="ice">
	                                <path className="st2"
                                          d="M573.4,773.4H389.7l0,0c0-9.6,7.8-17.4,17.4-17.4H556C565.6,756,573.4,763.8,573.4,773.4L573.4,773.4z"/>
                                    <path className="st2"
                                          d="M1218.4,692h-138.3l0,0c0-7.2,5.9-13.1,13.1-13.1h112.1C1212.5,678.9,1218.4,684.8,1218.4,692L1218.4,692z"/>
                                    <path className="st2"
                                          d="M550.7,674.4H412.4l0,0c0-7.2,5.9-13.1,13.1-13.1h112.1C544.8,661.3,550.7,667.2,550.7,674.4L550.7,674.4z"/>
                                    <path className="st2"
                                          d="M535.3,598.7H397.1v0c0-7.2,5.9-13.1,13.1-13.1h112.1C529.5,585.6,535.3,591.5,535.3,598.7L535.3,598.7z"/>
                                    <path className="st3"
                                          d="M604.5,725.2H466.2v0c0-7.2,5.9-13.1,13.1-13.1h112.1C598.6,712.1,604.5,717.9,604.5,725.2L604.5,725.2z"/>
                                    <path className="st2"
                                          d="M82.1,746.4h-39l0,0c0-4.2,3.4-7.5,7.5-7.5h23.9C78.7,738.9,82.1,742.2,82.1,746.4L82.1,746.4z"/>
                                    <g>
		<path className="st2"
              d="M282.8,738.5h-63.9l0,0c0-6.1,5-11.1,11.1-11.1h41.7C277.8,727.5,282.8,732.4,282.8,738.5L282.8,738.5z"/>
                                        <path className="st2"
                                              d="M346.9,746.9H199.6l0,0c0-7.7,6.2-13.9,13.9-13.9H333C340.7,733,346.9,739.2,346.9,746.9L346.9,746.9z"/>
	</g>
                                    <g>
		<path className="st3"
              d="M183.8,671.1h-63.9l0,0c0-6.1,5-11.1,11.1-11.1h41.7C178.8,660,183.8,664.9,183.8,671.1L183.8,671.1z"/>
                                        <path className="st3"
                                              d="M248,679.5H100.6v0c0-7.7,6.2-13.9,13.9-13.9H234C241.7,665.5,248,671.7,248,679.5L248,679.5z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M787.1,790.4H689l0,0c0-9.4,7.6-17,17-17h64C779.5,773.4,787.1,781,787.1,790.4L787.1,790.4z"/>
                                        <path className="st2"
                                              d="M885.7,803.3H659.5v0c0-11.8,9.6-21.4,21.4-21.4h183.4C876.1,781.9,885.7,791.5,885.7,803.3L885.7,803.3z"/>
	</g>
                                    <g>
		<path className="st3" d="M1076,742h98.1l0,0c0-9.4-7.6-17-17-17h-64C1083.6,725,1076,732.6,1076,742L1076,742z"/>
                                        <path className="st3"
                                              d="M977.5,754.9h226.2v0c0-11.8-9.6-21.4-21.4-21.4H998.9C987.1,733.5,977.5,743.1,977.5,754.9L977.5,754.9z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M343.8,691.5h70.2v0c0-6.7-5.5-12.2-12.2-12.2h-45.8C349.2,679.4,343.8,684.8,343.8,691.5L343.8,691.5z"/>
                                        <path className="st2"
                                              d="M273.3,700.8h161.8l0,0c0-8.5-6.9-15.3-15.3-15.3H288.6C280.1,685.5,273.3,692.3,273.3,700.8L273.3,700.8z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M145.4,774.9h70.2l0,0c0-6.7-5.5-12.2-12.2-12.2h-45.8C150.9,762.7,145.4,768.1,145.4,774.9L145.4,774.9z"/>
                                        <path className="st2"
                                              d="M74.9,784.1h161.8l0,0c0-8.5-6.9-15.3-15.3-15.3H90.2C81.8,768.8,74.9,775.6,74.9,784.1L74.9,784.1z"/>
	</g>
                                    <g>
		<path className="st3"
              d="M749.4,726.6h70.2l0,0c0-6.7-5.5-12.2-12.2-12.2h-45.8C754.8,714.4,749.4,719.9,749.4,726.6L749.4,726.6z"/>
                                        <path className="st3"
                                              d="M678.9,735.9h161.8l0,0c0-8.5-6.9-15.3-15.3-15.3H694.2C685.8,720.5,678.9,727.4,678.9,735.9L678.9,735.9z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M728.9,684.4h61.8l0,0c0-5.9-4.8-10.7-10.7-10.7h-40.3C733.7,673.7,728.9,678.5,728.9,684.4L728.9,684.4z"/>
                                        <path className="st2"
                                              d="M666.9,692.5h142.5v0c0-7.4-6-13.5-13.5-13.5H680.3C672.9,679,666.9,685.1,666.9,692.5L666.9,692.5z"/>
	</g>
                                    <g>
		<path className="st4" d="M1126.1,507.4h61.8l0,0c0-5.9-4.8-10.7-10.7-10.7h-40.3C1130.9,496.7,1126.1,501.5,1126.1,507.4L1126.1,507.4
			z"/>
                                        <path className="st4" d="M1064.1,515.6h142.5l0,0c0-7.4-6-13.5-13.5-13.5h-115.5C1070.1,502.1,1064.1,508.1,1064.1,515.6L1064.1,515.6
			z"/>
	</g>
                                    <g>
		<path className="st3"
              d="M294.8,601.2h-51.9l0,0c0-5,4-9,9-9h33.9C290.8,592.2,294.8,596.2,294.8,601.2L294.8,601.2z"/>
                                        <path className="st3"
                                              d="M346.9,608H227.2l0,0c0-6.3,5.1-11.3,11.3-11.3h97.1C341.9,596.7,346.9,601.8,346.9,608L346.9,608z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M958.7,702.1h-51.9v0c0-5,4-9,9-9h33.9C954.7,693.1,958.7,697.1,958.7,702.1L958.7,702.1z"/>
                                        <path className="st2"
                                              d="M1010.9,709H891.1l0,0c0-6.3,5.1-11.3,11.3-11.3h97.1C1005.8,697.6,1010.9,702.7,1010.9,709L1010.9,709z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M1224.3,624.3h-46.7l0,0c0-4.5,3.6-8.1,8.1-8.1h30.5C1220.7,616.2,1224.3,619.8,1224.3,624.3L1224.3,624.3z"
        />
                                        <path className="st2"
                                              d="M1240.6,630.7h-73.7l0,0c0-3.9,3.1-7,7-7h59.7C1237.4,623.7,1240.6,626.8,1240.6,630.7L1240.6,630.7z"/>
	</g>
                                    <g>
		<path className="st2"
              d="M47.2,683.7H0.5l0,0c0-4.5,3.6-8.1,8.1-8.1h30.5C43.6,675.6,47.2,679.2,47.2,683.7L47.2,683.7z"/>
                                        <path className="st2"
                                              d="M63.5,690.1h-73.7l0,0c0-3.9,3.1-7,7-7h59.7C60.3,683.1,63.5,686.2,63.5,690.1L63.5,690.1z"/>
	</g>
                                    <g>
		<path className="st3"
              d="M626,683.7h-46.7l0,0c0-4.5,3.6-8.1,8.1-8.1h30.5C622.3,675.6,626,679.2,626,683.7L626,683.7z"/>
                                        <path className="st3"
                                              d="M642.2,690.1h-73.7l0,0c0-3.9,3.1-7,7-7h59.7C639.1,683.1,642.2,686.2,642.2,690.1L642.2,690.1z"/>
	</g>
</g>
                                <g id="clouds">
	<g>
		<path className="st5" d="M116.6,414.1H40c-13.2,0-23.8-10.7-23.8-23.8v0c0-13.2,10.7-23.8,23.8-23.8h76.6c13.2,0,23.8,10.7,23.8,23.8
			v0C140.4,403.5,129.8,414.1,116.6,414.1z"/>
        <circle className="st5" cx="72.1" cy="366.5" r="24.1"/>
	</g>
                                    <g>
		<path className="st5" d="M261.5,283.7h-89.1c-15.3,0-27.7-12.4-27.7-27.7v0c0-15.3,12.4-27.7,27.7-27.7h89.1
			c15.3,0,27.7,12.4,27.7,27.7v0C289.2,271.3,276.8,283.7,261.5,283.7z"/>
                                        <circle className="st5" cx="209.7" cy="228.2" r="28"/>
	</g>
                                    <g>
		<path className="st5" d="M706.4,85.7h-50.2c-8.6,0-15.6-7-15.6-15.6v0c0-8.6,7-15.6,15.6-15.6h50.2c8.6,0,15.6,7,15.6,15.6v0
			C722,78.7,715,85.7,706.4,85.7z"/>
                                        <circle className="st5" cx="677.1" cy="54.4" r="15.8"/>
	</g>
                                    <path className="st2" d="M1172.3,263.1h115.9c19.9,0,36.1-16.1,36.1-36.1v0c0-19.9-16.1-36.1-36.1-36.1h-115.9
		c-19.9,0-36.1,16.1-36.1,36.1v0C1136.3,247,1152.4,263.1,1172.3,263.1z"/>
                                    <circle className="st2" cx="1236.7" cy="191.7" r="39.5"/>
                                    <path className="st2" d="M996.9,210.1h60.5c10.4,0,18.8-8.4,18.8-18.8v0c0-10.4-8.4-18.8-18.8-18.8h-60.5c-10.4,0-18.8,8.4-18.8,18.8v0
		C978.1,201.7,986.5,210.1,996.9,210.1z"/>
                                    <circle className="st2" cx="1030.5" cy="172.8" r="20.6"/>
                                    <g>
		<path className="st2" d="M169.7,110h52.7c9.1,0,16.4-7.3,16.4-16.4v0c0-9.1-7.3-16.4-16.4-16.4h-52.7c-9.1,0-16.4,7.3-16.4,16.4v0
			C153.3,102.6,160.7,110,169.7,110z"/>
                                        <circle className="st2" cx="199" cy="77.5" r="17.9"/>
	</g>
</g>
                                <g id="ice_1_">
                                    <g>
                                        <path className="st3"
                                              d="M211.7,626.8H61.3l0,0c0-3.7,3-6.7,6.7-6.7h137C208.7,620.1,211.7,623.1,211.7,626.8L211.7,626.8z"/>
                                        <path className="st3" d="M125.9,621.8H82.4v-2.3c0-3.2,2.6-5.8,5.8-5.8h31.9c3.2,0,5.8,2.6,5.8,5.8V621.8z"/>
                                    </g>
                                    <g>
                                        <path className="st2" d="M681,640.8H629v0c0-5,4-9,9-9H672C676.9,631.8,681,635.8,681,640.8L681,640.8z"/>
                                        <path className="st2"
                                              d="M733.1,647.6H613.4l0,0c0-6.3,5.1-11.3,11.3-11.3h97.1C728,636.3,733.1,641.4,733.1,647.6L733.1,647.6z"/>
                                    </g>
                                    <g>
                                        <path className="st3"
                                              d="M907.9,643.5h70.2l0,0c0-6.7-5.5-12.2-12.2-12.2h-45.8C913.4,631.3,907.9,636.7,907.9,643.5L907.9,643.5z"/>
                                        <path className="st3"
                                              d="M837.4,652.7h161.8v0c0-8.5-6.9-15.3-15.3-15.3H852.7C844.3,637.4,837.4,644.2,837.4,652.7L837.4,652.7z"/>
                                    </g>
                                </g>
                                <g id="tanker">
	<g>
		<path className="st6"
              d="M92,570.6l4.2,6.5l80,27.2c14.1,4.8,25.2,15.7,30.3,29.7l2.4,6.7h844.8l9.5-30.3l61.1-4.7l54-27.9v-7.1H92z"
        />
        <polygon className="st7" points="78.3,526.1 92,570.6 1178.4,570.6 1178.4,552.2 238.6,552.2 216.1,533.5 166.5,533.5 159.5,526.1
			"/>
        <path className="st8" d="M219,476.8v-3.4h-13.8v3.4h4.7v3.9h-2.1v6.8h2.5v20h-2.5v5.5h2.5v13.1h-50.9l6.2,6.6c0.5,0.5,1.2,0.8,1.9,0.8
			H215v-5h4v-47.8h-4.2v-3.9H219z"/>
        <polygon className="st5"
                 points="134,519.7 134,502.2 98.4,502.2 98.4,526.1 132.7,526.1 134,526.1 140.8,526.1 140.8,519.7 		"/>
        <polyline className="st5" points="98.4,526.1 79.7,526.1 79.7,502.2 98.4,502.2 		"/>
        <polyline className="st9" points="79.7,523.4 87.5,518.4 98.4,518.4 		"/>
        <polyline className="st9" points="87.5,502.2 92.4,507.1 98.4,507.1 		"/>
        <rect x="98.4" y="496.5" className="st8" width="35.6" height="5.6"/>
        <path className="st8" d="M801.8,545.1v-4.7h-4.5v-4.6h-7.1v2.4h2.4v2.2h-2.2v4.7H663.5v-4.7H659v-4.6h-7.1v2.4h2.4v2.2h-2.2v4.7h-18.6
			v-4.5h-14v-4.7h-4.3v-11.3l-4.7-7.2h-39.1v2.2h-27.6v2.3h-14v11.6h4.7v-7h13.8v7h-6.9v11.6h-13.7v-4.7h-7v-4.7H517v-2.3h-3.2v4.7
			h2.3v6.9H389.4v-4.7h-4.6v-4.6h-5.9v-2.4h-3.3v4.8h2.4v7H242.9v-6.2H232v7.9l6.6,5.5h4.3H378h11.4h178.8V547h40.1v5.2h4.9v-5h18v5
			h547.2v-7.1H801.8z M606.1,536h-2.2v4.7h-39.5v4.3h-20.5v-5h18.4v-6.6H553v-7h41.9l11.2,6.1V536z"/>
        <rect x="974.8" y="527" className="st10" width="11.9" height="18.1"/>
        <rect x="986.6" y="485.7" className="st5" width="60.3" height="59.4"/>
        <path className="st10" d="M1036.5,478.4v-4.9v-6.8h2.2v-4.6v-11.6h4.6v-2.2v-6.9v-4.6h-2.3v-4.6v-2.4v-2.2h2.3v-2.3h-2.3V423h-2.2
			v-0.1v-4.5h-2.6v4.6h-4.4v4.7h2.3v2.2h-4.6v-2.4h-2.4v2.4h-11.5v2.4h2.4v4.6h4.7v4.6h-2.3v6.9h4.6v2.2h2.2v11.6h-2.2v4.6h-2.2
			v-6.8h-4.7v6.8h-4.6v-9.2h-2.4v9.2h-6.9V464h2.5v-6.5v-7h-2.5v-2.4h-2.4v2.4h-4.9v7h2.6v6.5v2.7h-18.3v6.8h2.1v4.9h-2.1v7.3h71.5
			v-7.3H1036.5z M1022.7,478.4h-7v-4.9h7V478.4z"/>
        <rect x="1046.9" y="492.4" className="st5" width="7.7" height="6.5"/>
        <rect x="996.9" y="489.6" className="st11" width="4.7" height="4.7"/>
        <rect x="1024.8" y="489.6" className="st11" width="4.7" height="4.7"/>
        <rect x="1024.8" y="501" className="st11" width="4.7" height="4.7"/>
        <rect x="986.6" y="496.5" className="st11" width="60.3" height="2.4"/>
        <rect x="986.6" y="508.1" className="st11" width="60.3" height="2.4"/>
        <rect x="986.6" y="517.4" className="st10" width="60.3" height="4.6"/>
        <rect x="1025.2" y="521.9" className="st10" width="6.6" height="14.1"/>
        <rect x="1011" y="528.8" className="st10" width="35.8" height="2.5"/>
        <polygon className="st11" points="1022.8,510.5 1022.8,514.9 1013.4,514.9 1013.4,512.9 1008.7,512.9 1008.7,514.9 1001.9,514.9
			1001.9,512.9 999.5,512.9 999.5,514.9 999.5,516.5 999.5,517.4 1022.8,517.4 1024.8,517.4 1029.5,517.4 1029.5,510.5 		"/>
        <polygon className="st7" points="1089.3,491.9 1064,491.9 1064,457.5 1084.9,457.5 		"/>
        <rect x="1068.6" y="448.2" className="st10" width="11.6" height="9.4"/>
        <rect x="1061.7" y="491.9" className="st10" width="59.7" height="4.6"/>
        <polygon className="st5" points="1087.5,478.1 1064,478.1 1064,473.2 1086.9,473.2 		"/>
        <rect x="1061.7" y="496.5" className="st5" width="45.1" height="48.5"/>
        <rect x="1046.9" y="503.4" className="st11" width="60" height="7.1"/>
        <rect x="1046.9" y="510.5" className="st11" width="18.6" height="34.6"/>
        <rect x="1046.9" y="528.8" className="st5" width="23.7" height="7.3"/>
        <rect x="1046.9" y="517.4" className="st5" width="18.6" height="4.6"/>
        <polygon className="st5" points="1137.4,536.3 1137.4,517.6 1106.8,517.6 1106.8,545.1 1136.2,545.1 1137.4,545.1 1141.6,545.1
			1141.6,536.3 		"/>
        <rect x="1106.8" y="517.6" className="st11" width="30.6" height="6.7"/>
        <polygon className="st11" points="1160.9,505.9 1160.9,503.8 1139.9,503.8 1135.5,503.8 1135.5,508.1 1133,508.1 1133,517.6
			1135.5,517.6 1135.5,532.9 1139.9,532.9 1139.9,515 1146.9,510.5 1156.2,510.5 1160.9,510.5 1192.8,510.5 1192.8,515.1
			1195,515.1 1195,510.5 1195,509 1195,505.9 		"/>
        <rect x="136.5" y="540" className="st8" width="64.1" height="7.4"/>
        <rect x="123.9" y="527.3" className="st5" width="5" height="5"/>
        <rect x="143.7" y="527.3" className="st5" width="5" height="5"/>
        <rect x="153.3" y="527.3" className="st5" width="5" height="5"/>
        <rect x="237.8" y="563.3" className="st5" width="4.9" height="4.9"/>
        <circle className="st12" cx="241" cy="628.5" r="5"/>
        <path className="st13" d="M279.9,585.4H120.5l55.8,18.9c14.1,4.8,25.2,15.7,30.3,29.7l2.4,6.7h175.4c-14.9,0-29.4-4.5-41.8-12.8
			L279.9,585.4z"/>
        <path className="st14" d="M205.8,632l3.1,8.7h165.5c-15.9,0-31.6-3.9-45.6-11.5L259.7,592H140l38.3,13
			C191.1,609.3,201.2,619.3,205.8,632z"/>
        <path className="st13"
              d="M954.2,630.8c-14.4,6.5-30.1,9.9-46,9.9h145.4l9.5-30.3l61.1-4.7l42.5-22h-108.1L954.2,630.8z"/>
        <path className="st14" d="M1126.1,616.3v-6.9h-4.5v-3.5l-19.8,1.5v13.2h-4.5v-8.4h-8.9v28.5h8.9v-7.1c0-0.5,0.4-0.9,0.9-0.9l27.7-2.1
			c1.2,0,2.4-0.5,3.2-1.3l3.5-3.5c1-1,1-2.8-0.1-3.7L1126.1,616.3z"/>
        <line className="st15" x1="98.4" y1="502.2" x2="98.4" y2="526.1"/>
	</g>
</g>
                            </svg>

                            <svg id="level4Bg3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="mlsp">
	<path className="st2" d="M3138.2,550h-126.4c-47.9,0-86.8-38.9-86.8-86.8V336.8c0-47.9,38.9-86.8,86.8-86.8h126.4
		c47.9,0,86.8,38.9,86.8,86.8v126.4C3225,511.1,3186.1,550,3138.2,550z"/>
                                    <path className="st2" d="M3428.2,2910h-126.4c-47.9,0-86.8-38.9-86.8-86.8v-126.4c0-47.9,38.9-86.8,86.8-86.8h126.4
		c47.9,0,86.8,38.9,86.8,86.8v126.4C3515,2871.1,3476.1,2910,3428.2,2910z"/>
                                    <rect x="2129" y="2409" className="st2" width="228" height="228"/>
                                    <rect x="3813" y="2409" className="st2" width="228" height="228"/>
                                    <rect x="3017" y="2502.9" className="st2" width="136" height="257.1"/>
</g>
                                <g id="tanker">
	<path className="st3" d="M575.3,468.1c-4.6-2.6-11-5.1-18.8-5.1c-13.5,0-111.7,0-140.7,0c-5.1,0-8.1,1.7-8.1,1.7v17.4
		c0,0,2,1.2,5.6,1.6c0,1.1,0.9,2,2,2h9.7c0,0,0,0,0,0c0,1.6,1.3,2.9,3,2.8c1.5-0.1,2.7-1.5,2.7-3v-1.6c38.7,0,113.9,0,125.8,0
		c8.2,0,14.9-2.8,19.5-5.5h6.7v-10.3H575.3z"/>
                                    <path className="st4" d="M570.5,470.7c0,0,32.6-11.2,43.8-24.1"/>
                                    <polygon className="st3" points="723.6,342.7 707.9,342.7 707.9,350.6 643.3,350.6 643.3,423.1 631.8,423.1 634,431.3 635.3,432.5
		647.2,432.5 647.2,430.8 723.5,430.8 723.5,362.3 727.5,362.3 727.5,360.7 727.5,346.6 	"/>
                                    <g>
		<rect x="637.8" y="345.1" className="st5" width="78" height="78"/>
	</g>
                                    <polygon className="st3" points="663.4,352 656,353.6 656,345.1 648.7,345.1 648.7,336.7 652.8,336.7 646.3,330.2 632.6,330.2
		622.8,339.9 622.8,353.6 630.3,361.1 630.3,357.9 637.8,357.9 637.8,363.4 646.1,363.4 646.3,363.4 665.1,363.4 665.1,353.6 	"/>
                                    <polygon className="st6"
                                             points="630.3,357.9 630.3,361.1 632.6,363.4 637.8,363.4 637.8,357.9 	"/>
                                    <polygon className="st6"
                                             points="648.7,345.1 656,345.1 656,339.9 652.8,336.7 648.7,336.7 	"/>
                                    <g>
		<polygon className="st7" points="654.4,352 654.4,338.2 644.7,328.5 630.9,328.5 621.2,338.2 621.2,352 630.9,361.7 644.4,361.7
			644.7,361.7 663.4,361.7 663.4,352 		"/>
	</g>
                                    <circle className="st8" cx="637.8" cy="344.7" r="9.9"/>
                                    <text transform="matrix(0.7071 0.7071 -0.7071 0.7071 633.6909 344.9186)"
                                          className="st9 st10 st11">H</text>
                                    <g className="st3">
		<polygon points="663.4,366.6 690.1,366.6 693.3,369.7 693.3,408.9 666.6,408.9 663.4,405.7 		"/>
	</g>
                                    <g>
		<rect x="663.4" y="366.6" className="st9" width="26.7" height="39.1"/>
	</g>
                                    <g className="st3">
		<polygon points="695.6,368.9 715.8,368.9 715.8,389.1 697.9,389.1 695.6,386.7 		"/>
	</g>
                                    <g>
		<rect x="695.6" y="366.6" className="st12" width="20.2" height="20.2"/>
	</g>
                                    <g>
		<g className="st3">
			<polygon points="678.3,364.2 668.6,364.2 666.1,361.7 666.1,352 675.8,352 678.3,354.5 			"/>
		</g>
                                        <g>
			<rect x="666.1" y="352" className="st7" width="9.7" height="9.7"/>
		</g>
	</g>
                                    <g>
		<g className="st3">
			<polygon points="690.9,364.2 681.2,364.2 678.7,361.7 678.7,352 688.4,352 690.9,354.5 			"/>
		</g>
                                        <g>
			<rect x="678.7" y="352" className="st7" width="9.7" height="9.7"/>
		</g>
	</g>
                                    <g className="st3">
		<polygon points="661.4,412.1 644.4,412.1 642.1,409.9 642.1,370.7 659.2,370.7 661.4,372.9 		"/>
	</g>
                                    <g>
		<rect x="642.1" y="370.7" className="st7" width="17.1" height="39.2"/>
	</g>
                                    <g className="st13">
		<polygon points="699.9,420.5 666.7,420.5 663.4,417.2 663.4,409.9 696.5,409.9 699.9,413.2 		"/>
	</g>
                                    <g>
		<rect x="663.4" y="409.9" className="st14" width="33.1" height="7.3"/>
	</g>
                                    <polygon className="st15"
                                             points="643.7,417.1 631.8,417.1 631.8,423.1 645,423.1 645,418.4 	"/>
                                    <g>
		<rect x="707.9" y="342.7" className="st14" width="15.7" height="15.7"/>
	</g>
                                    <g>
		<rect x="631.8" y="417.1" className="st14" width="11.9" height="11.9"/>
	</g>
                                    <g className="st3">
		<polygon points="698.2,369.2 713.2,369.2 714.9,370.9 714.9,385.9 699.9,385.9 698.2,384.2 		"/>
	</g>
                                    <g>
		<rect x="698.2" y="369.2" className="st7" width="15" height="15"/>
	</g>
                                    <g className="st3">
		<polygon points="697.6,394.9 712.6,394.9 714.3,396.6 714.3,411.6 699.4,411.6 697.6,409.9 		"/>
	</g>
                                    <g>
		<rect x="697.6" y="394.9" className="st0" width="15" height="15"/>
	</g>
                                    <g className="st3">
		<polygon points="702,373 709.3,373 715.8,382.4 715.8,386.7 711.4,386.7 702,380.3 		"/>
	</g>
                                    <g>
		<rect x="702" y="373" className="st14" width="7.3" height="7.3"/>
	</g>
                                    <g className="st6">
		<polyline points="715.3,353.1 714.2,352 716.9,332.3 717.8,332.3 718.5,353.1 		"/>
	</g>
                                    <polygon className="st3"
                                             points="667.2,411.9 691.9,413.9 691.9,414.8 668.6,416.2 667.2,415.1 	"/>
                                    <g>
		<polyline className="st16" points="714.2,352 715,316.1 716.5,316.1 717.4,352 		"/>
	</g>
                                    <polygon className="st16"
                                             points="667.2,411.9 689.1,412.8 689.1,414.3 667.2,415.1 	"/>
                                    <path className="st3" d="M634,431.3l-0.5-1.8l0.4-0.4h2.1l3.8-4.5l-2.1-2.1l-22.7,29.4l0.1,0.2c-8.2,8.8-23,13.9-32.6,16.4v0.9
		c9.7-2.6,24.8-7.8,33.2-16.7l0.2,0.2l18-21.4L634,431.3z"/>
                                    <polygon className="st3"
                                             points="640.6,379.4 639.5,378.3 631.8,378.3 631.8,392.9 637.8,392.9 637.8,401 640.6,401 	"/>
                                    <polygon className="st6"
                                             points="631.8,392.9 631.8,399.9 632.9,401 637.8,401 637.8,392.9 	"/>
                                    <g>
		<polyline className="st16" points="639.5,423.8 613.5,448.6 612.4,447.6 637.2,421.6 		"/>
	</g>
                                    <g>
		<rect x="631.8" y="378.3" className="st7" width="7.6" height="21.6"/>
	</g>
                                    <g className="st13">
		<polygon points="699.4,350.8 695.3,350.8 694.2,349.7 694.2,345.6 698.2,345.6 699.4,346.7 		"/>
	</g>
                                    <g>
		<rect x="694.2" y="345.6" className="st14" width="4.1" height="4.1"/>
	</g>
                                    <g className="st15">
		<polyline points="697.2,349.4 685.4,348.8 685.4,348.2 696.7,346.5 697.2,347 		"/>
	</g>
                                    <g>
		<polyline className="st16" points="696.7,348.8 681.9,348.2 681.9,347.1 696.7,346.5 		"/>
	</g>
                                    <g className="st3">
		<polygon points="687.7,382.9 683.6,382.9 683,382.3 683,370.8 687.1,370.8 687.7,371.4 		"/>
	</g>
                                    <g>
		<rect x="683" y="370.8" className="st0" width="4.1" height="11.5"/>
	</g>
                                    <g className="st3">
		<polygon points="681.1,382.9 677,382.9 676.5,382.3 676.5,370.8 680.6,370.8 681.1,371.4 		"/>
	</g>
                                    <g>
		<rect x="676.5" y="370.8" className="st0" width="4.1" height="11.5"/>
	</g>
                                    <g className="st3">
		<polygon points="670.1,401.4 669.6,400.9 669.6,398.1 681,398.1 681.6,398.7 681.6,401.4 		"/>
	</g>
                                    <g>
		<rect x="669.6" y="398.1" className="st0" width="11.5" height="2.8"/>
	</g>
                                    <g className="st3">
		<polygon points="670.1,396.7 669.6,396.1 669.6,393.3 681,393.3 681.6,393.9 681.6,396.7 		"/>
	</g>
                                    <g>
		<rect x="669.6" y="393.3" className="st0" width="11.5" height="2.8"/>
	</g>
                                    <g className="st3">
		<polygon points="677,391.7 670.1,391.7 669.6,391.1 669.6,384.2 676.5,384.2 677,384.7 		"/>
	</g>
                                    <g>
		<rect x="669.6" y="384.2" className="st0" width="6.9" height="6.9"/>
	</g>
                                    <g>
		<g className="st3">
			<polygon points="703.6,364.2 693.8,364.2 691.4,361.7 691.4,352 701.1,352 703.6,354.5 			"/>
		</g>
                                        <g>
			<rect x="691.4" y="352" className="st7" width="9.7" height="9.7"/>
		</g>
	</g>
                                    <polygon className="st3"
                                             points="715.8,358.4 707.9,358.4 709.6,360.1 715.8,360.1 	"/>
                                    <g>
		<path className="st3" d="M658.9,377.5C658.9,377.5,658.9,377.5,658.9,377.5C658.9,377.5,658.9,377.5,658.9,377.5
			C658.9,377.5,658.9,377.5,658.9,377.5C658.8,377.5,658.8,377.5,658.9,377.5c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.5-0.9-0.8-1.5-0.8c-1,0-1.8,0.8-1.8,1.8c0,0.6,0.3,1.2,0.8,1.5c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0,1.3-0.6,1.3-1.3C659.1,378,659.1,377.8,658.9,377.5z"/>
                                        <g>
			<circle className="st14" cx="655.6" cy="376.1" r="1.8"/>
		</g>
	</g>
                                    <g>
		<path className="st3" d="M658.9,384.7C658.9,384.7,658.9,384.7,658.9,384.7C658.9,384.7,658.9,384.7,658.9,384.7
			C658.9,384.7,658.9,384.7,658.9,384.7C658.8,384.7,658.8,384.6,658.9,384.7c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.5-0.9-0.8-1.5-0.8c-1,0-1.8,0.8-1.8,1.8c0,0.6,0.3,1.2,0.8,1.5c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0,1.3-0.6,1.3-1.3C659.1,385.2,659.1,384.9,658.9,384.7z"/>
                                        <g>
			<circle className="st14" cx="655.6" cy="383.3" r="1.8"/>
		</g>
	</g>
                                    <g>
		<path className="st3" d="M658.9,391.9C658.9,391.9,658.9,391.9,658.9,391.9C658.9,391.9,658.9,391.9,658.9,391.9
			C658.9,391.9,658.9,391.9,658.9,391.9C658.8,391.8,658.8,391.8,658.9,391.9c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.5-0.9-0.8-1.5-0.8c-1,0-1.8,0.8-1.8,1.8c0,0.6,0.3,1.2,0.8,1.5c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0,1.3-0.6,1.3-1.3C659.1,392.4,659.1,392.1,658.9,391.9z"/>
                                        <g>
			<circle className="st14" cx="655.6" cy="390.5" r="1.8"/>
		</g>
	</g>
                                    <g>
		<path className="st3" d="M658.9,399.1C658.9,399.1,658.9,399.1,658.9,399.1C658.9,399.1,658.9,399.1,658.9,399.1
			C658.9,399.1,658.9,399.1,658.9,399.1C658.8,399,658.8,399,658.9,399.1c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.5-0.9-0.8-1.5-0.8c-1,0-1.8,0.8-1.8,1.8c0,0.6,0.3,1.2,0.8,1.5c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0,1.3-0.6,1.3-1.3C659.1,399.6,659.1,399.3,658.9,399.1z"/>
                                        <g>
			<circle className="st14" cx="655.6" cy="397.6" r="1.8"/>
		</g>
	</g>
                                    <line x1="636.1" y1="429" x2="636.1" y2="429"/>
                                    <path className="st14" d="M553.9,481.3c-13.7,0-111.7,0-140.7,0c-5.1,0-8.1-1.7-8.1-1.7v-17.4c0,0,3-1.7,8.1-1.7c29,0,127.2,0,140.7,0
		c15.9,0,26.2,10.4,26.2,10.4S569.8,481.3,553.9,481.3z"/>
                                    <path className="st3"
                                          d="M575.5,467.3H561v8.6l1.7,1.7h7.5c6.3-3,10-6.7,10-6.7S578.5,469.2,575.5,467.3z"/>
                                    <polygon className="st3" points="571.2,468 550.9,468 550.9,467.3 551.1,467.3 546.9,463.2 539.8,463.2 535,468 427.6,468 427.6,475.2
		534.8,475.2 534.8,475.3 539.8,480.4 546.9,480.4 552,475.3 552,475.2 571.2,475.2 	"/>
                                    <g>
		<g>
			<rect x="428.8" y="467.3" className="st17" width="141.7" height="7.1"/>
		</g>
                                        <g>
			<rect x="428.8" y="467.3" className="st17" width="141.7" height="7.1"/>
		</g>
	</g>
                                    <g>
		<rect x="561" y="465.5" className="st18" width="19.2" height="10.3"/>
	</g>
                                    <polygon className="st6"
                                             points="551.1,467.3 550.9,467.3 550.9,474.5 552,474.5 552,468.2 	"/>
                                    <g>
		<g>
			<polygon className="st0" points="546.1,462.3 539,462.3 533.9,467.3 533.9,474.5 539,479.5 546.1,479.5 551.1,474.5 551.1,467.3
				"/>
		</g>
                                        <g>
			<polygon className="st19" points="546.1,462.3 539,462.3 533.9,467.3 533.9,474.5 539,479.5 546.1,479.5 551.1,474.5 551.1,467.3
				"/>
		</g>
	</g>
                                    <circle className="st20" cx="542.5" cy="470.6" r="5.1"/>
                                    <text transform="matrix(1 0 0 1 541.0862 472.2241)"
                                          className="st9 st10 st21">H</text>
                                    <g className="st22">
		<polygon className="st3" points="425.2,468.2 421.7,468.2 421.7,464.4 420.9,463.5 412.1,463.5 412.1,468.2 408,468.2 408,474.5
			408.8,475.4 411.3,475.4 411.3,478.3 412.1,479.1 421.7,479.1 421.7,475.4 425.2,475.4 		"/>
	</g>
                                    <g>
		<polygon className="st0" points="424.3,467.3 420.9,467.3 420.9,463.5 411.3,463.5 411.3,467.3 408,467.3 408,474.5 411.3,474.5
			411.3,478.3 420.9,478.3 420.9,474.5 424.3,474.5 		"/>
	</g>
                                    <g className="st6">
		<path d="M432.5,472.6c0-2-1.2-3.7-2.9-4.6c-0.5-0.3-0.9-0.8-0.9-1.4v-1.4l-1.7-1.7l-0.8,1.7v1.3c0,0.6-0.4,1.1-0.9,1.4
			c-1,0.4-1.8,1.2-2.3,2.1h-5.4c-1.4,0-2.7,1-2.7,2.4c-0.1,1.5,1.1,2.7,2.6,2.7h5.5c0.5,0.9,1.3,1.6,2.3,2.1c0.5,0.2-0.8,0.4-0.8,1
			l1.7,1.7h2.5v-1.4c0-0.6,0.4-1.1,0.9-1.4C431.3,476.4,432.5,474.6,432.5,472.6z"/>
	</g>
                                    <g>
		<path className="st18" d="M430.7,470.9c0-2-1.2-3.7-2.9-4.6c-0.5-0.3-0.9-0.8-0.9-1.4v-1.4h-2.5v1.3c0,0.6-0.4,1.1-0.9,1.4
			c-1,0.4-1.8,1.2-2.3,2.1h-5.4c-1.4,0-2.7,1-2.7,2.4c-0.1,1.5,1.1,2.7,2.6,2.7h5.5c0.5,0.9,1.3,1.6,2.3,2.1
			c0.5,0.2,0.9,0.8,0.9,1.4v1.3h2.5v-1.4c0-0.6,0.4-1.1,0.9-1.4C429.6,474.7,430.7,472.9,430.7,470.9z"/>
	</g>
                                    <g className="st3">
		<polygon points="425,468 426.3,468 427,468.7 427,474.5 425.7,474.5 425,473.8 		"/>
	</g>
                                    <g>
		<rect x="425" y="468" className="st0" width="1.3" height="5.8"/>
	</g>
                                    <g>
		<rect x="425.5" y="469.5" className="st0" width="0.4" height="2.9"/>
	</g>
                                    <g className="st15">
		<rect x="425.6" y="469.6" width="0.4" height="2.9"/>
                                        <polygon
                                            points="425.5,469.5 425.9,469.5 426.1,469.6 426.1,472.5 425.6,472.5 425.5,472.3 		"/>
	</g>
                                    <g>
		<g>
			<rect x="425.5" y="469.5" className="st0" width="0.4" height="2.9"/>
		</g>
                                        <g>
			<rect x="425.5" y="469.5" className="st23" width="0.4" height="2.9"/>
		</g>
	</g>
                                    <g className="st6">
		<polygon points="414.3,463.5 418,463.5 418.8,464.4 418.8,468.1 415.1,468.1 414.3,467.2 		"/>
	</g>
                                    <g>
		<rect x="414.3" y="463.5" width="3.7" height="3.7"/>
	</g>
                                    <g className="st6">
		<polygon points="415.1,474.6 418,475.2 418.6,475.2 418.8,475.4 418.8,478.3 415.1,478.3 		"/>
	</g>
                                    <g>
		<rect x="414.3" y="474.6" width="3.7" height="3.7"/>
	</g>
                                    <g className="st6">
		<polygon points="530.9,466 513.5,466 511.7,464.6 529.2,463.2 530.9,464.6 		"/>
	</g>
                                    <g>
		<rect x="511.7" y="463.2" className="st0" width="17.5" height="1.4"/>
	</g>
                                    <g className="st6">
		<polygon points="530.9,480 513.5,480 511.7,478.6 529.2,477.2 530.9,478.6 		"/>
	</g>
                                    <g>
		<rect x="511.7" y="477.2" className="st0" width="17.5" height="1.4"/>
	</g>
</g>
                                <g id="route">
	<path className="st24" d="M1019.5,286h-72.7c-24.6,0-44.6-20-44.6-44.6v-59c0-24.6-20-44.6-44.6-44.6H726c-19.3,0-36.5-12.4-42.4-30.8
		c-13-40.2-50.8-69.3-95.3-69.3c-44.5,0-82.3,29.1-95.3,69.3c-5.9,18.4-23.1,30.8-42.4,30.8h-28.2c-24.6,0-44.6,20-44.6,44.6v22.6
		c0,24.6-20,44.6-44.6,44.6H250c-24.6,0-44.6,20-44.6,44.6v211.7c0,24.6,20,44.6,44.6,44.6h83.2c24.6,0,44.6,20,44.6,44.6v22.6
		c0,24.6,20,44.6,44.6,44.6H575c19.3,0,36.7,12.4,42.4,30.8c13.1,42.1,52.4,72.7,98.9,72.7c46.5,0,85.8-30.6,98.9-72.7
		c5.7-18.5,23.1-30.8,42.4-30.8h0c24.6,0,44.6-20,44.6-44.6v-59c0-24.6,20-44.6,44.6-44.6h72.7c24.6,0,44.6-20,44.6-44.6V330.6
		C1064.1,306,1044.2,286,1019.5,286z"/>
</g>
                                <g id="ice_1_">
                                    <g>
                                        <g className="st6">
                                            <polygon points="822.1,709.5 805.6,693 820.1,675.3 813.9,672.7 815.4,660.7 841.4,657.3 858.4,668.2 859.4,669.2 852.8,690.5
                                                839.4,711.2 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="821.1,708.5 804.6,692 819.1,674.3 812.9,671.7 814.4,659.7 840.4,656.3 858.4,668.2 851.8,689.5
                                                838.4,710.2 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="244.3,485.8 227.8,469.3 242.3,451.6 248.9,448 250.4,435.9 275.4,431.6 276.4,432.6 281.6,445.6 260.2,465.3
				261.6,487.5 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="243.3,484.8 226.8,468.3 241.3,450.6 247.9,447 249.4,434.9 275.4,431.6 280.6,444.6 259.2,464.3
				260.6,486.5 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="462.7,363.2 446.2,346.7 460.7,329 454.5,326.4 453.5,325.4 456.1,314.4 482.1,311.1 499.1,322 500.1,323
				484.1,340.3 489.4,361.7 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="461.7,362.2 445.2,345.7 459.7,328 453.5,325.4 455.1,313.4 481.1,310.1 499.1,322 483.1,339.3
				488.4,360.7 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="562.9,109.7 552,100.7 551,99.7 566.5,83 560.2,80.4 572.6,67 587.8,65 604.8,75.9 605.8,76.9 593.4,84.7
				597.5,113.7 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="561.9,108.7 551,99.7 565.5,82 559.2,79.4 571.6,66 586.8,64 604.8,75.9 592.4,83.7 596.5,112.7
				"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="501.8,760.2 485.3,743.7 497.8,734.1 493.6,723.4 495.2,711.4 521.2,718.6 538.2,718.9 539.2,719.9 527.2,737.3
				528.5,758.6 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="500.8,759.2 484.3,742.7 496.8,733.1 492.6,722.4 494.2,710.4 520.2,717.6 538.2,718.9 526.2,736.3
				527.5,757.6 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="57.8,266.4 41.4,249.9 53.8,240.3 49.6,229.6 51.2,217.6 70.6,226.6 86.2,230.2 87.2,231.2 95.2,248.6
				84.5,264.8 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="56.8,265.4 40.4,248.9 52.8,239.3 48.6,228.6 50.2,216.6 69.6,225.6 86.2,230.2 94.2,247.6
				83.5,263.8 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="136.1,777.2 119.6,760.7 117.5,745.6 127.9,740.4 129.5,728.3 155.5,735.6 172.5,735.9 173.5,736.9 178.8,761.6
				162.8,775.6 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="135.1,776.2 118.6,759.7 116.5,744.6 126.9,739.4 128.5,727.3 154.5,734.6 172.5,735.9 177.8,760.6
				161.8,774.6 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="918.7,312.8 902.2,296.3 900,281.2 910.4,276 912,263.9 938,271.2 955,271.5 956,272.5 961.4,297.2 935.6,295.6
							"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="917.7,311.8 901.2,295.3 899,280.2 909.4,275 911,262.9 937,270.2 955,271.5 960.4,296.2
				934.6,294.6 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="353.1,617 352.1,616 347.6,600.5 351.6,590.1 344.8,580.2 346.4,568.2 372.4,575.5 389.4,575.7 390.4,576.7
				390.1,598.6 379.7,615.5 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="352.1,616 346.6,599.5 350.6,589.1 343.8,579.2 345.4,567.2 371.4,574.5 389.4,575.7 389.1,597.6
				378.7,614.5 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="646,236.1 645,235.1 640.5,219.6 644.5,209.2 657.4,208.1 659,196 665.3,194.5 682.3,194.8 683.3,195.8
				683,217.7 672.7,234.5 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="645,235.1 639.5,218.6 643.5,208.2 656.4,207.1 658,195 664.3,193.5 682.3,194.8 682,216.7
				671.7,233.5 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="139,68.3 138,67.3 127.6,52.2 131.6,41.8 124.8,31.9 126.4,19.8 152.4,27.1 169.4,37.7 170.4,38.7 160.9,47.3
				159.8,67.1 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="138,67.3 126.6,51.2 130.6,40.8 123.8,30.9 125.4,18.8 151.4,26.1 169.4,37.7 159.9,46.3
				158.8,66.1 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="1098.1,101.7 1079.4,95.4 1078.4,94.4 1088.6,81.2 1081.8,71.3 1083.4,59.3 1109.4,61.4 1127.4,78.1
				1117.9,86.8 1117.1,100 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="1097.1,100.7 1078.4,94.4 1087.6,80.2 1080.8,70.3 1082.4,58.3 1108.4,60.4 1126.4,77.1
				1116.9,85.8 1116.1,99 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="1062.9,810.8 1046.4,794.3 1048.2,778.8 1054.7,774 1056.3,762 1082.3,758.7 1102.4,779.3 1084.9,786.8
				1080.3,807.3 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="1061.9,809.8 1045.4,793.3 1047.2,777.8 1053.7,773 1055.3,761 1081.3,757.7 1101.4,778.3
				1083.9,785.8 1079.3,806.3 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="1173.2,313.8 1172.2,312.8 1165.3,299.6 1167,284 1173.5,279.3 1175.1,267.2 1200.1,262.9 1201.1,263.9
				1196.4,280.2 1203.8,292 1190.6,310.2 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="1172.2,312.8 1164.3,298.6 1166,283 1172.5,278.3 1174.1,266.2 1200.1,262.9 1195.4,279.2
				1202.8,291 1189.6,309.2 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="131.6,406.3 120.6,395.3 143.1,360.8 169.1,382.5 170.1,383.5 161.1,403.8 148.1,395.8 146.6,402.3 140.1,399.8
				135.6,412.3 134.6,411.3 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="130.6,405.3 119.6,394.3 142.1,359.8 169.1,382.5 160.1,402.8 147.1,394.8 145.6,401.3 139.1,398.8
				134.6,411.3 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="1205.3,593.1 1204.3,592.1 1204.8,582.1 1227.3,547.6 1244.3,554.5 1245.3,555.5 1254.4,586.3 1232.3,582.6
				1230.7,589.1 1224.3,586.6 1223.3,585.6 1219.8,575.5 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="1204.3,592.1 1203.8,581.1 1226.3,546.6 1244.3,554.5 1253.4,585.3 1231.3,581.6 1229.7,588.1
				1223.3,585.6 1218.8,574.5 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="707,540.8 706,539.8 703.5,529.8 717.3,504 734.5,511.4 753.5,507.6 754.5,508.6 744,526.6 731,530.3 734,539.6
				728.2,545.1 716.5,538.1 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="706,539.8 702.5,528.8 716.3,503 733.5,510.4 753.5,507.6 743,525.6 730,529.3 733,538.6
				727.2,544.1 715.5,537.1 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="732.8,56.6 731.8,55.6 729.3,45.6 728.5,26 743.1,19.7 759,19.7 779.3,23.4 780.3,24.4 772.2,49.3 759.2,53
				743.4,43.3 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="731.8,55.6 728.3,44.6 727.5,25 742.1,18.7 758,18.7 779.3,23.4 771.2,48.3 758.2,52 742.4,42.3
				"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon
                                                points="42.9,654.9 57.4,649.4 50.1,634.9 59.4,623.4 48.4,608.9 47.4,607.9 22.4,616.4 22.9,642.9 23.9,643.9 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9"
                                                     points="41.9,653.9 56.4,648.4 49.1,633.9 58.4,622.4 47.4,607.9 21.4,615.4 22.9,642.9 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="943.1,399.1 957.6,393.6 960.3,380.1 969.5,368.6 958.5,354.1 957.5,353.1 932.5,361.6 923.1,387.1 924.1,388.1
							"/>
                                        </g>
                                        <g>
                                            <polygon className="st9"
                                                     points="942.1,398.1 956.6,392.6 959.3,379.1 968.5,367.6 957.5,353.1 931.5,360.6 923.1,387.1 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="387.6,39.5 366.6,51.5 376.2,60 364.6,71 365.6,72 381.6,84 394.4,73.3 410.6,74 416.1,56 410.6,41 409.6,40
				"/>
                                        </g>
                                        <g>
                                            <polygon className="st9"
                                                     points="386.6,38.5 365.6,50.5 375.2,59 364.6,71 380.6,83 393.4,72.3 409.6,73 415.1,55 409.6,40 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g className="st6">
                                            <polygon points="256.2,236.8 235.2,248.8 232.2,257.3 233.2,268.3 234.2,269.3 248.2,263.3 262.9,270.5 279.2,271.3 284.7,253.3
				279.2,238.3 278.2,237.3 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st9" points="255.2,235.8 234.2,247.8 231.2,256.3 233.2,268.3 247.2,262.3 261.9,269.5 278.2,270.3 283.7,252.3
				278.2,237.3 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="topchev">
                                    <g>
                                        <path className="st3" d="M213.3,471.4v-6.7l-1.4-1.4c-0.5-6.1-5.3-7.6-6.2-7.6c-0.9,0-6.2,1.6-6.2,8.6c0,8,0,18.9,0,36.2
                                            c0,3,1,4.8,1,4.8h10.4c0,0,1-1.8,1-4.8c0-6.1,0-11.4,0-16h1v-10h-1c0-1.1,0-2.1,0-3.1H213.3z"/>
                                        <g>
                                            <path className="st25" d="M209.9,462.3c0,8.2,0,18.9,0,36.2c0,3-1,4.8-1,4.8h-10.4c0,0-1-1.8-1-4.8c0-17.3,0-28.1,0-36.2
                                                c0-7,5.4-8.6,6.2-8.6C204.5,453.7,209.9,455.4,209.9,462.3z"/>
                                        </g>
                                        <polygon className="st3" points="209,465.4 208.4,461.5 206.4,459.5 203,461.5 202.4,465.4 202,472.7 202,475.5 205.9,475.5
                                            205.9,483 206.4,483.5 209.4,483.5 209.4,475.5 209.4,474 209.4,472.7 		"/>
                                        <g>
                                            <polygon className="st26"
                                                     points="207,463.4 206.4,459.5 201,459.5 200.4,463.4 200,470.7 200,473.5 207.4,473.5 207.4,470.7 			"/>
                                        </g>
                                        <g className="st3">
                                            <polygon points="201.5,459.5 201,459 203.1,455.5 205,454.7 205.8,455.5 207,459.5 			"/>
                                        </g>
                                        <g>
                                            <polygon className="st26" points="206.3,459 201,459 202.3,454.7 205,454.7 			"/>
                                        </g>
                                        <rect x="200" y="473.5" className="st19" width="7.5" height="29.8"/>
                                        <g>
                                            <rect x="205.9" y="473.5" className="st27" width="3" height="9.5"/>
                                        </g>
                                        <g className="st3">
                                            <path d="M197.8,498.8c0,2.3,0.6,3.8,0.9,4.5l1.6,0v-29.5h-2.5C197.8,480.3,197.8,488.4,197.8,498.8z"/>
                                        </g>
                                        <g>
                                            <path className="st25" d="M197.5,498.5c0,3,1,4.8,1,4.8h1.4v-29.8h-2.5C197.5,480,197.5,488.1,197.5,498.5z"/>
                                        </g>
                                        <g className="st3">
                                            <polygon points="202,483.5 199,483.5 198.5,483 198.5,473.5 201.5,473.5 202,474 			"/>
                                        </g>
                                        <rect x="198.5" y="473.5" className="st27" width="3" height="9.5"/>
                                        <g className="st3">
                                            <polygon
                                                points="206.5,464.8 206.1,462.1 202.3,462.1 201.9,464.8 201.6,470 201.6,471.9 206.8,471.9 206.8,470 			"/>
                                            <polygon points="206.5,464.8 206.1,462.1 205.6,461.6 202.3,462.1 201.9,464.8 201.6,470 201.1,471.4 201.6,471.9 206.8,471.9
                                                206.8,470 			"/>
                                        </g>
                                        <g>
                                            <g>
                                                <polygon className="st26" points="206,464.3 205.6,461.6 201.8,461.6 201.4,464.3 201.1,469.5 201.1,471.4 206.3,471.4 206.3,469.5
                                                                    "/>
                                            </g>
                                            <g>
                                                <polygon className="st18" points="206,464.3 205.6,461.6 201.8,461.6 201.4,464.3 201.1,469.5 201.1,471.4 206.3,471.4 206.3,469.5
                                                                    "/>
                                            </g>
                                        </g>
                                        <path className="st3" d="M207.1,464.3c-2.2,0.4-4.5,0.4-6.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.2c0,0,0,0,0,0c0,0,0,2.1,0,2.1
                                            c0.2,0.1,0.4,0.1,0.6,0.2c2.3,0.6,4.7,0.6,7,0.2L207.1,464.3z"/>
                                        <g>
                                            <path className="st9"
                                                  d="M208.3,465.4c-3,0.9-6.2,0.9-9.2,0c0,0,0-2.1,0-2.1c3,1,6.2,1,9.2,0C208.3,463.4,208.3,465.4,208.3,465.4z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g id="clouds" className="st28">
                                    <g className="st29">
                                        <circle className="st18" cx="186" cy="523.5" r="51"/>
                                        <circle className="st18" cx="242.8" cy="513.6" r="36.1"/>
                                        <circle className="st18" cx="138.5" cy="509.9" r="32.3"/>
                                        <circle className="st18" cx="138.5" cy="542.2" r="16.1"/>
                                    </g>
                                    <g className="st30">
		<circle className="st18" cx="196.5" cy="206.1" r="51"/>
                                        <circle className="st18" cx="253.3" cy="196.2" r="49.7"/>
                                        <circle className="st18" cx="149.1" cy="192.4" r="32.3"/>
                                        <circle className="st18" cx="149.1" cy="224.7" r="16.1"/>
	</g>
                                    <g className="st31">
		<circle className="st18" cx="829.5" cy="275.1" r="81.8"/>
                                        <circle className="st18" cx="898.8" cy="313.2" r="57.8"/>
                                        <circle className="st18" cx="891.3" cy="233.4" r="51.8"/>
                                        <circle className="st18" cx="731.4" cy="285.1" r="51.8"/>
	</g>
                                    <g className="st32">
		<circle className="st9" cx="662.4" cy="697.1" r="44.6"/>
                                        <circle className="st9" cx="707" cy="697.1" r="44.6"/>
                                        <circle className="st9" cx="685.2" cy="672" r="31.6"/>
                                        <circle className="st9" cx="620.9" cy="685.2" r="28.2"/>
	</g>
                                    <g className="st32">
		<circle className="st9" cx="1172.5" cy="165.8" r="39.6"/>
                                        <circle className="st9" cx="1217.1" cy="165.8" r="44.6"/>
                                        <circle className="st9" cx="1195.3" cy="140.7" r="31.6"/>
                                        <circle className="st9" cx="1142" cy="144" r="28.2"/>
	</g>
                                    <g className="st32">
		<circle className="st9" cx="25.3" cy="709.6" r="39.6"/>
                                        <circle className="st9" cx="-19.4" cy="709.6" r="44.6"/>
                                        <circle className="st9" cx="2.5" cy="684.4" r="31.6"/>
                                        <circle className="st9" cx="55.8" cy="687.7" r="28.2"/>
	</g>
                                </g>
                            </svg>

                            <div id="level4SeaBg"></div>

                        </div>

                        <div id="level5" className="level">

                            <div id="scene51" className="scene">
                                <div className="sceneContent">
                                    <div className="level">Уровень 5</div>
                                    <div className="question">Вы знаете <br/> товарный знак <br/> арктической <br/> нефти? <br/>Давайте <br/> проверим!</div>
                                    <button className="goAhead" onClick={this.goLevel51Scene1}>Далее</button>
                                </div>
                            </div>

                            <div id="scene52" className="scene">
                                <div className="sceneContent">
                                    <a id="level51Answer1" className="answer" href="#" onClick={this.doAnswerLevel51}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="options">
                                                    <circle className="cls-1" cx="130" cy="130" r="120"/>
                                                    <circle className="cls-2" cx="129.84" cy="102.19" r="21.94"/>
                                                    <line className="cls-3" x1="59.25" y1="190.71" x2="199.97"
                                                          y2="190.71"/>
                                                    <line className="cls-3" x1="64.04" y1="133.46" x2="64.04"
                                                          y2="190.71"/>
                                                    <polyline className="cls-4"
                                                              points="109.15 120.41 63.8 132.56 58.37 112.3 108.14 98.97"/>
                                                    <line className="cls-4" x1="172.85" y1="103.34" x2="150.6"
                                                          y2="109.3"/>
                                                    <line className="cls-4" x1="147.02" y1="88.55" x2="167.43"
                                                          y2="83.08"/>
                                                    <polygon className="cls-4"
                                                             points="182.19 125.14 168.68 93.6 164.61 59.53 181.15 55.1 194.66 86.64 198.73 120.71 182.19 125.14"/>
                                                    <line className="cls-4" x1="148.09" y1="114.37" x2="178.94"
                                                          y2="190.71"/>
                                                    <line className="cls-4" x1="161.11" y1="190.71" x2="134.2"
                                                          y2="124.09"/>
                                                    <polyline className="cls-4"
                                                              points="111.59 114.37 108.42 122.21 80.75 190.71"/>
                                                    <line className="cls-4" x1="98.57" y1="190.71" x2="125.49"
                                                          y2="124.09"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Конечно Urals!</span>
                                        <div className="result">
                                            <p><strong>Увы, неверно.</strong><br/>Urals — сорт российской высокосернистой нефти, добываемой в Ханты-Мансийскоми Ямало-Ненецком автономных округах, Башкортостане, Татарстанеи Самарской области</p>
                                            <button>Заново</button>
                                        </div>
                                    </a>
                                    <a id="level51Answer2" className="answer" href="#" onClick={this.doAnswerLevel51}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
                                            <title>Ресурс 2</title>
                                            <g id="Слой_2" data-name="Слой 2">
                                                <g id="options">
                                                    <circle className="cls-1" cx="130" cy="130" r="120"/>
                                                    <rect className="cls-2" x="68.65" y="130.18" width="124.29"
                                                          height="25.92"/>
                                                    <line className="cls-2" x1="78.15" y1="156.1" x2="78.15"
                                                          y2="178.85"/>
                                                    <line className="cls-2" x1="183.45" y1="156.1" x2="183.45"
                                                          y2="178.85"/>
                                                    <polyline className="cls-2"
                                                              points="87.7 130.18 97.61 57.04 118.06 57.04 127.97 130.18"/>
                                                    <line className="cls-2" x1="95.38" y1="73.53" x2="118.55"
                                                          y2="60.16"/>
                                                    <line className="cls-2" x1="92.18" y1="97.15" x2="121.17"
                                                          y2="80.45"/>
                                                    <line className="cls-2" x1="88.14" y1="126.99" x2="124.65"
                                                          y2="105.94"/>
                                                    <line className="cls-2" x1="95.38" y1="73.53" x2="121.24"
                                                          y2="80.45"/>
                                                    <line className="cls-2" x1="92.21" y1="97.19" x2="124.68"
                                                          y2="105.89"/>
                                                    <polyline className="cls-2"
                                                              points="144.78 130.18 144.78 103.19 172.94 103.19 172.94 130.18"/>
                                                    <polyline className="cls-2"
                                                              points="172.54 103.98 196.86 89.94 196.86 117.82"/>
                                                    <path className="cls-2"
                                                          d="M57.5,183.13c12.21,0,12.21,10.09,24.43,10.09s12.22-10.09,24.43-10.09,12.22,10.09,24.44,10.09,12.21-10.09,24.43-10.09,12.22,10.09,24.43,10.09,12.22-10.09,24.44-10.09"/>
                                                    <line className="cls-2" x1="91.88" y1="156.1" x2="91.88"
                                                          y2="178.85"/>
                                                    <line className="cls-2" x1="169.72" y1="156.1" x2="169.72"
                                                          y2="178.85"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>Однозначно Arco!</span>
                                        <div className="result">
                                            <p><strong>Верно!</strong><br/>Сорт нефти, добываемый на Приразломном месторождении, называется ARCO (сокращение от ARСtic Oil). Сорт отличается высокой плотностью (порядка 906 кг/м<sup>3</sup>), повышенным содержанием фракций для производства масел и низким содержанием парафина</p>
                                            <button>Дальше</button>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div id="scene53" className="scene">
                                <div className="sceneContent">
                                    <form id="level51Success" className="levelSuccess">
                                        <p><span className="success">Поздравляем!</span><br/> Теперь вы знаете<br/> о нефти немного<br/> больше!</p>
                                    </form>
                                </div>
                            </div>

                            <svg id="level5Bg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 800" xmlSpace="preserve">
                                <g id="bg">
                                    <rect className="st0" width="1280" height="800"/>
                                </g>
                                <g id="men">
                                    <g id="legs">
                                        <g>
                                            <rect x="800.9" y="835.1" className="st1" width="123.4" height="382.6"/>
                                            <rect x="800.9" y="1135.4" className="st2" width="123.4" height="82.3"/>
                                            <rect x="800.9" y="1020.9" className="st3" width="123.4" height="35.1"/>
                                            <rect x="800.9" y="916.5" className="st2" width="123.4" height="104.5"/>
                                            <rect x="800.9" y="835.1" className="st4" width="123.4" height="27.5"/>
                                        </g>
                                        <g>

                                            <rect x="954" y="835.1" transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 2031.4413 2052.821)"
                                                  className="st1" width="123.4" height="382.6"/>
                                            <rect x="954" y="1135.4"
                                                  transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 2031.4413 2353.1072)"
                                                  className="st2" width="123.4" height="82.3"/>

                                            <rect x="954" y="1020.9"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 2076.9272)"
                                                  className="st3" width="123.4" height="35.1"/>

                                            <rect x="954" y="916.5"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 1937.364)"
                                                  className="st2" width="123.4" height="104.5"/>

                                            <rect x="954" y="835.1"
                                                  transform="matrix(-1 -4.488289e-11 4.488289e-11 -1 2031.4413 1697.7552)"
                                                  className="st4" width="123.4" height="27.5"/>
                                        </g>
                                    </g>
                                    <g id="shoes_1_">
                                        <g>
                                            <path className="st5" d="M924.4,1217.7H800.9v-38.3h85.2C907.3,1179.5,924.4,1196.6,924.4,1217.7L924.4,1217.7z"/>
                                            <path className="st5" d="M810.4,1164.2L810.4,1164.2c-26.1,0-47.2,21.2-47.2,47.2v6.3h94.5v-6.3
                                            C857.7,1185.3,836.5,1164.2,810.4,1164.2z"/>
                                        </g>
                                        <g>
                                            <path className="st5" d="M954,1217.7h123.4v-38.3h-85.2C971.1,1179.5,954,1196.6,954,1217.7L954,1217.7z"/>
                                            <path className="st5" d="M1068,1164.2L1068,1164.2c26.1,0,47.2,21.2,47.2,47.2v6.3h-94.5v-6.3
                                            C1020.7,1185.3,1041.9,1164.2,1068,1164.2z"/>
                                        </g>
                                    </g>
                                    <g id="body_1_">
                                        <path className="st1" d="M788.9,837h150V364.7H817c-28.4,0-51.5,23-51.5,51.5v397.5C765.6,826.6,776,837,788.9,837z"/>
                                        <rect x="765.6" y="424.2" className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M880.2,506.3h-55.9c-10.7,0-19.3-8.7-19.3-19.3v-33.5h94.6v33.5C899.6,497.6,890.9,506.3,880.2,506.3z"/>
                                        <path className="st6"
                                              d="M818.7,756.4h67c10.6,0,19.2-8.6,19.2-19.2v-94.6H799.6v94.6C799.6,747.8,808.2,756.4,818.7,756.4z"/>
                                        <path className="st4"
                                              d="M814.8,683.3h74.9c8.4,0,15.2-6.8,15.2-15.2v-25.5H799.6v25.5C799.6,676.5,806.4,683.3,814.8,683.3z"/>
                                        <rect x="825" y="472.2" className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M842.3,642.6h-76.7v-32.4h44.3C827.8,610.3,842.3,624.7,842.3,642.6L842.3,642.6z"/>
                                        <path className="st1"
                                              d="M1089,837H939V364.7h121.9c28.4,0,51.5,23,51.5,51.5v397.5C1112.3,826.6,1101.9,837,1089,837z"/>

                                        <rect x="939" y="424.2"
                                              transform="matrix(-1 -4.488333e-11 4.488333e-11 -1 2051.2903 880.933)"
                                              className="st2" width="173.4" height="32.5"/>
                                        <path className="st2"
                                              d="M997.7,506.3h55.9c10.7,0,19.3-8.7,19.3-19.3v-33.5h-94.6v33.5C978.3,497.6,987,506.3,997.7,506.3z"/>
                                        <path className="st6"
                                              d="M1059.2,756.4h-67c-10.6,0-19.2-8.6-19.2-19.2v-94.6h105.4v94.6C1078.3,747.8,1069.7,756.4,1059.2,756.4z"/>
                                        <path className="st4"
                                              d="M1063.1,683.3h-74.9c-8.4,0-15.2-6.8-15.2-15.2v-25.5h105.4v25.5C1078.3,676.5,1071.5,683.3,1063.1,683.3z"/>

                                        <rect x="998.4" y="472.2"
                                              transform="matrix(-1 -4.490243e-11 4.490243e-11 -1 2051.2903 959.7552)"
                                              className="st7" width="54.5" height="15.3"/>
                                        <path className="st3"
                                              d="M1035.7,642.6h76.7v-32.4H1068C1050.1,610.3,1035.7,624.7,1035.7,642.6L1035.7,642.6z"/>
                                        <path className="st3"
                                              d="M842.3,810.3h-76.7v-32.4h44.3C827.8,777.9,842.3,792.4,842.3,810.3L842.3,810.3z"/>
                                        <path className="st3"
                                              d="M1035.7,810.3h76.7v-32.4H1068C1050.1,777.9,1035.7,792.4,1035.7,810.3L1035.7,810.3z"/>
                                        <path className="st5" d="M998,387v-22.3h-9.9V387c0,4.7-3.8,8.4-8.4,8.4h0c-3.8,0-6.8,3.1-6.8,6.8v25.1c0,8.3,6.7,15,15,15h10.3
                                        c8.3,0,15-6.7,15-15v-25.1c0-3.8-3.1-6.8-6.8-6.8l0,0C1001.8,395.5,998,391.7,998,387z"/>
                                        <path className="st8" d="M888.4,364.7v41.4c0,20.1-16.3,36.3-36.3,36.3h0c-7.7,0-13.9,6.2-13.9,13.9v47.8c0,16.2,13.1,29.3,29.3,29.3h0
                                        "/>
                                    </g>
                                    <g id="neck">
                                        <rect x="888.4" y="253.7" className="st9" width="101.7" height="111"/>
                                    </g>
                                    <g id="kapyushon">
                                        <path className="st10" d="M973,295.8l-6.2,6.2C969.1,300.1,971.2,298.1,973,295.8z"/>
                                        <path className="st11"
                                              d="M958.4,310.4l8.4-8.4c-3.2,2.6-6.8,4.8-10.6,6.4C956.9,309.1,957.7,309.7,958.4,310.4z"/>
                                        <g>
                                            <g>
                                                <path className="st4" d="M852.1,268.2v-1.7c-25.2,27.2-33.6,64.3-25.1,98.2h72.3c-7.4-7.5-10.8-16.5-10.6-25.6
                                                C866.6,323.3,852.1,297.4,852.1,268.2z"/>
                                                <path className="st11" d="M900.3,311.9c3.6-4,7.8-7,12.3-9.1c-10.4-8-17.1-20.5-17.1-34.6v-30c-14.9,5.1-28.8,13.5-40.6,25.4
                                                c-1,1-1.8,2-2.8,2.9v1.7c0,29.2,14.5,55.1,36.6,70.9C888.9,329.6,892.9,320,900.3,311.9z"/>
                                            </g>
                                            <g>
                                                <path className="st4" d="M1026.5,268.2v-1.7c25.2,27.2,33.6,64.3,25.1,98.2h-72.3c7.4-7.5,10.8-16.5,10.6-25.6
                                                C1012,323.3,1026.5,297.4,1026.5,268.2z"/>
                                                <path className="st11" d="M978.3,311.9c-3.6-4-7.8-7-12.3-9.1c10.4-8,17.1-20.5,17.1-34.6v-30c14.9,5.1,28.8,13.5,40.6,25.4
                                                c1,1,1.8,2,2.8,2.9v1.7c0,29.2-14.5,55.1-36.6,70.9C989.6,329.6,985.6,320,978.3,311.9z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="hands">
                                        <g id="rightHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="703.4" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1"
                                                      d="M762.5,527.3c0-28.8-23.4-52.2-52.2-52.2c-28.8,0-52.2,23.4-52.2,52.2v101.5h104.5V527.3z"/>
                                                <path className="st13"
                                                      d="M710.2,730.9c28.8,0,52.2-23.4,52.2-52.2v-49.9H658v49.9C658,707.5,681.4,730.9,710.2,730.9z"/>
                                                <path className="st3" d="M734.7,620.6H658v-32.4h44.3C720.2,588.2,734.7,602.7,734.7,620.6L734.7,620.6z"/>
                                                <rect x="657.9" y="619.8" className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M671.6,560.9L671.6,560.9c20.4,20.4,53.5,20.4,73.9,0l107-107c20.4-20.4,20.4-53.5,0-73.9l0,0
                                                c-20.4-20.4-53.5-20.4-73.9,0l-107,107C651.2,507.4,651.2,540.5,671.6,560.9z"/>

                                                <rect x="718.6" y="405.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 552.2482 -409.795)" className="st2"
                                                      width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M725.5,541.5l-54.2-54.2l22.9-22.9l31.3,31.3C738.2,508.4,738.2,528.9,725.5,541.5L725.5,541.5z"/>
                                            </g>
                                        </g>
                                        <g id="leftHand">
                                            <g className="forearm">
                                                <g>
                                                    <circle className="st13" cx="1175" cy="682.4" r="58.8"/>
                                                </g>
                                                <path className="st1"
                                                      d="M1115.9,527.3c0-28.8,23.4-52.2,52.2-52.2c28.8,0,52.2,23.4,52.2,52.2v101.5h-104.5V527.3z"/>
                                                <path className="st13"
                                                      d="M1168.1,730.9c-28.8,0-52.2-23.4-52.2-52.2v-49.9h104.5v49.9C1220.4,707.5,1197,730.9,1168.1,730.9z"/>
                                                <path className="st3"
                                                      d="M1143.7,620.6h76.7v-32.4h-44.3C1158.2,588.2,1143.7,602.7,1143.7,620.6L1143.7,620.6z"/>

                                                <rect x="1115.9" y="619.8" transform="matrix(-1 -4.493396e-11 4.493396e-11 -1 2336.4246 1248.5316)"
                                                      className="st2" width="104.6" height="9"/>
                                            </g>
                                            <g>
                                                <path className="st1" d="M1206.8,560.9L1206.8,560.9c-20.4,20.4-53.5,20.4-73.9,0l-107-107c-20.4-20.4-20.4-53.5,0-73.9l0,0
                                                c20.4-20.4,53.5-20.4,73.9,0l107,107C1227.2,507.4,1227.2,540.5,1206.8,560.9z"/>

                                                <rect x="1055.4" y="405.6"
                                                      transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2217.2639 5.0293)"
                                                      className="st2" width="104.5" height="112.2"/>
                                                <path className="st3"
                                                      d="M1152.8,541.5l54.2-54.2l-22.9-22.9l-31.3,31.3C1140.2,508.4,1140.2,528.9,1152.8,541.5L1152.8,541.5z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="head_1_">
                                        <path className="st14" d="M863.2,234.4h151.9c8,0,14.5-6.5,14.5-14.5v0c0-8-6.5-14.5-14.5-14.5H863.2c-8,0-14.5,6.5-14.5,14.5v0
                                        C848.8,228,855.2,234.4,863.2,234.4z"/>
                                        <path className="st15" d="M939.2,333.6L939.2,333.6c-36.1,0-65.4-29.3-65.4-65.4v-111h130.8v111C1004.6,304.3,975.3,333.6,939.2,333.6z
                                        "/>
                                        <path className="st16" d="M965.3,224.3h-52.2c-8.3,0-15.1,6.8-15.1,15.1v79.5c11.2,9.1,25.6,14.6,41.2,14.6s29.9-5.5,41.2-14.6v-79.5
                                        C980.4,231.1,973.6,224.3,965.3,224.3z"/>
                                        <path className="st17" d="M918.9,209.1h40.6c3.4,0,6.1,2.7,6.1,6.1v0c0,3.4-2.7,6.1-6.1,6.1h-40.6c-3.4,0-6.1-2.7-6.1-6.1v0
                                        C912.8,211.9,915.5,209.1,918.9,209.1z"/>
                                        <path className="st18" d="M972.7,202.9L972.7,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
                                        C976.8,201,975,202.9,972.7,202.9z"/>
                                        <path className="st18" d="M905.4,202.9L905.4,202.9c-2.3,0-4.1-1.8-4.1-4.1v-7.2c0-2.3,1.8-4.1,4.1-4.1l0,0c2.3,0,4.1,1.8,4.1,4.1v7.2
                                        C909.5,201,907.7,202.9,905.4,202.9z"/>
                                        <g id="mouth">
                                            <path className="st19"
                                                  d="M951.2,240.2v2.4c0,5.5-4.4,9.9-9.9,9.9H905c0.1,2,0.6,4,1.4,5.8h20.1c4.6,0,8.3,3.7,8.3,8.3v3.1h20 c10.3,0,18.6-8.3,18.6-18.6v-11H951.2z"/>
                                            <path className="st3"
                                                  d="M951.2,242.6v-2.4H905v11c0,0.5,0,0.9,0.1,1.4h36.3C946.8,252.5,951.2,248.1,951.2,242.6z"/>
                                            <path className="st20"
                                                  d="M926.5,258.3h-20.1c2.8,6.7,9.4,11.4,17.1,11.4h11.3v-3.1C934.9,262,931.1,258.3,926.5,258.3z"/>
                                        </g>
                                        <g id="upset" style={{opacity: 0}}>
                                            <path className="st19" d="M959.2,259.1c-1.5,0-2.9-0.8-3.6-2.3c-2.6-5.2-9.6-8.5-17.9-8.5s-15.3,3.3-17.9,8.5c-1,2-3.5,2.8-5.5,1.8	c-2-1-2.8-3.5-1.8-5.5c4-8,13.6-13,25.1-13c11.5,0,21.1,5,25.1,13c1,2,0.2,4.5-1.8,5.5C960.4,258.9,959.8,259.1,959.2,259.1z"/>
                                        </g>
                                        <rect x="861.1" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <rect x="1004" y="175.8" className="st21" width="12.7" height="39.4"/>
                                        <path className="st21"
                                              d="M887.8,226.5h-26.8v-26.8l0,0C875.8,199.7,887.8,211.7,887.8,226.5L887.8,226.5z"/>
                                        <path className="st21"
                                              d="M990,226.5h26.8v-26.8l0,0C1002,199.7,990,211.7,990,226.5L990,226.5z"/>
                                        <rect x="923.4" y="157.3" className="st22" width="31.5" height="64.1"/>
                                        <path className="st23"
                                              d="M1019.4,179.5h-62.4c-9.6,0-17.5-7.8-17.5-17.5v-4.8h97.4v4.8C1036.8,171.6,1029,179.5,1019.4,179.5z"/>
                                        <path className="st23"
                                              d="M899.2,179.5h-30.6c-9.6,0-17.5-7.8-17.5-17.5v-4.8h65.5v4.8C916.7,171.6,908.9,179.5,899.2,179.5z"/>
                                    </g>
                                    <g id="vorotnik">
                                        <path className="st1" d="M1026.5,368h-174v-9.3c0-19.4,15.7-35.1,35.1-35.1h103.9c19.4,0,35.1,15.7,35.1,35.1V368z"/>
                                        <rect x="912.1" y="323.6" className="st6" width="54.7" height="513.4"/>
                                        <g>
                                            <path className="st5" d="M893,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9H893c2.1,0,3.9,1.7,3.9,3.9l0,0
                                            C896.9,348.9,895.1,350.6,893,350.6z"/>
                                            <path className="st5" d="M1000.9,350.6h-15.5c-2.1,0-3.9-1.7-3.9-3.9l0,0c0-2.1,1.7-3.9,3.9-3.9h15.5c2.1,0,3.9,1.7,3.9,3.9l0,0
                                            C1004.7,348.9,1003,350.6,1000.9,350.6z"/>
                                        </g>
                                    </g>
                                    <g id="helmet">
                                        <path className="st3"
                                              d="M939.2,37L939.2,37c-47.1,0-85.2,38.1-85.2,85.2v27.9h170.4v-27.9C1024.4,75.2,986.2,37,939.2,37z"/>
                                        <path className="st24" d="M1040.2,168.3H838.1v0c0-11.6,9.4-21.1,21.1-21.1h159.9C1030.8,147.2,1040.2,156.7,1040.2,168.3L1040.2,168.3
                                        z"/>
                                        <path className="st25" d="M924,125.4l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V39c-5.9-1.3-11.9-2-18.2-2
                                        s-12.3,0.7-18.2,2v79.5C921,121.1,922.1,123.6,924,125.4z"/>
                                        <path className="st25" d="M877,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V42c-14.3,5.1-26.8,14-36.3,25.4v30.8
                                        C874,100.8,875.1,103.4,877,105.2z"/>
                                        <path className="st25" d="M971,105.2l8.5,8.2c3.7,3.6,9.6,3.6,13.3,0l8.5-8.2c1.9-1.8,3-4.3,3-7V67.4C994.8,56,982.3,47.2,968.1,42
                                        v56.2C968.1,100.8,969.1,103.4,971,105.2z"/>
                                    </g>
                                </g>
                            </svg>

                        </div>

                        <a id="close" href='/'>
                            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 35 34" xmlSpace="preserve">
                                <g>
                                    <line className="st0" x1="32.4" y1="2.2" x2="2.4" y2="32.2"/>
                                    <line className="st0" x1="32.4" y1="32.2" x2="2.4" y2="2.2"/>
                                </g>
                            </svg>
                        </a>

                        <a id="mute" className="muted" href="#">
                            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 44" xmlSpace="preserve">
                                <g>
                                    <path className="st0" d="M29.5,2.3l-13.6,8.4c0,0,0,0,0,0H4c-1.3,0-2.3,1-2.3,2.3v18.1c0,1.3,1,2.3,2.3,2.3h11.9c0,0,0,0,0,0l13.6,8.4 c1.4,0.8,3.1-0.2,3.1-1.8V4.1C32.6,2.6,30.9,1.6,29.5,2.3z"/>
                                    <g id="unmuted">
                                        <line className="st0" x1="57.9" y1="13.6" x2="41.8" y2="29.6"/>
                                        <line className="st0" x1="57.9" y1="29.6" x2="41.8" y2="13.6"/>
                                    </g>
                                    <g id="muted">
                                        <path className="st1" d="M40.3,13.1c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5"/>
                                        <path className="st1" d="M40.3,2.2c10.7,0,19.4,8.7,19.4,19.4S51.1,41,40.3,41"/>
                                    </g>
                                </g>
                            </svg>
                            <audio id="soundsound" src="/music/sound1.mp3" muted loop={true}></audio>
                        </a>

                        <div id="level">
                            <svg version="1.1" id="level_indicator" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58.53 58.31" xmlSpace="preserve">
                                <circle className="st1" cx="29.22" cy="29.01" r="25"/>
                                <circle className="st2" cx="29.22" cy="29.01" r="25"/>
                            </svg>
                            <div className="levelText"><span className="currentLevel">1</span><span className="levelsDivider">/</span><span className="allLevels">5</span></div>
                        </div>

                    </div>

                ) : (

                    <div id="wrapperGame" className="wrapper">
                        <p>
                            <img src="/loading.gif" alt="Загрузка..." />
                        </p>
                    </div>

                )}


            </DocumentMeta>
        )

    }

}
