import React, {Component} from 'react';

export class Test2 extends Component {

    constructor(props) {
        super(props);
        // reference to the DOM node
    }

    componentDidMount() {
        
        var questions = {
            0 : {
                'question' : 'Почему нефтегазовые компании по всему миру все чаще смотрят в сторону разведки и добычи углеводородов на шельфе?',
                'answers' : {
                    0 : 'Все просто: в море нефть и газ добывать легче, потому что они залегают не так глубоко',
                    1 : 'Это самое перспективное направление в условиях сокращения количества запасов легкодоступной нефти на суше',
                    2 : 'Под водой запасов нефти и газа всегда было больше, просто люди не умели их добывать',
                },
                'right_answer' : 1,
                'true' : '<span class="successful">Верно!</span> Мировая добыча углеводородов постепенно смещается в сторону морских месторождений, потому что на суше месторождения почти истощены. Россия также успешно наращивает запасы и добычу на шельфе, наиболее перспективной неизведанной частью которого является Арктика.',
                'false' : '<span class="unsuccessful">Вы не правы!</span> Мировая добыча углеводородов постепенно смещается в сторону морских месторождений, потому что на суше запасы почти истощены. Россия также успешно наращивает добычу на шельфе, наиболее перспективной неизведанной частью которого является Арктика.',
            },
            1 : {
                'question' : 'С помощью чего добывают «черное золото» с морского и океанского дна?',
                'answers' : {
                    0 : 'Строят специальные пирсы, уходящие далеко от берега',
                    1 : 'С помощью очень сложных технических сооружений - нефтяных платформ, позволяющих проводить как само бурение, так и непосредственную добычу углеводородов ',
                    2 : 'С помощью подводных буров прямо с берега',
                },
                'right_answer' : 1,
                'true' : '<span class="successful">Да!</span> Морские нефтегазодобывающие платформы представляют собой сложнейшие инженерные комплексы на огромных массивных опорах, добывающие нефть и газ с морских глубин по всему свету. Такие платформы – это целые «технограды», на борту которых находится самое современное и уникальное оборудование, с помощью которого происходит бурение скважин протяженностью до 10 км.',
                'false' : '<span class="unsuccessful">О, нет!</span> Добычу на шельфе ведут морские нефтегазодобывающие платформы, представляющие собой сложнейшие инженерные комплексы на огромных массивных опорах, добывающие нефть и газ с морских глубин по всему свету. Такие платформы – это целые «технограды», на борту которых находится самое современное и уникальное оборудование, с помощью которого происходит бурение скважин протяженностью до 10 км.',
            },
            2 : {
                'question' : 'Что чаще всего мешает работе морских нефтяных платформ? ',
                'answers' : {
                    0 : 'Высокая сейсмичность',
                    1 : 'Наличие в северных широтах айсбергов и дрейфующих ледовых полей',
                    2 : 'Сильные подводные течения и большие глубины',
                    3 : 'Разного рода природные катаклизмы – смерчи, ураганы, подводные землетрясения и цунами',
                    4 : 'Все вышеперечисленное',
                },
                'right_answer' : 4,
                'true' : '<span class="successful">Вы правы!</span> Освоение континентального шельфа обусловлено суровыми климатическими условиями и сложной ледовой обстановкой, низкой геологической изученностью недр, ограниченностью и подчас невозможностью применения традиционных методов обеспечения безопасной работы буровых установок. В этих условиях решение ключевых технических проблем безаварийной работы платформ без применения инновационных технологий просто невозможно.',
                'false' : '<span class="unsuccessful">На самом деле верны все перечисленные ответы!</span> Освоение континентального шельфа обусловлено суровыми климатическими условиями и сложной ледовой обстановкой, низкой геологической изученностью недр, ограниченностью и подчас невозможностью применения традиционных методов обеспечения безопасной работы буровых установок. В этих условиях решение ключевых технических проблем безаварийной работы платформ без применения инновационных технологий просто невозможно.',
            },
            3 : {
                'question' : 'Есть ли принципиальные отличия между процессом извлечения углеводородного сырья между добывающими скважинами морского и сухопутного типа?',
                'answers' : {
                    0 : 'Нет, основные элементы стационарной морской платформы совпадают с основными элементами сухопутного промысла',
                    1 : 'Да! Нефтяные платформы работают совсем иначе',
                    2 : 'Есть похожие технологические решения, но в целом процесс отличается',
                },
                'right_answer' : 0,
                'true' : '<span class="successful">Все верно,</span> каких-либо принципиальных отличий между процессом извлечения углеводородного сырья и его последующей отгрузки между добывающими скважинами морского и сухопутного типа нет, а основные элементы стационарной морской платформы совпадают с основными элементами сухопутного промысла.',
                'false' : '<span class="unsuccessful">Вы не правы!</span> На практике каких-либо принципиальных отличий между процессом извлечения углеводородного сырья и его последующей отгрузки между добывающими скважинами морского и сухопутного типа нет, а основные элементы стационарной морской платформы совпадают с основными элементами сухопутного промысла.',
            },
            4 : {
                'question' : 'Нефтяная платформа- типовое сооружение, производство которых поставлено на поток?',
                'answers' : {
                    0 : 'Да, это инженерные комплексы, которые похожи между собой. Различаются только фирмы, их изготавливающие ',
                    1 : 'Нет, выбор конкретного типа морской платформы производится с учетом конкретных задач, выполнение которых она должна обеспечивать, а также с учетом особенностей конкретного месторождения',
                    2 : 'В основном платформы похожи между собой, но бывает и так, что платформу изготавливают под конкретную задачу',
                },
                'right_answer' : 1,
                'true' : '<span class="successful">Вы правы!</span> Различные типы морских буровых платформ могут как относиться к какому-либо конкретному виду (стационарные, свободно закрепленные, полупогружные и т.д.), так и быть комбинированными. Выбор производится  с учетом конкретных задач, выполнение которых платформа должна обеспечивать, а также с учетом особенностей конкретного месторождения, поэтому говорить о существовании каких-либо типовых платформ, производство которых можно было бы поставить на поток, нельзя.',
                'false' : '<span class="unsuccessful">Ой, ошибочка!</span> Различные типы морских буровых платформ могут как относиться к какому-либо конкретному виду (стационарные, свободно закрепленные, полупогружные и т.д.), так и быть комбинированными. Выбор производится  с учетом конкретных задач, выполнение которых платформа должна обеспечивать, а также с учетом особенностей конкретного месторождения, поэтому говорить о существовании каких-либо типовых платформ, производство которых можно было бы поставить на поток, нельзя.',
            },
            5 : {
                'question' : '<small>Нефтяная полупогружная платформа может оставаться в одной и той же точке в течение нескольких лет, и все это время она должна выдерживать переменчивые морские погодные условия. Что обеспечивает ее стабилизацию в заданном месте посреди моря или океана?</small>',
                'answers' : {
                    0 : 'Нефтяная платформа стоит на якоре',
                    1 : 'Стабилизацию обеспечивают не только сваи и якоря, но и применение передовых систем позиционирования, управляющих положением платформы, активируя двигатели на основе информации, полученной от гирокомпаса, датчика ветра, а также заданного положения',
                    2 : 'Платформы – не суда, они стоят на дне и не зависят от погодных условий',
                },
                'right_answer' : 1,
                'true' : '<span class="successful">Все верно!</span> Стабилизацию современных нефтяных платформ в заданном месте в настоящее время обеспечивают не только сваи и якоря, но и применение передовых технологий позиционирования. Платформа может оставаться заякоренной в одной и той же точке в течение нескольких лет, и все это время она должна выдерживать переменчивые  морские погодные условия.',
                'false' : '<span class="unsuccessful">Увы, нет!</span> Стабилизацию современных нефтяных платформ в заданном месте в настоящее время обеспечивают не только сваи и якоря, но и применение передовых технологий позиционирования. Платформа может оставаться заякоренной в одной и той же точке в течение нескольких лет, и все это время она должна выдерживать переменчивые  морские погодные условия.',
            },
            6 : {
                'question' : 'Что представляет собой процесс морского бурения?',
                'answers' : {
                    0 : 'Морское бурение выполняется при помощи райзеров, которые представляют собой опускаемые до самого дна трубные колонны больших диаметров',
                    1 : 'Скважины бурят с берега, а нефть поступает на платформу',
                    2 : 'Нефтяная платформа – это и есть огромный бурильный аппарат',
                },
                'right_answer' : 0,
                'true' : '<span class="successful">Да!</span> Райзер (морская водоотделяющая колонна, морской стояк, marine riser) – представляет собой трубу большого диаметра, составленную из нескольких труб и предназначен для соединения морской буровой установки с подводным противовыбросовым оборудованием или блоком устьевого соединителя для последующего спуска в скважину бурильной и обсадной колонн и других технологических операций.',
                'false' : '<span class="unsuccessful">Нет!</span> Процесс морского бурения выполняется при помощи  райзеров (морская водоотделяющая колонна, морской стояк, marine riser), представляющих собой трубу большого диаметра, составленную из нескольких труб и предназначеных для соединения морской буровой установки с подводным противовыбросовым оборудованием или блоком устьевого соединителя, для последующего спуска в скважину бурильной и обсадной колонн и других технологических операций.',
            },
            7 : {
                'question' : 'Какая нефтяная платформа нужна, чтобы добывать углеводороды на мелководье (при глубине добычи несколько десятков метров)?',
                'answers' : {
                    0 : 'Нужна полупогружная платформа',
                    1 : 'Нужна плавучая установка, оборудованная опорами',
                    2 : 'Нужна платформа стационарного типа, которую можно закрепить на дне при помощи свай или бетонных блоков',
                },
                'right_answer' : 2,
                'true' : '<span class="successful">Верно!</span> Самыми простыми по своей конструкции являются платформы стационарного типа, которые можно использовать при глубине добычи несколько десятков метров, поскольку на таком мелководье есть возможность закрепить стационарную конструкцию при помощи свай или бетонных блоков. А вот когда от места разработки до берега  – сотня или более километров, без использования плавучей нефтяной платформы уже не обойтись.',
                'false' : '<span class="unsuccessful">Неверно!</span> Самыми простыми по своей конструкции являются платформы стационарного типа, и именно их можно использовать при глубине добычи несколько десятков метров, поскольку на таком мелководье есть возможность закрепить стационарную конструкцию при помощи свай или бетонных блоков. А вот когда от места разработки до берега  – сотня или более километров, без использования плавучей нефтяной платформы (и ее разновидностей) уже не обойтись.',
            },
            8 : {
                'question' : 'Какая главная особенность российских месторождений на арктическом шельфе?',
                'answers' : {
                    0 : 'Сложнейшие климатические условия и особенно непредсказуемое ледообразование ',
                    1 : 'Отсутствие береговой инфраструктуры',
                    2 : 'Существенные ветровые и волновые нагрузки',
                    3 : 'Все вышеперечисленное',
                },
                'right_answer' : 3,
                'true' : '<span class="successful">Вы абсолютно правы!</span> Если говорить о сложности освоения шельфа на арктических территориях, принадлежащих России, можно сказать, что она возрастает в направлении с запада на восток. От Баренцева моря, где более теплый климат, сформированный влиянием Гольфстрима, и небольшие глубины, до Чукотского моря, где даже разведка запасов сильно осложняется круглогодичными льдами. Кроме того, освоение арктического шельфа усложняет неразвитость инфраструктуры в некоторых регионах.',
                'false' : '<span class="unsuccessful">На самом деле верны все перечисленные ответы.</span> Если говорить о сложности освоения шельфа на арктических территориях, принадлежащих России, можно сказать, что она возрастает в направлении с запада на восток. От Баренцева моря, где более теплый климат, сформированный влиянием Гольфстрима, и небольшие глубины, до Чукотского моря, где даже разведка запасов сильно осложняется круглогодичными льдами. Кроме того, освоение арктического шельфа усложняет неразвитость инфраструктуры в некоторых регионах.',
            },
            9 : {
                'question' : 'Что такое паковые льды, являющиеся неотъемлемой частью арктического шельфа РФ?',
                'answers' : {
                    0 : 'Крупные свободно плавающие куски льда в океане или море',
                    1 : 'Ледовое образование, возвышающееся над уровнем моря более чем на 2 м и имеющее обычно волнообразную поверхность.',
                    2 : 'Морские льды толщиной не менее 3 метров, просуществовавшие более 2 годовых циклов. Их можно наблюдать в виде бескрайних ледяных полей.',
                },
                'right_answer' : 0,
                'true' : '<span class="successful">Верно!</span> Паковые льды являются уникальным природным явлением, наблюдающимся только в Арктическом регионе. Когда-то этот термин применяли абсолютно ко всем дрейфующим льдам, но после проведения исследований их выделили в отдельную группу. Эти льды дрейфуют в океане и формируются из морской воды, поэтому они плотнее и массивнее обычного льда из-за предельно низкого содержания солей. Даже случайное столкновение с паковыми льдами может нанести немалый вред даже самому современному судну.',
                'false' : '<span class="unsuccessful">Увы, нет!</span> Паковые льды являются уникальным природным явлением, наблюдающимся только в Арктическом регионе. Когда-то этот термин применяли абсолютно ко всем дрейфующим льдам, но после проведения исследований их выделили в отдельную группу. Эти льды дрейфуют в океане и формируются из морской воды, поэтому они плотнее и массивнее обычного льда из-за предельно низкого содержания солей. Даже случайное столкновение с паковыми льдами может нанести немалый вред даже самому современному судну.',
            }
        };

        var results = {
            0 : 'Вам надо бы узнать о добыче на шельфе побольше! Наш проект как раз для этого!',
            1 : 'Вам надо бы узнать о добыче на шельфе побольше! Наш проект как раз для этого!',
            2 : 'Вам надо бы узнать о добыче на шельфе побольше! Наш проект как раз для этого!',
            3 : 'Вы знаете о шельфовой добыче не так уж много, но все можно исправить!',
            4 : 'Вы знаете о шельфовой добыче не так уж много, но все можно исправить!',
            5 : 'Вы знаете о шельфовой добыче не так уж много, но все можно исправить!',
            6 : 'Вы отлично разбираетесь в теме! Продолжайте читать наш проект и станете самым настоящим знатоком в этой области!',
            7 : 'Вы отлично разбираетесь в теме! Продолжайте читать наш проект и станете самым настоящим знатоком в этой области!',
            8 : 'Вы отлично разбираетесь в теме! Продолжайте читать наш проект и станете самым настоящим знатоком в этой области!',
            9 : 'Вы отлично разбираетесь в теме! Продолжайте читать наш проект и станете самым настоящим знатоком в этой области!',
            10 : 'Да вы почти ученый! Вы знаете о добыче нефти на шельфе гораздо больше, чем среднестатистический обыватель! Так держать!',
        }

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        
        let totalQuestion = Object.size(questions),
            currentQuestion = 0,
            mark = 0,
            test2Form = document.querySelector('form#test2Form'),
            test2FormBtn = document.querySelector('#test2FormBtn'),
            formChecks = document.querySelector('.formChecks'),
            submitAnswer = document.querySelector('.submitAnswer'),
            goAhead = document.querySelector('#goAhead'),
            tryAgain = document.querySelector('#tryAgain'),
            testQuestion = document.querySelector('.testQuestion'),
            testAnswer = document.querySelector('.testAnswer'),
            testResults = document.querySelector('.testResults'),
            testContent = document.querySelector('.testContent'),
            startTest = document.querySelector('.testStart'),
            startTestButton = startTest.querySelector('button');

        startTestButton.addEventListener('click', function (e) {
            e.preventDefault();
            startTest.classList.add('hidden');
            testContent.classList.remove('hidden');
            test2Form.classList.remove('hidden');
            goToNextQuestion();
        });

        tryAgain.addEventListener('click', function (e) {
            e.preventDefault();
            window.location.reload(false);
        });

        goAhead.addEventListener('click', function (e) {
            e.preventDefault();
            goToNextQuestion();
        });

        function getRadioVal(form, name) {
            var val;
            // get list of radio buttons with specified name
            var radios = form.elements[name];

            // loop through list of radio buttons
            for (var i=0, len=radios.length; i<len; i++) {
                if ( radios[i].checked ) { // radio checked?
                    val = radios[i].value; // if so, hold its value in val
                    break; // and break out of for loop
                }
            }
            return val; // return value of checked radio or undefined if none checked
        }

        test2Form.addEventListener('submit', function (e) {

            e.preventDefault();

            let answer = getRadioVal(test2Form, 'answerRadios');

            if(answer){

                let rightAnswer = questions[currentQuestion].right_answer;

                formChecks.innerHTML = '';

                if(answer == rightAnswer){
                    mark++;
                    testAnswer.innerHTML =questions[currentQuestion].true;
                } else {
                    testAnswer.innerHTML =questions[currentQuestion].false;
                }
                test2FormBtn.classList.add('hidden');
                goAhead.classList.remove('hidden');
                currentQuestion++;


            } else {

                formChecks.classList.add('highlight');

                setTimeout(function () {
                    formChecks.classList.remove('highlight');
                }, 1000);

                //alert('Пожалуйста, выберите вариант ответа');
            }

        });

        function onRadioChanged(e) {
            //this.classList.add('checked');

            formChecks.querySelectorAll('.formCheck').forEach(function (formCheck) {
                formCheck.classList.remove('checked');
            });

            this.parentNode.classList.add('checked');

        }

        function goToNextQuestion() {

            if(currentQuestion < totalQuestion){

                testQuestion.innerHTML = questions[currentQuestion].question;
                test2Form.querySelector('.current').innerHTML = currentQuestion + 1;
                test2Form.querySelector('.total').innerHTML = totalQuestion;
                goAhead.classList.add('hidden');
                test2FormBtn.classList.remove('hidden');

                let answersCounter = 0;

                formChecks.innerHTML = '';
                testAnswer.innerHTML = '';

                Object.entries(questions[currentQuestion].answers).forEach(function (answer) {

                    let formCheck = document.createElement("div"),
                        formCheckInput = document.createElement("input"),
                        formCheckLabel = document.createElement("label"),
                        check = document.createElement("div");

                    formCheck.className = 'formCheck';

                    formCheckInput.className = 'formCheckInput';
                    formCheckInput.setAttribute('id', 'inputRadios' + answersCounter);
                    formCheckInput.setAttribute('name', 'answerRadios');
                    formCheckInput.setAttribute('type', 'radio');
                    formCheckInput.setAttribute('value', answersCounter);
                    //formCheckInput.setAttribute('required', 'required');
                    formCheckInput.addEventListener('change', onRadioChanged);

                    formCheckLabel.className = 'formCheckLabel';
                    formCheckLabel.setAttribute('for', 'inputRadios' + answersCounter);
                    formCheckLabel.innerHTML = answer[1];

                    check.className = 'check';

                    formCheck.appendChild(formCheckInput);
                    formCheck.appendChild(formCheckLabel);
                    formCheck.appendChild(check);

                    formChecks.appendChild(formCheck);

                    answersCounter++;

                });

            } else {

                testAnswer.innerHTML = '';

                testResults.querySelector('.currentMark').innerHTML = mark;
                testResults.querySelector('.totalMark').innerHTML = totalQuestion;
                testResults.querySelector('.testResultText').innerHTML = results[mark];

                formChecks.classList.add('hidden');
                testQuestion.classList.add('hidden');
                testResults.classList.remove('hidden');

                test2FormBtn.classList.add('hidden');
                goAhead.classList.add('hidden');
                tryAgain.classList.remove('hidden');

            }

        }

    }

    render() {

        return(
            <div id="wrapperTest" className="wrapper">
                    <div className="container">
                        <div className="col-row">
                            <div className="testPage2">
                                <div className="testPage2Wrapper">
                                    <div className="testPage2Content" style={{backgroundImage: 'url(https://shelf.ramir.space/backend/assets/uploads/images/content/' + this.props.data.content.img_url2 + ')'}}>
                                        <div className="testContent hidden">
                                            <h1>{this.props.data.content.title}</h1>
                                            <div className="testQuestion"></div>
                                            <div className="testAnswer"></div>
                                        </div>
                                        <div className="testStart">
                                            <div className="testSection">{this.props.data.section.title}</div>
                                            <div className="testName">{this.props.data.content.title}</div>
                                            <div className="testDescription">{this.props.data.content.description}</div>
                                            <div className="testStart">
                                                <button>Пройти тест</button>
                                                <div className="testTime">Приблизительное время прохождения теста – {this.props.data.content.reading_time} / 10 вопросов</div>
                                            </div>
                                        </div>
                                        <div className="testResults hidden">
                                            <div className="testResultMarks">
                                                <span className="currentMark"></span>/<span className="totalMark"></span>
                                            </div>
                                            <div className="testResultText"></div>
                                            <div className="sectionSocials">
                                                <ul className="shareResult">
                                                    <legend>Поделиться: </legend>
                                                    <li className="facebook">
                                                        <a>
                                                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="_main_page" transform="translate(-1222.000000, -243.000000)">
                                                                        <g id="icons_white-1" transform="translate(1222.000000, 243.000000)">
                                                                            <g id="icons_white">
                                                                                <g id="icons_facebook">
                                                                                    <path d="M12.9834,11.7002 L11.2334,11.7002 L11.2334,14.0332 L12.9834,14.0332 L12.9834,21.0332 L15.9004,21.0332 L15.9004,14.0332 L18.0244,14.0332 L18.2334,11.7002 L15.9004,11.7002 L15.9004,10.7272 C15.9004,10.1702 16.0114,9.9502 16.5504,9.9502 L18.2334,9.9502 L18.2334,7.0332 L16.0114,7.0332 C13.9144,7.0332 12.9834,7.9562 12.9834,9.7252 L12.9834,11.7002 Z" id="Fill-1" fill="#fff"></path>
                                                                                    <path d="M28.499,14.4995 L28.5,14.4995 C28.5,3.3075 24.7,0.4995 14.499,0.4995 C4.302,0.4995 0.501,3.3065 0.5,14.4955 L0.5,14.5045 L0.5,14.5045 C0.501,25.6915 4.301,28.4985 14.495,28.4995 L14.504,28.4995 C24.701,28.4995 28.5,25.6915 28.5,14.4995 L28.499,14.4995 Z" id="Stroke-3" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li className="odnoklassniki">
                                                        <a>
                                                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="_main_page" transform="translate(-1222.000000, -419.000000)">
                                                                        <g id="icons_white-1" transform="translate(1222.000000, 243.000000)">
                                                                            <g id="icons_white">
                                                                                <g id="icons_odnoklassniki" transform="translate(0.000000, 176.000000)">
                                                                                    <path d="M28.499,14.4995 L28.5,14.4995 C28.5,3.3075 24.7,0.4995 14.499,0.4995 C4.302,0.4995 0.501,3.3065 0.5,14.4955 L0.5,14.5045 L0.5,14.5045 C0.501,25.6915 4.301,28.4985 14.495,28.4995 L14.504,28.4995 C24.701,28.4995 28.5,25.6915 28.5,14.4995 L28.499,14.4995 Z" id="Stroke-3" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                    <g id="Group-3" transform="translate(10.000000, 6.000000)" fill="#fff">
                                                                                        <path d="M6.40744783,11.8174391 C7.26188261,11.6208304 8.08527391,11.2830478 8.84436087,10.8070478 C9.41866522,10.4463522 9.59236087,9.68652609 9.23092609,9.11222174 C8.86727391,8.53496087 8.11114348,8.36126522 7.53314348,8.72491739 C5.81540435,9.80478696 3.60392609,9.80478696 1.88323043,8.72491739 C1.30892609,8.36126522 0.5491,8.53496087 0.188404348,9.11222174 C-0.172291304,9.68652609 0.000665217391,10.4463522 0.574969565,10.8070478 C1.33479565,11.2830478 2.15744783,11.6208304 3.01188261,11.8174391 L0.664404348,14.1619609 C0.185447826,14.6409174 0.185447826,15.4207 0.664404348,15.8996565 C0.907578261,16.1398739 1.21875217,16.2610913 1.53362174,16.2610913 C1.8484913,16.2610913 2.16336087,16.1398739 2.40579565,15.8996565 L4.70966522,13.595787 L7.0164913,15.8996565 C7.49618696,16.3823087 8.27227391,16.3823087 8.75492609,15.8996565 C9.23388261,15.4207 9.23388261,14.6409174 8.75492609,14.1619609 L6.40744783,11.8174391 Z M4.70966522,8.39304783 C7.02536087,8.39304783 8.90792609,6.51048261 8.90792609,4.19774348 C8.90792609,1.88278696 7.02536087,0.00022173913 4.70966522,0.00022173913 C2.39396957,0.00022173913 0.51436087,1.88278696 0.51436087,4.19774348 C0.51436087,6.51048261 2.39396957,8.39304783 4.70966522,8.39304783 L4.70966522,8.39304783 Z M4.70966522,2.46004783 C5.66831739,2.46004783 6.4481,3.23983043 6.4481,4.19774348 C6.4481,5.15343913 5.66831739,5.93322174 4.70966522,5.93322174 C3.75101304,5.93322174 2.97196957,5.15343913 2.97196957,4.19774348 C2.97196957,3.23983043 3.75101304,2.46004783 4.70966522,2.46004783 L4.70966522,2.46004783 Z" id="Fill-1"></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li className="instagram">
                                                        <a>
                                                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="_main_page" transform="translate(-1222.000000, -331.000000)">
                                                                        <g id="icons_white-1" transform="translate(1222.000000, 243.000000)">
                                                                            <g id="icons_white">
                                                                                <g id="icons_instagram" transform="translate(0.000000, 88.000000)">
                                                                                    <path d="M28.5,14.5 L28.5,14.5 C28.5,3.3 24.7,0.5 14.5,0.5 C4.3,0.5 0.5,3.3 0.5,14.5 C0.5,25.7 4.3,28.5 14.5,28.5 C24.7,28.5 28.5,25.7 28.5,14.5 Z" id="Stroke-1" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                    <path d="M14.5,18.9004 C12.1,18.9004 10.1,16.9004 10.1,14.5004 C10.1,12.1004 12.1,10.1004 14.5,10.1004 C16.9,10.1004 18.9,12.1004 18.9,14.5004 C18.9,16.9004 16.9,18.9004 14.5,18.9004 M14.5,7.7004 C10.7,7.7004 7.7,10.7004 7.7,14.5004 C7.7,18.2994 10.7,21.2994 14.5,21.2994 C18.3,21.2994 21.3,18.2994 21.3,14.5004 C21.3,10.7004 18.3,7.7004 14.5,7.7004" id="Fill-3" fill="#fff"></path>
                                                                                    <path d="M21.5996,5.7998 C22.4836,5.7998 23.2006,6.5158 23.2006,7.3998 C23.2006,8.2838 22.4836,8.9998 21.5996,8.9998 C20.7166,8.9998 19.9996,8.2838 19.9996,7.3998 C19.9996,6.5158 20.7166,5.7998 21.5996,5.7998" id="Fill-5" fill="#fff"></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li className="twitter">
                                                        <a>
                                                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="_main_page" transform="translate(-1222.000000, -287.000000)">
                                                                        <g id="icons_white-1" transform="translate(1222.000000, 243.000000)">
                                                                            <g id="icons_white">
                                                                                <g id="icons_twitter" transform="translate(0.000000, 44.000000)">
                                                                                    <path d="M21.4209,10.7432 C20.9449,10.9542 20.4349,11.0962 19.8989,11.1602 C20.4449,10.8322 20.8659,10.3132 21.0639,9.6942 C20.5519,9.9972 19.9839,10.2182 19.3799,10.3372 C18.8979,9.8222 18.2079,9.5002 17.4449,9.5002 C15.7339,9.5002 14.4759,11.0972 14.8629,12.7542 C12.6599,12.6442 10.7069,11.5892 9.3999,9.9852 C8.7049,11.1762 9.0399,12.7352 10.2199,13.5242 C9.7859,13.5102 9.3769,13.3912 9.0199,13.1922 C8.9899,14.4202 9.8709,15.5692 11.1459,15.8252 C10.7729,15.9272 10.3639,15.9502 9.9479,15.8702 C10.2849,16.9232 11.2639,17.6892 12.4249,17.7112 C11.3099,18.5852 9.9059,18.9742 8.4999,18.8092 C9.6729,19.5612 11.0669,20.0002 12.5639,20.0002 C17.4849,20.0002 20.2659,15.8442 20.0979,12.1152 C20.6149,11.7412 21.0659,11.2742 21.4209,10.7432" id="Fill-1" fill="#fff"></path>
                                                                                    <path d="M28.499,14.4995 L28.5,14.4995 C28.5,3.3075 24.7,0.4995 14.499,0.4995 C4.302,0.4995 0.501,3.3065 0.5,14.4955 L0.5,14.5045 L0.5,14.5045 C0.501,25.6915 4.301,28.4985 14.495,28.4995 L14.504,28.4995 C24.701,28.4995 28.5,25.6915 28.5,14.4995 L28.499,14.4995 Z" id="Stroke-3" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li className="vk">
                                                        <a>
                                                            <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="_main_page" transform="translate(-1222.000000, -375.000000)">
                                                                        <g id="icons_white-1" transform="translate(1222.000000, 243.000000)">
                                                                            <g id="icons_white">
                                                                                <g id="icons_vk" transform="translate(0.000000, 132.000000)">
                                                                                    <path d="M14.9307,19.1631 C15.2957,19.1631 15.4457,18.9191 15.4407,18.6141 C15.4227,17.4641 15.8687,16.8451 16.6767,17.6511 C17.5697,18.5441 17.7537,19.1631 18.8377,19.1631 L20.7577,19.1631 C21.2427,19.1631 21.4337,19.0071 21.4337,18.7621 C21.4337,18.2441 20.5807,17.3311 19.8587,16.6601 C18.8477,15.7211 18.7997,15.6981 19.6707,14.5681 C20.7517,13.1651 22.1647,11.3671 20.9147,11.3671 L18.5267,11.3671 C18.0627,11.3671 18.0297,11.6271 17.8647,12.0171 C17.2677,13.4251 16.1327,15.2481 15.7017,14.9701 C15.2517,14.6791 15.4577,13.5261 15.4927,11.8131 C15.5007,11.3611 15.4987,11.0501 14.8077,10.8901 C14.4307,10.8031 14.0627,10.7671 13.7227,10.7671 C12.3587,10.7671 11.4177,11.3381 11.9517,11.4381 C12.8947,11.6141 12.8047,13.6531 12.5847,14.5341 C12.2017,16.0671 10.7627,13.3201 10.1637,11.9511 C10.0187,11.6221 9.9747,11.3671 9.4587,11.3671 L7.5057,11.3671 C7.2107,11.3671 7.0337,11.4631 7.0337,11.6761 C7.0337,12.0381 8.8087,15.7081 10.5047,17.5381 C12.1587,19.3231 13.7927,19.1631 14.9307,19.1631" id="Fill-1" fill="#fff"></path>
                                                                                    <path d="M28.499,14.4995 L28.5,14.4995 C28.5,3.3075 24.7,0.4995 14.499,0.4995 C4.302,0.4995 0.501,3.3065 0.5,14.4955 L0.5,14.5045 L0.5,14.5045 C0.501,25.6915 4.301,28.4985 14.495,28.4995 L14.504,28.4995 C24.701,28.4995 28.5,25.6915 28.5,14.4995 L28.499,14.4995 Z" id="Stroke-3" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <form id="test2Form" className="hidden">
                                            <div className="formChecks"></div>
                                            <div className="submitAnswer">
                                                <div className="leftContent">
                                                    <a id="tryAgain" className="hidden" href="#">Ещё раз</a>
                                                    <a id="goAhead" className="hidden" href="#">Дальше</a>
                                                    <button id="test2FormBtn" type="submit">Ответить</button>
                                                </div>
                                                <div className="rightContent">
                                                    <div className="testNumber">
                                                        <span className="current"></span>/<span className="total"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb240"></div>
                        </div>
                    </div>
                </div>
        )

    }

}
